import moment from 'moment'
import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import queryString from 'query-string'
import styled from 'styled-components'
import useAuth from 'services/hooks/useAuth'
import JobLocationInfo from 'components/general/JobLocationInfo'
import checkMark from 'components/forms/assets/checkMark'
import clockIcon from 'containers/JobBoardPage/assets/clockIcon'
import companyLogoPlaceholder from 'containers/JobBoardPage/assets/companyLogoPlaceholder'
import experienceLevelIcon from 'containers/JobBoardPage/assets/experienceLevelIcon'
import bookmarkOutline from 'containers/JobBoardPage/assets/bookmarkOutline'
import bookmarkFilled from 'containers/JobBoardPage/assets/bookmarkFilled'

const JobListing = ({ jobListing, handleSaveJob }) => {
  const { isAuthenticated } = useAuth()
  const {
    id,
    employer: { name: employer_name, company_logo_thumbnail },
    name,
    application_link,
    has_shared_profile,
    geo_locations,
    work_type,
    experience_level,
    is_featured,
    remote,
    created_at,
    is_saved
  } = jobListing
  const history = useHistory()

  const handleScrollPosition = () => {
    const scrollPosition = window.pageYOffset
    const parsedParams = queryString.parse(window.location.search)
    parsedParams.scroll_pos = scrollPosition
    const stringified = queryString.stringify(parsedParams)
    history.push({
      search: stringified
    })
  }

  const handleSave = (event, id) => {
    event.preventDefault()
    handleSaveJob(id, is_saved)
  }

  const handleCompanyLogo = () => {
    return company_logo_thumbnail ? (
      <JobImage backgroundImage={company_logo_thumbnail} />
    ) : (
      companyLogoPlaceholder
    )
  }

  const handleDate = () => {
    const dateCreated = moment(created_at)
    const diffFromToday = moment().diff(dateCreated, 'days')

    if (!diffFromToday) {
      return 'new'
    } else if (diffFromToday <= 7) {
      const dayOrDays = diffFromToday !== 1 ? 'days' : 'day'

      return `${diffFromToday} ${dayOrDays} ago`
    } else {
      return dateCreated.format('MMM D, YYYY')
    }
  }

  const renderPills = () => (
    <>
      {has_shared_profile && application_link && (
        <Pill applied={has_shared_profile}>{checkMark}Applied</Pill>
      )}
      {has_shared_profile && !application_link && (
        <Pill shared>{checkMark}Profile Shared</Pill>
      )}
      {is_featured && <Pill isFeatured={is_featured}>Featured</Pill>}
      <Pill date>{handleDate()}</Pill>
    </>
  )

  return (
    <JobListingCard
      $isFeatured={is_featured}
      onClick={handleScrollPosition}
      to={{ pathname: `/jobs/${id}`, state: { fromJobs: true } }}
    >
      <JobInfoWrapper>
        {isAuthenticated && (
          <SaveButton
            onClick={event => {
              handleSave(event, id)
            }}
          >
            {is_saved ? bookmarkFilled : bookmarkOutline}
            <SaveButtonTooltip>
              {is_saved ? 'Unsave' : 'Save this job for later'}
            </SaveButtonTooltip>
          </SaveButton>
        )}

        <LogoContainer
          companyLogo={company_logo_thumbnail}
          isFeatured={is_featured}
        >
          {handleCompanyLogo()}
        </LogoContainer>
        <JobInfo>
          <p>{employer_name}</p>
          <p>
            <b>{name}</b>
          </p>
          <JobInfoDetail>
            <JobLocationInfo geoLocations={geo_locations} remote={remote} />
            <EmploymentType>
              {clockIcon}
              {work_type}
            </EmploymentType>
            {experience_level && (
              <ExperienceLevel>
                {experienceLevelIcon}
                {experience_level}
              </ExperienceLevel>
            )}
          </JobInfoDetail>
        </JobInfo>
      </JobInfoWrapper>
      <JobCardPills>{renderPills()}</JobCardPills>
    </JobListingCard>
  )
}

export default JobListing

const JobListingCard = styled(Link)`
  align-items: center;
  background: ${({ $isFeatured, theme }) =>
    $isFeatured && theme.color.bg.featuredYellow};
  border: ${({ $isFeatured, theme }) =>
    !$isFeatured && `1px solid ${theme.color.bg.liteGray};`};
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  height: 139px;
  justify-content: space-between;
  margin: 16px 0 0;
  padding: 0 45px 0 24px;
  position: relative;
  text-transform: none;
  transition: 0.2s;
  :hover {
    box-shadow: 0px 2px 5px 0px rgba(42, 42, 59, 0.2);
  }
`

const JobInfoDetail = styled.div`
  display: flex;
`

const JobInfoWrapper = styled(JobInfoDetail)`
  align-items: center;
`

const JobCardPills = styled(JobInfoWrapper)``

const JobImage = styled.div`
  background: ${({ backgroundImage }) =>
    backgroundImage && `url("${backgroundImage}") center center no-repeat`};
  background-color: ${({ theme }) => theme.color.bg.white};
  background-size: contain;
  border-radius: 4px;
  height: 70px;
  width: 70px;
`

const LogoContainer = styled.div`
  border: ${({ companyLogo, isFeatured, theme }) =>
    !companyLogo && !isFeatured && `1px solid ${theme.color.border.gray}`};
  border-radius: 4px;
`

const JobInfo = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 16px;
  margin: 0 0 0 10px;
`

const EmploymentType = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.color.text.subText};
  display: flex;
  font-size: 14px;
  svg {
    margin-right: 5px;
  }
`

const ExperienceLevel = styled(EmploymentType)`
  margin-left: 16px;
`

const Pill = styled.div`
  align-items: center;
  color: ${({ date, theme }) => date && theme.color.text.tag};
  display: flex;
  border-radius: 14px;
  font-size: 12px;
  font-weight: bold;
  padding: 4px 16px;
  margin: 0 4px 0 4px;
  min-width: 97px;
  justify-content: center;
  ${({ isFeatured, theme }) =>
    isFeatured && `background: ${theme.color.primary.yellow4};`};
  ${({ shared, applied, theme }) =>
    (shared || applied) && `background: ${theme.color.bg.liteGreen};`};
  svg {
    margin-right: 6px;
  }
`

const SaveButton = styled.div`
  align-items: center;
  border-radius: 16px;
  cursor: pointer;
  display: flex;
  height: 32px;
  justify-content: center;
  margin-right: 8px;
  margin-top: 8px;
  position: absolute;
  right: 0;
  top: 0;
  width: 32px;
  :hover {
    background: rgba(55, 52, 64, 0.1);
  }
  :hover div {
    display: block;
  }
`

const SaveButtonTooltip = styled.div`
  background-color: ${({ theme }) => theme.color.bg.dark};
  border-radius: 4px;
  bottom: 35px;
  color: ${({ theme }) => theme.color.text.lite};
  display: none;
  font-size: 12px;
  font-style: normal;
  padding-bottom: 5px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  position: absolute;
  text-decoration: none;
  width: max-content;
`
