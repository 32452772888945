import React from 'react'

const rocketShip = (
  <svg width="107px" height="114px" viewBox="0 0 107 114" version="1.1">
    <g
      id="Engineer-files"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="projects-page_initial"
        transform="translate(-670.000000, -500.000000)"
      >
        <g id="3064647" transform="translate(665.000000, 501.000000)">
          <g
            id="Group-28-Copy"
            transform="translate(12.471337, 62.117431) rotate(-69.000000) translate(-12.471337, -62.117431) translate(1.971337, 53.117431)"
          >
            <path
              d="M0.583829859,13.4878106 C0.583829859,9.41937617 3.88892338,8.01604306 7.6609559,8.01604306 C11.4329884,8.01604306 14.2423664,9.41937617 14.2423664,13.4878106 C14.2423664,15.6043172 10.7713475,17.5770187 7.36934596,17.5770187 C3.96734441,17.5770187 0.583829859,15.4397385 0.583829859,13.4878106 Z"
              id="Fill-3"
              fill="#CA8DB2"
            ></path>
            <path
              d="M8.88377542,14.4666799 C6.71828838,14.6558896 0.282177585,14.8461458 3.54871183,9.20017856 C6.72902704,3.70323465 17.7347706,1.68976221 18.9010035,5.76006079 C19.4873697,7.8065521 17.9396206,9.7441546 17.3145195,10.3110564"
              id="Fill-3-Copy"
              stroke="#373440"
              strokeWidth="3"
              strokeLinecap="square"
              strokeLinejoin="round"
              transform="translate(10.836110, 9.071497) rotate(-330.000000) translate(-10.836110, -9.071497) "
            ></path>
          </g>
          <g id="Group-6" transform="translate(95.000000, 41.000000)">
            <path
              d="M4.58348282,7.8281826 C6.07743156,5.11190048 9.04680443,5.10179615 10.5222286,7.80797393 C11.9976527,10.5141517 11.9791281,15.9366116 10.4851793,18.6528937 C8.99123061,21.3691758 6.02185774,21.3792802 4.5464336,18.6731024 C3.07100947,15.9669246 3.08953408,10.5444647 4.58348282,7.8281826 Z"
              id="Path"
              fill="#79A0D8"
              fillRule="nonzero"
              transform="translate(7.534331, 13.240538) rotate(-330.000000) translate(-7.534331, -13.240538) "
            ></path>
            <path
              d="M1.36363636,11.0691621 C5.4232343,2.82562328 9.03587824,-0.813506568 12.2015682,0.151772532 C16.9501031,1.59969118 15.2972611,11.0691621 9.93633313,13.5294118"
              id="Path-2"
              stroke="#373440"
              strokeWidth="3"
            ></path>
          </g>
          <path
            d="M81,80 L81,75.5855758 C81,73.048 80.13912,70.6109697 78.60912,68.8155152 L68.76,57.2575758 L68.76,26.950303 C68.76,22.5502424 67.47072,18.2890303 65.10432,14.8752727 L55.5,1 L45.89568,14.8752727 C43.53336,18.2890303 42.24,22.5550303 42.24,26.950303 L42.24,59.5137778 L33.3112098,69.4986977 C31.7812098,71.2941522 30,73.048 30,75.5855758 L30,80 L81,80 Z"
            id="Path"
            fill="#AAA2C2"
            fillRule="nonzero"
          ></path>
          <path
            d="M49.7894626,57.511856 L49.7894626,27.5434452 C49.7894626,23.1925811 50.9479934,18.9790131 53.0744106,15.6034243 L57,8.03304623 L55.791852,1 L45.2836578,15.6034243 C43.1609068,18.9790131 41.9987098,23.1973155 41.9987098,27.5434452 L41.9987098,59.3982673 L33.1484147,68.9405675 C31.7735759,70.7159473 31,73.1257295 31,75.6349329 L31,80 L38.7907528,80 L38.7907528,75.6349329 C38.7907528,73.1257295 39.5643287,70.7159473 40.9391674,68.9405675 L49.7894626,57.511856 Z"
            id="Path"
            fill="#FFFFFF"
            fillRule="nonzero"
          ></path>
          <polygon
            id="Path"
            fill="#373440"
            fillRule="nonzero"
            points="72 80 75 80 75 90 72 90"
          ></polygon>
          <polygon
            id="Path"
            fill="#373440"
            fillRule="nonzero"
            points="36 80 39 80 39 90 36 90"
          ></polygon>
          <path
            d="M43,110 C46.9101557,106.666667 51.0768223,105 55.5,105 C59.9231777,105 64.0898443,106.666667 68,110"
            id="Line-7"
            stroke="#373440"
            strokeWidth="3"
            strokeLinecap="square"
          ></path>
          <path
            d="M11,93 C12.5808436,93.009245 14.1219834,93.3892265 15.6234193,94.1399445 C17.1248553,94.8906624 18.2503821,95.8440143 19,97"
            id="Line-4"
            stroke="#373440"
            strokeWidth="3"
            strokeLinecap="square"
          ></path>
          <path
            d="M91,93 C92.5808436,93.009245 94.1219834,93.3892265 95.6234193,94.1399445 C97.1248553,94.8906624 98.2503821,95.8440143 99,97"
            id="Line-4-Copy-2"
            stroke="#373440"
            strokeWidth="3"
            strokeLinecap="square"
            transform="translate(95.000000, 95.000000) scale(-1, 1) translate(-95.000000, -95.000000) "
          ></path>
          <path
            d="M17,110 C20.7212668,103.969626 25.5546001,100.664804 31.5,100.085535 C37.4453999,99.5062653 42.2787332,101.869249 46,107.174485"
            id="Line-5"
            stroke="#373440"
            strokeWidth="3"
            strokeLinecap="square"
          ></path>
          <path
            d="M64,110 C67.7212668,103.969626 72.5546001,100.664804 78.5,100.085535 C84.4453999,99.5062653 89.2787332,101.869249 93,107.174485"
            id="Line-5-Copy"
            stroke="#373440"
            strokeWidth="3"
            strokeLinecap="square"
            transform="translate(78.500000, 105.000000) scale(-1, 1) translate(-78.500000, -105.000000) "
          ></path>
          <path
            d="M50,35 C51.8333333,33.6666667 53.6666667,33 55.5,33 C57.3333333,33 59.1666667,33.6666667 61,35"
            id="Line"
            stroke="#373440"
            strokeWidth="3"
            strokeLinecap="square"
          ></path>
          <path
            d="M68.6820357,28.1465072 L68.6820357,21.4267489 C68.6820357,19.1027532 67.9459835,16.838467 66.579461,14.9586871 L57.3347367,2.24170237 C56.6852436,1.34826377 55.4344503,1.15050686 54.5410117,1.79999993 C54.3677691,1.9259402 54.2158809,2.07889084 54.0911513,2.25300713 L44.9751246,14.9785221 C43.6369918,16.8464883 42.917419,19.0866051 42.917419,21.3844078 L42.917419,59.0613324 C42.917419,59.5637258 42.7283421,60.0477181 42.3877854,60.4170693 L32.324084,71.3316707 C31.4726924,72.2550487 31,73.4650295 31,74.7210131 L31,79 C31,79.5522847 31.4477153,80 32,80 L80,80 C80.5522847,80 81,79.5522847 81,79 L81,74.7686947 C81,73.4842238 80.5056745,72.2490196 79.6195161,71.319187 L69.2342293,60.4220628 C68.8797659,60.0501298 68.6820357,59.5560481 68.6820357,59.0422597 L68.6820357,38.6138995 L68.6820357,38.6138995"
            id="Path-3"
            stroke="#373440"
            strokeWidth="3"
          ></path>
        </g>
      </g>
    </g>
  </svg>
)

const starGroup = (
  <svg
    width="64px"
    height="64px"
    viewBox="0 0 64 64"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      id="Engineer-files"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="homepage_first-view"
        transform="translate(-1070.000000, -457.000000)"
      >
        <g id="Group-22" transform="translate(152.000000, 393.000000)">
          <g id="Group-20" transform="translate(767.000000, 37.000000)">
            <g id="Group-12" transform="translate(151.000000, 27.000000)">
              <g id="Rectangle"></g>
              <g id="Group-11-Copy" mask="url(#mask-2)">
                <g transform="translate(48.959234, 19.247643) rotate(-39.000000) translate(-48.959234, -19.247643) translate(37.959234, 8.247643)">
                  <path
                    d="M11.6039837,19.1023613 L7.05320971,20.6904169 C6.47522363,20.8921131 5.84316622,20.58707 5.64146998,20.0090839 C5.59769341,19.8836366 5.57682786,19.7513405 5.5798691,19.6185092 L5.61647489,18.0196848 L5.68968647,14.8220361 L5.68968647,14.8220361 L3.5583651,12.0357819 C2.88726382,11.1584583 3.05444012,9.9032118 3.93176381,9.23211053 C4.12434901,9.08479411 4.34211711,8.97372506 4.57444142,8.90432333 L7.948747,7.89632443 L7.948747,7.89632443 L9.96164058,4.99990803 C10.5919979,4.09286697 11.8383055,3.86857044 12.7453465,4.49892771 C12.9408234,4.63477631 13.1104782,4.80443118 13.2463268,4.99990803 L15.2592204,7.89632443 L15.2592204,7.89632443 L18.633526,8.90432333 C19.6918816,9.22048356 20.2935497,10.3347488 19.9773895,11.3931043 C19.9079878,11.6254286 19.7969187,11.8431967 19.6496023,12.0357819 L17.5182809,14.8220361 L17.5182809,14.8220361 L17.5986542,18.3324825 C17.6239372,19.4367626 16.7492372,20.3524544 15.6449571,20.3777374 C15.4052818,20.3832249 15.1665724,20.345576 14.9402207,20.2665874 L11.6039837,19.1023613 L11.6039837,19.1023613 Z"
                    id="Star"
                    stroke="none"
                    fill="#FF9943"
                    fillRule="evenodd"
                  ></path>
                  <path
                    d="M8.72765422,17.9385502 L7.1327278,18.4966678 L6.66886576,18.6589886 C5.62628689,19.0238215 4.48535419,18.4743999 4.12052124,17.431821 C4.04115946,17.2050298 4.00331381,16.9657938 4.00879837,16.7255804 L4.01993978,16.2376073 L4.01993978,16.2376073 L4.05847219,14.5499594 L4.09700461,12.8623114 L1.75380944,9.79056788 C1.08388274,8.91234695 1.25273856,7.65732533 2.13095949,6.98739863 C2.32261951,6.84119605 2.53913157,6.73084624 2.77005096,6.66167258 L6.47496305,5.55183799 L6.47496305,5.55183799 L8.67875592,2.37192193 C9.30793414,1.46406261 10.5539493,1.23814693 11.4618086,1.86732514 C11.6588971,2.00391437 11.8298161,2.17483341 11.9664054,2.37192193 L14.1701982,5.55183799 L14.1701982,5.55183799 L17.8751103,6.66167258 C18.9332248,6.97863881 19.5340441,8.09336195 19.2170779,9.1514764 C19.1479042,9.38239579 19.0375544,9.59890786 18.8913519,9.79056788 L16.5481567,12.8623114 L16.5481567,12.8623114 L16.5866891,14.5499594 L16.6252215,16.2376073 L16.6363629,16.7255804 C16.6615759,17.8298621 15.7868179,18.7454985 14.6825362,18.7707114 C14.4423228,18.776196 14.2030868,18.7383503 13.9762955,18.6589886 L13.5124335,18.4966678 L13.5124335,18.4966678"
                    id="Star-Copy"
                    stroke="#373440"
                    strokeWidth="3"
                    fill="none"
                  ></path>
                </g>
              </g>
              <g id="Group-11" mask="url(#mask-2)">
                <g transform="translate(14.094409, 28.903226)">
                  <path
                    d="M20.9701071,31.3368234 L12.8108662,34.1654938 C11.7805341,34.5226925 10.6557181,33.97701 10.2985194,32.9466778 C10.2214017,32.7242335 10.1846881,32.4897901 10.1901125,32.2544197 L10.2556176,29.4120653 L10.3866278,23.7273564 L10.3866278,23.7273564 L5.19924965,16.9902741 C4.52537315,16.1150802 4.68857311,14.8593106 5.56376695,14.1854341 C5.75852689,14.035474 5.97926195,13.9227006 6.21490731,13.8527669 L14.4291572,11.4149801 L14.4291572,11.4149801 L19.3312831,4.40725914 C19.9644279,3.50216165 21.2114196,3.2817004 22.1165171,3.91484517 C22.3081876,4.04892483 22.4748514,4.21558867 22.6089311,4.40725914 L27.511057,11.4149801 L27.511057,11.4149801 L35.7253068,13.8527669 C36.7842277,14.1670285 37.3878933,15.2802128 37.0736317,16.3391337 C37.003698,16.5747791 36.8909246,16.7955141 36.7409645,16.9902741 L31.5535863,23.7273564 L31.5535863,23.7273564 L31.7492561,32.2177299 C31.7747053,33.3220062 30.9001432,34.2378296 29.7958669,34.2632789 C29.5574584,34.2687733 29.3199887,34.2315857 29.094673,34.1534726 L20.9701071,31.3368234 L20.9701071,31.3368234 Z"
                    id="Star"
                    stroke="none"
                    fill="#FFC043"
                    fillRule="evenodd"
                  ></path>
                  <path
                    d="M15.5983407,31.1564292 L12.8072194,32.1257915 L9.94687463,33.1191953 C8.90344274,33.4815814 7.7638021,32.9294847 7.401416,31.8860528 C7.32306561,31.6604556 7.28575308,31.4226494 7.29124557,31.183897 L7.3598404,28.2021601 L7.3598404,28.2021601 L7.42727213,25.2709821 L7.49470385,22.339804 L2.09791964,15.3182405 C1.42479802,14.4424659 1.58908068,13.1868375 2.46485523,12.5137158 C2.6590252,12.3644765 2.87895333,12.2521674 3.11369512,12.1823774 L11.6561311,9.64266599 L11.6561311,9.64266599 L16.7496781,2.348301 C17.3820644,1.44267342 18.6288711,1.22116761 19.5344986,1.85355393 C19.7272042,1.98811741 19.8946822,2.15559539 20.0292457,2.348301 L25.1227927,9.64266599 L25.1227927,9.64266599 L33.6652287,12.1823774 C34.7239964,12.4971547 35.3271199,13.6106328 35.0123426,14.6694006 C34.9425526,14.9041424 34.8302435,15.1240705 34.6810042,15.3182405 L29.28422,22.339804 L29.28422,22.339804 L29.3516517,25.2709821 L29.4190834,28.2021601 L29.4876783,31.183897 C29.5130821,32.2881743 28.6384824,33.2039619 27.5342051,33.2293657 C27.2954527,33.2348582 27.0576464,33.1975457 26.8320492,33.1191953 L23.9717044,32.1257915 L23.9717044,32.1257915"
                    id="Star-Copy"
                    stroke="#373440"
                    strokeWidth="3"
                    fill="none"
                  ></path>
                  <path
                    d="M17.8733329,30.8610283 C18.4110965,30.7099421 19.4433546,30.4369985 20.9701071,30.0421975"
                    id="Path-3"
                    stroke="#373440"
                    strokeWidth="2"
                    fill="none"
                  ></path>
                </g>
              </g>
              <g id="Group-28" mask="url(#mask-2)">
                <g transform="translate(57.736766, 51.275041) rotate(-240.000000) translate(-57.736766, -51.275041) translate(52.736766, 45.275041)">
                  <path
                    d="M9.29032258,7.74193548 C9.29032258,9.73746237 7.67294624,11.3548387 5.67741935,11.3548387 C3.68189247,11.3548387 2.06451613,9.73746237 2.06451613,7.74193548 C2.06451613,5.7464086 3.68189247,4.12903226 5.67741935,4.12903226 C7.67294624,4.12903226 9.29032258,5.7464086 9.29032258,7.74193548"
                    id="Fill-3"
                    stroke="none"
                    fill="#FFD943"
                    fillRule="evenodd"
                  ></path>
                  <path
                    d="M2.63309944,7.18094755 C2.07398456,6.6355146 1.72916041,5.88107835 1.7310916,5.04686985 C1.73497839,3.37845286 3.12444098,2.02327391 4.8348881,2.01965723 C6.54533522,2.016055 7.9285105,3.36538302 7.92463998,5.03380001 C7.9236797,5.44336869 7.83922936,5.83406098 7.68702981,6.1905165 C7.52922967,6.56008872 7.29860258,6.89285815 7.01270084,7.17170554"
                    id="Fill-3-Copy"
                    stroke="#373440"
                    strokeWidth="3"
                    fill="none"
                    strokeLinecap="square"
                    strokeLinejoin="round"
                    transform="translate(4.827866, 4.600302) rotate(-330.000000) translate(-4.827866, -4.600302) "
                  ></path>
                </g>
              </g>
              <g id="Group-28-Copy-2" mask="url(#mask-2)">
                <g transform="translate(0.000000, 22.741935)">
                  <path
                    d="M11.3548387,6.70967742 C11.3548387,8.7052043 9.73746237,10.3225806 7.74193548,10.3225806 C5.7464086,10.3225806 4.12903226,8.7052043 4.12903226,6.70967742 C4.12903226,4.71415054 5.7464086,3.09677419 7.74193548,3.09677419 C9.73746237,3.09677419 11.3548387,4.71415054 11.3548387,6.70967742"
                    id="Fill-3"
                    stroke="none"
                    fillOpacity="0.699491914"
                    fill="#FF9F43"
                    fillRule="evenodd"
                  ></path>
                  <path
                    d="M2.59792217,7.89826889 C1.94562148,7.24374935 1.54332663,6.33842585 1.54557968,5.33737565 C1.55011428,3.33527525 3.17115397,1.70906051 5.16667561,1.7047205 C7.16219724,1.70039782 8.77590174,3.31959145 8.77138614,5.32169184 C8.77026581,5.81317425 8.67174041,6.282005 8.49417426,6.70975162 C8.31007411,7.15323829 8.04100916,7.5525616 7.70745713,7.88717848"
                    id="Fill-3-Copy"
                    stroke="#373440"
                    strokeWidth="3"
                    fill="none"
                    strokeLinecap="square"
                    strokeLinejoin="round"
                    transform="translate(5.158483, 4.801495) rotate(-421.000000) translate(-5.158483, -4.801495) "
                  ></path>
                </g>
              </g>
              <g id="Group-28-Copy" mask="url(#mask-2)">
                <g transform="translate(17.446556, 6.631999) rotate(-69.000000) translate(-17.446556, -6.631999) translate(11.446556, 0.631999)">
                  <path
                    d="M1.16938235,8.18240169 C1.16938235,5.71869863 3.1676693,4.86888867 5.44827145,4.86888867 C7.7288736,4.86888867 9.42744686,5.71869863 9.42744686,8.18240169 C9.42744686,9.46408479 7.32884003,10.6586845 5.27196167,10.6586845 C3.21508331,10.6586845 1.16938235,9.36442166 1.16938235,8.18240169 Z"
                    id="Fill-3"
                    stroke="none"
                    fill="#FF9343"
                    fillRule="evenodd"
                  ></path>
                  <path
                    d="M5.22892413,9.21110682 C4.04420561,9.3234218 0.523067195,9.43635799 2.31015875,6.08490856 C4.05008064,2.82191947 10.0712241,1.62672106 10.7092596,4.04285266 C11.0300553,5.25765109 10.1832957,6.40781305 9.8413085,6.74432629"
                    id="Fill-3-Copy"
                    stroke="#373440"
                    strokeWidth="3"
                    fill="none"
                    strokeLinecap="square"
                    strokeLinejoin="round"
                    transform="translate(6.297029, 6.008523) rotate(-330.000000) translate(-6.297029, -6.008523) "
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export { rocketShip, starGroup }
