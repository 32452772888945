import styled from 'styled-components'

const TitleH4 = styled.h4`
  margin: 0;
  padding: 0;
  margin-bottom: 16px;
  font-family: ${({ theme }) => theme.font.headFamily};
  font-size: 48px;
  font-weight: normal;
  letter-spacing: -0.5px;
  line-height: 56px;
  text-align: center;
  color: ${({ theme }) => theme.color.text.black};
`

export default TitleH4
