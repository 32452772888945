import { useState } from 'react'
import { apiConfig } from 'services/requests/apiConfig'
import { requests } from 'services/requests/requests'

const requestTypes = {
  WILL_REQUEST: 'willRequest',
  REQUESTING: 'requesting',
  REQUESTED: 'requested'
}

const useApi = () => {
  const [requestState, setRequestState] = useState(requestTypes.WILL_REQUEST)

  const requestActions = {
    willRequest: () => {
      setRequestState(requestTypes.WILL_REQUEST)
    },
    requesting: () => {
      setRequestState(requestTypes.REQUESTING)
    },
    requested: () => {
      setRequestState(requestTypes.REQUESTED)
    }
  }

  return {
    api: apiConfig(requestActions, requestState),
    requests: endpointConfig => requests(endpointConfig)
  }
}

export default useApi
