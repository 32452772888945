import React, { useState } from 'react'
import moment from 'moment'
import styled from 'styled-components'
import useProfileContext from 'services/context/profileContext/useProfileContext'
import useIsPublic from 'services/hooks/useIsPublic'
import publicationIcon from '../assets/publicationIcon'
import editIcon from '../assets/editIcon'
import addIcon from '../assets/addIcon'
import hookIcon from '../assets/hookIcon'

const Publication = ({ publication }) => {
  const { profileDispatchActions } = useProfileContext()
  const { isAuthenticated, isPublic } = useIsPublic()
  const [showEditButton, setShowEditButton] = useState(false)
  const [publicationId, setPublicationId] = useState(-1)

  const handleSetUpdateForm = publication => {
    profileDispatchActions.setUpdateForm(publication, 'publications')
  }

  return (
    <ProfileCardItem
      onMouseOver={() => {
        setShowEditButton(true)
        setPublicationId(publication.id)
      }}
      onMouseLeave={() => {
        setShowEditButton(false)
        setPublicationId(-1)
      }}
    >
      <ProfileCardItemIcon>{publicationIcon}</ProfileCardItemIcon>
      <ProfileCardItemContent>
        <Title>
          <p>{publication.title}</p>
          {publication.link && (
            <a href={publication.link} target="blank">
              {hookIcon}
            </a>
          )}
        </Title>
        <Authors>
          {publication.authors &&
            publication.authors.map((author, index) => (
              <span key={`author-${index}`}>{author}</span>
            ))}
        </Authors>
        <Publisher>
          {publication.publisher && publication.publisher + ', '}
          {moment(new Date(publication.year, publication.month - 1)).format(
            'MMM YYYY'
          )}
        </Publisher>
        <Description>{publication.description}</Description>
      </ProfileCardItemContent>
      {isAuthenticated &&
        !isPublic &&
        showEditButton &&
        publicationId === publication.id && (
          <EditButton onClick={() => handleSetUpdateForm(publication)}>
            {editIcon}
          </EditButton>
        )}
    </ProfileCardItem>
  )
}

const Publications = ({ publications }) => {
  const { profileDispatchActions } = useProfileContext()
  const { isAuthenticated, isPublic } = useIsPublic()

  const handleSetCreateForm = () => {
    profileDispatchActions.setCreateForm('publications')
  }

  if (isPublic && !publications.length) return null

  const renderPublications = () => (
    <ProfileCardBody>
      {publications.map(publication => {
        return (
          <Publication
            key={`publication-${publication.id}`}
            publication={publication}
          />
        )
      })}
    </ProfileCardBody>
  )

  return (
    <ProfileCard>
      <ProfileCardHeader>
        <h6>Publications</h6>
        {isAuthenticated && !isPublic && (
          <AddButton onClick={() => handleSetCreateForm()}>{addIcon}</AddButton>
        )}
      </ProfileCardHeader>
      {publications.length ? renderPublications() : null}
    </ProfileCard>
  )
}

export default Publications

const ProfileCard = styled.div`
  background: ${({ theme }) => theme.color.bg.white};
  border: 1px solid ${({ theme }) => theme.color.border.norm};
  border-radius: 4px;
  display: block;
  height: auto;
  margin: 0 0 16px;
  overflow: hidden;
  padding: 18px 32px;
  width: ${({ width }) => width || 'calc(100% - 32px)'};
  @media screen and (max-width: 1200px) {
    width: 100%;
  }
  @media screen and (max-width: 568px) {
    padding: 16px;
  }
`

const ProfileCardHeader = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`

const ProfileCardBody = styled.div`
  margin-top: 25px;
`

const ProfileCardItem = styled.div`
  display: flex;
  margin-bottom: 16px;
  position: relative;
  &:last-child {
    margin-bottom: 0;
  }
  &:last-of-type {
    div:nth-child(2) {
      border: none;
    }
  }
`

const ProfileCardItemIcon = styled.div`
  margin-right: 22px;
`

const ProfileCardItemContent = styled.div`
  border-bottom: 1px solid #f2f2f2;
  padding-bottom: 16px;
  width: 100%;
`

const Title = styled.div`
  color: rgb(44, 44, 44);
  font-size: 16px;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  letter-spacing: 0.5px;
  line-height: 20px;
  margin-bottom: 3px;
  padding-right: 8px;
  width: 100%;
  p {
    display: inline;
    font-weight: ${({ theme }) => theme.font.weight.bold};
    margin-right: 5px;
  }
  a {
    margin-left: 5px;
  }
`

const Authors = styled(Title)`
  span {
    font-weight: ${({ theme }) => theme.font.weight.regular};
    margin-right: 3px;
    :after {
      content: ', ';
    }
  }
  span:last-of-type {
    :after {
      content: '';
    }
  }
`

const Publisher = styled.div`
  color: rgb(135, 133, 140);
  font-size: 14px;
  font-weight: ${({ theme }) => theme.font.weight.regular};
  letter-spacing: 0.25px;
  line-height: 17px;
`

const Description = styled.div`
  color: rgb(55, 52, 64);
  font-size: 16px;
  font-weight: ${({ theme }) => theme.font.weight.regular};
  letter-spacing: 0.5px;
  line-height: 20px;
  margin-top: 10px;
`

const ActionButton = styled.button`
  align-items: center;
  background: none;
  border: none;
  border-radius: 100%;
  cursor: pointer;
  display: flex;
  height: 32px;
  justify-content: center;
  padding: 7px;
  transition: all 300ms ease-out 0s;
  width: 32px;
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
`

const AddButton = styled(ActionButton)``

const EditButton = styled(ActionButton)`
  position: absolute;
  right: 0px;
  top: 0px;
`
