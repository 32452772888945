import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { IconAction } from 'components/styled/Buttons'
import useAppContext from 'services/context/appContext/useAppContext'
import editIcon from 'containers/ProfilePage/assets/editIcon'
import addIcon from 'containers/ProfilePage/assets/addIcon'

const ExpandableContent = ({
  header,
  children,
  addFormAction,
  actionType,
  hasContent
}) => {
  const { state } = useAppContext()
  const { publicProfileData } = state

  const handleIcon = () => {
    if (actionType === 'add') return addIcon
    if (actionType === 'edit') return editIcon
  }

  return (
    <ExpandableContentWrapper>
      <ContentHeaderContainer>
        <h6>{header}</h6>
        {!publicProfileData && actionType && addFormAction && (
          <div onClick={addFormAction}>
            <IconAction>{handleIcon()}</IconAction>
          </div>
        )}
      </ContentHeaderContainer>
      {hasContent && <ContentContainer>{children}</ContentContainer>}
    </ExpandableContentWrapper>
  )
}

ExpandableContent.propTypes = {
  header: PropTypes.string,
  actionType: PropTypes.string,
  addFormAction: PropTypes.func,
  hasContent: PropTypes.bool
}

ExpandableContent.defaultProps = {
  hasContent: false
}

export default ExpandableContent

const ExpandableContentWrapper = styled.div`
  display: block;
  margin: 0 auto;
  padding: 16px;
  position: relative;
  width: 100%;
`

const ContentHeaderContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  padding: 0;
  position: relative;
  width: 100%;
`

const ContentContainer = styled.div`
  padding: 32px 0 8px;
`
