import { createGlobalStyle } from 'styled-components'

/* Base styles contain general styles from style guide
Tooltip-Text is included, need to be able to hover on the parent of the tooltip to see it.
.tooltip-text can be nested inside any of the following elements. When hovering on that element, the tooltip for it would appear. */

export const BaseConnectStyle = createGlobalStyle`
    body {
        background: ${({ theme }) => theme.color.bg.norm};
        overflow-x: hidden;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    th,
    p,
    a,
    button,
    label,
    td,
    li,
    textarea,
    optgroup,
    select,
    input,
    div {
        font-family: ${({ theme }) => theme.font.family};
        margin: 0;
        line-height: normal;
        color: ${({ theme }) => theme.color.text.dark};

        .tooltip-text {
            position: absolute;
            top: -10px;
            left: calc(100% + 10px);
            color: ${({ theme }) => theme.color.text.white};
            font-family: ${({ theme }) => theme.font.family};
            font-size: 10px;
            width: auto;
            text-align: center;
            margin: 0 auto 5px;
            white-space: nowrap;
            background: ${({ theme }) => theme.color.bg.dark};
            padding: 2px 5px;
            transition: all 300ms ease-out;
            visibility: hidden;
            opacity: 0;
        }

        &:hover {
            >.tooltip-text {
                /* only if tooltip text is direct child */
                visibility: visible;
                opacity: 1;
            }
        }
    }

    h1,
    h2,
    h3 {
        font-size: 34px;
        font-weight: ${({ theme }) => theme.font.weight.medium};
        letter-spacing: -1px;

        &.sub {
            font-size: 16px;
            letter-spacing: 0.15px;
            line-height: 24px;
        }
    }

    h4 {
        font-size: 28px;
        font-weight: ${({ theme }) => theme.font.weight.bold};
        color: ${({ theme }) => theme.color.primary.black4};
        letter-spacing: 0.25px;

        &.sub {
            font-size: 14px;
            font-weight: ${({ theme }) => theme.font.weight.medium};
            letter-spacing: 0.1px;
            line-height: 24px;
        }
    }

    th,
    h5 {
        font-size: 24px;
        font-weight: ${({ theme }) => theme.font.weight.medium};
        letter-spacing: 0px;
    }

    
    h6 {
        font-size: 18px;
        font-weight: ${({ theme }) => theme.font.weight.medium};
        letter-spacing: 0.15px;
        text-transform: uppercase;
        color: ${({ theme }) => theme.color.primary.black2};
    }
    .subtitle1 {
        font-size: 16px;
        font-weight: ${({ theme }) => theme.font.weight.medium};
        letter-spacing: 0.15px;
        line-height: 24px;
    }
    .subtitle2 {
        color: ${({ theme }) => theme.color.primary.black4};
        font-size: 14px;
        font-weight: ${({ theme }) => theme.font.weight.medium};
        letter-spacing: 0.1px;
        line-height: 24px;
    }

    p,
    div,
    td,
    span {
        font-size: 16px;
        font-weight: ${({ theme }) => theme.font.weight.regular};
        letter-spacing: 0.5px;
        line-height: 20px;

        b,
        strong {
            font-weight: ${({ theme }) => theme.font.weight.bold};
        }

        &.sub {
            font-size: 14px;
            letter-spacing: 0.25px;
            line-height: 17px;
        }
    }

    label,
    input,
    textarea,
    optgroup,
    select,
    .body2 {
        font-size: 14px;
        font-weight: ${({ theme }) => theme.font.weight.regular};
        letter-spacing: 0.25px;
        color: ${({ theme }) => theme.color.primary.black3};
        line-height: 17px;
        &.hidden {
            display: none;
        }
    }

    input[type=button],
    input[type=submit],
    input[type=reset],
    button,
    .btn,
    optgroup,
    select {
        font-size: 16px;
        font-weight: ${({ theme }) => theme.font.weight.bold};
        text-transform: uppercase;
        letter-spacing: 1px;
        cursor: pointer;
        &.hidden {
            display: none;
        }
    }

    a,
    .strong-link {
        font-size: 16px;
        font-weight: ${({ theme }) => theme.font.weight.medium};
        text-transform: uppercase;
        letter-spacing: 1px;
        cursor: pointer;
        text-decoration: none;
    }

    .tiny {
        font-size: 9px;
        font-family: 'AvenirNextCondensed-Regular',sans-serif;
        font-weight: ${({ theme }) => theme.font.weight.medium};
        text-transform: uppercase;
        letter-spacing: 0px;

        &.sub {
            font-size: 6px;
        }
    }

    .caption,
    .label {
        font-size: 12px;
        font-weight: ${({ theme }) => theme.font.weight.bold};
        letter-spacing: 0.4px;
        line-height: 16px;
    }

    .overline,
    .tooltip-text {
        font-size: 12px;
        font-weight: ${({ theme }) => theme.font.weight.black};
        text-transform: uppercase;
        letter-spacing: 1px;
        line-height: 16px;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      appearance: none;
      margin: 0;
    }
    input[type=number] {
    /* stylelint-disable-next-line */
      -moz-appearance: textfield;
    }

    button {
      outline: 0 !important;
    }
`
