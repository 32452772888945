import styled from 'styled-components'

const Tag = styled.span`
  font-size: 12px;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  letter-spacing: 0.4px;
  line-height: 16px;
  background: ${({ theme }) => theme.color.bg.lite};
  border: 1px solid ${({ theme }) => theme.color.border.tag};
  color: ${({ theme }) => theme.color.text.tag};
  border-radius: 12px;
  padding: 4px 8px 4px;
  margin: 0 8px 8px 0;
  min-width: 16px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  &.expand {
    justify-content: space-between;
    padding: 4px 32px 4px 8px;
  }
  .action {
    position: absolute;
    right: 0;
    padding: 4px 8px;
    font-size: 10px;
    line-height: 15px;
    color: ${({ theme }) => theme.color.primary.black4};
    transition: all 300ms ease-out;
    opacity: 0.5;
    &:hover {
      opacity: 1;
    }
  }
`

export default Tag
