import React from 'react'

const tickIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g stroke="#589678" transform="translate(-550 -98)">
        <g transform="translate(538 88)">
          <g transform="translate(12 6)">
            <g transform="translate(0 4)">
              <rect width="15" height="15" x="0.5" y="0.5" rx="7.5"></rect>
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M5 8.25L7.24807739 10.5 11 6"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default tickIcon
