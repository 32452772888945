import React from 'react'
import styled from 'styled-components'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import Radios from 'components/forms/Radios'
import InputSubmit from 'components/forms/InputSubmit'
import { Container, Divider } from 'components/styled/Containers'
import { SurveyTitle } from 'components/styled/Text'
import { questionnaire } from 'containers/QuestionnairePage/messages/messages'
import useAppContext from 'services/context/appContext/useAppContext'
import useScrollToTop from 'services/hooks/useScrollToTop'
import { generalPreferencesValidation } from 'services/validations/questionnaireValidations'

const GeneralPreferences = () => {
  useScrollToTop()
  const methods = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(generalPreferencesValidation),
    shouldFocusError: true
  })

  const { api, requests, history } = useAppContext()
  const { handleSubmit, formState } = methods

  // Destructure questionnaire data
  const { question1, question2, question3 } = questionnaire.generalPrefs

  const onFailure = error => {
    console.log(error)
  }

  const onSuccess = response => {
    history.push('/questionnaire/employer-preferences')
  }

  // Submit general preferences form
  const onSubmit = async data => {
    data.has_seen_form = true
    api.request(
      requests().createGeneralPreferences,
      data,
      response => {
        onSuccess(response)
      },
      error => onFailure(error)
    )
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <SurveyTitle>General Preferences</SurveyTitle>
        <Radios
          vertical={question1.vertical}
          question={question1.question}
          groupName={question1.groupName}
          options={question1.options}
          required="required"
        />
        <Divider />
        <Radios
          vertical={question2.vertical}
          width={question2.width}
          question={question2.question}
          groupName={question2.groupName}
          options={question2.options}
          required="required"
        />
        <Divider />
        <Radios
          vertical={question3.vertical}
          question={question3.question}
          groupName={question3.groupName}
          options={question3.options}
          required="required"
        />
        <Divider />
        <SubmitBtnContainer>
          <InputSubmit
            background="#FFFFFF"
            color="#373440"
            width="100px"
            border="#363540"
            testId="input-submit"
            text="Save"
            formState={formState}
          />
          <EditMessageText>
            These preferences may be edited later.
          </EditMessageText>
        </SubmitBtnContainer>
      </form>
    </FormProvider>
  )
}

export default GeneralPreferences

const SubmitBtnContainer = styled(Container)`
  align-items: center;
  display: flex;
`

const EditMessageText = styled.p`
  color: #3b3b3b;
  font-size: 14px;
  font-weight: 500;
  margin: 0 0 0 16px;
`
