const appActionTypes = {
  SET_AUTHENTICATION: 'SET_AUTHENTICATION',
  SET_ONBOARDING: 'SET_ONBOARDING',
  SET_PROFILE_DATA: 'SET_PROFILE_DATA',
  SET_PUBLIC_PROFILE_DATA: 'SET_PUBLIC_PROFILE_DATA',
  SET_GENERAL_PREFERENCES_DATA: 'SET_GENERAL_PREFERENCES_DATA',
  SET_EMPLOYER_PREFERENCES_DATA: 'SET_EMPLOYER_PREFERENCES_DATA',
  SET_JOB_BOARD_SETTINGS_DATA: 'SET_JOB_BOARD_SETTINGS_DATA',

  // TODO: Remove extra dispatch actions
  LOGOUT: 'LOGOUT',
  UPDATED_PROFILE: 'UPDATED_PROFILE',
  ZOOM_IN: 'ZOOM_IN',
  ZOOM_OUT: 'ZOOM_OUT',
  RESET_STATE: 'RESET_STATE'
}

export default appActionTypes
