import React from 'react'
import { Switch, Route } from 'react-router-dom'
import AuthPage from 'containers/AuthPage'
import HomePage from 'containers/HomePage'
import HowToShowcasePage from 'containers/HowToShowcasePage'
import JobBoardPage from 'containers/JobBoardPage'
import JobListingPage from 'containers/JobListingPage/JobListingPage'
import Navbar from 'components/nav/Navbar'
import ProfilePage from 'containers/ProfilePage'
import { ProfileProvider } from 'services/context/profileContext/ProfileContext'
import connectTheme from 'styles/themes'
import ProjectPage from 'containers/ProjectPage/ProjectPage'
import NotFoundPage from 'containers/ErrorPage/NotFoundPage'

const UnAuthedApp = () => {
  return (
    <>
      <Navbar indexing={connectTheme.order.navbar} />
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route exact path="/showcase" component={HowToShowcasePage} />
        <Route exact path="/jobs" component={JobBoardPage} />
        <Route path="/jobs/:job" component={JobListingPage} />
        <Route exact path="/profile/:user">
          <ProfileProvider>
            <ProfilePage />
          </ProfileProvider>
        </Route>
        <Route exact path="/profile/:user/projects">
          <ProfileProvider>
            <ProfilePage isProjects />
          </ProfileProvider>
        </Route>
        <Route path="/profile/:user/projects/:project">
          <ProfileProvider>
            <ProjectPage />
          </ProfileProvider>
        </Route>
        <Route path="/not-found-page" component={NotFoundPage} />
        <Route component={AuthPage} />
      </Switch>
    </>
  )
}

export default UnAuthedApp
