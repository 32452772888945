import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useFormContext } from 'react-hook-form'

const InputSubmit = ({
  background,
  border,
  width,
  color,
  text,
  disabled,
  testId
}) => {
  const { formState } = useFormContext()

  const isDisabled = Object.keys(formState.errors).length !== 0

  return (
    <InputStyled
      background={background}
      color={color}
      width={width}
      border={border}
      data-testid={testId}
      disabled={disabled || isDisabled}
      type="submit"
      value={text}
    />
  )
}

InputSubmit.propTypes = {
  background: PropTypes.string,
  color: PropTypes.string,
  width: PropTypes.string,
  text: PropTypes.string,
  disabled: PropTypes.bool,
  testId: PropTypes.string
}

InputSubmit.defaultProps = {
  text: 'Submit',
  disabled: false
}

export default InputSubmit

const InputStyled = styled.input`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ width }) => width || '100%'};
  padding: 9px 30px;
  border-radius: 4px;
  color: ${({ theme, color }) => color || theme.color.primary.black4};
  background: ${({ theme, background }) =>
    background || theme.color.primary.yellow4};
  border: 1px solid
    ${({ theme, border }) => border || theme.color.primary.yellow4};
  transition: all 300ms ease-out;
  font-size: 14px;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;
  &:focus {
    border-color: ${({ theme }) => theme.color.primary.black4};
    outline: 0;
  }
  &:hover {
    background: ${({ theme }) => theme.color.primary.black4};
    border-color: ${({ theme }) => theme.color.primary.black4};
    color: ${({ theme }) => theme.color.text.white};
  }
  &:disabled {
    cursor: not-allowed;
    background: ${({ theme }) => theme.color.bg.disabled};
    color: ${({ theme }) => theme.color.text.disabled};
    border: 1px solid ${({ theme }) => theme.color.bg.disabled};
  }
`
