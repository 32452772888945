import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { FlexContainer } from 'components/styled/Containers'
import tickIcon from 'containers/ProfilePage/assets/tickIcon'

const PopupNotification = ({ content, cleanHandler }) => {
  const [shouldShow, setShouldShow] = useState(true)

  const closePopup = useCallback(() => {
    setShouldShow(false)
    cleanHandler()
  }, [cleanHandler])

  useEffect(() => {
    const timerId = setTimeout(closePopup, 10000)
    return () => {
      clearTimeout(timerId)
    }
  }, [closePopup])

  const renderIcon = () => (
    <TickIcon data-testid="tick-icon">{tickIcon}</TickIcon>
  )

  const renderContent = () => <PopupContent>{content}</PopupContent>

  return (
    shouldShow && (
      <PopupNotificationContainer align="center">
        {renderIcon()}
        {renderContent()}
      </PopupNotificationContainer>
    )
  )
}

export default PopupNotification

PopupNotification.propTypes = {
  content: PropTypes.string.isRequired,
  cleanHandler: PropTypes.func.isRequired
}

const PopupNotificationContainer = styled(FlexContainer)`
  background: #e2f2ea;
  bottom: 20px;
  border-radius: 18px;
  font-weight: 500;
  letter-spacing: 0.15px;
  line-height: 24px;
  padding: 8px 12px;
  position: relative;
  width: fit-content;
`

const TickIcon = styled(FlexContainer)`
  margin-right: 8px;
`

const PopupContent = styled.div`
  color: #589678;
  font-weight: 500;
`
