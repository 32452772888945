import React from 'react'

const warning = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g transform="translate(-280 -937)">
        <g transform="translate(70 910)">
          <g transform="translate(210 27)">
            <circle cx="8" cy="8" r="7.5" stroke="#D54E4C"></circle>
            <path
              fill="#D54E4C"
              fillRule="nonzero"
              d="M7 11.005a1 1 0 112 0 1 1 0 01-2 0zM7.098 5a.905.905 0 111.8 0l-.35 3.507a.553.553 0 01-1.1 0L7.098 5z"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default warning
