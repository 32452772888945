import React from 'react'
import styled from 'styled-components'

const Datafolios = () => (
  <Container>
    <TitleText>TOP TEMPLATES</TitleText>
    <SubTitleText>
      Select one of the templates below to help you get started on your
      datafolio.{' '}
      <SubTitleLink
        href={`${window.location.origin}/showcase`}
        target="_blank"
        rel="noopener noreferrer"
      >
        What's a datafolio?.
      </SubTitleLink>
    </SubTitleText>
    <TemplatesContainer>
      <Template>
        <img
          alt={'Results Driven'}
          src={
            'https://storage.googleapis.com/connect-internal-assets/datafolio-templates/results-driven.png'
          }
          onClick={() =>
            window.open(
              'https://docs.google.com/presentation/d/17YszD4rPhfKejl8JSV28EEmaaoQdkXDLJ3hbOtyUw3g/edit?usp=sharing',
              '_blank'
            )
          }
        ></img>
        <ResultsDrivenTemplate>
          <TemplateCell
            width={'118px'}
            height={'35px'}
            backgroundColor={'#faf1ef'}
          >
            <span>Intro</span>
          </TemplateCell>
          <TemplateCell
            width={'65px'}
            height={'35px'}
            backgroundColor={'#faf1ef'}
          >
            <span>Outline</span>
          </TemplateCell>
          <TemplateCell
            width={'51px'}
            height={'70px'}
            backgroundColor={'#faf1ef'}
          >
            <span>Data</span>
          </TemplateCell>
          <TemplateCell
            width={'132px'}
            height={'70px'}
            backgroundColor={'#faf1ef'}
          >
            <span>Results</span>
          </TemplateCell>
        </ResultsDrivenTemplate>
        <TemplateTitleText>Results Driven</TemplateTitleText>
      </Template>
      <Template>
        <img
          alt={'Data Driven'}
          src={
            'https://storage.googleapis.com/connect-internal-assets/datafolio-templates/data-driven.png'
          }
          onClick={() =>
            window.open(
              'https://docs.google.com/presentation/d/1yeTdteCPs2AdQKzfHtSN_tyEzKBGknqCX3HWUQMaG8s/edit?usp=sharing',
              '_blank'
            )
          }
        ></img>
        <DataDrivenTemplate>
          <TemplateCell
            width={'110px'}
            height={'35px'}
            backgroundColor={'#eff9fa'}
          >
            <span>Intro</span>
          </TemplateCell>
          <TemplateCell
            width={'73px'}
            height={'35px'}
            backgroundColor={'#eff9fa'}
          >
            <span>Outline</span>
          </TemplateCell>
          <TemplateCell
            width={'131px'}
            height={'70px'}
            backgroundColor={'#eff9fa'}
          >
            <span>Data</span>
          </TemplateCell>
          <TemplateCell
            width={'52px'}
            height={'70px'}
            backgroundColor={'#eff9fa'}
          >
            <span>Results</span>
          </TemplateCell>
        </DataDrivenTemplate>
        <TemplateTitleText>Data Driven</TemplateTitleText>
      </Template>
      <Template>
        <img
          alt={'Equal Emphasis'}
          src={
            'https://storage.googleapis.com/connect-internal-assets/datafolio-templates/equal-emphasis.png'
          }
          onClick={() =>
            window.open(
              'https://docs.google.com/presentation/d/1Qk_JUw32kP8mo16F5SjEuMgTh8Z24O3_Zkulo98zgN4/edit?usp=sharing',
              '_blank'
            )
          }
        ></img>
        <EqualEmphasisTemplate>
          <TemplateCell
            width={'124px'}
            height={'35px'}
            backgroundColor={'#f2effa'}
          >
            <span>Intro</span>
          </TemplateCell>
          <TemplateCell
            width={'59px'}
            height={'35px'}
            backgroundColor={'#f2effa'}
          >
            <span>Outline</span>
          </TemplateCell>
          <TemplateCell
            width={'58px'}
            height={'70px'}
            backgroundColor={'#f2effa'}
          >
            <span>Data</span>
          </TemplateCell>
          <TemplateCell
            width={'58px'}
            height={'70px'}
            backgroundColor={'#f2effa'}
          >
            <span>Data</span>
          </TemplateCell>
          <TemplateCell
            width={'58px'}
            height={'70px'}
            backgroundColor={'#f2effa'}
          >
            <span>Results</span>
          </TemplateCell>
        </EqualEmphasisTemplate>
        <TemplateTitleText>Equal Emphasis</TemplateTitleText>
      </Template>
    </TemplatesContainer>
  </Container>
)

export default Datafolios

const Container = styled.div`
  align-items: start;
  display: flex;
  flex-direction: column;
  margin-top: 64px;
`

const TitleText = styled.h1`
  color: ${({ theme }) => theme.color.primary.black2};
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.15px;
  margin-bottom: 23px;
  text-transform: uppercase;
`

const SubTitleText = styled.p`
  color: ${({ theme }) => theme.color.primary.black3};
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0.1px;
  margin-bottom: 24px;
`

const SubTitleLink = styled.a`
  border-bottom: 1px solid ${({ theme }) => theme.color.primary.yellow5};
  color: ${({ theme }) => theme.color.primary.yellow5};
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0.1px;
  text-transform: none;
`

const TemplatesContainer = styled.div`
  display: flex;
  gap: 16px;
`

const Template = styled.div`
  align-items: start;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 207px;
  img {
    display: none;
    height: 129px;
    left: 0;
    position: absolute;
    top: 0;
    width: inherit;
    z-index: ${({ theme }) => theme.order.modal};
  }
  :hover {
    img {
      display: block;
    }
  }
`

const ResultsDrivenTemplate = styled.div`
  background-color: ${({ theme }) => theme.color.bg.white};
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding: 8px;
  span {
    color: #cfaea9;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.1px;
    padding-left: 4px;
  }
`

const DataDrivenTemplate = styled(ResultsDrivenTemplate)`
  span {
    color: #a3c0c3;
  }
`

const EqualEmphasisTemplate = styled(ResultsDrivenTemplate)`
  span {
    color: #b2aacc;
  }
`

const TemplateCell = styled.div`
  align-items: start;
  background-color: ${({ backgroundColor }) => backgroundColor};
  display: flex;
  height: ${({ height }) => height};
  width: ${({ width }) => width};
`

const TemplateTitleText = styled.span`
  color: ${({ theme }) => theme.color.primary.black3};
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0.25px;
  padding: 8px;
  text-align: right;
`
