import { useState, useEffect, useReducer } from 'react'
import useAppContext from 'services/context/appContext/useAppContext'
import {
  profExpIcon,
  educationIcon,
  skillsIcon,
  summaryQuillIcon,
  rocketLaunchIcon
} from 'containers/ProfilePage/assets/onboardingAssets'

const useOnboarding = () => {
  const { state } = useAppContext()
  const { profileData, updatedProfile } = state
  const [notificationQueue, setNotificationQueue] = useState([])
  const [notificationUpdate, setNotificationUpdate] = useState(0)
  const [actionItemsRemaining, setActionItemsRemaining] = useState(0)

  const {
    summary,
    work_experiences,
    skills,
    educations,
    projects,
    resume
  } = profileData

  // Order is set here, simply change order to reflect change
  const initialNotificationContent = {
    work_experiences: {
      hide: false,
      icon: profExpIcon,
      header: 'Add your professional experience(s)',
      body:
        'Please indicate any professional experience(s) you would like employers to see.',
      name: 'work_experiences',
      action: 'add',
      dismissable: true
    },
    educations: {
      hide: false,
      icon: educationIcon,
      header: 'Add your educational experience(s)',
      body: 'Please indicate your educational history on your profile.',
      name: 'educations',
      action: 'add',
      dismissable: true
    },
    skills: {
      hide: false,
      icon: skillsIcon,
      header: 'Add your top skills',
      body: 'Please highlight up to 15 skills that you are proficient in.',
      name: 'skills',
      action: 'add',
      dismissable: true
    },
    summary: {
      hide: false,
      icon: summaryQuillIcon,
      header: 'Add a summary',
      body:
        'The summary is meant to help you emphasize your professional goals and experiences.',
      name: 'summary',
      action: 'add',
      dismissable: true
    },
    projects: {
      hide: false,
      icon: rocketLaunchIcon,
      header: 'Add your first project',
      body:
        'Your projects are a demonstration of your skills. Please include visuals if possible.',
      name: 'projects',
      action: 'add',
      dismissable: true
    }
  }

  const hideContent = (state, key) => {
    let contentState = state
    contentState[key].hide = true
    setNotificationUpdate(notificationUpdate + 1)
    return contentState
  }

  const [notificationContent, dispatchNotifications] = useReducer(
    hideContent,
    initialNotificationContent
  )

  useEffect(() => {
    let remainingActionItems = 0
    let currentQueue = []

    if (!profileData) {
      setNotificationQueue([])
      return
    }

    for (let key in notificationContent) {
      const doesExist = Array.isArray(profileData[key])
        ? profileData[key].length > 0
        : profileData[key]

      if (!doesExist && key !== 'projects') {
        remainingActionItems = remainingActionItems + 1
      }
    }
    setActionItemsRemaining(remainingActionItems)

    for (let key in notificationContent) {
      const doesExist = Array.isArray(profileData[key])
        ? profileData[key].length > 0
        : profileData[key]

      if (!doesExist && !notificationContent[key].hide) {
        currentQueue.push(notificationContent[key])
      }
    }

    if (currentQueue.length) {
      setNotificationQueue(currentQueue)
    } else {
      setNotificationQueue([])
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    notificationUpdate,
    updatedProfile,
    work_experiences,
    educations,
    skills,
    projects,
    summary,
    resume
  ])

  return {
    notification: notificationQueue[0],
    notificationQueue: notificationQueue,
    totalNotifications: Object.keys(initialNotificationContent).length,
    actionItemsRemaining,
    dispatchNotifications
  }
}

export default useOnboarding
