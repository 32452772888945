import React, { useRef } from 'react'
import styled from 'styled-components'
import Modal from 'components/styled/Modal'
import {
  FlexContainer,
  Container,
  AbsoluteContainer
} from 'components/styled/Containers'
import { Button } from 'components/styled/Buttons'
import useClickedOutsideEvent from 'services/hooks/useClickedOutsideEvent'
import close from 'containers/ProfilePage/assets/close'

const SaveAsDraftModal = ({ toggleSaveModal, isExistingProject, onSubmit }) => {
  const modalRef = useRef(null)
  useClickedOutsideEvent(modalRef, toggleSaveModal)

  const handleCloseModal = () => {
    toggleSaveModal(prevState => !prevState)
  }

  const handleModalMessage = () =>
    isExistingProject ? (
      <p>
        This project will be hidden from every part of your profile (featured
        lists, experience, education)
      </p>
    ) : (
      <p>
        Save as a draft to be published later. Draft projects are hidden from
        your public profile.
      </p>
    )

  return (
    <ExtendModal>
      <ModalPopupContainer ref={modalRef}>
        <ExtendAbsoluteContainer>
          <CloseSVG onClick={toggleSaveModal}>{close}</CloseSVG>
        </ExtendAbsoluteContainer>
        <h6>Save as Draft</h6>
        <Container margin="8px 0 32px 0">{handleModalMessage()}</Container>
        <FlexContainer align="center">
          <CopyButton
            onClick={() => {
              toggleSaveModal()
              onSubmit()
            }}
          >
            Save as draft
          </CopyButton>
          <CancelButton onClick={handleCloseModal}>Cancel</CancelButton>
        </FlexContainer>
      </ModalPopupContainer>
    </ExtendModal>
  )
}

export default SaveAsDraftModal

const ExtendAbsoluteContainer = styled(AbsoluteContainer)`
  right: 24px;
  top: 24px;
`

const ExtendModal = styled(Modal)`
  align-items: center;
  height: 100%;
  justify-content: center;
  width: 100%;
`

const CloseSVG = styled(FlexContainer)`
  align-items: center;
  border-radius: 100%;
  cursor: pointer;
  height: 30px;
  justify-content: center;
  width: 30px;
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
`

const ModalPopupContainer = styled(Container)`
  background: ${({ theme }) => theme.color.bg.norm};
  border-radius: 8px;
  min-width: 500px;
  padding: 32px;
  text-align: left;
  @media (max-width: ${({ theme }) => theme.media.mobile}) {
    min-width: 0;
    width: 100%;
  }
`

const CopyButton = styled(Button)`
  background: #d54e4c;
  border: none;
  border-radius: 4px;
  color: ${({ theme }) => theme.color.text.white};
  height: 36px;
  margin-right: 12px;
  &:hover {
    opacity: 0.8;
  }
`

const CancelButton = styled(Button)`
  background: transparent;
  border: 1px solid transparent;
  color: #5f5d66;
  height: 36px;
  border-radius: 4px;
  &:hover {
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(0, 0, 0, 0.1);
    color: currentColor;
    transition: all 300ms ease-out;
  }
`
