import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import Modal from 'components/styled/Modal'
import InputSubmit from 'components/forms/InputSubmit'
import Input from 'components/forms/Input'
import Textarea from 'components/forms/Textarea'
import InlineImageUpload from 'components/forms/InlineImageUpload'
import {
  Container,
  FlexContainer,
  FormContainer,
  AbsoluteContainer
} from 'components/styled/Containers'
import { Button } from 'components/styled/Buttons'
import Navbar from 'components/nav/Navbar'
import AutoComplete from 'components/forms/AutoComplete'
import useAppContext from 'services/context/appContext/useAppContext'
import { basicProfileInfoValidations } from 'services/validations/resumeValidations'
import close from 'containers/ProfilePage/assets/close'
import connectTheme from 'styles/themes'
import filterLocationResults from 'containers/QuestionnairePage/helpers/filterLocationResults'
import useFetchLocations from 'containers/QuestionnairePage/hooks/useFetchLocations'

const BasicProfileModal = ({ setBasicProfileModalShowing, profileData }) => {
  const [currentResumeFile, setCurrentResumeFile] = useState(null)
  const [currentProfilePhoto, setCurrentProfilePhoto] = useState(null)
  const { api, requests, appDispatchActions } = useAppContext()
  const {
    linkedin_link,
    github_link,
    role,
    name,
    resume,
    photo,
    summary,
    personal_website_link,
    geo_location
  } = profileData

  const fetchLocations = useFetchLocations()

  const formProps = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(basicProfileInfoValidations),
    shouldFocusError: false
  })

  useEffect(() => {
    formProps.reset({
      linkedin_link,
      github_link,
      role,
      name,
      resume,
      photo,
      summary,
      personal_website_link,
      geo_location
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Closes modal
  const handleCloseModal = () => {
    setBasicProfileModalShowing(false)
  }

  // On form submit
  const onSubmit = async data => {
    const payload = { ...data }

    // Add file uploads
    if (currentResumeFile) {
      payload.resume = currentResumeFile
    }

    if (data?.geo_location) {
      payload.location = data.geo_location.label
    }

    if (currentProfilePhoto) {
      payload.photo = currentProfilePhoto
    }

    await api.request(requests().patchProfile, payload, response => {
      appDispatchActions.updatedProfile()
      setBasicProfileModalShowing(false)
    })
  }

  const getGeoLocationDefaultValue = () => {
    if (geo_location) {
      return filterLocationResults({
        features: [geo_location]
      })[0]
    }
  }

  return (
    <Modal width="100%" height="100%" align="center" justify="center">
      <ModalPopupContainer background="#fff" padding="32px">
        <TopNavContainer>
          <Navbar indexing={connectTheme.order.modalMobileNav} />
        </TopNavContainer>
        <ModalContentContainer>
          <FlexContainer justify="space-between" margin="0 0 28px">
            <h6>Edit Basic Info & Social</h6>
            <AbsoluteContainer top="-8px" right="0">
              <CloseSVG
                className="pointer"
                justify="center"
                align="center"
                onClick={handleCloseModal}
              >
                {close}
              </CloseSVG>
            </AbsoluteContainer>
          </FlexContainer>
          <FormProvider {...formProps}>
            <FormContainer onSubmit={formProps.handleSubmit(onSubmit)}>
              <MobileFlexWrapper>
                <MobileContainerWrapper width="55%" margin="0 24px 0 0">
                  <Input
                    label="Full Name"
                    testId="award-input-year"
                    type="text"
                    name="name"
                    placeholder="Ex: John Doe"
                    register={formProps.register}
                    errors={formProps.errors}
                  />
                  <Input
                    label="Title"
                    testId="award-input-year"
                    type="text"
                    name="role"
                    placeholder="Ex: Software Engineer"
                    register={formProps.register}
                    errors={formProps.errors}
                  />
                  <AutoComplete
                    name="geo_location"
                    label="Location"
                    defaultValue={getGeoLocationDefaultValue()}
                    requestFunc={fetchLocations}
                    placeholder="Type to enter location"
                    required
                  />
                  <Textarea
                    label="Summary"
                    testId="award-textarea-description"
                    type="text"
                    name="summary"
                    placeholder="Briefly describe your professional goals and experiences"
                    register={formProps.register}
                    errors={formProps.errors}
                    showCaption={true}
                    maxlength="280"
                  />
                  <Input
                    label="LinkedIn"
                    testId="award-input-year"
                    type="text"
                    name="linkedin_link"
                    placeholder="https://www.linkedin.com/in/user-name"
                    register={formProps.register}
                    errors={formProps.errors}
                  />
                  <Input
                    label="GitHub"
                    testId="award-input-year"
                    type="text"
                    name="github_link"
                    placeholder="https://github.com/user-name"
                    register={formProps.register}
                    errors={formProps.errors}
                  />
                  <Input
                    label="Personal Website"
                    testId="award-input-year"
                    type="text"
                    name="personal_website_link"
                    placeholder="https://my-personal-website.com"
                    register={formProps.register}
                    errors={formProps.errors}
                  />
                  <FlexContainer margin="40px 0 0">
                    <Container width="fit-content">
                      <InputSubmit
                        testId="form-helper-input"
                        text={'Save'}
                        formState={formProps.formState}
                      />
                    </Container>
                    <Container margin="0 12px 0" width="fit-content">
                      <CloseButton
                        type="button"
                        onClick={() => handleCloseModal()}
                        bgColor="#ffffff"
                        textColor="#5f5d66"
                      >
                        Cancel
                      </CloseButton>
                    </Container>
                  </FlexContainer>
                </MobileContainerWrapper>
                <RightFormContainer width="45%">
                  <InlineImageUpload
                    editingFile={photo ? photo.file_path : ''}
                    label="Profile Photo"
                    caption="File types: JPG or PNG"
                    setFileId={setCurrentProfilePhoto}
                    isImg={true}
                  />
                  <InlineImageUpload
                    editingFile={resume ? resume.file_path : ''}
                    label="Resume Upload"
                    caption="File type: PDF"
                    setFileId={setCurrentResumeFile}
                    isImg={false}
                  />
                </RightFormContainer>
              </MobileFlexWrapper>
            </FormContainer>
          </FormProvider>
        </ModalContentContainer>
      </ModalPopupContainer>
    </Modal>
  )
}

export default BasicProfileModal

const CloseSVG = styled(FlexContainer)`
  width: 30px;
  height: 30px;
  border-radius: 100%;
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
`

const TopNavContainer = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
`

const ModalContentContainer = styled.div`
  @media (max-width: 768px) {
    padding: 32px 20px;
  }
`

const ModalPopupContainer = styled(Container)`
  border-radius: 8px;
  min-width: 600px;
  text-align: left;
  max-width: 600px;
  top: 80px;
  input[type='submit']:hover {
    background: #ffd175;
    border: 1px solid #ffd175;
    color: ${({ theme, color }) => color || theme.color.primary.black4};
  }
  @media (max-width: 768px) {
    top: 0;
    min-width: 100%;
    max-height: 100vh;
    border-radius: 0;
    padding: 72px 0 0;
  }
`

const CloseButton = styled(Button)`
  height: 36px;
  width: 105px;
  &:hover {
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(0, 0, 0, 0.1);
    color: currentColor;
    transition: all 300ms ease-out;
  }
`

const RightFormContainer = styled(Container)`
  border-radius: 4px;
  border: 1px solid rgb(215, 214, 217);
  padding: 24px;
  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 20px;
  }
`

const MobileContainerWrapper = styled(Container)`
  @media (max-width: 768px) {
    width: 100%;
  }
`

const MobileFlexWrapper = styled(FlexContainer)`
  @media (max-width: 768px) {
    flex-direction: column-reverse;
  }
`
