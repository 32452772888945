import React, { useRef } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import Modal from 'components/styled/Modal'
import {
  FlexContainer,
  Container,
  AbsoluteContainer
} from 'components/styled/Containers'
import { Button } from 'components/styled/Buttons'
import useAppContext from 'services/context/appContext/useAppContext'
import useClickedOutsideEvent from 'services/hooks/useClickedOutsideEvent'
import close from 'containers/ProfilePage/assets/close'
import logoutIcon from 'containers/SettingsPage/assets/logoutIcon'

const LogoutModal = ({ toggleModal }) => {
  const { api, requests, appDispatchActions } = useAppContext()
  const history = useHistory()

  const handleLogout = async () => {
    await api.request(requests().logout, {}, response => {
      api.removeTokens()
      appDispatchActions.resetAppState()
      history.push('/')
    })
  }

  const modalRef = useRef(null)
  useClickedOutsideEvent(modalRef, toggleModal)

  return (
    <ExtendModal>
      <ModalPopupContainer ref={modalRef}>
        <ExtendAbsoluteContainer>
          <CloseSVG onClick={toggleModal}>{close}</CloseSVG>
        </ExtendAbsoluteContainer>
        <h6>Logging Out</h6>
        <PromptContainer>
          <p>Are you sure you want to logout?</p>
        </PromptContainer>
        <FlexContainer>
          <LogoutButton onClick={handleLogout}>
            <IconContainer>{logoutIcon}</IconContainer>
            Logout
          </LogoutButton>
          <CancelButton onClick={toggleModal}>Cancel</CancelButton>
        </FlexContainer>
      </ModalPopupContainer>
    </ExtendModal>
  )
}

export default LogoutModal

const ExtendAbsoluteContainer = styled(AbsoluteContainer)`
  top: 24px;
  right: 24px;
`

const ExtendModal = styled(Modal)`
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`

const CloseSVG = styled(FlexContainer)`
  width: 30px;
  height: 30px;
  border-radius: 100%;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
`

const IconContainer = styled(Container)`
  margin: 0 10px 0 0;
  padding: 4px 0 0;
`

const PromptContainer = styled(Container)`
  margin: 8px 0 32px 0;
`

const ModalPopupContainer = styled(Container)`
  border-radius: 8px;
  min-width: 500px;
  text-align: left;
  background: ${({ theme }) => theme.color.bg.norm};
  padding: 32px;
  @media (max-width: ${({ theme }) => theme.media.mobile}) {
    min-width: 0;
    width: 100%;
  }
`

const LogoutButton = styled(Button)`
  background: ${({ theme }) => theme.color.bg.warningRed};
  border: 1px solid ${({ theme }) => theme.color.bg.warningRed};
  height: 36px;
  color: ${({ theme }) => theme.color.bg.norm};
  border-radius: 4px;
  width: 120px;
  margin-right: 12px;
  &:hover {
    background: ${({ theme }) => theme.color.bg.warningRedHover};
    border: 1px solid ${({ theme }) => theme.color.bg.warningRedHover};
    color: ${({ theme }) => theme.color.bg.norm};
  }
  path {
    fill: ${({ theme }) => theme.color.bg.norm};
  }
`
const CancelButton = styled(Button)`
  background: transparent;
  border: 1px solid transparent;
  color: ${({ theme }) => theme.color.primary.black3};
  height: 36px;
  border-radius: 4px;
  &:hover {
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(0, 0, 0, 0.1);
    color: currentColor;
    transition: all 300ms ease-out;
  }
`
