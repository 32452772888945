import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import ToggleSetNav from 'components/styled/ToggleSetNav'
import { Persona } from 'components/styled/Images'
import { FlexContainer, Container } from 'components/styled/Containers'
import { ContentWrapper } from 'components/styled/Wrappers'
import UserSocial from 'containers/ProfilePage/components/UserSocial'
import InitialOnboarding from 'containers/ProfilePage/components/onboarding/InitialOnboarding'
import BasicProfileModal from 'containers/ProfilePage/components/basicProfileModal/BasicProfileModal'
import Projects from './Projects'
import UserProfileLayout from './UserProfileLayout'
import useAppContext from 'services/context/appContext/useAppContext'
import editIcon from '../assets/editIcon'
import close from 'containers/ProfilePage/assets/close'

const ProfileLayout = ({ profileData, isProjects }) => {
  const { state } = useAppContext()
  const [profileModalShowing, setProfileModalShowing] = useState(false)
  const { name, role, location, summary, photo } = profileData
  const history = useHistory()
  const initials = name.split(' ')
  const userSlug = state.profileData?.slug || state.publicProfileData.slug
  const [displayProjectNotFound, setDisplayProjectNotFound] = useState(false)

  useEffect(() => {
    handleProjectNotFoundNotification()
  }, [])

  const handleOpenModal = () => {
    setProfileModalShowing(true)
  }

  const handleProjectNotFoundNotification = () => {
    window.localStorage.getItem('displayProjectNotFound') &&
      setDisplayProjectNotFound(true)
  }

  const renderProjectNotFoundNotification = () => {
    return (
      <ContentWrapper>
        <ProjectNotFoundNotification>
          <b>Sorry, this project is no longer available.</b> Check out{' '}
          {name.split(' ')[0]}'s profile and other projects below.
        </ProjectNotFoundNotification>
        <CloseNotificationButton
          onClick={() => {
            window.localStorage.removeItem('displayProjectNotFound')
            setDisplayProjectNotFound(false)
          }}
        >
          {close}
        </CloseNotificationButton>
      </ContentWrapper>
    )
  }

  return (
    <Container>
      {!state.publicProfileData && (
        <InitialOnboarding openBasicProfileModal={handleOpenModal} />
      )}
      {displayProjectNotFound && renderProjectNotFoundNotification()}
      {profileModalShowing && (
        <BasicProfileModal
          basicProfileModalShowing={profileModalShowing}
          setBasicProfileModalShowing={setProfileModalShowing}
          profileData={profileData}
        />
      )}
      <BasicProfileContainer>
        <ContentWrapper>
          <MobileFlexContainer>
            {!state.publicProfileData && (
              <EditProfileButton onClick={handleOpenModal}>
                {editIcon}
              </EditProfileButton>
            )}
            <MobilePersonaContainer>
              {photo ? (
                <Persona backgroundImage={photo.file_path} />
              ) : (
                <Persona>
                  {initials[0] && initials[0][0]}
                  {initials[1] && initials[1][0]}
                </Persona>
              )}
            </MobilePersonaContainer>
            <MobileProfileContainer>
              <NameAndSocialContainer>
                <h5>{name}</h5>
                <MobileUserSocialContainer>
                  <UserSocial profileData={profileData} />
                </MobileUserSocialContainer>
              </NameAndSocialContainer>
              <TitleAndLocationContainer>
                <TitleText>{role}</TitleText>
                <LocationText>{location}</LocationText>
              </TitleAndLocationContainer>
              <SummaryContainer>
                <SummaryText>{summary}</SummaryText>
              </SummaryContainer>
              <InvertedMobileUserSocialContainer>
                <UserSocial profileData={profileData} />
              </InvertedMobileUserSocialContainer>
            </MobileProfileContainer>
          </MobileFlexContainer>
        </ContentWrapper>
        <ContentWrapper>
          <ToggleSetNav>
            <Container
              onClick={() => {
                history.push(`/profile/${userSlug}`)
              }}
              className={`top-toggle-label action ${!isProjects && 'active'}`}
            >
              <h4 className="sub">Profile</h4>
            </Container>
            <Container
              onClick={() => {
                history.push(`/profile/${userSlug}/projects`)
              }}
              className={`top-toggle-label action ${isProjects && 'active'}`}
            >
              <h4 className="sub">Projects</h4>
            </Container>
          </ToggleSetNav>
        </ContentWrapper>
      </BasicProfileContainer>
      {!isProjects && <UserProfileLayout profileData={profileData} />}
      {isProjects && <Projects profileData={profileData} />}
    </Container>
  )
}

export default ProfileLayout

const ProjectNotFoundNotification = styled.div`
  background: #f3ebd5;
  border: 1px solid #ddcebb;
  border-radius: 8px;
  color: #5f5d66;
  margin-bottom: 25px;
  padding: 20px 0;
  text-align: center;
  width: 100%;
  b {
    color: #373440;
  }
`

const MobilePersonaContainer = styled(Container)`
  margin-right: 16px;
  @media (max-width: 768px) {
    margin-bottom: 20px;
    margin-right: 0;
  }
`

const MobileUserSocialContainer = styled(Container)`
  @media (max-width: 768px) {
    display: none;
  }
`

const InvertedMobileUserSocialContainer = styled(Container)`
  display: none;
  margin-top: 12px;
  @media (max-width: 768px) {
    display: block;
  }
`

const MobileProfileContainer = styled.div`
  margin-bottom: 32px;
  margin-right: 32px;
  padding-right: 16px;
  width: 100%;
  @media (max-width: 768px) {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-right: 0;
    padding: 0 20px;
  }
`

const MobileFlexContainer = styled(FlexContainer)`
  position: relative;
  width: 100%;
  @media (max-width: 768px) {
    align-items: center;
    flex-direction: column;
    text-align: center;
  }
`

const BasicProfileContainer = styled(FlexContainer)`
  flex-direction: column;
  margin: 0 auto;
  h4 {
    text-transform: none;
  }
`

const NameAndSocialContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin: 0 0 12px 0;
  position: relative;
`

const TitleAndLocationContainer = styled.div`
  margin: 0 0 12px 0;
  position: relative;
`

const SummaryContainer = styled.div``

const TitleText = styled.p`
  color: ${({ theme }) => theme.color.primary.black5};
  font-size: 14px;
  font-weight: ${({ theme }) => theme.font.weight.medium};
  letter-spacing: 0.25px;
`

const LocationText = styled.p`
  color: ${({ theme }) => theme.color.text.subText};
  font-size: 14px;
  font-weight: ${({ theme }) => theme.font.weight.regular};
  letter-spacing: 0.25px;
  line-height: 17px;
`

const SummaryText = styled.p`
  color: ${({ theme }) => theme.color.text.dark};
  font-size: 16px;
  font-weight: ${({ theme }) => theme.font.weight.regular};
  letter-spacing: 0.5px;
`

const EditProfileButton = styled.div`
  align-items: center;
  background-color: white;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  height: 32px;
  justify-content: center;
  margin-right: 24px;
  margin-top: -16px;
  position: absolute;
  right: 0;
  top: 50%;
  width: 32px;
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
  @media (max-width: 768px) {
    margin-right: 12px;
    margin-top: 12px;
    top: 0;
  }
`

const CloseNotificationButton = styled(EditProfileButton)`
  background-color: #f3ebd5;
  margin-right: 5px;
  top: 20px;
`
