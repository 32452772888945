import React from 'react'
import styled from 'styled-components'
import { Container, FlexContainer } from 'components/styled/Containers'
import TitleH2 from 'components/styled/titles/TitleH2'
import Subtitle from 'components/styled/titles/Subtitle'

const ErrorPage = ({ subtitle, errorCode }) => {
  return (
    <ErrorPageWrapper direction="column" justify="space-between">
      <Container margin="0 auto" maxWidth="1140px" height="100%" width="100%">
        <ContentContainer justify={'center'} direction={'column'}>
          <H1Styled>Oops!</H1Styled>
          <Subtitle1Styled>{subtitle}</Subtitle1Styled>
          <Subtitle2Styled>Error code: {errorCode}</Subtitle2Styled>
        </ContentContainer>
      </Container>
      <ShapeContainer align={'center'}>
        <Cable />
        <Plug />
        <PlugEnds direction={'column'} justify={'space-around'}>
          <PlugEnd />
          <PlugEnd />
        </PlugEnds>
        <Socket align={'center'} justify={'center'}>
          <SocketInner align={'center'} justify={'space-evenly'}>
            <Circle />
            <Circle />
          </SocketInner>
        </Socket>
      </ShapeContainer>
    </ErrorPageWrapper>
  )
}

export default ErrorPage

const ErrorPageWrapper = styled(FlexContainer)`
  background-color: ${({ theme }) => theme.color.primary.black3};
  height: 100vh;
  padding: 72px 0px 0px;
  @media screen and (max-width: ${({ theme }) => theme.media.mobile}) {
    padding-top: 72px;
  }
`

const ContentContainer = styled(FlexContainer)`
  height: 100%;
  margin-left: 60px;
  justify-content: center;
  width: 500px;
`

const ShapeContainer = styled(FlexContainer)`
  height: 193px;
  margin-bottom: 128px;
  width: 100%;
`

const Cable = styled.div`
  background-color: ${({ theme }) => theme.color.primary.black2};
  height: 32px;
  width: 55%;
`

const Plug = styled.div`
  background-color: ${({ theme }) => theme.color.primary.black2};
  border-bottom-left-radius: 66px;
  border-top-left-radius: 66px;
  height: 132px;
  margin-left: -10px;
  width: 66px;
`

const PlugEnds = styled(FlexContainer)`
  height: 132px;
  margin-left: 21px;
  width: 103px;
`

const PlugEnd = styled.div`
  background-color: ${({ theme }) => theme.color.primary.black2};
  border-radius: 12px;
  height: 24px;
  width: 82px;
`

const Socket = styled(FlexContainer)`
  background-color: ${({ theme }) => theme.color.primary.black2};
  border-radius: 8px;
  height: 193px;
  margin-left: 54px;
  width: 193px;
`

const SocketInner = styled(FlexContainer)`
  border-radius: 8px;
  border: 6px solid ${({ theme }) => theme.color.primary.black3};
  height: 143px;
  width: 143px;
`

const Circle = styled.div`
  background-color: ${({ theme }) => theme.color.primary.black3};
  border-radius: 12px;
  height: 24px;
  width: 24px;
`

const H1Styled = styled(TitleH2)`
  color: ${({ theme }) => theme.color.text.white};
  font-size: 72px;
  margin: 0 0 40px;
`

const Subtitle1Styled = styled(Subtitle)`
  color: ${({ theme }) => theme.color.text.white};
  font-size: 34px;
  font-weight: 400;
  height: 80px;
  letter-spacing: 0.15px;
  line-height: 36px;
  width: 500px;
`

const Subtitle2Styled = styled(Subtitle)`
  color: ${({ theme }) => theme.color.primary.yellow4};
  font-size: 24px;
  font-weight: 500;
`
