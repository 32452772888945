import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import ShareModal from 'containers/ProjectPage/ShareModal'
import SaveAsDraftModal from 'containers/ProjectPage/SaveAsDraftModal'
import useIsPublic from 'services/hooks/useIsPublic'
import { useAppContext, useProfileContext } from 'services/modules/helpers'
import pencilIcon from '../assets/pencilIcon'
import whiteStarIcon from '../assets/whiteStarIcon'
import whiteGearIcon from '../assets/whiteGearIcon'
import yellowStarIcon from '../assets/yellowStarIcon'

const Project = props => {
  const {
    id,
    title,
    summary,
    link,
    project_assets,
    description,
    is_featured,
    is_public,
    featured_order,
    project_skills,
    thumbnail,
    fromPage = 'project'
  } = props

  const history = useHistory()
  const { isAuthenticated, isPublic } = useIsPublic()
  const { state, api, requests, appDispatchActions } = useAppContext()
  const { profileDispatchActions } = useProfileContext()
  const [iconHovered, setIconHovered] = useState('')
  const [isSaveModalShowing, setIsSaveModalShowing] = useState(false)
  const [isModalShowing, setIsModalShowing] = useState(false)
  const [isDisplayDropdown, setIsDisplayDropdown] = useState(false)

  const featureFileConfig = requests(id)['featureProject']
  const userSlug = state.profileData?.slug || state.publicProfileData.slug
  const { projects } = state.profileData || []

  const handleFeaturedProject = () => {
    const new_order = featured_order
      ? 0
      : projects.reduce((max_order, { featured_order }) => {
          if (max_order <= featured_order && featured_order) {
            return featured_order + 1
          } else {
            return max_order
          }
        }, 1)

    api.request(
      featureFileConfig,
      {
        is_featured: !is_featured,
        featured_order: new_order
      },
      () => {
        appDispatchActions.updatedProfile()
      }
    )
  }

  const handleShareModal = () => {
    setIsModalShowing(prevState => !prevState)
  }

  const handleSaveModal = () => {
    setIsSaveModalShowing(prevState => !prevState)
  }

  const handleDelete = () => {
    appDispatchActions.updatedProfile()
    profileDispatchActions.setDeleteModal(id, fromPage)
  }

  const handleFormatDataOnEdit = async () => {
    await api.request(
      requests(id).updateProject,
      {
        description,
        is_featured: false,
        is_public: !is_public,
        link,
        project_assets,
        project_skills,
        title
      },
      () => {
        setIsSaveModalShowing(false)
        appDispatchActions.updatedProfile()
      }
    )
  }

  const handleSettingsDropdown = () => {
    setIsDisplayDropdown(prevState => !prevState)
    if (isDisplayDropdown) {
      setIconHovered('settings')
    } else {
      setIconHovered('')
    }
  }

  const renderActionButtonTag = iconName => {
    if (iconHovered === iconName) {
      return iconName === 'feature' ? (
        <ActionButtonTag>
          {is_featured ? 'Unfeature' : 'Feature'}
        </ActionButtonTag>
      ) : (
        <ActionButtonTag>{iconName}</ActionButtonTag>
      )
    }
  }

  const renderFeatureButton = iconName => {
    return (
      <ActionButton
        onMouseOver={() => {
          isDisplayDropdown && setIsDisplayDropdown(false)
          setIconHovered(iconName)
        }}
        onMouseLeave={() => setIconHovered('')}
        onClick={e => {
          e.stopPropagation()
          handleFeaturedProject()
        }}
      >
        {is_featured ? yellowStarIcon : whiteStarIcon}
        {renderActionButtonTag(iconName)}
        {iconHovered === iconName && (
          <ActionButtonTag>
            {is_featured ? 'Unfeature' : 'Feature'}
          </ActionButtonTag>
        )}
      </ActionButton>
    )
  }

  const renderEditButton = iconName => {
    return (
      <ActionButton
        onMouseOver={() => {
          isDisplayDropdown && setIsDisplayDropdown(false)
          setIconHovered(iconName)
        }}
        onMouseLeave={() => setIconHovered('')}
        onClick={e => {
          e.stopPropagation()
          history.push(`/profile/project/${id}`)
        }}
      >
        {pencilIcon}
        {renderActionButtonTag(iconName)}
      </ActionButton>
    )
  }

  const renderSettingsButton = iconName => {
    return (
      <ActionButton
        onMouseOver={() => !isDisplayDropdown && setIconHovered(iconName)}
        onMouseLeave={() => setIconHovered('')}
        onClick={e => {
          e.stopPropagation()
          handleSettingsDropdown()
        }}
      >
        {whiteGearIcon}
        {isDisplayDropdown && (
          <GearDropdownList onMouseLeave={() => setIsDisplayDropdown(false)}>
            {is_public && (
              <DropdownOptionText
                onClick={e => {
                  e.stopPropagation()
                  handleShareModal()
                }}
              >
                Share
              </DropdownOptionText>
            )}
            <DropdownOptionText
              onClick={() =>
                is_public ? handleSaveModal() : handleFormatDataOnEdit()
              }
            >
              {is_public ? 'Unpublish' : 'Publish'}
            </DropdownOptionText>
            <DropdownOptionText
              onClick={e => {
                e.stopPropagation()
                handleDelete()
              }}
            >
              Delete
            </DropdownOptionText>
          </GearDropdownList>
        )}
        {renderActionButtonTag(iconName)}
      </ActionButton>
    )
  }

  return (
    <>
      <ProjectWrapper
        onClick={() => {
          const redirectPage = is_public
            ? `/profile/${userSlug}/projects/${id}`
            : `/profile/project/${id}`
          history.push(redirectPage)
        }}
      >
        {is_featured && <FeatureIndicator>Featured</FeatureIndicator>}
        {!is_public && <DraftIndicator>Draft</DraftIndicator>}
        {isAuthenticated && !isPublic && (
          <ActionWrapper>
            {is_public && renderFeatureButton('feature')}
            {renderEditButton('edit')}
            {renderSettingsButton('settings')}
          </ActionWrapper>
        )}
        {thumbnail ? (
          <ProjectImage>
            <img src={thumbnail.file_path} alt={title} />
          </ProjectImage>
        ) : (
          <ProjectBlankImage>
            <img
              src={
                'https://storage.googleapis.com/connect-internal-assets/projects/blank-project-image.jpeg'
              }
              alt={title}
            />
          </ProjectBlankImage>
        )}
        <ProjectInfoWrapper>
          <Title>{title}</Title>
          <Summary>{summary || description}</Summary>
          {project_skills && project_skills.length > 0 && (
            <Skills>
              {project_skills.map((skill, index) => {
                return (
                  index < 3 && (
                    <Badge key={`project-skill-${skill.id}`}>
                      {skill.name}
                    </Badge>
                  )
                )
              })}
            </Skills>
          )}
        </ProjectInfoWrapper>
      </ProjectWrapper>
      {isModalShowing && (
        <ShareModal toggleShareModal={handleShareModal} projectId={id} />
      )}
      {isSaveModalShowing && (
        <SaveAsDraftModal
          toggleSaveModal={handleSaveModal}
          isExistingProject={true}
          onSubmit={handleFormatDataOnEdit}
        />
      )}
    </>
  )
}

export default Project

const ProjectImage = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.color.bg.liteGray};
  background-size: cover;
  border-left: 1px solid ${({ theme }) => theme.color.bg.borderless};
  border-right: 1px solid ${({ theme }) => theme.color.bg.borderless};
  border-top: 1px solid ${({ theme }) => theme.color.bg.borderless};
  border-radius: 8px;
  display: flex;
  justify-content: center;
  overflow: hidden;
  height: 100%;
  img {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    height: 100%;
    object-fit: cover;
    width: 100%;
  }
  :after {
    background: ${({ theme }) => theme.color.primary.black};
    border-radius: 8px;
    content: '';
    height: 100%;
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    transform-origin: center;
    transition: all 300ms ease-out;
    width: 100%;
  }
`

const ProjectBlankImage = styled(ProjectImage)`
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  height: 75%;
  img {
    object-fit: initial;
  }
`

const ProjectInfoWrapper = styled.div`
  background-color: ${({ theme }) => theme.color.bg.white};
  border: 1px solid ${({ theme }) => theme.color.bg.borderless};
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  bottom: 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 25%;
  padding: 15px;
  position: absolute;
  transition: height 0.3s ease-in;
  width: 100%;
`

const Summary = styled.div`
  color: ${({ theme }) => theme.color.bg.suvaGray};
  display: none;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.4px;
  line-height: 16px;
  overflow: hidden;
  flex-direction: vertical;
  -webkit-line-clamp: 3;
`

const Skills = styled.div`
  display: none;
  gap: 3px;
  width: 100%;
`
const ActionWrapper = styled.div`
  display: flex;
  gap: 4px;
  position: absolute;
  right: 10px;
  top: 10px;
  visibility: hidden;
  z-index: 10;
`

const ProjectWrapper = styled.div`
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100%;
  position: relative;
  width: 100%;
  &:hover {
    ${ProjectInfoWrapper} {
      height: max-content;
    }
    ${Summary} {
      display: flex;
    }
    ${Skills} {
      display: flex;
    }
    ${/* sc-selector */ ProjectImage}::after {
      opacity: 0.7;
    }
    ${ActionWrapper} {
      visibility: visible;
    }
  }
`

const Title = styled.div`
  color: ${({ theme }) => theme.color.primary.black3};
  display: flex;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0.25px;
  line-height: 17px;
  overflow: hidden;
  width: 100%;
  flex-direction: vertical;
  -webkit-line-clamp: 1;
`

const Badge = styled.div`
  background: ${({ theme }) => theme.color.bg.lite};
  border: 1px solid ${({ theme }) => theme.color.border.tag};
  border-radius: 12px;
  color: ${({ theme }) => theme.color.border.tag};
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.4px;
  line-height: 16px;
  overflow: hidden;
  padding: 4px 12px;
`

const FeatureIndicator = styled.div`
  background: ${({ theme }) => theme.color.primary.yellow4};
  border: 1px solid ${({ theme }) => theme.color.bg.white};
  border-radius: 4px;
  color: ${({ theme }) => theme.color.primary.black4};
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 500;
  left: 10px;
  letter-spacing: 0.1px;
  line-height: 24px;
  padding-left: 8px;
  padding-right: 8px;
  position: absolute;
  top: 10px;
  z-index: 5;
`

const DraftIndicator = styled(FeatureIndicator)`
  background-color: ${({ theme }) => theme.color.bg.blueMagenta};
  color: ${({ theme }) => theme.color.bg.white};
`

const ActionButton = styled.div`
  align-items: center;
  border: none;
  border-radius: 16px;
  color: ${({ theme }) => theme.color.primary.black4};
  display: flex;
  height: 32px;
  justify-content: center;
  padding: 0;
  position: relative;
  width: 32px;
  &:hover {
    background: rgba(123 122 128 / 0.8);
    opacity: 1;
  }
  svg {
    pointer-events: none;
  }
`

const ActionButtonTag = styled.div`
  background: ${({ theme }) => theme.color.bg.white};
  border: 1px solid ${({ theme }) => theme.color.border.veryLiteGray};
  border-radius: 4px;
  color: ${({ theme }) => theme.color.primary.black2};
  font-size: 10px;
  font-weight: 900;
  padding: 4px 8px;
  position: absolute;
  text-transform: uppercase;
  top: 38px;
`

const GearDropdownList = styled.div`
  border-radius: 4px;
  background: ${({ theme }) => theme.color.bg.white};
  border: 1px solid ${({ theme }) => theme.color.primary.black0};
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 4px;
  position: absolute;
  right: 5px;
  top: 42px;
  width: fit-content;
`

const DropdownOptionText = styled.p`
  background: ${({ theme }) => theme.color.bg.white};
  border-radius: 2px;
  color: ${({ theme }) => theme.color.primary.black3};
  cursor: pointer;
  font-size: 14px;
  padding: 4px 20px 4px 4px;
  text-align: left;
  text-transform: capitalize;
  width: 100%;
  :hover {
    background: ${({ theme }) => theme.color.bg.buttonHover};
  }
`
