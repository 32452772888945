import React from 'react'

const bookmark = (
  <svg
    width="8px"
    height="12px"
    viewBox="0 0 8 12"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        transform="translate(-1186.000000, -234.000000)"
        fill="#373440"
        fillRule="nonzero"
      >
        <g transform="translate(152.000000, 105.000000)">
          <g transform="translate(1.000000, 52.000000)">
            <g transform="translate(768.000000, 1.000000)">
              <g transform="translate(233.000000, 60.000000)">
                <path d="M39,16.8064516 C39.5522847,16.8064516 40,17.2541669 40,17.8064516 L40,26.3922381 C40,26.9445228 39.5522847,27.3922381 39,27.3922381 C38.7347835,27.3922381 38.4804296,27.2868812 38.2928932,27.0993448 L36.7071068,25.5135584 C36.3165825,25.1230341 35.6834175,25.1230341 35.2928932,25.5135584 L33.7071068,27.0993448 C33.3165825,27.4898691 32.6834175,27.4898691 32.2928932,27.0993448 C32.1053568,26.9118085 32,26.6574545 32,26.3922381 L32,17.8064516 C32,17.2541669 32.4477153,16.8064516 33,16.8064516 L39,16.8064516 Z M39,17.8064516 L33,17.8064516 L33,26.3922381 L34.5857864,24.8064516 C35.3257272,24.0665108 36.5012114,24.0275666 37.2869988,24.6896189 L37.4142136,24.8064516 L39,26.3922381 L39,17.8064516 Z"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default bookmark
