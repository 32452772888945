import React from 'react'

const companyLogoPlaceholder = (
  <svg
    width="70px"
    height="70px"
    viewBox="0 0 70 70"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      id="Job-Board"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="job-match-logged_Company-Logo-Placeholder"
        transform="translate(-177.000000, -565.000000)"
      >
        <g
          id="Company-Icon-Placeholder--Copy-2"
          transform="translate(177.000000, 565.000000)"
        >
          <rect
            id="Rectangle"
            fill="#FFFFFF"
            x="0"
            y="0"
            width="70"
            height="70"
            rx="4"
          ></rect>
          <path
            d="M47.9745455,17.755102 C48.3962902,17.755102 48.7381818,18.0969937 48.7381818,18.5187384 L48.7381818,23.1918367 L51.4872727,23.1918367 C51.9090174,23.1918367 52.2509091,23.5337284 52.2509091,23.9554731 L52.2509091,42.9755102 L54.0836364,42.9755102 C54.5053811,42.9755102 54.8472727,43.3174018 54.8472727,43.7391466 L54.8472727,53.2363636 C54.8472727,53.6581084 54.5053811,54 54.0836364,54 L13.9163636,54 C13.4946189,54 13.1527273,53.6581084 13.1527273,53.2363636 L13.1527273,41.4738404 C13.1527273,41.0520957 13.4946189,40.7102041 13.9163636,40.7102041 L18.4977273,40.710102 L18.4981818,21.8411874 C18.4981818,21.4194427 18.8400735,21.077551 19.2618182,21.077551 L32.7018182,21.077551 C33.1235629,21.077551 33.4654545,21.4194427 33.4654545,21.8411874 L33.4647273,35.877102 L39.1157273,35.877102 L39.1163636,23.9554731 C39.1163636,23.5337284 39.4582553,23.1918367 39.88,23.1918367 L42.6290909,23.1918367 L42.6290909,18.5187384 C42.6290909,18.0969937 42.9709826,17.755102 43.3927273,17.755102 L47.9745455,17.755102 Z"
            id="Combined-Shape-Copy"
            fill="#ECEDF3"
          ></path>
          <path
            d="M47.8218182,17 C48.2435629,17 48.5854545,17.3418916 48.5854545,17.7636364 L48.5854545,22.4367347 L51.3345455,22.4367347 C51.7562902,22.4367347 52.0981818,22.7786263 52.0981818,23.2003711 L52.0981818,42.2204082 L53.9309091,42.2204082 C54.3526538,42.2204082 54.6945455,42.5622998 54.6945455,42.9840445 L54.6945455,52.4812616 C54.6945455,52.9030063 54.3526538,53.244898 53.9309091,53.244898 L13.7636364,53.244898 C13.3418916,53.244898 13,52.9030063 13,52.4812616 L13,40.7187384 C13,40.2969937 13.3418916,39.955102 13.7636364,39.955102 L18.345,39.955 L18.3454545,21.0860853 C18.3454545,20.6643406 18.6873462,20.322449 19.1090909,20.322449 L32.5490909,20.322449 C32.9708356,20.322449 33.3127273,20.6643406 33.3127273,21.0860853 L33.312,35.122 L38.963,35.122 L38.9636364,23.2003711 C38.9636364,22.7786263 39.305528,22.4367347 39.7272727,22.4367347 L42.4763636,22.4367347 L42.4763636,17.7636364 C42.4763636,17.3418916 42.8182553,17 43.24,17 L47.8218182,17 Z"
            id="Combined-Shape"
            stroke="#C4C3CB"
            strokeWidth="0.610909091"
            strokeLinejoin="round"
          ></path>
          <path
            d="M31.1745455,35.122449 L43.0872727,35.122449 C43.5090174,35.122449 43.8509091,35.4643406 43.8509091,35.8860853 L43.8509091,53.244898 L43.8509091,53.244898 L30.4109091,53.244898 L30.4109091,35.8860853 C30.4109091,35.4643406 30.7528007,35.122449 31.1745455,35.122449 Z"
            id="Rectangle"
            stroke="#C4C3CB"
            strokeWidth="0.610909091"
          ></path>
          <g
            id="Window-Backgrounds"
            transform="translate(16.512727, 23.946939)"
            fill="#FFFFFF"
          >
            <rect
              id="Rectangle"
              x="7.33090909"
              y="0"
              width="4.12363636"
              height="3.92653061"
              rx="0.610909091"
            ></rect>
            <rect
              id="Rectangle-Copy-2"
              x="7.33090909"
              y="6.49387755"
              width="4.12363636"
              height="3.92653061"
              rx="0.610909091"
            ></rect>
            <rect
              id="Rectangle-Copy-3"
              x="7.33090909"
              y="13.1387755"
              width="4.12363636"
              height="3.92653061"
              rx="0.610909091"
            ></rect>
            <rect
              id="Rectangle-Copy-6"
              x="17.1054545"
              y="15.1020408"
              width="8.24727273"
              height="3.92653061"
              rx="0.610909091"
            ></rect>
            <rect
              id="Rectangle-Copy-11"
              x="26.2690909"
              y="0.604081633"
              width="2.13818182"
              height="3.92653061"
              rx="0.610909091"
            ></rect>
            <rect
              id="Rectangle-Copy-13"
              x="26.2690909"
              y="6.49387755"
              width="2.13818182"
              height="3.92653061"
              rx="0.610909091"
            ></rect>
            <rect
              id="Rectangle-Copy-12"
              x="31.0036364"
              y="0.604081633"
              width="2.13818182"
              height="3.92653061"
              rx="0.610909091"
            ></rect>
            <rect
              id="Rectangle-Copy-14"
              x="31.0036364"
              y="6.49387755"
              width="2.13818182"
              height="3.92653061"
              rx="0.610909091"
            ></rect>
            <rect
              id="Rectangle-Copy-15"
              x="31.0036364"
              y="12.3836735"
              width="2.13818182"
              height="3.92653061"
              rx="0.610909091"
            ></rect>
            <rect
              id="Rectangle-Copy-16"
              x="31.0036364"
              y="17.6693878"
              width="2.13818182"
              height="3.92653061"
              rx="0.610909091"
            ></rect>
            <rect
              id="Rectangle-Copy-4"
              x="7.33090909"
              y="19.6326531"
              width="4.12363636"
              height="3.92653061"
              rx="0.610909091"
            ></rect>
            <path
              d="M0.610909091,23.5591837 L2.59636364,23.5591837 C2.93375941,23.5591837 3.20727273,23.832697 3.20727273,24.1700928 L3.20727273,29.4489796 L3.20727273,29.4489796 L0,29.4489796 L0,24.1700928 C-4.13190655e-17,23.832697 0.273513316,23.5591837 0.610909091,23.5591837 Z"
              id="Rectangle-Copy-5"
            ></path>
            <path
              d="M20.16,23.5591837 L21.84,23.5591837 C22.1773958,23.5591837 22.4509091,23.832697 22.4509091,24.1700928 L22.4509091,29.2979592 L22.4509091,29.2979592 L19.5490909,29.2979592 L19.5490909,24.1700928 C19.5490909,23.832697 19.8226042,23.5591837 20.16,23.5591837 Z"
              id="Rectangle-Copy-8"
            ></path>
            <path
              d="M35.8909091,23.5591837 L37.5709091,23.5591837 C37.9083049,23.5591837 38.1818182,23.832697 38.1818182,24.1700928 L38.1818182,28.6853432 C38.1818182,29.1070879 37.8399265,29.4489796 37.4181818,29.4489796 L35.28,29.4489796 L35.28,29.4489796 L35.28,24.1700928 C35.28,23.832697 35.5535133,23.5591837 35.8909091,23.5591837 Z"
              id="Rectangle-Copy-9"
            ></path>
          </g>
          <g
            id="Windows"
            transform="translate(15.596364, 23.493878)"
            stroke="#C4C3CB"
            strokeWidth="0.610909091"
          >
            <rect
              id="Rectangle"
              x="7.63636364"
              y="0"
              width="4.12363636"
              height="3.92653061"
              rx="0.610909091"
            ></rect>
            <rect
              id="Rectangle-Copy-2"
              x="7.63636364"
              y="6.49387755"
              width="4.12363636"
              height="3.92653061"
              rx="0.610909091"
            ></rect>
            <rect
              id="Rectangle-Copy-3"
              x="7.63636364"
              y="13.1387755"
              width="4.12363636"
              height="3.92653061"
              rx="0.610909091"
            ></rect>
            <rect
              id="Rectangle-Copy-6"
              x="17.4109091"
              y="15.1020408"
              width="8.24727273"
              height="3.92653061"
              rx="0.610909091"
            ></rect>
            <rect
              id="Rectangle-Copy-11"
              x="26.5745455"
              y="0.604081633"
              width="2.13818182"
              height="3.92653061"
              rx="0.610909091"
            ></rect>
            <rect
              id="Rectangle-Copy-13"
              x="26.5745455"
              y="6.49387755"
              width="2.13818182"
              height="3.92653061"
              rx="0.610909091"
            ></rect>
            <rect
              id="Rectangle-Copy-12"
              x="31.3090909"
              y="0.604081633"
              width="2.13818182"
              height="3.92653061"
              rx="0.610909091"
            ></rect>
            <rect
              id="Rectangle-Copy-14"
              x="31.3090909"
              y="6.49387755"
              width="2.13818182"
              height="3.92653061"
              rx="0.610909091"
            ></rect>
            <rect
              id="Rectangle-Copy-15"
              x="31.3090909"
              y="12.3836735"
              width="2.13818182"
              height="3.92653061"
              rx="0.610909091"
            ></rect>
            <rect
              id="Rectangle-Copy-16"
              x="31.3090909"
              y="17.6693878"
              width="2.13818182"
              height="3.92653061"
              rx="0.610909091"
            ></rect>
            <rect
              id="Rectangle-Copy-4"
              x="7.63636364"
              y="19.6326531"
              width="4.12363636"
              height="3.92653061"
              rx="0.610909091"
            ></rect>
            <path
              d="M3.2382034,23.6149256 L3.78949175,29.7476067 L0.305454545,29.7476067 L0.305454545,24.1249689 L3.2382034,23.6149256 Z"
              id="Rectangle-Copy-5"
            ></path>
            <path
              d="M22.7872943,23.6149256 L23.3385827,29.7476067 L19.8545455,29.7476067 L19.8545455,24.1249689 L22.7872943,23.6149256 Z"
              id="Rectangle-Copy-8"
            ></path>
            <path
              d="M38.5218794,23.6142863 L39.0979867,29.3029261 L35.5854545,29.7476067 L35.5854545,24.1249689 L38.5218794,23.6142863 Z"
              id="Rectangle-Copy-9"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default companyLogoPlaceholder
