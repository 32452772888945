import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useFormContext } from 'react-hook-form'
import Radio from 'components/forms/Radio'
import { FlexContainer, Container } from 'components/styled/Containers'
import { InputLabel, ErrorMessage } from 'components/styled/Text'

const Radios = ({ vertical, question, groupName, options, required }) => {
  const form = useFormContext()

  return (
    <Container margin="0 0 20px 0">
      {question && (
        <InputLabel className={required} marginBottom="20px">
          {question}
        </InputLabel>
      )}
      <RadioFlex direction={vertical ? 'column' : 'row'}>
        {options &&
          options.map(option => {
            return (
              <Radio
                key={option.id}
                name={option.optionText}
                helper={option.optionHelper}
                groupName={groupName}
                vertical={vertical}
                width={option.width}
                value={option.value}
              />
            )
          })}
      </RadioFlex>
      {form && form.errors[groupName] && (
        <ErrorMessage>{form.errors[groupName].message}</ErrorMessage>
      )}
    </Container>
  )
}

// -- PROP DESCRIPTIONS -- //
// question: question text above the radios
// groupName: name associated with react-hook-form
// vertical: stacks radio options vertically when true
// options: list of radios options

Radios.propTypes = {
  question: PropTypes.string,
  groupName: PropTypes.string,
  vertical: PropTypes.bool,
  options: PropTypes.array
}

Radios.defaultProps = {
  groupName: 'radios',
  vertical: true
}

export default Radios

const RadioFlex = styled(FlexContainer)`
  @media (max-width: 420px) {
    flex-direction: column;
  }
`
