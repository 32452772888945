import { useEffect, useCallback, useState } from 'react'
import useAppContext from 'services/context/appContext/useAppContext'

const useFetchSuggestableSkills = () => {
  const [{ api, requests }] = useState(useAppContext())
  const [suggestableSkillList, setSuggestableSkillList] = useState([])

  const fetchSkills = useCallback(async () => {
    await api.request(
      requests({ authed: false }).getSuggestableSkills,
      {},
      response => {
        const {
          data: { skills }
        } = response
        setSuggestableSkillList(skills)
      }
    )
  }, [api, requests])

  useEffect(() => {
    fetchSkills()
  }, [fetchSkills])

  return { suggestableSkillList }
}

export default useFetchSuggestableSkills
