import React from 'react'
import styled from 'styled-components'

const LoadingSpinner = () => (
  <StyledLoadingSpinner>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </StyledLoadingSpinner>
)

export default LoadingSpinner

const StyledLoadingSpinner = styled.div`
  display: inline-block;
  height: 80px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 80px;

  div {
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border: 5px solid transparent;
    border-top-color: #373440;
    border-radius: 50%;
    box-sizing: border-box;
    display: block;
    height: 48px;
    margin: 8px;
    position: absolute;
    width: 48px;
  }
  div:nth-child(1) {
    animation-delay: -0.45s;
  }
  div:nth-child(2) {
    animation-delay: -0.3s;
  }
  div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0turn);
    }
    100% {
      transform: rotate(1turn);
    }
  }
`
