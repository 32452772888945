import React from 'react'
import styled from 'styled-components'
import useAppContext from 'services/context/appContext/useAppContext'
import {
  Container,
  FixedContainer,
  MaxWidthContainer,
  FlexContainer
} from 'components/styled/Containers'
import DesktopNav from './DesktopNav'
import MobileNav from './MobileNav'
import C1LogoLight from 'components/graphics/C1Logo'

const Navbar = ({ indexing }) => {
  const { state, history } = useAppContext()
  const { isAuthenticated, profileData } = state

  const handleProfile = () => {
    history.push(isAuthenticated ? `/profile/${profileData.slug}` : '/')
  }

  return (
    <NavbarContainer
      top="0"
      left="0"
      height="72px"
      background="#373440"
      indexing={indexing}
    >
      <MaxWidthContainer height="72px">
        <FlexContainer height="100%" align="center" justify="space-between">
          <Container>
            <C1LogoLight onClick={handleProfile} />
          </Container>
          <DesktopNav isAuthenticated={isAuthenticated} />
          <MobileNav isAuthenticated={isAuthenticated} />
        </FlexContainer>
      </MaxWidthContainer>
    </NavbarContainer>
  )
}

export default Navbar

const NavbarContainer = styled(FixedContainer)`
  z-index: ${({ indexing }) => indexing};
  width: 100%;
  padding: 0 20px;
`
