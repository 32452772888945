import React from 'react'

const magnifyingGlassIcon = (
  <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1">
    <g
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      opacity="0.5"
    >
      <g
        transform="translate(-977.000000, -129.000000)"
        fill="#000000"
        fillRule="nonzero"
      >
        <g transform="translate(152.000000, 121.000000)">
          <g transform="translate(809.000000, 0.000000)">
            <g transform="translate(16.000000, 8.000000)">
              <path d="M11.1222016,9.4402372 C13.0746269,6.7436132 12.4216418,3.00958026 9.66464549,1.10036496 C6.90764919,-0.808850336 3.09001863,-0.170620496 1.1380597,2.5264598 C-0.813899224,5.22308391 -0.161380537,8.95666055 2.59608215,10.8658759 C4.56389922,12.2285584 7.16557839,12.3334854 9.24113803,11.1345803 L13.7509328,15.5191605 C14.2378732,16.0205292 15.0480411,16.0406022 15.5606343,15.5643248 C16.0732276,15.0885036 16.09375,14.2960766 15.6072762,13.7947081 C15.5918843,13.7787409 15.5769589,13.7641424 15.5606343,13.7490875 L11.1222016,9.4402372 Z M6.12639928,9.84899641 C3.94776119,9.84945261 2.18143654,8.12317524 2.18003716,5.99224455 C2.17957087,3.86131387 3.94449624,2.13366785 6.12360072,2.13275514 C8.29944024,2.13184304 10.0648321,3.85538324 10.0690358,5.98357664 C10.0727612,8.1149635 8.30876872,9.84534669 6.12919773,9.84899641 C6.12826496,9.84899641 6.12779845,9.84899641 6.12639928,9.84899641 Z"></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default magnifyingGlassIcon
