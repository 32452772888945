import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import { Swipeable } from 'react-swipeable'
import { FlexContainer, Container } from 'components/styled/Containers'
import { IconAction } from 'components/styled/Buttons'
import useAppContext from 'services/context/appContext/useAppContext'
import { ChevronLeft, ChevronRight } from 'containers/HomePage/assets/chevrons'

const Carousel = ({ slides, slideType }) => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const { appDispatchActions } = useAppContext()

  const nextSlide = () => {
    const next = (currentIndex + 1) % slides.length
    setCurrentIndex(next)
  }

  const prevSlide = () => {
    const prev = (currentIndex - 1 + slides.length) % slides.length
    setCurrentIndex(prev)
  }

  const zoomIn = imageUrl => {
    appDispatchActions.zoomIn({ src: imageUrl })
  }

  const swipeConfig = {
    onSwipedLeft: () => nextSlide(),
    onSwipedRight: () => prevSlide(),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
    trackTouch: true
  }

  const carouselKey = imageUrl => {
    // Avoid edge case where the same imageUrl is used twice or more
    return `${Math.floor(Math.random() * 100)}-${Date.now()}-${
      imageUrl
        .split('?')[0]
        .split('/')
        .pop()
        .split('.')[0]
    }`
  }

  return (
    <Swipeable {...swipeConfig} style={{ width: '100%' }}>
      <CarouselWrapper
        slideType={slideType}
        justify="space-between"
        align="center"
      >
        <ActionColumn
          direction="left"
          slideType={slideType}
          className="end-lg col-lg-2"
        >
          <IconAction width="64px" height="64px" onClick={prevSlide}>
            <ChevronLeft />
          </IconAction>
        </ActionColumn>
        <CarouselView
          className={` col-lg-${slideType === 'project-carousel' ? '12' : '8'}`}
        >
          <CurrentSlide slideType={slideType}>
            {slides.map(({ imageUrl }, index) => (
              <Slide
                className="slide"
                key={`slide-${carouselKey(imageUrl)}`}
                onClick={() => zoomIn(imageUrl)}
                isActive={index === currentIndex}
              >
                <img
                  src={imageUrl}
                  alt={`C1 Connect Datafolio Example #${index}`}
                />
              </Slide>
            ))}
          </CurrentSlide>
          {!slideType === 'individual project' && (
            <CarouselDots
              slideType={slideType}
              justify="center"
              align="center"
              className="dots"
            >
              {slides.map(({ imageUrl }, index) => (
                <CarouselDot
                  key={`dot-${carouselKey(imageUrl)}`}
                  className="dot"
                  onClick={() => setCurrentIndex(index)}
                  isActive={index === currentIndex}
                ></CarouselDot>
              ))}
            </CarouselDots>
          )}
        </CarouselView>
        <ActionColumn
          direction="right"
          slideType={slideType}
          className="col-lg-2"
        >
          <IconAction width="64px" height="64px" onClick={nextSlide}>
            <ChevronRight />
          </IconAction>
        </ActionColumn>
      </CarouselWrapper>
    </Swipeable>
  )
}

export default Carousel

// -- PROP DESCRIPTIONS -- //
/*
  slides: all the slides in the carousel
  slideType: sets style type
*/

Carousel.propTypes = {
  slides: PropTypes.array,
  slideType: PropTypes.string
}

Carousel.defaultProps = {
  slides: [0, 1, 2, 3, 4, 5],
  slideType: ''
}

const CarouselView = styled(Container)`
  height: 100%;
  width: 100%;
`

const CarouselWrapper = styled(FlexContainer)`
  margin: 32px auto;
  min-height: 400px;
  padding: 32px 0;
  height: auto;
  width: 100%;
  ${({ slideType }) =>
    slideType === 'project-carousel' &&
    css`
      margin: 0 auto;
      padding: 0 0 0 0;
      position: relative;
      &:hover {
        ${ActionColumn} {
          opacity: 1;
          visibility: visible;
        }
      }
    `}
  h4 {
    color: #ffffff;
    text-align: left;
  }

  @media (max-width: 768px) {
    margin: 8px auto;
    min-height: 200px;
    padding: 0;

    ${({ slideType }) =>
      slideType === 'project-carousel' &&
      css`
        height: 100vh;
        margin: 0 auto 0;
        ${CarouselView} {
          ${CurrentSlide} {
            height: 100%;
          }
        }
      `}
  }
`

const CurrentSlide = styled.div`
  background: ${({ slideType }) =>
    !slideType === 'individual project' && '#746e7f'};
  display: block;
  margin: 0 0 8px;
  overflow: hidden;
  padding: ${({ slideType }) =>
    !slideType === 'individual project' && '0 0 67.5% 0'};
  position: ${({ slideType }) =>
    !slideType === 'individual project' && 'relative'};
  width: 100%;
  ${({ slideType }) =>
    slideType === 'project-carousel' &&
    css`
      background: transparent;
      height: 400px;
      margin: 0 0 0 0;
      padding: 0 0 0 0;
    `}
`

const Slide = styled.div`
  align-items: center;
  cursor: zoom-in;
  display: flex;
  height: auto;
  justify-content: center;
  margin: 0 0 8px;
  opacity: 0;
  transition: all 300ms ease-in-out 150ms;
  visibility: hidden;
  width: 100%;
  img {
    height: auto;
    width: 100%;
  }
  ${({ isActive }) =>
    isActive &&
    css`
      height: 100%;
      left: 50%;
      opacity: 1;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      visibility: visible;
    `}
`

const ActionColumn = styled(FlexContainer)`
  padding: 0 32px;
  svg {
    cursor: pointer;
  }
  ${direction =>
    direction === 'left' &&
    css`
      button {
        float: right;
        margin: 0 0 0 0;
      }
    `}

  ${direction =>
    direction === 'right' &&
    css`
      button {
        float: left;
        margin: 0 0 0 0;
      }
    `}

  ${({ slideType, direction, theme }) =>
    slideType === 'project-carousel' &&
    css`
      align-items: center;
      display: flex;
      height: 100%;
      justify-content: center;
      opacity: 0;
      position: absolute;
      transition: all 300ms ease-out;
      visibility: hidden;
      z-index: ${theme.order.front};
      svg {
        height: 45px;
        width: 45px;
        .circle {
          fill: #fff;
        }
        .border {
          stroke: #696969;
        }
      }
      top: 0;
      ${direction === 'left' &&
        css`
          left: 0;
        `};
      ${direction === 'right' &&
        css`
          right: 0;
        `};
    `} 


  @media (max-width: 768px) {
    display: none;
    padding: 0 4px;
    ${({ slideType }) =>
      slideType === 'project-carousel' &&
      css`
        display: flex;
        opacity: 1;
        visibility: visible;
      `}
  }
`

const CarouselDots = styled(FlexContainer)`
  margin: 16px auto;
  width: 100%;

  ${({ slideType }) =>
    slideType === 'project-carousel' &&
    css`
      bottom: 0;
      left: 50%;
      margin: 0 0 0 0;
      padding: 16px 0;
      position: absolute;
      transform: translate(-50%, 0);
      z-index: ${({ theme }) => theme.order.carouselActions};
      &::before {
        background: ${({ theme }) => theme.color.bg.modal};
        content: '';
        height: 100%;
        left: 0;
        opacity: 0.1;
        pointer-events: none;
        position: absolute;
        top: 0;
        width: 100%;
      }
    `}

  @media (max-width: 768px) {
    ${({ slideType }) =>
      slideType === 'project-carousel' &&
      css`
        bottom: unset;
        margin: 0 auto 0;
        top: 0;
      `}
  }
`

const CarouselDot = styled.span`
  background: ${({ theme, isActive }) =>
    isActive ? theme.color.bg.norm : theme.color.bg.modal};
  border-radius: 2px;
  cursor: pointer;
  height: 4px;
  margin: 0 4px;
  transition: all 300ms ease-in-out 150ms;
  width: 17px;
  &:hover {
    background: ${({ theme }) => theme.color.bg.norm};
  }
`
