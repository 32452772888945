import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import C1LogoLight from 'components/graphics/C1Logo'
import Input from 'components/forms/Input'
import Textarea from 'components/forms/Textarea'
import ImageUpload from 'components/forms/ImageUpload'
import NavBar from 'components/styled/Navigation'
import { Button } from 'components/styled/Buttons'
import {
  FormContainer,
  FlexContainer,
  Container
} from 'components/styled/Containers'
import SaveAsDraftModal from 'containers/ProjectPage/SaveAsDraftModal'
import Datafolios from './Datafolios'
import useAppContext from 'services/context/appContext/useAppContext'
import useProfileContext from 'services/context/profileContext/useProfileContext'
import useFetchSuggestableSkills from 'services/hooks/useFetchSuggestableSkills'
import { projectValidations } from 'services/validations/projectValidations'
import close from 'containers/ProfilePage/assets/close'
import backArrowIcon from 'containers/ProjectPage/assets/backArrowIcon'

const ProjectForm = () => {
  const { state, api, requests, appDispatchActions, history } = useAppContext()
  const userSlug = state.profileData?.slug || state.publicProfileData.slug
  const { profileState, profileDispatchActions } = useProfileContext()
  const methods = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(projectValidations),
    shouldFocusError: false
  })
  const { register, errors, reset } = methods
  const [fileUploads, setFileUploads] = useState([])
  const [currentProject, setCurrentProject] = useState(null)
  const { suggestableSkillList } = useFetchSuggestableSkills()
  const [skillText, setSkillText] = useState('')
  const [skills, setSkills] = useState([])
  const [isSaveModalShowing, setIsSaveModalShowing] = useState(false)
  const [cursor, setCursor] = useState(-1)
  const [suggestionSkills, setSuggestionSkills] = useState([])
  const skillInput = useRef()
  const suggestionBoxRef = useRef()

  useEffect(() => {
    const pathCheck = history.location.pathname.split('/').slice(1, 4)
    if (pathCheck[2]) {
      const proj = state.profileData.projects.find(project => {
        return project.id === pathCheck[2]
      })
      const projCopy = { ...proj }
      setFileUploads(projCopy.project_assets)
      if (projCopy.project_skills.length > 0) {
        const s = []
        projCopy.project_skills.forEach((skill, index) => {
          s.push({ id: index, name: skill.name, order: index })
        })
        setSkills(s)
      }
      setCurrentProject(projCopy)

      reset(projCopy)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSuccess = () => {
    let redirectingUrl = `/profile/${userSlug}/`
    api.setState().requested()
    if (
      profileState.formData &&
      (profileState.formData.formActionType === 'create' ||
        profileState.formData.formActionType === 'update')
    ) {
      profileDispatchActions.setProfileTab('resume')
    } else {
      redirectingUrl += 'projects'
      profileDispatchActions.setProfileTab('projects')
    }

    history.push(redirectingUrl)
    appDispatchActions.updatedProfile()
  }

  const onCancel = () => {
    history.goBack()
  }

  const goToProfile = () => {
    history.push(`/profile/${state.profileData.slug}`)
  }

  const handleFormatDataOnCreate = async (data, isPublic) => {
    const dataCopy = { ...data }

    dataCopy.project_skills = skills
    dataCopy.project_assets = fileUploads
    dataCopy.is_public = isPublic

    await api.request(requests().createProject, dataCopy, () => onSuccess())
  }

  const handleFormatDataOnEdit = async (data, id, isPublic) => {
    const dataCopy = { ...data }

    dataCopy.project_skills = skills.map(
      ({ id, ...project_skills }) => project_skills
    )
    dataCopy.project_assets = fileUploads
    dataCopy.is_public = isPublic

    if (!isPublic) {
      dataCopy.is_featured = false
    }

    await api.request(requests(id).updateProject, dataCopy, () => onSuccess())
  }

  const onSubmit = (data, isPublic = true) => {
    api.setState().requesting()
    if (!currentProject) {
      handleFormatDataOnCreate(data, isPublic)
    } else {
      handleFormatDataOnEdit(data, currentProject.id, isPublic)
    }
  }

  const handleDelete = () => {
    profileDispatchActions.setDeleteModal(currentProject.id, 'project')
  }

  const toggleSaveModal = () => {
    setIsSaveModalShowing(!isSaveModalShowing)
  }

  const pathCheck = history.location.pathname.split('/').slice(1, 4)
  const errorCheck = Object.keys(methods.formState.errors).length !== 0

  const handleSkillInputKeyDown = e => {
    if (e.keyCode === 32 && e.target.value.trim().length === 0) {
      e.preventDefault()
    } else if (e.key === 'Escape') {
      setSuggestionSkills([])
      setCursor(-1)
      e.preventDefault()
    } else if (e.key === 'Backspace') {
      setCursor(-1)
    } else if (e.keyCode === 38 && cursor > 0) {
      setCursor(cursor - 1)
      setSkillText(suggestionSkills[cursor - 1])
      suggestionBoxRef.current.scrollTop =
        suggestionBoxRef.current.scrollTop - 25
    } else if (e.keyCode === 40 && cursor < suggestionSkills.length - 1) {
      setCursor(cursor + 1)
      setSkillText(suggestionSkills[cursor + 1])
      suggestionBoxRef.current.scrollTop =
        suggestionBoxRef.current.scrollTop + 25
    } else if (e.key === 'Enter') {
      if (
        skills.length < 5 &&
        !skills.some(
          skill =>
            skill.name.toLowerCase() === e.target.value.toLowerCase().trim()
        )
      ) {
        setSkills(skills => [
          ...skills,
          { id: skills.length, name: skillText, order: skills.length }
        ])
      }
      setSuggestionSkills([])
      setSkillText('')
      setCursor(-1)
      e.preventDefault()
    }
  }

  const handleRemoveSkillOnClick = id => {
    const updatingSkills = skills.filter(skill => {
      return skill.id !== id
    })
    setSkills(updatingSkills)
  }

  const handleSkillInputOnChange = e => {
    const value = e.target.value.trim()
    let suggestions = []
    if (value.length > 0) {
      suggestions = suggestableSkillList.filter(skill => {
        if (!skills.length) {
          return skill.toLowerCase().startsWith(value.toLowerCase())
        } else {
          for (let index = 0; index < skills.length; index++) {
            if (
              skills[index].name.toLowerCase() === skill.toLowerCase() ||
              !skill.toLowerCase().startsWith(value.toLowerCase())
            ) {
              return false
            }
          }
          return true
        }
      })
    }
    setSkillText(e.target.value)
    setSuggestionSkills(suggestions)
  }

  const handleSuggestionSelected = value => {
    if (skills.length < 5) {
      setSkills(skills => [
        ...skills,
        { id: skills.length, name: value, order: skills.length }
      ])
    }
    setSkillText('')
    setSuggestionSkills([])
    setCursor(-1)
    skillInput.current.focus()
  }

  return (
    <ProjectPageWrapper>
      {isSaveModalShowing && (
        <SaveAsDraftModal
          toggleSaveModal={toggleSaveModal}
          isExistingProject={!!currentProject}
          onSubmit={methods.handleSubmit(data => onSubmit(data, false))}
        />
      )}
      <FormProvider {...methods}>
        <NavBar>
          <div className="row between-xs">
            <FlexContainer
              justify="space-between"
              align="center"
              className="actions"
              width="100%"
            >
              <C1LogoLight onClick={goToProfile} />
              <h3 className="sub">
                {pathCheck[2] ? 'Edit Project' : 'Add New Project'}
              </h3>
              <ActionButtonMobileContainer>
                <SaveAsDraftButton
                  className={
                    (errorCheck || api.getState() === 'requesting') &&
                    'disabled'
                  }
                  testId="form-helper-input"
                  onClick={toggleSaveModal}
                >
                  Save as draft
                </SaveAsDraftButton>
                <Button
                  className={
                    (errorCheck || api.getState() === 'requesting') &&
                    'disabled'
                  }
                  testId="form-helper-input"
                  onClick={methods.handleSubmit(data => onSubmit(data))}
                >
                  {api.getState() === 'requesting'
                    ? 'Publishing...'
                    : 'Publish'}
                </Button>
              </ActionButtonMobileContainer>
            </FlexContainer>
          </div>
        </NavBar>
        <Container width="100%" padding="0 20px 0 20px">
          <MaxWidthContainer justify="center" align="center">
            <NewProjectFormContainer
              id="project"
              onSubmit={methods.handleSubmit(onSubmit)}
            >
              <BackButton onClick={onCancel}>{backArrowIcon} Back</BackButton>
              <div className="container-fluid">
                <div className="row center-xs">
                  <div className="col-xs-12 col-md-8 col-lg-8">
                    <ImageContainer>
                      <ImageUpload
                        setFileUploads={setFileUploads}
                        fileUploads={fileUploads}
                        currentImages={
                          currentProject && currentProject.project_assets
                        }
                        thumbnail={
                          currentProject &&
                          currentProject.project_assets[0]?.asset
                        }
                      />
                    </ImageContainer>
                    <Datafolios />
                  </div>
                  <div className="col-xs-12 col-md-4 col-lg-4">
                    <Input
                      testId="project-input-title"
                      type="text"
                      name="title"
                      label="Title"
                      placeholder="Project title"
                      register={register}
                      errors={errors}
                      required="required"
                    />
                    {!!currentProject?.summary && (
                      <Textarea
                        testId="project-input-summary"
                        type="text"
                        name="summary"
                        label="Summary"
                        placeholder="One sentence describing your project."
                        register={register}
                        errors={errors}
                        showCaption
                        maxlength="100"
                        tooltipText="We've removed the summary field for all new projects, but will
                        continue to support it for older projects."
                      />
                    )}
                    <Input
                      testId="project-input-url"
                      type="text"
                      name="link"
                      label="URL"
                      placeholder="https://google.com"
                      register={register}
                      errors={errors}
                    />
                    <Textarea
                      testId="project-input-description"
                      type="text"
                      name="description"
                      placeholder="A longer description of your project."
                      label="Description"
                      register={register}
                      errors={errors}
                      showCaption={true}
                      rows="8"
                      maxlength="280"
                      required="required"
                    />
                    <SuggesstionInputWrapper skillLength={skills.length}>
                      <label>Tools, languages, and skills used</label>
                      <SuggestionBoxWrapper>
                        <input
                          type="text"
                          placeholder="Ex: Matlab, Python and Data visualization"
                          maxLength={32}
                          ref={skillInput}
                          value={skillText}
                          onKeyDown={handleSkillInputKeyDown}
                          onChange={handleSkillInputOnChange}
                        />
                        {!!suggestionSkills.length && (
                          <SuggestionBox ref={suggestionBoxRef}>
                            {suggestionSkills.map((skill, i) => (
                              <SuggestionItem
                                className={cursor === i ? 'active' : null}
                                key={`suggesstion_item_${skill}`}
                                onClick={() => handleSuggestionSelected(skill)}
                              >
                                {skill}
                              </SuggestionItem>
                            ))}
                          </SuggestionBox>
                        )}
                      </SuggestionBoxWrapper>
                      <span>
                        {skills.length < 5
                          ? 'You can add up to 5 skills.'
                          : 'You have reached the max number of skills allowed.'}
                      </span>
                    </SuggesstionInputWrapper>
                    {skills.length ? (
                      <SkillsWraper>
                        <Skills>
                          {skills.map((skill, index) => {
                            return (
                              <Skill key={`skill_tag_${index}`}>
                                {skill.name}
                                <button
                                  type="button"
                                  onClick={() =>
                                    handleRemoveSkillOnClick(skill.id)
                                  }
                                >
                                  {close}
                                </button>
                              </Skill>
                            )
                          })}
                        </Skills>
                      </SkillsWraper>
                    ) : null}
                    {currentProject && (
                      <FlexContainer margin="28px 0 0">
                        <DeleteButton type="button" onClick={handleDelete}>
                          Delete this project
                        </DeleteButton>
                      </FlexContainer>
                    )}
                  </div>
                </div>
              </div>
            </NewProjectFormContainer>
          </MaxWidthContainer>
          <InvertedActionButtonMobileContainer>
            <Button onClick={onCancel}>Cancel</Button>
            <Container margin="0 10px"></Container>
            <Button
              className={
                (errorCheck || api.getState() === 'requesting') && 'disabled'
              }
              testId="form-helper-input"
              onClick={methods.handleSubmit(onSubmit)}
            >
              {api.getState() === 'requesting'
                ? 'Saving...'
                : pathCheck[2]
                ? 'Save'
                : 'Add'}
            </Button>
          </InvertedActionButtonMobileContainer>
        </Container>
      </FormProvider>
    </ProjectPageWrapper>
  )
}

export default ProjectForm

const ProjectPageWrapper = styled(Container)`
  z-index: ${({ theme }) => theme.order.projectPage};
`

const ActionButtonMobileContainer = styled(FlexContainer)`
  display: flex;
  padding-top: 10px;
  @media (max-width: 420px) {
    display: none;
  }
`

const SaveAsDraftButton = styled(Button)`
  background: none;
  border-color: #dfa228;
  color: #ffc043;
`

const InvertedActionButtonMobileContainer = styled(FlexContainer)`
  display: none;
  @media (max-width: 420px) {
    display: flex;
    width: 100%;
    justify-content: center;
    padding-top: 10px;
  }
`

const ImageContainer = styled(Container)`
  margin-right: 112px;
  @media (max-width: 992px) {
    margin-right: 0;
  }
`

const BackButton = styled.button`
  align-items: center;
  background: none;
  border: none;
  color: ${({ theme }) => theme.color.text.darkGray};
  display: flex;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 1px;
  margin-bottom: 40px;
  text-transform: uppercase;
`

const NewProjectFormContainer = styled(FormContainer)`
  width: 100%;
`

const MaxWidthContainer = styled(FlexContainer)`
  max-width: 1140px;
  margin: 0 auto;
`

const DeleteButton = styled.button`
  background: transparent;
  border-bottom: 1px solid ${({ theme }) => theme.color.bg.warningRed};
  border: none;
  color: ${({ theme }) => theme.color.bg.warningRed};
  font-size: 16px;
  font-weight: normal;
  height: 20px;
  letter-spacing: 0.5px;
  padding: 0;
  text-transform: none;
`

const SuggesstionInputWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  label {
    color: ${({ theme }) => theme.color.primary.black5};
    letter-spacing: 0.25px;
    line-height: 17px;
    margin-bottom: 4px;
  }
  input {
    appearance: none;
    background: ${({ theme }) => theme.color.bg.darkNorm};
    border-radius: 4px;
    border: 1px solid ${({ theme }) => theme.color.border.input};
    color: ${({ theme }) => theme.color.primary.black5};
    cursor: text;
    height: 40px;
    margin-bottom: 4px;
    opacity: 1;
    outline: none;
    padding: 12px 14px;
    width: 100%;
    &:focus {
      background: transparent;
      border-color: ${({ theme }) => theme.color.border.active};
    }
  }
  span {
    align-self: flex-end;
    color: ${({ skillLength, theme }) =>
      skillLength === 5
        ? theme.color.bg.warningRed
        : theme.color.primary.black5};
    font-size: 10px;
    font-style: italic;
    letter-spacing: 0.18px;
    line-height: 17px;
  }
`

const SuggestionBoxWrapper = styled.div`
  position: relative;
  width: 100%;
`

const SuggestionBox = styled.div`
  background: ${({ theme }) => theme.color.bg.norm};
  border-radius: 0px 0px 4px 4px;
  border: 1px solid ${({ theme }) => theme.color.border.gray};
  left: 0px;
  max-height: 200px;
  overflow-y: scroll;
  padding: 8px;
  position: absolute;
  scroll-behavior: smooth;
  top: 100%;
  width: 100%;
  z-index: 40;
`

const SuggestionItem = styled.div`
  border-radius: 2px;
  color: rgb(95, 93, 102);
  cursor: pointer;
  font-size: 14px;
  padding: 8px;
  text-align: left;
  transition: all 200ms ease-out 0s;
  &:hover {
    background: ${({ theme }) => theme.color.bg.borderless};
  }
  &.active {
    background: ${({ theme }) => theme.color.bg.borderless};
  }
`

const Skills = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  max-width: 436px;
`

const Skill = styled.span`
  align-items: center;
  background: ${({ theme }) => theme.color.bg.lite};
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.color.border.tag};
  color: ${({ theme }) => theme.color.text.tag};
  display: flex;
  font-size: 12px;
  font-weight: bold;
  height: 24px;
  letter-spacing: 0.4px;
  padding: 4px 24px 4px 13px;
  position: relative;
  width: max-content;
  button:first-of-type {
    appearance: none;
    background-color: transparent;
    border: 0px;
    margin: 0px;
    opacity: 0.5;
    outline: 0px;
    padding: 0px;
    position: absolute;
    right: 13px;
    transition: all 300ms ease-out 0s;
    svg {
      width: 8px;
    }
    &:hover {
      opacity: 1;
    }
  }
`

const SkillsWraper = styled.div`
  padding-bottom: 11px;
  padding-top: 11px;
  label {
    color: rgb(44, 42, 51);
    display: block;
    letter-spacing: 0.25px;
    line-height: 17px;
    margin-bottom: 3px;
    text-align: left;
    svg {
      display: inline;
      vertical-align: middle;
    }
  }
`
