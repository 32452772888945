export const settings = {
  basicInfo: {
    question1: {
      inputs: [
        {
          type: 'email',
          questionName: 'change-email',
          label: 'New Email'
        },
        {
          type: 'email',
          questionName: 'change-email-confirm',
          label: 'Confirm New Email'
        }
      ]
    }
  },
  jobBoard: {
    question1: {
      groupName: 'share_profile',
      vertical: true,
      options: [
        {
          id: 1,
          optionText: 'Always share my Connect profile',
          value: 'Always share'
        },
        {
          id: 2,
          optionText: 'Ask me before sharing',
          value: 'Ask me'
        }
      ]
    }
  },
  generalPrefs: {
    question1: {
      groupName: 'job_search_status',
      vertical: true,
      options: [
        {
          id: 1,
          optionText: 'Actively looking for new job opportunities.',
          value: 'Active'
        },
        {
          id: 2,
          optionText:
            'Not actively looking, but am open to new job opportunities.',
          value: 'Open'
        },
        {
          id: 3,
          optionText: 'Not interested in new job opportunities at this time.',
          value: 'Not interested'
        }
      ]
    },
    question2: {
      groupName: 'employment_type',
      vertical: false,
      options: [
        {
          id: 1,
          optionText: 'Full-time',
          width: '130px',
          value: 'Full-time'
        },
        {
          id: 2,
          optionText: 'Internship',
          width: '140px',
          value: 'Internship'
        }
      ]
    },
    question3: {
      groupName: 'remote_work_status',
      vertical: false,
      options: [
        {
          id: 1,
          optionText: 'Yes',
          width: '90px',
          value: 'Yes'
        },
        {
          id: 2,
          optionText: 'Remote only',
          value: 'Remote only'
        },
        {
          id: 3,
          optionText: 'No',
          width: '85px',
          value: 'No'
        }
      ]
    }
  },
  employerPrefs: {
    question1: {
      questionName: 'locations',
      label: 'Please indicate locations you would be open to relocate to?',
      extraLabelInformation: 'Choose up to 10'
    },
    question1b: {
      question: 'How important is location to you?',
      groupName: 'location_importance',
      vertical: true,
      options: [
        {
          id: 1,
          optionText:
            'Critical (I will only work in the location(s) indicated)',
          value: 'Critical'
        },
        {
          id: 2,
          optionText: 'Moderately important',
          value: 'Moderate'
        },
        {
          id: 3,
          optionText: 'Not important (I will work in any location)',
          value: 'Not'
        }
      ]
    },
    question2: {
      questionName: 'industries',
      label: 'Please indicate your industry preferences:',
      subText: 'Choose as many as you want',
      vertical: true,
      options: []
    },
    question2b: {
      question: 'How important is industry to you?',
      groupName: 'industry_importance',
      vertical: true,
      options: [
        {
          id: 1,
          optionText: 'Critical (I will only work in the industries indicated)',
          value: 'Critical'
        },
        {
          id: 2,
          optionText: 'Moderately important',
          value: 'Moderate'
        },
        {
          id: 3,
          optionText: 'Not important (I will work in any industry)',
          value: 'Not'
        }
      ]
    },
    question3: {
      questionName: 'sizes',
      label: 'Please indicate your company size preferences:',
      subText: 'Choose as many as you want',
      vertical: true,
      options: [
        {
          id: 1,
          optionText: 'Small (0-100 employees)',
          optionValue: 'size_small'
        },
        {
          id: 2,
          optionText: 'Medium (100-1,000 employees)',
          optionValue: 'size_medium'
        },
        {
          id: 3,
          optionText: 'Large (1,000+ employees)',
          optionValue: 'size_large'
        }
      ]
    },
    question3b: {
      question: 'How important is company size to you?',
      groupName: 'size_importance',
      vertical: true,
      options: [
        {
          id: 1,
          optionText: 'Critical (I will only work at companies this size)',
          value: 'Critical'
        },
        {
          id: 2,
          optionText: 'Moderately important',
          value: 'Moderate'
        },
        {
          id: 3,
          optionText: 'Not important (I will work at any size company)',
          value: 'Not'
        }
      ]
    },

    question4: {
      questionName: 'profession_types',
      label: 'Please indicate the types of data roles that interest you:',
      options: [],
      vertical: true
    },
    question4b: {
      question: 'How important is the type of data role to you?',
      groupName: 'role_importance',
      vertical: true,
      options: [
        {
          id: 1,
          optionText: 'Critical (I will only work in this data role)',
          value: 'Critical'
        },
        {
          id: 2,
          optionText: 'Moderately important',
          value: 'Moderate'
        },
        {
          id: 3,
          optionText:
            'Not important (I will consider an alternative data role)',
          value: 'Not'
        }
      ]
    }
  }
}
