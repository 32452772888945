import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import AutoComplete from 'components/forms/AutoComplete'
import useFetchLocations from 'containers/QuestionnairePage/hooks/useFetchLocations'
import { Checkbox, ClearButton } from 'components/forms/DropdownCheckboxList'
import { FlexContainer } from 'components/styled/Containers'
import { Controller, FormProvider, useForm } from 'react-hook-form'

const LocationFilter = ({ handleCheck, handleClear, locations }) => {
  const [show, setShow] = useState(false)
  const [checkedCount, setCheckedCount] = useState(0)
  const dropdownRef = useRef(null)
  const methods = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit'
  })

  const { getValues, watch, control, setValue } = methods

  const watchLocationFilter = watch('location-filter')
  const watchOpenToRemoteFilter = watch('open-to-remote')

  const openToRemote = locations.find(({ value }) => value === 'open-to-remote')
    .checked

  const fetchLocations = useFetchLocations({
    excludeValues: {
      getUsedValues: getValues,
      inputName: 'location-filter'
    }
  })

  const getSelectedLocations = () => {
    const currentLocations = locations.filter(({ label = null }) => label)
    return currentLocations
  }

  const outsideEventListener = e => {
    const elements = e.composedPath()
    if (
      dropdownRef.current === e.target ||
      elements.includes(dropdownRef.current)
    )
      return
    setShow(false)
  }

  useEffect(() => {
    setValue('open-to-remote', Boolean(openToRemote))
  }, [openToRemote, setValue])

  useEffect(() => {
    if (watchLocationFilter?.length || watchOpenToRemoteFilter) {
      const numberOfAppliedFilters =
        watchLocationFilter?.length + watchOpenToRemoteFilter
      setCheckedCount(numberOfAppliedFilters)
    } else {
      setCheckedCount(0)
    }
  }, [watchLocationFilter, watchOpenToRemoteFilter])

  useEffect(() => {
    window.addEventListener('click', outsideEventListener, { passive: true })
    return () => {
      window.removeEventListener('click', outsideEventListener)
    }
  }, [])

  const handleToggleContent = event => {
    if (event.target !== event.currentTarget) return
    setShow(!show)
  }

  return (
    <Dropdown
      ref={dropdownRef}
      anyChecked={checkedCount}
      onClick={handleToggleContent}
      show={show}
    >
      <DropdownTitle>
        {'Location'}
        {checkedCount ? <DropdownInfo>{checkedCount}</DropdownInfo> : null}
      </DropdownTitle>
      <DropdownIcon />
      <ContentContainer show={show}>
        <Content>
          <FormProvider {...methods}>
            <AutoComplete
              key={checkedCount}
              isMulti
              name="location-filter"
              placeholder="Search by city"
              requestFunc={fetchLocations}
              limitSelectedOptions={10}
              onChange={locations => {
                handleCheck('locations', locations)
              }}
              defaultValue={getSelectedLocations()}
            />
            <Separator />
            <FlexContainer align="center" justify="space-between">
              <Controller
                name="open-to-remote"
                control={control}
                defaultValue={openToRemote}
                render={({ onChange, onBlur, value, name, ref }) => (
                  <StyledCheckbox
                    type="checkbox"
                    onChange={e => {
                      onChange(e.target.checked)
                      handleCheck(
                        'open-to-remote',
                        'open-to-remote',
                        e.target.checked
                      )
                    }}
                    onBlur={onBlur}
                    checked={value}
                    name={name}
                    ref={ref}
                    value="Open to remote"
                    isChecked={value}
                  />
                )}
              />

              <ClearButton onClick={e => handleClear('locations', e)}>
                Clear
              </ClearButton>
            </FlexContainer>
          </FormProvider>
        </Content>
      </ContentContainer>
    </Dropdown>
  )
}

export default LocationFilter

const Dropdown = styled.div`
  align-items: center;
  background-color: ${({ anyChecked, theme, show }) =>
    show
      ? theme.color.bg.white
      : anyChecked
      ? theme.color.bg.lite
      : theme.color.border.norm};
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.color.border.input};
  color: ${({ theme }) => theme.color.text.dark};
  cursor: pointer;
  display: flex;
  flex: 1;
  font-size: 14px;
  font-weight: ${({ theme }) => theme.font.weight.regular};
  height: 32px;
  justify-content: space-between;
  letter-spacing: 0.44px;
  padding: 6px 8px 6px 8px;
  position: relative;
  transition: all 100ms ease 0s;
  max-width: 238px;
  &:hover {
    border: 1px solid ${({ theme }) => theme.color.primary.yellow4};
  }
  @media screen and (max-width: 768px) {
    max-width: initial;
    min-width: initial;
    width: 100%;
  }
`

const DropdownTitle = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.color.primary.black3};
  display: flex;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0.25px;
  line-height: 17px;
`

const DropdownInfo = styled.span`
  align-items: center;
  background: ${({ theme }) => theme.color.primary.yellow5};
  border-radius: 50%;
  color: ${({ theme }) => theme.color.text.white};
  display: flex;
  font-size: 11px;
  font-weight: ${({ theme }) => theme.font.weight.regular};
  height: 18px;
  justify-content: center;
  letter-spacing: 0.2px;
  margin-left: 5px;
  padding-top: 1px;
  width: 18px;
`

const DropdownIcon = styled.div`
  display: inline-block;
  margin-bottom: 3px;
  padding: 3px;
  border: solid ${({ theme }) => theme.color.primary.black};
  border-width: 0 1px 1px 0;
  transform: rotate(45deg);
`

const ContentContainer = styled.div`
  display: ${({ show }) => (show ? 'block' : 'none')};
  height: auto;
  position: absolute;
  right: 0;
  top: 35px;
  width: 100%;
  z-index: ${({ theme }) => theme.order.front};
`

const Content = styled.div`
  background-color: ${({ theme }) => theme.color.bg.white};
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border: 1px solid ${({ theme }) => theme.color.border.input};
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 12px 16px;
  width: 100%;
  ::-webkit-scrollbar {
    background-color: ${({ theme }) => theme.color.bg.white};
    width: 16px;
  }
  ::-webkit-scrollbar-track {
    background-color: ${({ theme }) => theme.color.bg.white};
  }
  ::-webkit-scrollbar-track:hover {
    background-color: ${({ theme }) => theme.color.bg.gray};
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.color.bg.liteGray};
    border-radius: 16px;
    border: 5px solid ${({ theme }) => theme.color.border.white};
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: ${({ theme }) => theme.color.bg.darkGray};
    border: 4px solid ${({ theme }) => theme.color.bg.gray};
  }
  ::-webkit-scrollbar-button {
    display: none;
  }
`
const Separator = styled.div`
  background: #d8d8d8;
  height: 1px;
  margin: 15px 0;
`
const StyledCheckbox = styled(Checkbox)`
  cursor: pointer;
  right: 15px;
  white-space: nowrap;
  &:hover {
    background: transparent;
  }
`
