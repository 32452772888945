import * as yup from 'yup'
require('yup-password')(yup)

export const loginValidation = yup.object().shape({
  email: yup
    .string()
    .strict(false)
    .trim()
    .required('Email is required')
    .test('Valid Email', 'Please enter a valid email', value => {
      if (value) {
        return validateEmail(value)
      }
    }),
  password: yup
    .string()
    .strict(false)
    .trim()
    .required('Password is required')
})

export const signupValidation = yup.object().shape({
  password: yup
    .string()
    .strict(false)
    .trim()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters')
    .minUppercase(1, 'Password must contain at least 1 uppercase character')
    .minNumbers(1, 'Password must contain at least 1 number')
    .minSymbols(1, 'Password must contain at least 1 symbol'),
  confirmPassword: yup
    .string()
    .strict(false)
    .trim()
    .required('Confirm Password is required')
    .oneOf([yup.ref('password'), null], 'Passwords must match'),
  privacy_consent: yup
    .boolean()
    .oneOf([true], 'You must accept our Terms and Privacy Policy')
})

export const passwordResetRequestValidation = yup.object().shape({
  email: yup
    .string()
    .strict(false)
    .trim()
    .required('Email is required')
    .test('Valid Email', 'Please enter a valid email', value => {
      if (value) {
        return validateEmail(value)
      }
    })
})

export const passwordResetValidation = yup.object().shape({
  password: yup
    .string()
    .strict(false)
    .trim()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters')
    .minUppercase(1, 'Password must contain at least 1 uppercase character')
    .minNumbers(1, 'Password must contain at least 1 number')
    .minSymbols(1, 'Password must contain at least 1 symbol'),
  confirmPassword: yup
    .string()
    .strict(false)
    .trim()
    .required('Confirm Password is required')
    .oneOf([yup.ref('password'), null], 'Passwords must match')
})

function validateEmail(email) {
  // eslint-disable-next-line
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}
