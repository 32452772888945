import React, { useState } from 'react'
import ReactTooltip from 'react-tooltip'
import styled from 'styled-components'
import useAppContext from 'services/context/appContext/useAppContext'
import useAuth from 'services/hooks/useAuth'
import { FlexContainer } from 'components/styled/Containers'
import bookmark from '../assets/bookmark'
import bookmarkWhite from '../assets/bookmarkWhite'
import checkMark from 'components/forms/assets/checkMark'
import externalLinkIcon from 'containers/JobListingPage/assets/externalLinkIcon'
import lightning from '../assets/lightning'

const CallToAction = ({
  jobListingId,
  isSaved,
  isQuickApply,
  applicationLink,
  completedExternalApplication,
  hasSharedProfile,
  hasApplicationLink,
  setShowModal,
  setShowUndoNotification,
  togglePrompt,
  setTogglePrompt
}) => {
  const { isAuthenticated } = useAuth()
  const [{ api, requests, state }] = useState(useAppContext())
  const { share_profile } = state.jobBoardSettingsData || {}
  const [saved, setSaved] = useState(isSaved)
  const [sharedProfile, setSharedProfile] = useState(hasSharedProfile)

  const applyToJob = async () => {
    await api.request(requests().completeExternalApplication, {
      job_listing_id: jobListingId
    })
    setShowUndoNotification(true)
    setTogglePrompt(!togglePrompt)
  }

  const shareProfile = async () => {
    await api.request(requests().shareProfile, {
      job_listing_id: jobListingId
    })
  }

  const handleSaveJob = () => {
    if (!saved) {
      api.request(requests({ authed: isAuthenticated }).saveJob, {
        job_listing_id: jobListingId
      })
    } else {
      api.request(
        requests({ jobListingId: jobListingId, authed: isAuthenticated })
          .deleteJob,
        {}
      )
    }
    setSaved(!saved)
  }

  const renderAlreadySharedProfile = () => {
    return (
      <CompletedContainer direction="column">
        <CompletedBox align="center" justify="center">
          {checkMark}
          <CompletedText>Your profile has just been shared!</CompletedText>
        </CompletedBox>
      </CompletedContainer>
    )
  }

  const renderShareProfile = () => {
    return (
      <ApplyBox>
        <BoxHeader>Interested?</BoxHeader>
        <p>
          Share your profile with this company so they know you're interested in
          this role.
        </p>
        <ActionContainer>
          <ApplyButton
            onClick={() => {
              shareProfile()
              setSharedProfile(true)
            }}
            align="center"
            justify="center"
          >
            {lightning}
            Quick Apply
          </ApplyButton>
          <SaveButton
            align="center"
            justify="center"
            isSaved={saved}
            onClick={handleSaveJob}
          >
            {saved ? bookmarkWhite : bookmark}
            {saved ? 'Saved' : 'Save'}
          </SaveButton>
        </ActionContainer>
        <QuickApplyInfoBox>
          <ReactTooltip
            backgroundColor="#373440"
            textColor="#D7D6D9"
            place="top"
            effect="solid"
            className="tooltip-content"
          />
          What is{' '}
          <u
            data-tip={
              'By clicking Quick Apply, you are giving us permission to share your profileinformation with our partners, including your contact and resume details.'
            }
          >
            quick apply?
          </u>
        </QuickApplyInfoBox>
      </ApplyBox>
    )
  }

  const handleQuickApply = () => {
    return sharedProfile ? renderAlreadySharedProfile() : renderShareProfile()
  }

  // If the user is not signed in, they are given the option to create an account
  // If the user are signed in they are asked to apply externally
  // If the user have not yet chosen to share their profile they are presented with a modal allowing them to do so
  // If the user HAVE shared their profile they are asked if they have applied
  // If the user say they have applied they are shown an indication that they have applied
  // If the user say they have not applied they begin this same flow again
  const handleCallToAction = () => {
    // togglePrompt is intended to describe the "Did you apply?" prompt
    if (togglePrompt && hasApplicationLink) {
      return (
        <ApplyBox>
          <BoxHeader>Did you apply?</BoxHeader>
          <p>
            Let us know whether you have completed the application on the
            employer's career page
          </p>
          <ButtonsContainer justify="space-between">
            <ConfirmDenyButton onClick={applyToJob}>Yes</ConfirmDenyButton>
            <ConfirmDenyButton onClick={() => setTogglePrompt(false)}>
              No
            </ConfirmDenyButton>
          </ButtonsContainer>
          <a target="blank" href={applicationLink}>
            <ExternalLink align="center">
              <p>Go to application site</p>
              {externalLinkIcon}
            </ExternalLink>
          </a>
        </ApplyBox>
      )
    } else {
      if (completedExternalApplication) {
        return (
          <CompletedContainer direction="column">
            <CompletedBox align="center" justify="center">
              {checkMark}
              <CompletedText>You have applied to this job</CompletedText>
            </CompletedBox>
            <a target="blank" href={applicationLink}>
              <ExternalLink align="center">
                <p>Go to application site</p>
                {externalLinkIcon}
              </ExternalLink>
            </a>
          </CompletedContainer>
        )
      } else {
        if (hasSharedProfile) {
          return hasApplicationLink ? (
            <ApplyBox>
              <BoxHeader>Ready to apply?</BoxHeader>
              <ActionContainer>
                <a target="blank" href={applicationLink}>
                  <ApplyButton
                    onClick={() => {
                      setTogglePrompt(true)
                    }}
                    align="center"
                    justify="center"
                  >
                    Apply Externally
                  </ApplyButton>
                </a>
                <SaveButton
                  align="center"
                  justify="center"
                  isSaved={saved}
                  onClick={handleSaveJob}
                >
                  {saved ? bookmarkWhite : bookmark}
                  {saved ? 'Saved' : 'Save'}
                </SaveButton>
              </ActionContainer>
            </ApplyBox>
          ) : (
            <CompletedContainer direction="column">
              <CompletedBox align="center" justify="center">
                {checkMark}
                <CompletedText>
                  Your profile has just been shared!
                </CompletedText>
              </CompletedBox>
            </CompletedContainer>
          )
        }
        return hasApplicationLink ? (
          <ApplyBox>
            <BoxHeader>Ready to apply?</BoxHeader>
            <ActionContainer>
              <ApplyButton
                onClick={() => {
                  if (share_profile === 'Always share') {
                    shareProfile()
                    setTogglePrompt(true)
                    window.open(applicationLink, '_blank')
                  } else {
                    setShowModal(true)
                  }
                }}
                align="center"
                justify="center"
              >
                Apply Externally
              </ApplyButton>
              <SaveButton
                align="center"
                justify="center"
                isSaved={saved}
                onClick={handleSaveJob}
              >
                {saved ? bookmarkWhite : bookmark}
                {saved ? 'Saved' : 'Save'}
              </SaveButton>
            </ActionContainer>
          </ApplyBox>
        ) : (
          <ApplyBox>
            <BoxInfo>
              This is a private opportunity for DS4A. You do not need to
              complete an external application
            </BoxInfo>
            <ActionContainer>
              <ApplyButton
                onClick={() => {
                  if (share_profile === 'Always share') {
                    shareProfile()
                    setTogglePrompt(true)
                  } else {
                    setShowModal(true)
                  }
                }}
                align="center"
                justify="center"
              >
                Share My Profile
              </ApplyButton>
              <SaveButton
                isSaved={saved}
                onClick={() => {
                  handleSaveJob()
                }}
              >
                {saved ? bookmarkWhite : bookmark}
                {saved ? 'Saved' : 'Save'}
              </SaveButton>
            </ActionContainer>
          </ApplyBox>
        )
      }
    }
  }

  return (
    <>
      {isAuthenticated &&
        (isQuickApply ? handleQuickApply() : handleCallToAction())}
    </>
  )
}

export default CallToAction

const ApplyBox = styled.div`
  box-shadow: 0px 2px 8px 0px rgba(55, 52, 64, 0.2);
  height: fit-content;
  padding: 24px;
  width: 366px;
  p {
    margin-bottom: 18px;
  }
`

const ButtonsContainer = styled(FlexContainer)`
  margin-bottom: 32px;
`

const BoxHeader = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin: 0 0 16px;
`

const BoxInfo = styled.div`
  background: ${({ theme }) => theme.color.bg.lite};
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.color.text.fade};
  color: #373440;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.1px;
  margin-bottom: 24px;
  padding: 16px 32px;
`

const ApplyButton = styled(FlexContainer)`
  background: ${({ theme }) => theme.color.primary.yellow4};
  border-radius: 4px;
  color: ${({ theme }) => theme.color.bg.dark};
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  gap: 5px;
  padding: 13px 0;
  text-align: center;
  text-transform: uppercase;
  width: 193px;
`

const SaveButton = styled(FlexContainer)`
  background: ${({ theme, isSaved }) =>
    !isSaved ? theme.color.text.white : theme.color.text.darkGray};
  border: 1px solid ${({ theme }) => theme.color.bg.darkGray};
  border-radius: 4px;
  color: ${({ theme, isSaved }) =>
    !isSaved ? theme.color.text.darkGray : theme.color.text.white};
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  gap: 4px;
  height: 45px;
  padding: 13px 0;
  text-align: center;
  text-transform: uppercase;
  width: 109px;
`

const ConfirmDenyButton = styled.div`
  background: ${({ theme }) => theme.color.primary.yellow4};
  border-radius: 4px;
  color: ${({ theme }) => theme.color.bg.dark};
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  height: 32px;
  margin-top: 16px;
  padding: 6px 0;
  text-align: center;
  text-transform: uppercase;
  width: 155px;
`

const CompletedContainer = styled(FlexContainer)``

const CompletedBox = styled(FlexContainer)`
  background: #daedd8;
  border-radius: 4px;
  height: fit-content;
  padding: 28px;
  width: 366px;
  svg {
    margin-right: 10px;
  }
`

const CompletedText = styled.p`
  font-size: 16px;
  font-weight: 500;
`

const ExternalLink = styled(FlexContainer)`
  margin-top: 8px;
  p {
    color: ${({ theme }) => theme.color.primary.black3};
    font-weight: 500;
    text-transform: none;
  }
  svg {
    margin-left: 4px;
  }
`

const ActionContainer = styled(FlexContainer)`
  gap: 16px;
  width: 100%;
`

const QuickApplyInfoBox = styled.div`
  font-size: 14px;
  padding-top: 22px;
  u {
    color: ${({ theme }) => theme.color.text.link2};
    cursor: pointer;
  }
  .tooltip-content {
    max-width: 250px;
  }
`
