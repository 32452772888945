import React from 'react'

const suitcaseIcon = (
  <svg
    width="16px"
    height="16px"
    viewBox="0 0 16 16"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      opacity="0.5"
    >
      <g
        transform="translate(-265.000000, -244.000000)"
        fill="#000000"
        fillRule="nonzero"
      >
        <g transform="translate(153.000000, 165.000000)">
          <g transform="translate(112.000000, 5.000000)">
            <g transform="translate(0.000000, 73.787941)">
              <g transform="translate(0.000000, 0.212059)">
                <path
                  d="M10,3.5971897 C9.632,3.5971897 9.33333333,3.23906948 9.33333333,
                2.79781421 L9.33333333,1.59875098 L6.66666667,1.59875098 L6.66666667,2.79781421 
                C6.66666667,3.23906948 6.368,3.5971897 6,3.5971897 C5.632,3.5971897 5.33333333,
                3.23906948 5.33333333,2.79781421 L5.33333333,1.59875098 C5.33333333,0.717039813 
                5.93133333,0 6.66666667,0 L9.33333333,0 C10.0686667,0 10.6666667,0.717039813 
                10.6666667,1.59875098 L10.6666667,2.79781421 C10.6666667,3.23906948 10.368,3.5971897 
                10,3.5971897 Z"
                ></path>
                <path
                  d="M8.47333333,10.695644 C8.35333333,10.7516003 8.18,10.7915691 8,10.7915691 
                C7.82,10.7915691 7.64666667,10.7516003 7.48666667,10.6796565 L0,7.68999219 L0,13.7892272 
                C0,15.0042779 0.82,15.9875098 1.83333333,15.9875098 L14.1666667,15.9875098 C15.18,15.9875098 
                16,15.0042779 16,13.7892272 L16,7.68999219 L8.47333333,10.695644 Z"
                ></path>
                <path
                  d="M16,4.59640906 L16,6.42697892 L8.16,9.56053084 C8.10666667,9.5845121 
                8.05333333,9.59250585 8,9.59250585 C7.94666667,9.59250585 7.89333333,9.5845121 
                7.84,9.56053084 L0,6.42697892 L0,4.59640906 C0,3.38135831 0.82,2.39812646 1.83333333,
                2.39812646 L14.1666667,2.39812646 C15.18,2.39812646 16,3.38135831 16,4.59640906 Z"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default suitcaseIcon
