import React from 'react'

const accountIcon = (
  <svg width="32px" height="32px" viewBox="0 0 32 32" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-236.000000, 0.000000)">
        <g transform="translate(236.000000, 0.000000)">
          <rect x="0" y="0" width="32" height="32" rx="2"></rect>
          <g
            transform="translate(4.000000, 4.000000)"
            fill="#e1e1e1"
            fillRule="nonzero"
          >
            <path
              d="M12,0 C5.38666667,0 0,5.38666667 0,12 C0,18.6133333 5.38666667,24 
                    12,24 C18.6133333,24 24,18.6133333 24,12 C24,5.38666667 18.6133333,0 12,0 Z 
                    M5.86666667,21.0444051 L5.86666667,19.4461538 C5.86666667,16.5128205 
                    8.26666667,14.1128205 11.2,14.1128205 L12.8,14.1128205 C15.7333333,14.1128205 
                    18.1333333,16.5128205 18.1333333,19.4461538 L18.1333333,21.0444051 C16.3733333,
                    22.2444051 14.2666667,22.9377384 12,22.9377384 C9.73333333,22.9377384 7.62666667,
                    22.2444051 5.86666667,21.0444051 Z M12,13.0461538 C10.24,13.0461538 8.8,11.6061538 
                    8.8,9.84615385 C8.8,8.08615385 10.24,6.64615385 12,6.64615385 C13.76,6.64615385 
                    15.2,8.08615385 15.2,9.84615385 C15.2,11.6061538 13.76,13.0461538 12,13.0461538 
                    Z M19.2,20.2133333 L19.2,19.4681189 C19.2,16.5347856 17.2266667,14.0547856 
                    14.5066667,13.3081189 C15.5733333,12.5347856 16.2666667,11.2814522 16.2666667,
                    9.86811889 C16.2666667,7.52145222 14.3466667,5.60145222 12,5.60145222 C9.65333333,
                    5.60145222 7.73333333,7.52145222 7.73333333,9.86811889 C7.73333333,11.2814522 
                    8.42666667,12.5347856 9.49333333,13.3081189 C6.77333333,14.0547856 4.8,16.5347856 
                    4.8,19.4681189 L4.8,20.2133333 C2.50666667,18.2133333 1.06666667,15.28 1.06666667,
                    12 C1.06666667,5.97333333 5.97333333,1.06666667 12,1.06666667 C18.0266667,1.06666667 
                    22.9333333,5.97333333 22.9333333,12 C22.9333333,15.28 21.4933333,18.2133333 
                    19.2,20.2133333 Z"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default accountIcon
