export const sponsorLogos = [
  {
    src:
      'https://storage.googleapis.com/connect-internal-assets/logos/boulder_logo.png',
    height: '20px'
  },
  {
    src:
      'https://storage.googleapis.com/connect-internal-assets/logos/lyft.png',
    height: '22px'
  },
  {
    src:
      'https://storage.googleapis.com/connect-internal-assets/logos/point72.png',
    height: '45px'
  },
  {
    src:
      'https://storage.googleapis.com/connect-internal-assets/logos/twoSigma.png',
    height: '18px'
  },
  {
    src: 'https://storage.googleapis.com/connect-internal-assets/logos/df.png',
    height: '20px'
  },
  {
    src:
      'https://storage.googleapis.com/connect-internal-assets/logos/matchgroup.png',
    height: '20px'
  }
]

export const datafolioExamples = [
  {
    imageUrl:
      'https://storage.googleapis.com/connect-internal-assets/showcase/examples/latam1.png'
  },
  {
    imageUrl:
      'https://storage.googleapis.com/connect-internal-assets/showcase/examples/latam2.png'
  },
  {
    imageUrl:
      'https://storage.googleapis.com/connect-internal-assets/showcase/examples/latam3.png'
  },
  {
    imageUrl:
      'https://storage.googleapis.com/connect-internal-assets/showcase/examples/latam4.png'
  },
  {
    imageUrl:
      'https://storage.googleapis.com/connect-internal-assets/showcase/examples/latam5.png'
  },
  {
    imageUrl:
      'https://storage.googleapis.com/connect-internal-assets/showcase/examples/latam6.png'
  }
]
