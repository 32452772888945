import React, { useRef } from 'react'
import styled from 'styled-components'
import Carousel from 'components/general/Carousel'
import Modal from 'components/styled/Modal'
import { Container, FlexContainer } from 'components/styled/Containers'
import { IconAction } from 'components/styled/Buttons'
import useAppContext from 'services/context/appContext/useAppContext'
import crossMark from 'components/forms/assets/crossMark'
import useClickedOutsideEvent from 'services/hooks/useClickedOutsideEvent'

const ExpandedInspirationProject = ({
  currentProject,
  closeModal,
  setViewProject
}) => {
  const { title, inspiration_project_assets } = currentProject
  const modalRef = useRef(null)
  const { appDispatchActions } = useAppContext()

  useClickedOutsideEvent(modalRef, () => setViewProject(false))

  const zoomIn = imageUrl => {
    appDispatchActions.zoomIn({ src: imageUrl })
  }

  const renderInspirationProjectAssets = () => {
    if (inspiration_project_assets.length > 1) {
      const filepaths = inspiration_project_assets.reduce(
        (arr, { inspiration_asset: { file_path } }) => [
          ...arr,
          { imageUrl: file_path }
        ],
        []
      )
      return (
        <CarouselFlex>
          <Carousel slideType="project-carousel" slides={filepaths} />
        </CarouselFlex>
      )
    } else if (inspiration_project_assets[0]) {
      return (
        <CenterFlex
          onClick={() =>
            zoomIn(inspiration_project_assets[0].inspiration_asset.file_path)
          }
        >
          <ProjImg
            src={inspiration_project_assets[0].inspiration_asset.file_path}
            alt={`Datafolio: ${title}`}
          />
        </CenterFlex>
      )
    } else {
      return (
        <CenterFlex>
          <FillerImg
            src="https://storage.googleapis.com/connect-internal-assets/projects/defaultProjectImage.png"
            alt={`Datafolio: ${title}`}
          />
        </CenterFlex>
      )
    }
  }

  return (
    <ExtendModal>
      <ProjectContainer ref={modalRef}>
        <ExtendIconAction className="close-btn" onClick={closeModal}>
          {crossMark}
        </ExtendIconAction>
        <ExpandedProjectWrapper>
          {renderInspirationProjectAssets()}
        </ExpandedProjectWrapper>
      </ProjectContainer>
    </ExtendModal>
  )
}

export default ExpandedInspirationProject

const ExtendIconAction = styled(IconAction)`
  height: 48px;
  width: 48px;
  background: ${({ theme }) => theme.color.bg.norm};
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
  svg {
    height: 20px;
    width: 20px;
  }
  &:hover {
    background: ${({ theme }) => theme.color.bg.norm};
  }
`

const ProjectContainer = styled.div`
  background: ${({ theme }) => theme.color.bg.norm};
  width: 60vw;
  max-width: 943px;
  position: relative;
  @media (max-width: ${({ theme }) => theme.media.mobile}) {
    width: 100%;
  }
`

const ExtendModal = styled(Modal)`
  width: 100%;
  height: 100%;
  align-items: flex-start;
  justify-content: center;
`

const ProjImg = styled.img`
  width: 100%;
  height: auto;
`

const FillerImg = styled.img`
  height: 48px;
  width: 67px;
`

const CarouselFlex = styled(FlexContainer)`
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0;
  height: auto;
  background-color: ${({ theme }) => theme.color.bg.lite};
  min-height: 400px;
  cursor: zoom-in;
  @media screen and (max-width: ${({ theme }) => theme.media.mobile}) {
    background-color: ${({ theme }) => theme.color.bg.darkModal};
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
  }
`

const CenterFlex = styled(CarouselFlex)`
  justify-content: center;
  align-items: center;
`

const ExpandedProjectWrapper = styled(Container)`
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.color.border.norm};
  width: 100%;
  height: 100%;
  padding: 0;
  @media screen and (max-width: ${({ theme }) => theme.media.mobile}) {
    position: relative;
    height: 100vh;
    width: 100%;
    background: transparent;
  }
`
