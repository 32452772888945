import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { useFormContext } from 'react-hook-form'
import { useDrop } from 'react-dnd'
import DraggableItem from 'components/forms/DraggableItem'
import {
  FlexContainer,
  AbsoluteContainer,
  Container
} from 'components/styled/Containers'
import { Button } from 'components/styled/Buttons'
import { ErrorMessage, InputLabel } from 'components/styled/Text'
import useAppContext from 'services/context/appContext/useAppContext'
import useClickedOutsideEvent from 'services/hooks/useClickedOutsideEvent'
import useProfileContext from 'services/context/profileContext/useProfileContext'
import plusIcon from 'components/forms/assets/plusIcon'
import downArrow from 'components/forms/assets/downArrow'

const DropdownSelect = ({
  label,
  options,
  name,
  placeholder,
  required,
  projectAssociationData,
  setProjectAssociationData,
  isUpdate
}) => {
  const [dropdownState, setDropdownState] = useState(false)
  const [moveNum, setMoveNum] = useState(false)
  const [intStatus, setIntStatus] = useState(false)
  const { state } = useAppContext()
  const history = useHistory()
  const form = useFormContext()
  const { profileState, profileDispatchActions } = useProfileContext()

  // Drag and drop logix
  const calculateMove = monitor => {
    const hovering = monitor.isOver()
    const currentItem = monitor.getItem()
    const offestPosition = monitor.getDifferenceFromInitialOffset()
    let indexMove

    if (hovering && offestPosition) {
      const base = 24
      const deltaY = offestPosition.y
      indexMove = Math.floor(deltaY / base)
      const isPositive = indexMove >= 0

      if (indexMove + currentItem.id > projectAssociationData.length - 1) {
        indexMove = projectAssociationData.length
      } else if (indexMove + currentItem.id < 0) {
        indexMove = -1
      } else {
        indexMove = indexMove + currentItem.id
      }

      if (!moveNum || moveNum !== indexMove) {
        setMoveNum(indexMove)
        setIntStatus(isPositive ? 'positive' : 'negative')
      }
    }

    return false
  }

  const [collectedProps, dropRef] = useDrop({
    accept: 'project',
    collect: (monitor, props) => {
      return {
        hovering: monitor.isOver()
      }
    },
    hover: (item, monitor) => {
      calculateMove(monitor)
    },
    drop: (item, monitor) => {
      const arrItem = projectAssociationData[item.id]
      const filterArr = projectAssociationData.filter((proj, index) => {
        return item.id !== index
      })

      if (intStatus === 'positive') {
        filterArr.splice(moveNum, 0, arrItem)
        setProjectAssociationData(filterArr)
        setMoveNum(false)
        setIntStatus(false)
      } else {
        filterArr.splice(moveNum + 1, 0, arrItem)
        setProjectAssociationData(filterArr)
        setMoveNum(false)
        setIntStatus(false)
      }
    }
  })

  const highlightTopLine = moveNum === -1

  // Destructure projects
  const { projects } = state.profileData

  // Adds to current project array
  const handleAddProject = project => {
    setProjectAssociationData(initialArr => [...initialArr, project])
    setDropdownState(false)
  }

  // handle on add new project
  const handleAddNewProject = () => {
    if (profileState.formData.formActionType === 'create') {
      const formDataCopy = { ...profileState.formData }
      const data = form.getValues()
      formDataCopy.data = data
      profileDispatchActions.setCreateProjectInForm(formDataCopy)
    }
    history.push('/profile/project')
  }

  // handle dropdown state
  const handleDropdownState = () => {
    setDropdownState(!dropdownState)
  }

  // Clicked outside of
  const dropdownRef = useRef(null)
  useClickedOutsideEvent(dropdownRef, () => setDropdownState(false))

  return (
    <Container margin="0 0 16px">
      <FlexContainer width="100%" direction="column" align="flex-start">
        <LabelContainer>
          {label && <InputLabel className={required}>{label}</InputLabel>}
        </LabelContainer>
        {projectAssociationData.length > 0 && (
          <DropContainer
            ref={dropRef}
            collectedProps={collectedProps}
            margin="4px 0 0"
            width="100%"
          >
            <HoverDropLine isHighlighted={highlightTopLine} />
            {projectAssociationData.map((project, index) => {
              const isHighlighted = moveNum === index
              return (
                <Container key={project.id}>
                  <DraggableItem
                    project={project}
                    index={index}
                    projectAssociationData={projectAssociationData}
                    setProjectAssociationData={setProjectAssociationData}
                  />
                  <HoverDropLine isHighlighted={isHighlighted} />
                </Container>
              )
            })}
          </DropContainer>
        )}
        <SelectContainer
          ref={dropdownRef}
          width="100%"
          className="pointer"
          justify="space-between"
          onClick={handleDropdownState}
        >
          <PlaceholderText>Click to Select</PlaceholderText>
          <SVGContainer dropdownState={dropdownState}>{downArrow}</SVGContainer>
          {dropdownState && (
            <DropdownOptions width="100%" left="0">
              <DropdownOption className="icon" onClick={handleAddNewProject}>
                <FlexContainer align="center">
                  <PlusButton>{plusIcon}</PlusButton>Add New Project
                </FlexContainer>
              </DropdownOption>
              {projects.map(project => {
                return (
                  <DropdownOption
                    key={project.id}
                    onClick={() => handleAddProject(project)}
                  >
                    <FlexContainer align="center">
                      <ProjectImg
                        src="https://storage.googleapis.com/c1-assessments-proctoring-binaries/tenant-logo-placeholder.png"
                        alt="project img"
                      />
                      {project.title}
                    </FlexContainer>
                  </DropdownOption>
                )
              })}
            </DropdownOptions>
          )}
        </SelectContainer>
      </FlexContainer>
      {form && form.errors[name] && (
        <ErrorMessage>{form.errors[name].message}</ErrorMessage>
      )}
    </Container>
  )
}

// -- PROP DESCRIPTIONS -- //
// inline: when set to true dropdown question is inline with dropdown
// label: the question text that gets rendered
// options: list of dropdown options
// name: name associated with react-hook-form
// placeholder: initial text showing in dropdown
// currentTags: if any current project values are set

DropdownSelect.propTypes = {
  label: PropTypes.string,
  options: PropTypes.array,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  currentTags: PropTypes.array
}

DropdownSelect.defaultProps = {
  label: 'This is sample dropdown text',
  name: 'select',
  currentTags: []
}

export default DropdownSelect

const HoverDropLine = styled.div`
  height: 2px;
  width: 100%;
  border-radius: 4px;
  background: ${({ isHighlighted }) =>
    isHighlighted ? '#ffc043' : 'transparent'};
`

const DropContainer = styled(Container)`
  padding: 10px 0;
  border-radius: 2px;
`

const SVGContainer = styled(Container)`
  svg {
    transform: ${({ dropdownState }) => dropdownState && `rotate(180deg)`};
  }
`

const LabelContainer = styled(Container)`
  margin-right: 32px;
`

const ProjectImg = styled.img`
  width: 36px;
  margin-right: 12px;
  text-transform: none;
`

const PlaceholderText = styled.div`
  color: ${({ theme }) => theme.color.primary.black3};
  font-size: 14px;
  text-transform: none;
`

const PlusButton = styled(Button)`
  border-radius: 100%;
  padding: 0;
  height: 28px;
  width: 28px;
  margin-right: 8px;
  background: #383440;
  border: 1px solid #383440;
  svg {
    opacity: 1;
  }
  &:hover {
    background: #514c5d;
    border: 1px solid #383440;
  }
`

const SelectContainer = styled(FlexContainer)`
  cursor: pointer;
  background: ${({ theme }) => theme.color.bg.darkNorm};
  height: auto;
  min-height: 40px;
  border-radius: 4px;
  margin: 8px 0 0;
  color: ${({ theme }) => theme.color.primary.black3};
  text-transform: capitalize;
  border: 1px solid
    ${({ theme, errors }) =>
      errors ? theme.color.border.error : theme.color.border.input};
  &:hover {
    background: transparent;
  }
  &:focus {
    background: ${({ theme }) => theme.color.bg.white};
    border-color: ${({ theme }) => theme.color.border.active};
  }
  padding: 12px 14px;
`

const DropdownOptions = styled(AbsoluteContainer)`
  background: #ffffff;
  top: calc(100% + 2px);
  padding: 8px;
  z-index: ${({ theme }) => theme.order.dropdownOptions};
  max-height: 200px;
  overflow: scroll;
  border-radius: 4px;
  border: 1px solid #d7d6d9;
`

const DropdownOption = styled(Container)`
  padding: 12px 8px;
  border-radius: 8px;
  transition: 200ms ease-out;
  text-align: left;
  width: 100%;
  &.list {
    padding: 4px 8px;
  }
  &.icon {
    svg {
      opacity: 1;
    }
  }
  svg {
    opacity: 0;
  }
  &:hover {
    transition: 200ms ease-out;
    background: #e1e1e1;
    svg {
      opacity: 1;
    }
  }
`
