import React, { useState } from 'react'
import styled from 'styled-components'
import { Switch, Route, Redirect } from 'react-router-dom'
import Signup from 'containers/AuthPage/components/Signup'
import Login from 'containers/AuthPage/components/Login'
import PasswordReset from 'containers/AuthPage/components/PasswordReset'
import RequestPasswordReset from 'containers/AuthPage/components/RequestPasswordReset'
import { PageWrapper } from 'components/styled/Wrappers'

const AuthPage = () => {
  const [formErrorMessage, setFormErrorMessage] = useState(null)

  return (
    <AuthPageWrapper>
      <div className="container-fluid">
        <div className="row center-xs">
          <div className="col-md-4 col-sm-6 col-xs-10">
            <Switch>
              <Route
                path="/login"
                children={
                  <Login
                    formErrorMessage={formErrorMessage}
                    setFormErrorMessage={setFormErrorMessage}
                  />
                }
              />
              <Route
                exact
                path="/signup"
                children={
                  <Signup
                    formErrorMessage={formErrorMessage}
                    setFormErrorMessage={setFormErrorMessage}
                  />
                }
              />
              <Route
                exact
                path="/password-reset"
                children={
                  <PasswordReset
                    formErrorMessage={formErrorMessage}
                    setFormErrorMessage={setFormErrorMessage}
                  />
                }
              />
              <Route
                exact
                path="/request-new-password"
                children={<RequestPasswordReset />}
              />
              <Route>
                <Redirect to={`/`} />
              </Route>
            </Switch>
          </div>
        </div>
      </div>
    </AuthPageWrapper>
  )
}

export default AuthPage

const AuthPageWrapper = styled(PageWrapper)`
  padding-bottom: 0px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  @media (max-width: 768px) {
    padding-top: 96px;
  }
`
