import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useFormContext } from 'react-hook-form'
import {
  FlexContainer,
  AbsoluteContainer,
  Container
} from 'components/styled/Containers'
import { ErrorMessage, InputLabel } from 'components/styled/Text'
import useClickedOutsideEvent from 'services/hooks/useClickedOutsideEvent'
import downArrow from 'components/forms/assets/downArrow'

const Select = ({
  inline,
  label,
  options,
  name,
  placeholder,
  required,
  initialValue,
  disabled,
  blankLabel
}) => {
  const [dropdownState, setDropdownState] = useState(false)
  const [previouslySelected, setPreviouslySelected] = useState('')
  const [selectionState, setSelectionState] = useState('')
  const form = useFormContext()

  // Opens and closes dropdown
  const handleDropdownState = () => {
    setDropdownState(!dropdownState)
  }

  const selectOption = option => {
    setSelectionState(option)
    form.setValue(name, option)
    form.formState.submitCount > 0 && form.trigger(name)
  }

  // Clicked outside of
  const dropdownRef = useRef(null)
  useClickedOutsideEvent(dropdownRef, () => setDropdownState(false))

  useEffect(() => {
    if (initialValue && !selectionState) {
      selectOption(initialValue)
    } else if (selectionState && initialValue === 'Present') {
      setPreviouslySelected(selectionState)
      selectOption('')
    } else if (previouslySelected) {
      selectOption(previouslySelected)
    } else {
      selectOption('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue])

  return (
    <SelectWrapper className={disabled && 'disabled'} margin="0 0 16px">
      <FlexContainer
        width="100%"
        direction={inline ? 'row' : 'column'}
        align={inline ? 'center' : 'flex-start'}
      >
        <LabelContainer blankLabel={blankLabel} inline={inline}>
          <InputLabel className={required}>{label}</InputLabel>
        </LabelContainer>
        <SelectContainer
          ref={dropdownRef}
          errors={form && form.errors[name]}
          width="100%"
          className="pointer"
          justify="space-between"
          direction="column"
          onClick={e => {
            handleDropdownState(e)
          }}
        >
          <Selection
            justify="flex-start"
            align="center"
            wrap="nowrap"
            className="sub"
            selectionState={selectionState}
          >
            {selectionState || placeholder || 'Select'}
          </Selection>
          <Arrow>{downArrow}</Arrow>
          <input hidden={true} name={name} ref={form && form.register} />
          {dropdownState && (
            <DropdownOptions width="100%" top="100%" left="0">
              {options &&
                options.map((option, index) => {
                  return (
                    <DropdownOption
                      key={index}
                      onClick={() => selectOption(option)}
                    >
                      {option}
                    </DropdownOption>
                  )
                })}
            </DropdownOptions>
          )}
        </SelectContainer>
      </FlexContainer>
      {form && form.errors[name] && (
        <ErrorMessage>{form.errors[name].message}</ErrorMessage>
      )}
    </SelectWrapper>
  )
}

// -- PROP DESCRIPTIONS -- //
// inline: when set to true dropdown question is inline with dropdown
// label: the question text that gets rendered
// options: list of dropdown options
// name: name associated with react-hook-form
// placeholder: initial text showing in dropdown
// autocomplete: is this an autocomplete?
// multiselect: is this an multi-select with tags?

Select.propTypes = {
  inline: PropTypes.bool,
  label: PropTypes.string,
  options: PropTypes.array,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  autocomplete: PropTypes.bool
}

Select.defaultProps = {
  inline: false,
  name: 'select',
  autocomplete: false
}

export default Select

const SelectWrapper = styled(Container)`
  &.disabled {
    opacity: 0.4;
    cursor: not-allowed;
    pointer-events: none;
  }
`

const LabelContainer = styled(Container)`
  padding-top: ${({ inline }) => (inline ? '5px' : '0px')};
  margin-right: 32px;
  opacity: ${({ blankLabel }) => (blankLabel ? 0 : 1)};
  label {
    white-space: nowrap;
  }
  @media (max-width: 420px) {
    display: ${({ blankLabel }) => blankLabel && 'none'};
  }
`

const SelectContainer = styled(FlexContainer)`
  cursor: pointer;
  background: ${({ theme }) => theme.color.bg.darkNorm};
  height: auto;
  height: 40px;
  border-radius: 4px;
  margin: 8px 0 0;
  border: 1px solid
    ${({ theme, errors }) =>
      errors ? theme.color.border.error : theme.color.border.input};
  &:hover {
    background: transparent;
  }
  &:focus {
    background: transparent;
    border-color: ${({ theme }) => theme.color.border.active};
  }
`

const Selection = styled(FlexContainer)`
  text-transform: capitalize;
  margin-right: 16px;
  text-align: left;
  padding: 8px 16px;
  min-height: 40px;
  height: auto;
  color: ${({ theme, selectionState }) =>
    selectionState ? theme.color.primary.black5 : theme.color.primary.black3};
`

const Arrow = styled.span`
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translate(0, -40%);
`

const DropdownOptions = styled(AbsoluteContainer)`
  background: #ffffff;
  border-radius: 0px 0px 4px 4px;
  border: 1px solid #cbcbcb;
  padding: 8px;
  z-index: ${({ theme }) => theme.order.dropdownOptions};
  max-height: 150px;
  overflow: scroll;
`

const DropdownOption = styled(Container)`
  padding: 8px 8px;
  border-radius: 2px;
  transition: 200ms ease-out;
  text-align: left;
  color: ${({ theme }) => theme.color.primary.black3};
  font-size: 14px;
  &:hover {
    background: #e1e1e1;
  }
`
