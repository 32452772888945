import React, { useState } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import Input from 'components/forms/Input'
import InputSubmit from 'components/forms/InputSubmit'
import { FormContainer } from 'components/styled/Containers'
import { AuthFormTitle, AuthFormLinkText } from 'components/styled/Text'
import FormDivider from 'containers/AuthPage/styled/FormDivider'
import { passwordResetRequestValidation } from 'services/validations/authValidation'
import useAppContext from 'services/context/appContext/useAppContext'

const RequestPasswordReset = () => {
  const [showEmailSent, setShowEmailSent] = useState(false)
  const { api, requests } = useAppContext()
  const methods = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(passwordResetRequestValidation),
    shouldFocusError: false
  })

  // Submit request password reset form
  const onSubmit = async data => {
    await api.request(requests().requestPasswordReset, data)
    setShowEmailSent(true)
  }

  return (
    <FormProvider {...methods}>
      <FormContainer onSubmit={methods.handleSubmit(onSubmit)}>
        <AuthFormTitle>Request Password Reset</AuthFormTitle>
        <Input type="text" name="email" placeholder="Email" />
        <SubmitBtnContainer>
          <InputSubmit
            color="#373440"
            background="#FFFFFF"
            border="#373440"
            text="Request new password"
            disabled={showEmailSent}
          />
        </SubmitBtnContainer>
        {showEmailSent && (
          <SuccessSpanContainer>
            <SuccessSpan>
              Email sent! Check your inbox for information on resetting your
              password.
            </SuccessSpan>
          </SuccessSpanContainer>
        )}
        <ExtendFormDivider />
        <AuthFormLinkText>
          Don't need to reset? <Link to="/login">Back to Log In</Link>
        </AuthFormLinkText>
      </FormContainer>
    </FormProvider>
  )
}

export default RequestPasswordReset

const ExtendFormDivider = styled(FormDivider)`
  margin-top: 0;
`

const SubmitBtnContainer = styled.div`
  margin: 28px 0;
`

const SuccessSpanContainer = styled.div`
  width: 100%;
  text-align: left;
`

const SuccessSpan = styled.span`
  color: ${({ theme }) => theme.color.system.green4};
  font-size: 12px;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  letter-spacing: 0.4px;
  line-height: 16px;
`
