import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { FlexContainer, Container } from 'components/styled/Containers'
import TitleH2 from 'components/styled/titles/TitleH2'
import Subtitle from 'components/styled/titles/Subtitle'
import { ContentWrapper } from 'components/styled/Wrappers'
import { sponsorLogos } from 'containers/HomePage/helpers/content'
import {
  floatingPerson,
  restingReader
} from 'containers/HomePage/assets/graphics'
import { underline } from 'styles/mixins'

const ConnectSlides = ({ slidesRef }) => (
  <ConnectSlidesWrapper ref={slidesRef}>
    <ContentWrapper>
      <ContentContainer>
        <SlideContainer>
          <DataTalentMobileFlexContainer>
            <Container>
              <TitleH2>
                Connecting data talent with the world's best employers.
              </TitleH2>
              <Subtitle>
                Browse our job board to find opportunities from some of the top
                organizations leveraging data & analytics.
              </Subtitle>
              <Link to="/jobs">Learn More</Link>
            </Container>
            <Artwork>{floatingPerson}</Artwork>
          </DataTalentMobileFlexContainer>
          <MobileLogoFlexContainer>
            {sponsorLogos.map((logo, index) => (
              <Logo {...logo} key={`logo-${index}`} />
            ))}
          </MobileLogoFlexContainer>
        </SlideContainer>
        <SlideContainer>
          <MobileFlexContainer>
            <ArtworkReader>{restingReader}</ArtworkReader>
            <ContentContainer>
              <ShiftLeftTitleH2>
                Bring your data projects to life with our library of templates.
              </ShiftLeftTitleH2>
              <Subtitle>
                C1 Connect helps you build a portfolio to feature your best
                work. Browse through our gallery for inspiration.
              </Subtitle>
              <Link to="/showcase">Build your portfolio</Link>
            </ContentContainer>
          </MobileFlexContainer>
        </SlideContainer>
      </ContentContainer>
    </ContentWrapper>
  </ConnectSlidesWrapper>
)

export default ConnectSlides

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
`

const MobileFlexContainer = styled(FlexContainer)`
  margin: 16px auto;
  justify-content: space-between;
  align-items: center;
  @media (max-width: ${({ theme }) => theme.media.mobile}) {
    flex-direction: column-reverse;
  }
`

const Artwork = styled.div`
  @media (max-width: ${({ theme }) => theme.media.mobileSm}) {
    transform: scale(0.8);
  }
`

const ArtworkReader = styled(Artwork)`
  position: relative;
  top: 224px;
  z-index: ${({ theme }) => theme.order.front};
  @media (max-width: ${({ theme }) => theme.media.mobile}) {
    position: relative;
    top: 0px;
  }
`

const DataTalentMobileFlexContainer = styled(FlexContainer)`
  margin: 16px auto;
  justify-content: space-between;
  align-items: center;
  @media (max-width: ${({ theme }) => theme.media.mobile}) {
    flex-direction: column;
  }
`

const MobileLogoFlexContainer = styled(FlexContainer)`
  margin: 16px auto;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  @media (max-width: 1200px) {
    justify-content: center;
  }
`

const ConnectSlidesWrapper = styled(Container)`
  position: relative;
  min-height: 900px;
  background: #f9f5ea;
  width: 100%;
  padding: 64px 16px 32px;
  @media (max-width: ${({ theme }) => theme.media.mobile}) {
    min-height: 300px;
  }
`

const ShiftLeftTitleH2 = styled(TitleH2)`
  position: relative;
  left: -32px;
  max-width: 535px;
  @media (max-width: ${({ theme }) => theme.media.mobile}) {
    left: 0px;
  }
`

const SlideContainer = styled(Container)`
  height: auto;
  min-height: 20vh;
  a {
    font-size: 14px;
    font-weight: ${({ theme }) => theme.font.weight.bold};
    color: ${({ theme }) => theme.color.text.link};
    position: relative;
    ${underline}
  }
`

const Logo = styled.img`
  width: auto;
  height: ${({ height }) => height || '20px'};
  margin: 0 16px 16px 0;
`
