import React from 'react'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { CaptureConsole } from '@sentry/integrations'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import TagManager from 'react-gtm-module'
import { ThemeProvider } from 'styled-components'
import App from 'containers/App'
import ErrorPage from 'containers/ErrorPage/InternalErrorPage'
import { AppProvider } from 'services/context/appContext/AppContext'
import connectTheme from 'styles/themes'
import { BaseConnectStyle } from 'styles/base'
import 'styles/normalize.css'
import 'styles/flexboxgrid.css'
import { name, version } from '../package.json'

console.log('You are using Correlation One ' + name + ' version: ' + version)

if (process.env.REACT_APP_GTM_ID) {
  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM_ID,
    auth: process.env.REACT_APP_GTM_AUTH,
    preview: process.env.REACT_APP_GTM_PREVIEW
  }

  TagManager.initialize(tagManagerArgs)
}

Sentry.init({
  dsn:
    'https://c84545712a544cd4bba631725b8806a4@o1174160.ingest.sentry.io/6375190',
  integrations: [
    new BrowserTracing({
      tracingOrigins: [process.env.REACT_APP_API_URL, /^\//]
    }),
    new CaptureConsole({
      levels: ['error']
    })
  ],
  autoSessionTracking: false,
  environment: process.env.REACT_APP_ENV,
  enabled: process.env.NODE_ENV === 'production',
  release: version,
  tracesSampleRate: 0.05
})

ReactDOM.render(
  <AppProvider>
    <ThemeProvider theme={connectTheme}>
      <Router>
        <Sentry.ErrorBoundary fallback={ErrorPage}>
          <App />
        </Sentry.ErrorBoundary>
      </Router>
      <BaseConnectStyle />
    </ThemeProvider>
  </AppProvider>,
  document.getElementById('root')
)
