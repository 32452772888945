import styled from 'styled-components'

// Extended Header Text
const SurveyTitle = styled.h6`
  margin-bottom: 16px;
`

const AuthFormTitle = styled.h3`
  margin-bottom: 32px;
`

// Extended Body text
const ErrorMessage = styled.p`
  font-size: 14px;
  font-weight: ${({ theme }) => theme.font.weight.medium};
  letter-spacing: 0.18px;
  color: ${({ theme }) => theme.color.text.error};
  width: 100%;
  text-align: left;
  margin-top: 4px;
  &.hidden {
    display: none;
  }
`

const AuthFormLinkText = styled.p`
  color: ${({ theme }) => theme.color.primary.black4};
  font-size: 14px;
  font-weight: ${({ theme }) => theme.font.weight.medium};
  letter-spacing: 0.1px;
  line-height: 24px;
  a {
    font-size: 14px;
    font-weight: ${({ theme }) => theme.font.weight.medium};
    letter-spacing: 0.1px;
    line-height: 24px;
    text-transform: capitalize;
    text-decoration: none;
    color: ${({ theme }) => theme.color.primary.yellow5};
  }
`

const SurveyQuestion = styled.p`
  color: #5f5d66;
  font-family: ${props => props.theme.fontFamily};
  font-size: 14px;
  letter-spacing: 0.25px;
  line-height: 17px;
`

// Extended Form Text
const InputLabel = styled.label`
  display: inline-block;
  margin-bottom: ${props => props.marginBottom};
  position: relative;
  color: ${({ theme }) => theme.color.primary.black5};
  font-size: 14px;
  font-weight: normal;
  height: 17px;
  letter-spacing: 0.25px;
  line-height: 17px;
  &.required {
    &:after {
      position: absolute;
      content: '★';
      right: -10px;
      top: -3px;
      font-size: 6px;
      color: ${({ theme }) => theme.color.text.danger};
    }
  }
`

const InputCaption = styled.p`
  font-weight: ${({ theme }) => theme.font.weight.regular};
  color: rgba(0, 0, 0, 0.5);
  font-size: 12px;
  letter-spacing: 0.4px;
  line-height: 16px;
  position: relative;
  margin-bottom: 16px;
`

export {
  SurveyTitle,
  AuthFormTitle,
  ErrorMessage,
  AuthFormLinkText,
  SurveyQuestion,
  InputLabel,
  InputCaption
}
