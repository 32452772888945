import React from 'react'

const certificateIcon = (
  <svg width="24px" height="22px" viewBox="0 0 24 22" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        transform="translate(-188.000000, -2338.000000)"
        fill="#D7D6D9"
        fillRule="nonzero"
      >
        <g transform="translate(188.000000, 2338.000000)">
          <path d="M2.40000002,0 C1.06800002,0 0,1.03052631 0,2.31578946 L0,13.8947368 C0,15.168421 1.08,16.2105263 2.40000002,16.2105263 L12,16.2105263 L12,22 L15.6,18.5263158 L19.2,22 L19.2,16.2105263 L21.6,16.2105263 C22.92,16.2105263 24,15.168421 24,13.8947368 L24,5.78947367 L24,3.47368421 L24,2.31578946 C24,1.04210526 22.92,0 21.6,0 L16.8,0 L16.8,0 L2.40000002,0 M12,2.31578946 L15.6,4.63157895 L19.2,2.31578946 L19.2,6.36842106 L22.8,8.10526316 L19.2,9.84210527 L19.2,13.8947368 L15.6,11.5789474 L12,13.8947368 L12,9.84210527 L8.4,8.10526316 L12,6.36842106 L12,2.31578946 M2.40000002,2.31578946 L8.40000002,2.31578946 L8.40000002,4.63157895 L2.40000002,4.63157895 L2.40000002,2.31578946 M2.40000002,6.94736842 L6.00000001,6.94736842 L6.00000001,9.26315788 L2.40000002,9.26315788 L2.40000002,6.94736842 M2.40000002,11.5789474 L8.40000002,11.5789474 L8.40000002,13.8947368 L2.40000002,13.8947368 L2.40000002,11.5789474 Z"></path>
        </g>
      </g>
    </g>
  </svg>
)

export default certificateIcon
