import styled from 'styled-components'

const PageWrapper = styled.div`
  width: 100vw;
  margin: 0 auto;
  overflow: auto;
  overflow-x: hidden;
  display: block;
  min-height: 100vh;
  height: auto;
  position: relative;
  background: ${props => props.background};
  padding: 110px 20px 60px;
  &.relaxed {
    padding: 0;
  }
  @media screen and (max-width: 768px) {
    padding: 60px 20px 60px;
  }
`

const ContentWrapper = styled.div`
  width: 100%;
  max-width: 1140px;
  margin: 0 auto;
  padding: 0;
  display: block;
  height: auto;
  position: relative;
  @media screen and (max-width: 768px) {
    padding: 0 16px;
  }
  @media screen and (max-width: 420px) {
    padding: 0 0;
  }
`

export { PageWrapper, ContentWrapper }
