import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useFormContext } from 'react-hook-form'

const Radio = ({ name, groupName, vertical, width, value, helper }) => {
  const form = useFormContext()

  return (
    <RadioLabel
      width={width}
      className={`radios ${helper && 'helper'}`}
      vertical={vertical}
      htmlFor={name}
    >
      <RadioStyled
        type="radio"
        ref={form && form.register}
        name={groupName}
        value={value}
        helper={helper}
        readOnly={false}
      />
      <div className="body1">
        {name}
        {helper && <div className="helper-text">{helper}</div>}
      </div>
    </RadioLabel>
  )
}

// -- PROP DESCRIPTIONS -- //
// name: text rendered next to button
// groupName: name associated with react-hook-form
// vertical: stacks radio options vertically when true

Radio.propTypes = {
  name: PropTypes.string,
  groupName: PropTypes.string,
  vertical: PropTypes.bool,
  width: PropTypes.string,
  helper: PropTypes.string
}

Radio.defaultProps = {
  groupName: 'radios',
  vertical: true
}

export default Radio

const RadioStyled = styled.input`
  appearance: none;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  position: absolute;
  &:before {
    height: 15px;
    width: 15px;
    background: ${({ theme }) => theme.color.bg.norm};
    border: 1px solid ${({ theme }) => theme.color.border.lite};
    border-radius: 100%;
    margin-right: 8px;
    position: absolute;
    content: '';
    top: 9px;
    left: 15px;
    z-index: ${({ theme }) => theme.order.front};
  }
  &:after {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    content: '';
    position: absolute;
    border: 1px solid transparent;
  }
  &:hover {
    cursor: pointer;
    &:after {
      background: #e1e1e1;
      color: #363540;
      cursor: pointer;
    }
  }
  &:focus {
    outline: 0;
  }
  &:active {
    outline: 0;
  }
  &:checked {
    outline: 0;
    &:before {
      background: ${({ theme }) => theme.color.primary.yellow4};
      border-color: ${({ theme }) => theme.color.primary.yellow4};
    }
    &:after {
      border-color: ${({ theme }) => theme.color.primary.black0};
      background: ${({ theme }) => theme.color.bg.white};
    }
  }
`

const RadioLabel = styled.label`
  display: inline-block;
  margin-bottom: ${props => (props.vertical ? '4px' : '0px')};
  margin-right: 4px;
  min-height: 35px;
  &.helper {
    min-height: 44px;
    .helper-text {
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 0.4px;
      color: rgb(135, 133, 140);
      line-height: 14px;
    }
  }
  position: relative;
  outline: 0;
  .body1 {
    white-space: nowrap;
    margin-left: 26px;
    padding: 7px 15px;
    position: absolute;
    pointer-events: none;
    line-height: 19px;
  }
  width: ${props =>
    props.vertical ? '100%' : props.width ? props.width : '160px'};
  @media (max-width: 768px) {
    &.helper {
      min-height: 60px;
      margin-bottom: 8px;
    }
    width: 100%;
    margin-bottom: 5px;
    min-height: 48px;
    .body1 {
      white-space: normal;
    }
  }
`
