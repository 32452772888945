import React from 'react'
import Education from 'containers/ProfilePage/components/Education'
import Certificates from 'containers/ProfilePage/components/Certificates'
import Awards from 'containers/ProfilePage/components/Awards'
import WorkExperiences from 'containers/ProfilePage/components/WorkExperiences'
import Skills from 'containers/ProfilePage/components/Skills'
import Publications from 'containers/ProfilePage/components/Publications'

const Resume = ({ profileData }) => {
  // Profile data
  const {
    awards,
    educations,
    skills,
    work_experiences,
    certificates,
    publications
  } = profileData
  return (
    <>
      <WorkExperiences
        experiences={work_experiences}
        profileData={profileData}
      />
      <Education educations={educations} profileData={profileData} />
      <Certificates certificates={certificates} />
      <Awards awards={awards} />
      <Publications publications={publications} />
      <Skills skills={skills} />
    </>
  )
}

export default Resume
