import React from 'react'

const google = (
  <svg
    width="20px"
    height="20px"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/1999/xlink"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        transform="translate(-552.000000, -331.000000)"
        fill="#FFFFFF"
        fillRule="nonzero"
      >
        <g transform="translate(535.000000, 134.000000)">
          <g transform="translate(1.000000, 62.000000)">
            <g transform="translate(0.000000, 122.000000)">
              <g transform="translate(16.000000, 13.000000)">
                <path
                  d="M6.92259375,8.14978125 C8.08790625,6.61678125 9.9301875,5.625 
                  12,5.625 C13.7027812,5.625 15.3037031,6.28809375 16.5078281,7.49217187 
                  L17.0049844,7.98932812 L20.9824687,4.01189062 L20.4853125,3.51473437 
                  C18.2188125,1.24823437 15.2053594,0 12,0 C8.7946875,0 5.78123437,1.24823437 
                  3.5146875,3.5146875 C3.30895312,3.72046875 3.11184375,3.93253125 2.92303125,4.15017187 
                  L6.92259375,8.14978125 Z"
                ></path>
                <path
                  d="M19.8497812,21.0769687 C20.0674219,20.8881562 20.2795312,20.6910469 
                  20.4853125,20.4853125 C22.7517656,18.2187656 24,15.2053125 24,12 C24,11.2699219 
                  23.9339062,10.5384844 23.8035,9.82607812 L23.6979844,9.2495625 L11.296875,9.2495625 
                  L11.296875,14.8745625 L17.6930625,14.8745625 C17.2507031,15.7545469 16.6179375,16.5048281 
                  15.8556094,17.0827969 L19.8497812,21.0769687 Z"
                ></path>
                <path
                  d="M14.6063437,17.82225 C13.8052031,18.1795781 12.9213281,18.375 
                  12,18.375 C9.9301875,18.375 8.08790625,17.3832187 6.92259375,15.8502187 
                  L2.92303125,19.8497812 C3.11184375,20.0674219 3.30895312,20.2795312 
                  3.5146875,20.4852656 C5.78123437,22.7517656 8.7946875,24 12,24 C14.4335625,24 
                  16.756125,23.2798594 18.7255781,21.9414844 L14.6063437,17.82225 Z"
                ></path>
                <path
                  d="M6.18107812,14.6030156 C5.8239375,13.8077812 5.625,12.9267187 5.625,12 
                  C5.625,11.0732812 5.8239375,10.1922187 6.18107812,9.39698437 L2.05851562,5.27442187 
                  C0.720140625,7.243875 0,9.5664375 0,12 C0,14.4335625 0.720140625,16.756125 
                  2.05851562,18.7255781 L6.18107812,14.6030156 Z"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default google
