import React from 'react'

const notFoundProfileIcon = (
  <svg
    width="285px"
    height="285px"
    viewBox="0 0 285 285"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-577.000000, -213.000000)">
        <g transform="translate(577.000000, 213.000000)">
          <circle fill="#F9F5EA" cx="142.5" cy="142.5" r="142.5"></circle>
          <g transform="translate(26.000000, 74.000000)">
            <g transform="translate(54.952807, 0.000000)">
              <ellipse
                fill="#FFC043"
                cx="51.4537666"
                cy="61.4329609"
                rx="51.4537666"
                ry="51.5"
              ></ellipse>
              <ellipse
                stroke="#2A2A3B"
                strokeWidth="3"
                cx="55.4344262"
                cy="53.575419"
                rx="53.5273224"
                ry="53.575419"
              ></ellipse>
              <path
                d="M46.8077876,95.3870413 C49.6342013,95.9092182 52.5097475,96.1805769
                55.4344262,96.2011173 C78.9548153,96.2011173 98.0218579,77.1169421 
                98.0218579,53.575419 C98.0218579,30.0338959 78.9548153,10.9497207 
                55.4344262,10.9497207 C49.3924132,10.9497207 43.6442739,12.2090743 
                38.4377344,14.4798331 C29.3899474,18.4258977 21.9777142,25.4262611 
                17.501058,34.1797319 C14.5255919,39.9978367 12.8469945,46.590423
                12.8469945,53.575419 C12.8469945,59.9129812 14.2288375,65.9275066 
                16.7074064,71.3336222 C18.4769296,75.193207 20.8054488,78.7426883 
                23.5892137,81.8782225"
                stroke="#2A2A3B"
                strokeWidth="3"
                strokeLinecap="round"
              ></path>
              <path
                d="M29.2568306,86.0335196 C32.519186,88.9794556 
                36.2048304,91.3691687 40.1967213,93.0726257"
                stroke="#2A2A3B"
                strokeWidth="3"
                strokeLinecap="round"
              ></path>
              <path
                d="M55.8251366,24.2458101 C53.1328517,24.2458101 50.5242528,24.604434 
                48.044423,25.2765582 C35.4188451,28.6985496 26.1311475,40.2469114 
                26.1311475,53.9664804"
                stroke="#2A2A3B"
                strokeWidth="3"
                strokeLinecap="round"
              ></path>
              <line
                x1="99.5846995"
                y1="96.2011173"
                x2="109.743169"
                y2="106.368715"
                stroke="#2A2A3B"
                strokeWidth="3"
              ></line>
              <path
                d="M103.790502,105.103437 C107.585534,101.304995 113.74126,101.302231 
                117.539701,105.097262 C117.54176,105.099319 117.543818,105.101377 
                117.545876,105.103437 L135.231356,122.804808 C139.030796,126.607662 
                139.030796,132.769687 135.231356,136.572541 C131.436324,140.370983 
                125.280598,140.373747 121.482157,136.578715 C121.480098,136.576658 
                121.47804,136.5746 121.475982,136.572541 L103.790502,118.87117 
                C99.9910618,115.068316 99.9910618,108.906291 103.790502,105.103437 Z"
                fill="#FFC043"
              ></path>
              <path
                d="M113.008759,101.406428 C115.112991,101.405483 117.217584,102.207276 
                118.822827,103.811077 L118.822827,103.811077 L136.514482,121.518623 
                C138.121626,123.127211 138.925198,125.234759 138.925198,127.342306 
                C138.925198,129.449853 138.121626,131.5574 136.514482,133.165989 
                C134.909728,134.772185 132.805856,135.575867 130.701624,135.576812 
                C128.597391,135.577757 126.492799,134.775964 124.887555,133.172163 
                L124.887555,133.172163 L107.195901,115.464617 C105.588756,113.856029 
                104.785184,111.748482 104.785184,109.640934 C104.785184,107.533387 
                105.588756,105.42584 107.195901,103.817252 C108.800655,102.211056 
                110.904527,101.407373 113.008759,101.406428 Z"
                stroke="#2A2A3B"
                strokeWidth="3"
              ></path>
              <line
                x1="115.213115"
                y1="107.932961"
                x2="132.404372"
                y2="125.139665"
                stroke="#FFFFFF"
                strokeWidth="2.5"
                strokeLinecap="round"
              ></line>
            </g>
            <path
              d="M205.681023,41.4260911 L178.211982,41.4260911 M217.373123,41.4260911 
              L213.48296,41.4260911 M195.588212,54.0701597 L183.139691,54.0701597"
              stroke="#2A2A3B"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
            <path
              d="M44.9117806,75.399391 L17.5599723,75.399391 M7.66964955,75.399391 
              L0.475192046,75.399391 M51.9057091,89.0959305 L39.5103159,89.0959305"
              stroke="#2A2A3B"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default notFoundProfileIcon
