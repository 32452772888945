import React from 'react'
import styled from 'styled-components'
import { ContentWrapper } from 'components/styled/Wrappers'
import { FlexContainer, Container } from 'components/styled/Containers'
import TitleH2 from 'components/styled/titles/TitleH2'
import {
  whyMakeDatafolios,
  datafolioSteps
} from 'containers/HowToShowcasePage/helpers/content'
import { underline } from 'styles/mixins'

const DatafolioSetup = () => {
  return (
    <DatfolioSetupWrapper>
      <ContentWrapper>
        <ContentContainer>
          <ExtendTitleH2>Why make a datafolio</ExtendTitleH2>
          <IconFlex>
            {whyMakeDatafolios.map(({ asset, sub }, index) => (
              <IconSet key={`set-${index}`}>
                <Icon>{asset}</Icon>
                <BodyContent>{sub}</BodyContent>
              </IconSet>
            ))}
          </IconFlex>
        </ContentContainer>
        <ContentContainer>
          <ExtendTitleH2>How to create a datafolio</ExtendTitleH2>
          {datafolioSteps.map(({ num, head, copy }, index) => (
            <Step key={`step-${index}`}>
              <Number>
                <Num>{num}</Num>
              </Number>
              <Content>
                <Header>{head}</Header>
                {copy}
              </Content>
            </Step>
          ))}
        </ContentContainer>
      </ContentWrapper>
    </DatfolioSetupWrapper>
  )
}

export default DatafolioSetup

const DatfolioSetupWrapper = styled(Container)`
  background: ${({ theme }) => theme.color.bg.liteYellow};
  width: 100%;
  padding: 64px 20px;
  justify-content: center;
  align-items: center;
`

const Header = styled.h5`
  margin: 0 0 16px;
`

const IconFlex = styled(FlexContainer)`
  justify-content: space-between;
  margin: 32px 0;
  @media (max-width: ${({ theme }) => theme.media.mobile}) {
    flex-direction: column;
    align-items: center;
  }
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
`

const ExtendTitleH2 = styled(TitleH2)`
  text-align: center;
`

const Icon = styled.div`
  margin: 0 0 16px;
`

const IconSet = styled.div`
  margin: 16px 0 32px;
  width: 25%;
  overflow: hidden;
  white-space: wrap;
  @media (max-width: ${({ theme }) => theme.media.mobile}) {
    width: 100%;
  }
`

const Step = styled(FlexContainer)`
  align-items: center;
  margin: 16px 0 0;
  a {
    line-height: 2em;
    color: ${({ theme }) => theme.color.text.link};
    position: relative;
    ${underline}
  }
`

const Number = styled.div`
  width: 10%;
  margin: 0 32px 0 0;
  text-align: center;
`

const Num = styled.h1`
  font-family: ${({ theme }) => theme.font.headFamily};
  font-size: 120px;
  font-weight: normal;
  letter-spacing: 0.93px;
  color: ${({ theme }) => theme.color.text.fade};
  line-height: 1;
`

const Content = styled.div`
  width: 90%;
  text-align: left;
  margin-left: 12px;
`

const BodyContent = styled.div`
  width: 90%;
  text-align: left;
`
