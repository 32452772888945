import React, { createContext, useReducer } from 'react'
import styled from 'styled-components'
import settingsActionTypes from './settingsActionTypes'

// Set object with initial state
const initialSettingsState = {
  formData: null,
  formSubmissions: 0,
  formExpanded: null
}

// Create context
export const SettingsContext = createContext(initialSettingsState)

// Set Reducer
const settingsReducer = (state, action) => {
  const { type, payload } = action
  const {
    FORM_EXPANDED,
    SET_UPDATE_FORM,
    FORM_SUBMITTED,
    REMOVE_FORM_DATA
  } = settingsActionTypes

  switch (type) {
    case FORM_EXPANDED:
      return { ...state, formExpanded: payload }
    case SET_UPDATE_FORM:
      return { ...state, formData: payload }
    case FORM_SUBMITTED:
      return { ...state, formSubmissions: state.formSubmissions + 1 }
    case REMOVE_FORM_DATA:
      return { ...state, formData: null }
    default:
      return state
  }
}

// Create profile provider
export const SettingsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(settingsReducer, initialSettingsState)

  return (
    <SettingsWrapper>
      <SettingsContext.Provider value={{ state, dispatch }}>
        {children}
      </SettingsContext.Provider>
    </SettingsWrapper>
  )
}

const SettingsWrapper = styled.div`
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.color.bg.norm};
`
