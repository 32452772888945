import filterLocationResults from 'containers/QuestionnairePage/helpers/filterLocationResults'
import { useAppContext } from 'services/modules/helpers'

const useFetchLocations = ({ excludeValues = {} } = {}) => {
  const { api, requests } = useAppContext()

  const fetchLocations = (inputText, callback) => {
    api.request(
      requests(inputText).getGeocodingPlace,
      null,
      response => {
        const results = filterLocationResults(response.data)
        const { getUsedValues = null, inputName = null } = excludeValues
        const shouldExcludeUsedValues = getUsedValues && inputName
        if (shouldExcludeUsedValues) {
          const usedResults = getUsedValues(inputName) || []
          const usedLabels = usedResults.map(({ label }) => label) || []
          const filteredResults = results.filter(
            ({ label }) => !usedLabels.includes(label)
          )
          return callback(filteredResults)
        } else {
          return callback(results)
        }
      },
      error => console.error(error)
    )
  }

  return fetchLocations
}

export default useFetchLocations
