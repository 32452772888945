import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const ProfileCompletionNotificaiton = ({
  actionItemsRemaining,
  totalSteps
}) => {
  return (
    <ProfileCompletionContainer>
      <BarAndButtonContainer>
        <ProgressContainer>
          <ProgressBarContainer>
            <ProgressBar
              percentComplete={
                ((totalSteps - actionItemsRemaining) / totalSteps) * 100
              }
            />
          </ProgressBarContainer>
          <p>
            ({totalSteps - actionItemsRemaining}/{totalSteps})
          </p>
        </ProgressContainer>
        <Link to="/profile">
          <GoToProfileButton>Go to profile</GoToProfileButton>
        </Link>
      </BarAndButtonContainer>
      <>
        <ActionItemsRemainingText>
          {actionItemsRemaining} step
          {actionItemsRemaining > 1 ? 's' : ''} remaining.
        </ActionItemsRemainingText>
        <ProfileCompletionText>
          Completing your profile increases your chances of matching with a
          company.
        </ProfileCompletionText>
      </>
    </ProfileCompletionContainer>
  )
}

export default ProfileCompletionNotificaiton

const ProfileCompletionContainer = styled.div`
  background: #f2f2f2;
  border-radius: 4px;
  height: 122px;
  margin-top: 16px;
  padding: 16px;
  width: 100%;
`

const BarAndButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const ProgressContainer = styled.div`
  align-items: center;
  display: flex;
`

const ProgressBarContainer = styled.div`
  background: #d3d3d5;
  border-radius: 4px;
  height: 8px;
  margin-right: 15px;
  width: 151px;
`

const ProgressBar = styled.div`
  background: #373440;
  border-radius: 4px;
  height: 100%;
  width: ${({ percentComplete }) => `${percentComplete}%`};
`

const GoToProfileButton = styled.button`
  border: 1px solid #979797;
  border-radius: 4px;
  color: #373440;
  font-size: 14px;
  font-weight: bold;
  padding: 5px 11px;
  text-transform: uppercase;
`

const ActionItemsRemainingText = styled.p`
  color: #373440;
  font-size: 14px;
  font-weight: 500;
`

const ProfileCompletionText = styled(ActionItemsRemainingText)`
  color: #5f5d66;
  margin-top: 4px;
`
