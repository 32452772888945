import React, { useRef } from 'react'
import styled from 'styled-components'
import { PageWrapper } from 'components/styled/Wrappers'
import HomeHero from './components/HomeHero'
import ConnectSlides from './components/ConnectSlides'
import DatafolioCarousel from './components/DatafolioCarousel'
import FooterCTA from 'components/general/FooterCTA'
import ImageZoomModal from 'components/general/ImageZoomModal'
import useAppContext from 'services/context/appContext/useAppContext'
import useScrollToTop from 'services/hooks/useScrollToTop'

const HomePage = () => {
  useScrollToTop()
  const { state } = useAppContext()
  const { zoomIn, zoomImgSrc } = state
  const slides = useRef(null)

  return (
    <HomePageWrapper>
      {zoomIn && <ImageZoomModal imgSrc={zoomImgSrc} />}
      <HomeHero slidesRef={slides} />
      <ConnectSlides slidesRef={slides} />
      <DatafolioCarousel />
      <FooterCTA bgColor="#2c2a33">
        <h1>Create your data portfolio with C1 Connect.</h1>
      </FooterCTA>
    </HomePageWrapper>
  )
}

export default HomePage

const HomePageWrapper = styled(PageWrapper)`
  overflow: hidden;
  padding: 0;
  @media screen and (max-width: ${({ theme }) => theme.media.mobile}) {
    padding: 0;
  }
`
