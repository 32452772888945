import React from 'react'

const settingsIcon = (
  <svg
    width="23px"
    height="20px"
    viewBox="0 0 23 27"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Engineer-files"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="graphics" transform="translate(-380.000000, -399.000000)">
        <g id="Group-3" transform="translate(381.000000, 399.000000)">
          <line
            x1="3.5"
            y1="0.5"
            x2="3.5"
            y2="3.74772758"
            id="Line-6"
            stroke="#d7d6d9"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></line>
          <line
            x1="10.5"
            y1="0.5"
            x2="10.5"
            y2="15.5"
            id="Line-6-Copy"
            stroke="#d7d6d9"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></line>
          <line
            x1="17.5"
            y1="0.5"
            x2="17.5"
            y2="10.5"
            id="Line-6-Copy-2"
            stroke="#d7d6d9"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></line>
          <line
            x1="3.5"
            y1="9"
            x2="3.5"
            y2="26.5"
            id="Line-6-Copy-3"
            stroke="#d7d6d9"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></line>
          <line
            x1="10.5"
            y1="21.5"
            x2="10.5"
            y2="26.5"
            id="Line-6-Copy-4"
            stroke="#d7d6d9"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></line>
          <line
            x1="17.5"
            y1="16.5"
            x2="17.5"
            y2="26.5"
            id="Line-6-Copy-5"
            stroke="#d7d6d9"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></line>
          <circle
            id="Oval"
            fill="#d7d6d9"
            transform="translate(4.000000, 6.000000) rotate(-30.000000) translate(-4.000000, -6.000000) "
            cx="4"
            cy="6"
            r="3"
          ></circle>
          <path
            d="M3.5,9 C4.88071187,9 6,7.88071187 6,6.5 C6,5.11928813 4.88071187,4 3.5,4 C2.11928813,4 1,5.11928813 1,6.5 C1,6.83896291 1.06745904,7.16216992 1.18968406,7.45692798"
            id="Oval"
            stroke="#373440"
            strokeLinecap="round"
            strokeLinejoin="round"
            transform="translate(3.500000, 6.500000) rotate(-150.000000) translate(-3.500000, -6.500000) "
          ></path>
          <circle
            id="Oval-Copy-2"
            fill="#d7d6d9"
            transform="translate(11.000000, 18.000000) rotate(-30.000000) translate(-11.000000, -18.000000) "
            cx="11"
            cy="18"
            r="3"
          ></circle>
          <path
            d="M10.4150635,21 C11.7957754,21 12.9150635,19.8807119 12.9150635,18.5 C12.9150635,17.1192881 11.7957754,16 10.4150635,16 C9.03435163,16 7.91506351,17.1192881 7.91506351,18.5 C7.91506351,18.8389629 7.98252255,19.1621699 8.10474757,19.456928"
            id="Oval-Copy"
            stroke="#373440"
            strokeLinecap="round"
            strokeLinejoin="round"
            transform="translate(10.415064, 18.500000) rotate(-150.000000) translate(-10.415064, -18.500000) "
          ></path>
          <circle
            id="Oval-Copy-3"
            fill="#d7d6d9"
            transform="translate(18.000000, 14.000000) rotate(-30.000000) translate(-18.000000, -14.000000) "
            cx="18"
            cy="14"
            r="3"
          ></circle>
          <path
            d="M17.4150635,16 C18.7957754,16 19.9150635,14.8807119 19.9150635,13.5 C19.9150635,12.1192881 18.7957754,11 17.4150635,11 C16.0343516,11 14.9150635,12.1192881 14.9150635,13.5 C14.9150635,13.8389629 14.9825225,14.1621699 15.1047476,14.456928"
            id="Oval-Copy-4"
            stroke="#373440"
            strokeLinecap="round"
            strokeLinejoin="round"
            transform="translate(17.415064, 13.500000) rotate(-150.000000) translate(-17.415064, -13.500000) "
          ></path>
        </g>
      </g>
    </g>
  </svg>
)

export default settingsIcon
