export const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
]

export const populateYears = (year = 2030) => {
  const years = []
  do {
    years.push(year)
    year = year - 1
  } while (year > 1959)

  return years
}
