import React, { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useDropzone } from 'react-dropzone'
import AddExtraImage from 'components/forms/AddExtraImage'
import {
  Container,
  FlexContainer,
  AbsoluteContainer
} from 'components/styled/Containers'
import { ErrorMessage } from 'components/styled/Text'
import useAppContext from 'services/context/appContext/useAppContext'
import DragAndDrop from 'components/forms/assets/dragAndDrop'
import close from 'containers/ProfilePage/assets/close'
import thumbnailIcon from 'components/forms/assets/thumbnailIcon'
import connectTheme from 'styles/themes'

const ImageUpload = ({
  setFileUploads,
  fileUploads,
  currentImages,
  thumbnail
}) => {
  const { api, requests } = useAppContext()
  const [currentImage, setCurrentImage] = useState('')
  const [otherUploadImages, setOtherUploadImages] = useState([])
  const [loadingImages, setLoadingImages] = useState(false)
  const [uploadError, setUploadError] = useState(false)
  const [uploadErrorMessage, setUploadErrorMessage] = useState('')

  const fileTypes = [
    'image/jpeg',
    'image/png',
    'application/pdf',
    'application/vnd.ms-powerpoint'
  ]

  const isFileTypeValid = file => {
    return fileTypes.includes(file.type)
  }

  const isFileSizeValid = file => {
    const fileSize = parseFloat(file.size / (1024 * 1024)).toFixed(2)
    return fileSize < 32 //32mb
  }

  useEffect(() => {
    if (currentImages) {
      thumbnail && setCurrentImage(thumbnail.file_path)

      const arrCopy = currentImages.map(img => img.asset.file_path)
      setOtherUploadImages(arrCopy)
    }
  }, [currentImages, thumbnail])

  const onDrop = useCallback(
    acceptedFiles => {
      acceptedFiles.forEach(file => {
        let hasError = false
        if (!isFileTypeValid(file)) {
          hasError = true
          setUploadErrorMessage(
            `Please upload either a PDF or an image (PNG, JPG, PPT).`
          )
          setUploadError(true)
        }

        if (!hasError && !isFileSizeValid(file)) {
          hasError = true
          setUploadErrorMessage(`Please upload a file smaller than 32MB.`)
          setUploadError(true)
        }

        if (!hasError) {
          setLoadingImages(true)
          let formData = new FormData()
          formData.append('file_path', file)
          formData.append('convert_pdfs', true)

          api.request(
            requests().uploadAsset,
            formData,
            ({ data }) => {
              const order = !fileUploads.length ? 0 : fileUploads.length
              const newFiles = data.map((file, index) => {
                return {
                  path: file.file_path,
                  asset: { id: file.id },
                  order: order + index
                }
              })

              if (uploadError) setUploadError(false)
              setLoadingImages(false)
              setCurrentImage(newFiles[0])
              setOtherUploadImages([...otherUploadImages, ...newFiles])
              setFileUploads([...fileUploads, ...newFiles])
            },
            error => {
              setLoadingImages(false)
              setUploadError(true)
              if (error.response.status === 400) {
                setUploadErrorMessage('Something went wrong')
              } else if (error.response.status === 500) {
                setUploadErrorMessage(
                  'PDF uploads should only include a max of 5 pages.'
                )
              }
            }
          )
        }
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [api]
  )

  const handleRemoveImage = (img, index) => {
    const newImgArr = otherUploadImages.filter(image => image !== img)
    const newFileUploadsArr = fileUploads.filter((file, ind) => ind !== index)

    if (!newImgArr.length) {
      setCurrentImage('')
    } else if (newImgArr.indexOf(img) === -1) {
      setCurrentImage(newImgArr[newImgArr.length - 1])
    }

    setOtherUploadImages(newImgArr)
    setFileUploads(newFileUploadsArr)
  }
  const handleCurrentImageClick = img => {
    setCurrentImage(img)
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })
  return (
    <Container>
      <ImageUploadWrapper
        width="100%"
        direction="column"
        isDragActive={isDragActive}
      >
        <DragContainer
          align="center"
          height="90%"
          width="100%"
          {...getRootProps()}
        >
          {otherUploadImages.length > 0 && isDragActive && (
            <AbsoluteContainer
              width="100%"
              height="100%"
              top="0"
              left="0"
              zindex={connectTheme.order.front}
            >
              <FlexContainer justify="center" align="center" height="100%">
                <DropMessage>DROP</DropMessage>
              </FlexContainer>
            </AbsoluteContainer>
          )}
          {loadingImages && (
            <AbsoluteContainer
              width="100%"
              height="100%"
              top="0"
              left="0"
              zindex={connectTheme.order.front}
              background="#f8f8f8"
            >
              <FlexContainer justify="center" align="center" height="100%">
                <DropMessage>Loading Files...</DropMessage>
              </FlexContainer>
            </AbsoluteContainer>
          )}
          <input {...getInputProps()} accept={fileTypes} />
          {currentImage && (
            <FlexContainer
              width="100%"
              direction="column"
              align="center"
              justify="center"
              className="content"
            >
              <FlexContainer
                justify="center"
                align="center"
                className="image-container"
              >
                <img
                  src={currentImage.path || currentImage}
                  alt="Datafolio preview..."
                />
              </FlexContainer>
            </FlexContainer>
          )}
          {!currentImage && (
            <FlexContainer
              width="100%"
              direction="column"
              align="center"
              justify="center"
            >
              <DragAndDrop dragging={isDragActive} />
              <Container height="50px" margin="24px 0 0" width="250px">
                <h5>
                  {isDragActive
                    ? `Drop the files here ...`
                    : `Drag and drop a file(s), or click to select`}
                </h5>
              </Container>
            </FlexContainer>
          )}
        </DragContainer>
        {!currentImage && (
          <FlexContainer width="100%" justify="center" padding="16px 0">
            <p className="sub">Supported file types: PNG, JPG, PDF, and PPT.</p>
          </FlexContainer>
        )}
      </ImageUploadWrapper>
      {uploadError && (
        <Container margin="0 0 20px">
          <ErrorMessage>{uploadErrorMessage}</ErrorMessage>
        </Container>
      )}
      <FlexContainer wrap="wrap">
        {otherUploadImages.map((image, index) => {
          return (
            <SmallUploadContainer key={index} className="pointer">
              {image && (
                <AbsoluteContainer
                  onClick={() => handleRemoveImage(image, index)}
                  className="delete-btn"
                  top="-12px"
                  right="6px"
                  zindex={connectTheme.order.front}
                >
                  <RemoveImageButton>{close}</RemoveImageButton>
                </AbsoluteContainer>
              )}
              {image && index === 0 && (
                <AbsoluteContainer
                  top="-12px"
                  left="-12px"
                  zindex={connectTheme.order.front}
                >
                  <Container className="thumbnail">{thumbnailIcon}</Container>
                </AbsoluteContainer>
              )}
              <SmallImageUploadWrappers
                className="pointer"
                onClick={() => handleCurrentImageClick(image)}
              >
                <FlexContainer
                  justify="center"
                  align="center"
                  className="image-container"
                >
                  {image && (
                    <img src={image.path || image} alt="Datafolio preview..." />
                  )}
                </FlexContainer>
              </SmallImageUploadWrappers>
            </SmallUploadContainer>
          )
        })}
        {otherUploadImages.length > 0 && <AddExtraImage onDrop={onDrop} />}
      </FlexContainer>
    </Container>
  )
}

export default ImageUpload

// -- PROP DESCRIPTIONS -- //
// name: name associated with react-hook-form
// className: add a classname

ImageUpload.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string
}

const DropMessage = styled(Container)`
  background: rgb(0, 0, 0, 0.4);
  font-size: 50px;
  padding: 20px 20px;
  border-radius: 4px;
  color: #ffffff;
`

const SmallUploadContainer = styled(Container)`
  .delete-btn {
    visibility: hidden;
    opacity: 0;
    transition: all 300ms ease-out;
  }
  &:hover {
    .delete-btn {
      visibility: visible;
      opacity: 1;
    }
  }
  .thumbnail svg {
    fill: #ffc043;
    transform: scale(-1, 1);
  }
`

const SmallImageUploadWrappers = styled.div`
  position: relative;
  border-radius: 8px;
  border: 1px dashed #afaeb3;
  outline: none;
  overflow: hidden;
  height: 70px;
  width: 80px;
  margin-right: 16px;
  margin-bottom: 16px;
  &:hover {
    border: 1px dashed #dfa228;
  }
  img {
    width: 100%;
  }
`

const DragContainer = styled(FlexContainer)`
  outline: none;
  cursor: pointer;
`

const RemoveImageButton = styled.div`
  align-items: center;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  height: 20px;
  justify-content: center;
  width: 20px;
  z-index: ${({ theme }) => theme.order.front};
  svg {
    height: 6px;
    width: 6px;
  }
`

const ImageUploadWrapper = styled(FlexContainer)`
  border-radius: 8px;
  margin-bottom: 16px;
  border: 1px dashed
    ${({ isDragActive }) => (isDragActive ? '#dfa228' : '#afaeb3')};
  outline: none;
  overflow: hidden;
  height: 366px;
  .sub {
    color: #5f5d66;
  }
  .content {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
  }
  a {
    color: #dfa228;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.25px;
    text-transform: none;
    border-bottom: 1px solid #dfa228;
  }
  .image-container {
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.color.primary.black3};
    overflow: hidden;
    img {
      width: 100%;
      max-height: 366px;
      object-fit: contain;
    }
  }
  &:hover {
    .main {
      opacity: 1;
    }
    .mask {
      &-1,
      &-3,
      &-4 {
        fill: #ffc043;
      }
    }
  }
`
