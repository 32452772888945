import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import Modal from 'components/styled/Modal'
import {
  FlexContainer,
  Container,
  AbsoluteContainer
} from 'components/styled/Containers'
import { Button } from 'components/styled/Buttons'
import Input from 'components/forms/Input'
import useAppContext from 'services/context/appContext/useAppContext'
import useClickedOutsideEvent from 'services/hooks/useClickedOutsideEvent'
import close from 'containers/ProfilePage/assets/close'

const ShareModal = ({ toggleShareModal, projectId }) => {
  const { state } = useAppContext()
  const [
    isVerificationTextDisplayed,
    setIsVerificationTextDisplayed
  ] = useState(false)
  const modalRef = useRef(null)
  useClickedOutsideEvent(modalRef, toggleShareModal)
  const userSlug = state.profileData?.slug || state.publicProfileData.slug
  const projectURL = `${window.location.origin}/profile/${userSlug}/projects/${projectId}`

  useClickedOutsideEvent(modalRef, toggleShareModal)
  const handleClickCopy = () => {
    setIsVerificationTextDisplayed(true)
    navigator.clipboard.writeText(projectURL)
  }

  return (
    <ExtendModal>
      <ModalPopupContainer ref={modalRef}>
        <ExtendAbsoluteContainer>
          <CloseSVG onClick={toggleShareModal}>{close}</CloseSVG>
        </ExtendAbsoluteContainer>
        <h6>Share Your Project</h6>
        <Input type="text" name="URL" value={projectURL} disabled />
        <FlexContainer align="center">
          <CopyButton onClick={handleClickCopy}>Copy</CopyButton>
          {isVerificationTextDisplayed && (
            <VerificationText>Link Copied</VerificationText>
          )}
        </FlexContainer>
      </ModalPopupContainer>
    </ExtendModal>
  )
}

export default ShareModal

const ExtendAbsoluteContainer = styled(AbsoluteContainer)`
  right: 24px;
  top: 24px;
`

const ExtendModal = styled(Modal)`
  align-items: center;
  height: 100%;
  justify-content: center;
  width: 100%;
`

const CloseSVG = styled(FlexContainer)`
  align-items: center;
  border-radius: 100%;
  cursor: pointer;
  height: 30px;
  justify-content: center;
  width: 30px;
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
`

const ModalPopupContainer = styled(Container)`
  background: ${({ theme }) => theme.color.bg.norm};
  border-radius: 8px;
  min-width: 500px;
  padding: 32px;
  text-align: left;
  @media (max-width: ${({ theme }) => theme.media.mobile}) {
    min-width: 0;
    width: 100%;
  }
`

const CopyButton = styled(Button)`
  background: ${({ theme }) => theme.color.primary.yellow4};
  border-radius: 4px;
  color: ${({ theme }) => theme.color.bg.dark};
  height: 36px;
  margin-right: 12px;
  width: 120px;
  &:hover {
    opacity: 0.8;
  }
  path {
    fill: ${({ theme }) => theme.color.bg.norm};
  }
`

const VerificationText = styled.div`
  color: ${({ theme }) => theme.color.primary.black4};
`
