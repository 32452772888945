import React from 'react'

const bookmarkWhite = (
  <svg
    width="8px"
    height="11px"
    viewBox="0 0 8 11"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        transform="translate(-1181.000000, -235.000000)"
        fill="#FFFFFF"
        fillRule="nonzero"
      >
        <g transform="translate(152.000000, 105.000000)">
          <g transform="translate(1.000000, 52.000000)">
            <g transform="translate(768.000000, 1.000000)">
              <g transform="translate(233.000000, 60.000000)">
                <path d="M34,17 C34.5522847,17 35,17.4477153 35,18 L35,26.5857864 C35,27.1380712 34.5522847,27.5857864 34,27.5857864 C33.7347835,27.5857864 33.4804296,27.4804296 33.2928932,27.2928932 L31.7071068,25.7071068 C31.3165825,25.3165825 30.6834175,25.3165825 30.2928932,25.7071068 L28.7071068,27.2928932 C28.3165825,27.6834175 27.6834175,27.6834175 27.2928932,27.2928932 C27.1053568,27.1053568 27,26.8510029 27,26.5857864 L27,18 C27,17.4477153 27.4477153,17 28,17 L34,17 Z M34,18 L28,18 L28,26.5857864 L29.5857864,25 C30.3257272,24.2600592 31.5012114,24.221115 32.2869988,24.8831672 L32.4142136,25 L34,26.5857864 L34,18 Z"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default bookmarkWhite
