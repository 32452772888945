import React from 'react'
import useAuth from 'services/hooks/useAuth'
import AuthedApp from './components/AuthedApp'
import UnAuthedApp from './components/UnAuthedApp'

const App = () => {
  const { isAuthenticating, isAuthenticated } = useAuth()

  if (isAuthenticating) return <div>Loading...</div>

  if (isAuthenticated) return <AuthedApp />

  return <UnAuthedApp />
}

export default App
