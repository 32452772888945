import React from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import {
  ProjectWrapper,
  ProjectBanner,
  ProjectDetails
} from 'containers/ProfilePage/styled/ProjectStyles'

const InspirationProject = props => {
  const { id, title, thumbnail, expandProject, disabled } = props

  return (
    <ExtendProjectWrapper disabled={disabled}>
      <ProjectBanner {...thumbnail} onClick={() => expandProject(id)} />
      <ExtendProjectDetails>
        <BodySub>{title}</BodySub>
      </ExtendProjectDetails>
    </ExtendProjectWrapper>
  )
}

export default InspirationProject

// -- PROP DESCRIPTIONS -- //
/*
  description: long project description
  order: order of project 
  inspiration_project_assets: An array of project assets
  inspiration_project_skills: An array of project skills - { name, order }
  summary: project summary
  thumbnail: project thumnail image - url
  title: the project title
  expandProject: opens modal view of project
  disabled: disable user from accessing expanded project
*/

InspirationProject.propTypes = {
  description: PropTypes.string,
  link: PropTypes.string,
  order: PropTypes.number,
  inspiration_project_assets: PropTypes.array,
  inspiration_project_skills: PropTypes.array,
  summary: PropTypes.string,
  thumbnail: PropTypes.object,
  title: PropTypes.string,
  expandProject: PropTypes.func,
  disabled: PropTypes.bool
}

const ExtendProjectDetails = styled(ProjectDetails)`
  padding: 16px;
  left: 0;
  bottom: 0;
`

const BodySub = styled.p`
  font-size: 14px;
  letter-spacing: 0.25px;
  line-height: 17px;
`

const ExtendProjectWrapper = styled(ProjectWrapper)`
  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      opacity: 0.5;
    `}
`
