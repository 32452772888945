import React from 'react'

const externalLinkIcon = (
  <svg
    width="14px"
    height="14px"
    viewBox="0 0 14 14"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        transform="translate(-1059.000000, -282.000000)"
        fill="#373440"
        fillRule="nonzero"
        stroke="#373440"
        strokeWidth="0.3"
      >
        <g transform="translate(153.000000, 166.000000)">
          <g transform="translate(768.000000, 110.000000)">
            <g transform="translate(139.000000, 7.000000)">
              <path
                d="M8.3500612,0.839657283 L10.5654835,0.839657283 
              L5.500612,5.90697674 C5.33659731,6.07099143 5.33659731,
              6.3378213 5.500612,6.50183599 C5.58139535,6.58261934 
              5.68910649,6.62423501 5.79681763,6.62423501 C5.90452876,
              6.62423501 6.0122399,6.58261934 6.09302326,6.50183599 
              L11.1603427,1.43451652 L11.1603427,3.6499388 C11.1603427,
              3.88249694 11.3488372,4.07099143 11.5789474,4.07099143 
              C11.8115055,4.07099143 12,3.88249694 12,3.6499388 L12,
              0.421052632 C12,0.188494492 11.8115055,0 11.5789474,0 
              L8.3500612,0 C8.11750306,0 7.92900857,0.188494492 7.92900857,
              0.421052632 C7.93145655,0.651162791 8.11750306,0.839657283 
              8.3500612,0.839657283 Z"
              ></path>
              <path
                d="M2.51897185,12 L9.47858017,12 C10.869033,12 
              11.997552,10.869033 11.997552,9.48102815 L11.997552,
              6.20318237 C11.997552,5.97062424 11.8090575,5.78457772 
              11.5764994,5.78457772 C11.3439412,5.78457772 11.1578947,
              5.97307222 11.1578947,6.20318237 L11.1578947,9.47858017 
              C11.1578947,10.4039168 10.4039168,11.1578947 9.47858017,
              11.1578947 L2.51897185,11.1578947 C1.59363525,11.1578947 
              0.839657283,10.4039168 0.839657283,9.47858017 L0.839657283,
              3.95104039 L0.839657283,2.52141983 C0.839657283,1.59608323 
              1.59363525,0.842105263 2.51897185,0.842105263 L5.76744186,
              0.842105263 C6,0.842105263 6.18604651,0.653610771 6.18604651,
              0.421052632 C6.18604651,0.188494492 5.99755202,0 5.76744186,
              0 L2.51897185,0 C1.12851897,0 0,1.13096695 0,2.51897185 
              L0,3.94859241 L0,9.47858017 C0,10.869033 1.13096695,12 
              2.51897185,12 Z"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default externalLinkIcon
