import React from 'react'

const uploadResume = (
  <svg width="60px" height="62px" viewBox="0 0 60 62" version="1.1">
    <title>236B7903-80AD-46BB-9F70-5C6806290DEA@1.5x</title>
    <defs>
      <rect
        id="path-1"
        x="0"
        y="19.954023"
        width="32.7816092"
        height="42.045977"
      ></rect>
      <mask
        id="mask-2"
        maskContentUnits="userSpaceOnUse"
        maskUnits="objectBoundingBox"
        x="0"
        y="0"
        width="32.7816092"
        height="42.045977"
        fill="white"
      >
        <use href="#path-1"></use>
      </mask>
    </defs>
    <g
      id="Engineer-files"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Signup_DS-basic-info-hover"
        transform="translate(-628.000000, -466.000000)"
      >
        <g id="Group-13-Copy" transform="translate(628.000000, 466.000000)">
          <use
            id="Rectangle"
            stroke="#FFC043"
            mask="url(#mask-2)"
            strokeWidth="6"
            strokeDasharray="7,8"
            href="#path-1"
          ></use>
          <path
            d="M54.2988506,1.5 L54.7988506,47.1724138 L20.0287356,47.6724138 L20.0287356,2 L54.2988506,1.5 Z"
            id="Rectangle"
            stroke="#373440"
            strokeWidth="3"
            fill="#FAFAFA"
          ></path>
          <rect
            id="Rectangle-Copy"
            fill="#373440"
            x="27.0804598"
            y="22.8045977"
            width="19.954023"
            height="2.85057471"
            rx="1.42528736"
          ></rect>
          <circle
            id="Oval"
            stroke="#FFC043"
            strokeWidth="3"
            cx="32.7816092"
            cy="12.8275862"
            r="4.20114943"
          ></circle>
          <rect
            id="Rectangle-Copy-2"
            fill="#373440"
            x="27.0804598"
            y="28.5057471"
            width="19.954023"
            height="2.85057471"
            rx="1.42528736"
          ></rect>
          <rect
            id="Rectangle-Copy-3"
            fill="#373440"
            x="27.0804598"
            y="34.2068966"
            width="11.4022989"
            height="2.85057471"
            rx="1.42528736"
          ></rect>
          <path
            d="M49.3541973,34.0396034 C49.7412521,34.2331308 50.0550971,34.5469758 50.2486245,34.9340306 L59.127499,52.6917797 C59.6214775,53.6797367 59.2210289,54.8810828 58.2330719,55.3750613 C57.955361,55.5139167 57.6491349,55.5862069 57.3386447,55.5862069 L52.0227036,55.5857617 L52.0229885,59.2873563 C52.0229885,60.3919258 51.127558,61.2873563 50.0229885,61.2873563 L46.8965517,61.2873563 C45.7919822,61.2873563 44.8965517,60.3919258 44.8965517,59.2873563 L44.8957036,55.5857617 L39.5808956,55.5862069 C38.4763261,55.5862069 37.5808956,54.6907764 37.5808956,53.5862069 C37.5808956,53.2757167 37.6531857,52.9694906 37.7920412,52.6917797 L46.6709157,34.9340306 C47.1648942,33.9460736 48.3662403,33.5456249 49.3541973,34.0396034 Z"
            id="Combined-Shape"
            fill="#373440"
          ></path>
        </g>
      </g>
    </g>
  </svg>
)

export default uploadResume
