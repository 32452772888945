import React from 'react'
import styled from 'styled-components'
import { Route, Switch, useHistory } from 'react-router-dom'
import QuestionnaireToggle from 'containers/QuestionnairePage/components/QuestionnaireToggle'
import BasicInfo from 'containers/QuestionnairePage/components/BasicInfo'
import EmployerPreferences from 'containers/QuestionnairePage/components/EmployerPreferences'
import GeneralPreferences from 'containers/QuestionnairePage/components/GeneralPreferences'
import ResumeUpload from 'containers/QuestionnairePage/components/ResumeUpload'
import PreferenceInfo from 'containers/QuestionnairePage/components/PreferenceInfo'
import { PageWrapper } from 'components/styled/Wrappers'
import { FlexContainer, Container } from 'components/styled/Containers'
import C1LogoDark from 'components/graphics/C1LogoDark'

const QuestionnairePage = ({ setProfileReqStatus }) => {
  const history = useHistory()
  const isNotBasicInfoPath =
    history.location.pathname !== '/questionnaire/basic-info'

  return (
    <QuestionnairePageWrapper>
      <MaxWidthContainer>
        <C1ConnectLogoContainer>
          <C1LogoDark />
        </C1ConnectLogoContainer>
        {renderToggleAndPreferences(isNotBasicInfoPath)}
        <FlexContainer>
          <ToggleContainer>
            <QuestionnaireToggle />
          </ToggleContainer>
          <ResponsiveContainer>
            <Switch>
              <Route
                exact
                path="/questionnaire/basic-info"
                component={BasicInfo}
              />
              <Route
                exact
                path="/questionnaire/general-preferences"
                component={GeneralPreferences}
              />
              <Route
                exact
                path="/questionnaire/employer-preferences"
                children={
                  <EmployerPreferences
                    setProfileReqStatus={setProfileReqStatus}
                  />
                }
              />
              <Route
                exact
                path="/questionnaire/resume-upload"
                component={ResumeUpload}
              />
            </Switch>
          </ResponsiveContainer>
          {isNotBasicInfoPath && (
            <PreferenceInfoContainer>
              <PreferenceInfo />
            </PreferenceInfoContainer>
          )}
        </FlexContainer>
      </MaxWidthContainer>
    </QuestionnairePageWrapper>
  )
}

export default QuestionnairePage

const renderToggleAndPreferences = isNotBasicInfoPath => {
  return (
    <FlexBetween>
      <QuestionnaireToggle />
      {isNotBasicInfoPath && <PreferenceInfo />}
    </FlexBetween>
  )
}

const QuestionnairePageWrapper = styled(PageWrapper)`
  padding-left: 20px;
  padding-right: 20px;
`

const C1ConnectLogoContainer = styled.div`
  width: 190px;
  height: 53px;
  margin-bottom: 40px;
`

const ResponsiveContainer = styled(Container)`
  margin: 0 40px;
  width: 90%;
  max-width: 600px;
  @media (max-width: ${({ theme }) => theme.media.tablet}) {
    width: 100%;
    margin: 0;
  }
`

const ToggleContainer = styled.div`
  min-width: 200px;
  @media (max-width: ${({ theme }) => theme.media.tablet}) {
    display: none;
  }
`

const PreferenceInfoContainer = styled.div`
  min-width: 300px;
  @media (max-width: ${({ theme }) => theme.media.tablet}) {
    display: none;
  }
`

const MaxWidthContainer = styled.div`
  margin: 0 auto;
  max-width: 1300px;
`

const FlexBetween = styled(FlexContainer)`
  display: none;
  @media (max-width: ${({ theme }) => theme.media.tablet}) {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
`
