import React, { useState } from 'react'
import styled from 'styled-components'
import { FormProvider, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers'
import Radios from 'components/forms/Radios'
import Checkboxes from 'components/forms/Checkboxes'
import InputSubmit from 'components/forms/InputSubmit'
import AutoComplete from 'components/forms/AutoComplete'
import { Container, Divider } from 'components/styled/Containers'
import { questionnaire } from 'containers/QuestionnairePage/messages/messages'
import useAppContext from 'services/context/appContext/useAppContext'
import useScrollToTop from 'services/hooks/useScrollToTop'
import { employerPreferencesValidation } from 'services/validations/questionnaireValidations'
import useEmployerPreferences from 'services/hooks/useEmployerPreferences'
import useFetchLocations from 'containers/QuestionnairePage/hooks/useFetchLocations'

const EmployerPreferences = () => {
  useScrollToTop()

  const {
    question1,
    question1b,
    question2,
    question2b,
    question3,
    question3b,
    question4,
    question4b
  } = questionnaire.employerPrefs

  const history = useHistory()

  const methods = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(employerPreferencesValidation),
    shouldFocusError: true
  })

  const fetchLocations = useFetchLocations({
    excludeValues: {
      getUsedValues: methods.getValues,
      inputName: question1.questionName
    }
  })

  const [{ api, requests }] = useState(useAppContext())
  const { handleSubmit, formState, errors } = methods

  const { industryList, professionList } = useEmployerPreferences()

  const onFailure = error => {
    console.error(error)
  }

  const onSuccess = () => {
    history.push('/questionnaire/resume-upload')
  }

  const onSubmit = async data => {
    const submitData = {
      ...data
    }
    submitData.has_seen_form = true
    submitData.geo_locations = data.locations
    delete submitData.locations

    api.request(
      requests().createEmployerPreferences,
      submitData,
      response => {
        onSuccess(response)
      },
      error => onFailure(error)
    )
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <h6>Employer Preferences</h6>
        <ExtraLabelInformation>Choose up to 10</ExtraLabelInformation>
        <AutoComplete
          requestFunc={fetchLocations}
          placeholder={question1.label}
          name={question1.questionName}
          errors={errors}
          isMulti
          required
          limitSelectedOptions={10}
        />
        <Radios
          vertical={question1b.vertical}
          question={question1b.question}
          groupName={question1b.groupName}
          options={question1b.options}
          required="required"
        />
        <Divider />
        <Checkboxes
          questionName={question2.questionName}
          label={question2.label}
          options={industryList}
          required="required"
          subText={question2.subText}
          vertical={question2.vertical}
        />
        <Radios
          vertical={question2b.vertical}
          question={question2b.question}
          groupName={question2b.groupName}
          options={question2b.options}
          required="required"
        />
        <Divider />
        <Checkboxes
          questionName={question3.questionName}
          label={question3.label}
          options={question3.options}
          required="required"
          subText={question3.subText}
          vertical={question3.vertical}
        />
        <Radios
          vertical={question3b.vertical}
          question={question3b.question}
          groupName={question3b.groupName}
          options={question3b.options}
          required="required"
        />
        <Divider />
        <Checkboxes
          questionName={question4.questionName}
          label={question4.label}
          options={professionList}
          required="required"
          vertical={question4.vertical}
        />
        <Radios
          vertical={question4b.vertical}
          question={question4b.question}
          groupName={question4b.groupName}
          options={question4b.options}
          required="required"
        />
        <Divider />
        <SubmitBtnContainer>
          <InputSubmit
            background="#FFFFFF"
            color="#373440"
            width="100px"
            border="#363540"
            testId="input-submit"
            text="Save"
            formState={formState}
          />
          <EditMessageText>
            These preferences may be edited later.
          </EditMessageText>
        </SubmitBtnContainer>
      </form>
    </FormProvider>
  )
}

export default EmployerPreferences

const SubmitBtnContainer = styled(Container)`
  align-items: center;
  display: flex;
`

const EditMessageText = styled.p`
  color: #3b3b3b;
  font-size: 14px;
  font-weight: 500;
  margin: 0 0 0 16px;
`

const ExtraLabelInformation = styled.div`
  color: #00000080;
  font-size: 12px;
  letter-spacing: 0.4px;
  line-height: 16px;
  margin-bottom: 16px;
`
