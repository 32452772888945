import styled, { css } from 'styled-components'

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  padding: 8px 32px;
  color: ${({ theme, textColor }) => textColor || theme.color.primary.black4};
  background: ${({ theme, bgColor }) => bgColor || theme.color.primary.yellow4};
  outline: none;
  border: 1px solid
    ${({ theme, bgColor }) => bgColor || theme.color.primary.yellow4};
  border-radius: 4px;
  transition: all 300ms ease-out;
  font-size: 14px;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
  &.inverse {
    background: transparent;
    border-color: transparent;
    color: ${({ bgColor }) => bgColor || '#fff'};
    &:hover {
      background: rgba(0, 0, 0, 0.1);
    }
  }
  &.light {
    background: transparent;
    color: ${({ theme, bgColor }) => bgColor || theme.color.primary.yellow4};
    border-color: ${({ theme, bgColor }) =>
      bgColor || theme.color.primary.yellow4};
    &:hover {
      background: rgba(0, 0, 0, 0.1);
    }
  }
  &.dark {
    color: #fff;
    background: ${({ theme }) => theme.color.primary.black4};
    border-color: ${({ theme, bgColor }) =>
      bgColor || theme.color.primary.black4};
  }
  &.disabled {
    cursor: not-allowed;
    background: ${({ theme }) => theme.color.bg.disabled};
    color: ${({ theme }) => theme.color.text.disabled};
    border: 1px solid ${({ theme }) => theme.color.bg.disabled};
  }
  &.light-disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`

const ToggleButton = styled(Button)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-transform: capitalize;
  color: ${({ theme }) => theme.color.primary.black4};
  background: ${({ theme }) => theme.color.bg.white};
  border: 1px solid ${({ theme }) => theme.color.border.input};
  border-radius: 4px;
  width: auto;
  min-width: 80px;
  padding: 0 8px;
  font-size: 14px;
  font-weight: ${({ theme }) => theme.font.weight.medium};
  letter-spacing: 0.1px;
  line-height: 24px;
  z-index: ${({ theme }) => theme.order.front};
  .icon-action {
    margin: 0 8px 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &:hover,
  &.active:hover {
    background: ${({ theme }) => theme.color.bg.buttonHover};
    border: 1px solid ${({ theme }) => theme.color.bg.buttonHover};
    color: ${({ theme }) => theme.color.primary.black4};
    .icon-action {
      svg {
        .icon {
          fill: ${({ theme }) => theme.color.primary.yellow4};
        }
      }
    }
  }
  &.active {
    background: ${({ theme }) => theme.color.primary.yellow4};
    color: ${({ theme }) => theme.color.primary.black4};
    border: 1px solid #fff;
  }
`

const SocialButton = styled(Button)`
  border-radius: 4px;
  margin-bottom: ${props => props.marginBottom};
  display: flex;
  justify-content: flex-start;
  text-transform: capitalize;
  color: ${({ theme }) => theme.color.bg.norm};
  padding: 12px 16px 8px;
  width: 100%;
  &:hover {
    background: ${({ theme, bgColor }) =>
      bgColor || theme.color.primary.yellow4};
    color: ${({ theme }) => theme.color.bg.norm};
  }
`

// TODO: Remove styling for font icons

const IconAction = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  border: none;
  border-radius: 100%;
  padding: 8px;
  width: ${({ width }) => width || '32px'};
  height: ${({ height }) => height || '32px'};
  transition: all 300ms ease-out;
  i {
    font-size: ${({ fontSize }) => fontSize || '1.15em'};
    color: ${({ theme, color }) => color || theme.color.primary.black4};
  }
  .icon {
    transition: all 300ms ease-out;
  }
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
  &.cover {
    height: 48px;
    width: 48px;
    background: #fff;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
    svg {
      height: 20px;
      width: 20px;
    }
  }
  ${({ variant, isActive }) =>
    variant === 'settings' &&
    css`
      svg circle {
        transition: all 300ms ease-out;
      }
      &:hover {
        svg circle {
          fill: ${({ theme }) => theme.color.primary.yellow4};
        }
      }
      ${isActive &&
        css`
          svg circle {
            fill: ${({ theme }) => theme.color.primary.yellow4};
          }
        `}
    `}
`

const ExtendedIconAction = styled(IconAction)`
  position: absolute;
  top: 0;
  right: 0;
`

const IconSelectButton = styled.button`
  position: relative;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: auto;
  min-width: 40px;
  height: 24px;
  transition: all 300ms ease-out;
  z-index: ${({ theme }) => theme.order.front};
  color: ${({ theme }) => theme.color.primary.black4};
  background: ${({ theme }) => theme.color.bg.white};
  border: 1px solid ${({ theme }) => theme.color.border.input};
  padding: 2px 20px 2px 8px;
  .icon {
    transition: transform 300ms ease-out;
  }
  &.active,
  &:hover {
    background: ${({ theme }) => theme.color.bg.buttonHover};
    border: 1px solid ${({ theme }) => theme.color.bg.buttonHover};
    &:after {
      top: 8px;
      transform: rotate(135deg);
    }
  }
  &:after {
    border-style: solid;
    border-width: 2px 2px 0 0;
    content: '';
    display: inline-block;
    height: 4px;
    width: 4px;
    position: absolute;
    right: 8px;
    top: 10px;
    transform: rotate(-45deg);
    vertical-align: top;
  }
  .action-list {
    position: absolute;
    top: calc(100% + 4px);
    left: 0;
    height: auto;
    transition: all 300ms ease-out;
    background: #fff;
    margin: 0 0 0 0;
    list-style: none;
    text-align: left;
    padding: 4px;
    min-width: 86px;
    width: auto;
    .action {
      font-size: 14px;
      font-weight: ${({ theme }) => theme.font.weight.regular};
      letter-spacing: 0.25px;
      color: ${({ theme }) => theme.color.primary.black3};
      line-height: 17px;
      text-transform: none;
      text-align: left;
      width: 100%;
      padding: 4px 8px;
      &:hover {
        background: ${({ theme }) => theme.color.primary.black0};
      }
    }
  }
`

export {
  Button,
  SocialButton,
  IconAction,
  ExtendedIconAction,
  ToggleButton,
  IconSelectButton
}
