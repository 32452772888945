import React, { useState } from 'react'
import styled from 'styled-components'
import { ContentWrapper } from 'components/styled/Wrappers'
import { FlexContainer, Container } from 'components/styled/Containers'
import TitleH2 from 'components/styled/titles/TitleH2'
import InspirationProject from 'containers/HowToShowcasePage/components/InspirationProject'
import ExpandedInspirationProject from 'containers/HowToShowcasePage/components/ExpandedInspirationProject'
import useFetchInspirationSlides from 'services/hooks/useFetchInspirationSlides'

const DatafolioInspiration = () => {
  const { slides, isAuthenticated } = useFetchInspirationSlides()
  const [viewProject, setViewProject] = useState(false)
  const [currentProject, setCurrentProject] = useState(0)

  const expandProject = project => {
    setViewProject(true)
    setCurrentProject(project)
  }

  const closeProject = () => {
    setViewProject(false)
  }

  return (
    <InspirationWrapper>
      <ContentWrapper>
        <ContentContainer>
          <ExtendTitleH2>Datafolio Inspirations</ExtendTitleH2>
          <SlidesContainer align="center" wrap="wrap" justify="center">
            {slides &&
              slides.map((project, index) => (
                <ProjectContainer key={`inspire-${index}`}>
                  <InspirationProject
                    disabled={!isAuthenticated && index >= 3}
                    {...project}
                    expandProject={() => expandProject(project)}
                  />
                </ProjectContainer>
              ))}
          </SlidesContainer>
        </ContentContainer>
      </ContentWrapper>
      {viewProject && (
        <ExpandedInspirationProject
          currentProject={currentProject}
          closeModal={closeProject}
          setViewProject={setViewProject}
        />
      )}
    </InspirationWrapper>
  )
}

export default DatafolioInspiration

const InspirationWrapper = styled(Container)`
  background: ${({ theme }) => theme.color.bg.lite};
  justify-content: center;
  padding: 64px 0;
  width: 100%;
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  max-width: 800px;
  width: 100%;
`

const ExtendTitleH2 = styled(TitleH2)`
  text-align: center;
`

const SlidesContainer = styled(FlexContainer)`
  margin: 32px auto;
  width: 100%;
`

const ProjectContainer = styled(Container)`
  margin: 0 0 8px;
  padding: 0 8px;
  width: 250px;
  @media (max-width: ${({ theme }) => theme.media.mobile}) {
    width: 350px;
  }
`
