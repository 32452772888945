import React, { useState, useCallback } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useDropzone } from 'react-dropzone'
import { FlexContainer, Container } from 'components/styled/Containers'
import useAppContext from 'services/context/appContext/useAppContext'
import checkMark from 'components/forms/assets/checkMark'

const InlineImageUpload = ({
  editingFile,
  label,
  caption,
  setFileId,
  isImg
}) => {
  const [currentFile, setCurrentFile] = useState(editingFile || '')
  const [isFileTooLarge, setIsFileTooLarge] = useState(false)
  const [reuploadShowing, setReuploadShowing] = useState(false)
  const { api, requests } = useAppContext()
  const isResume = label === 'Resume Upload'

  const onDrop = useCallback(
    acceptedFiles => {
      acceptedFiles.forEach((file, indexFile) => {
        let formData = new FormData()
        formData.append('file_path', file)
        formData.append('convert_pdfs', isImg)

        api.request(requests().uploadAsset, formData, response => {
          setCurrentFile(response.data[0])
          setFileId({ id: response.data[0].id })
        })
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const reject = event => event[0].errors[0].code && setIsFileTooLarge(true)

  const { getRootProps, getInputProps } = useDropzone({
    accept: isResume ? 'application/pdf' : '.jpeg, .jpg, .png',
    maxSize: isResume ? 33554432 : Infinity,
    onDropRejected: event => reject(event),
    onDrop,
    multiple: false,
    noDrag: true
  })

  return (
    <>
      <TextLabel>{label}</TextLabel>
      {caption && <CaptionLabel>{caption}</CaptionLabel>}
      <UploadContainer {...getRootProps()}>
        <input {...getInputProps()} />
        {!currentFile && (
          <FlexContainer height="100%" align="center">
            <UploadButton type="button">Choose file</UploadButton>
            <NoFileChosen>No file</NoFileChosen>
          </FlexContainer>
        )}
        {currentFile && (
          <FlexContainer
            onMouseEnter={() => setReuploadShowing(true)}
            onMouseLeave={() => setReuploadShowing(false)}
            justify="center"
            align="center"
            className="pointer"
          >
            {reuploadShowing && (
              <UploadButton type="button">Upload new file</UploadButton>
            )}
            {!reuploadShowing && (
              <FlexContainer>
                <NoFileChosen>File uploaded</NoFileChosen>
                <Container padding="2px 0 0" margin="0 0 0 8px">
                  {checkMark}
                </Container>
              </FlexContainer>
            )}
          </FlexContainer>
        )}
        {isResume && isFileTooLarge && (
          <FileSizeMessage>
            Please upload a PDF smaller than 32 MB
          </FileSizeMessage>
        )}
      </UploadContainer>
    </>
  )
}

export default InlineImageUpload

// -- PROP DESCRIPTIONS -- //
// caption: caption that lies underneath the label

InlineImageUpload.propTypes = {
  caption: PropTypes.string
}

const UploadButton = styled.button`
  background: #383440;
  padding: 4px 10px;
  color: #ffffff;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0.25px;
  text-transform: none;
  border-radius: 4px;
  border: none;
  margin-right: 16px;
  &:hover {
    background: hsl(260, 10%, 33%);
  }
`

const TextLabel = styled.p`
  display: inline-block;
  margin-bottom: ${props => props.marginBottom};
  position: relative;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.25px;
  color: #5f5d66;
  width: 100%;
  text-align: left;
  text-transform: capitalize;
`

const CaptionLabel = styled.p`
  color: rgb(135, 133, 140);
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.4px;
  line-height: 16px;
  text-align: left;
`

const NoFileChosen = styled.p`
  color: #5f5d66;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0.25px;
`

const FileSizeMessage = styled.p`
  color: #e12916;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.18px;
  line-height: 16px;
  margin-top: 12px;
  margin-left: -8px;
  text-align: left;
  width: 100%;
`

const UploadContainer = styled.div`
  width: 100%;
  outline: none;
  height: 40px;
  border-radius: 4px;
  margin: 8px 0 0;
  margin-bottom: 16px;
  padding: 8px;
  border: 1px solid
    ${({ theme, errors }) =>
      errors ? theme.color.border.error : theme.color.border.input};

  background: ${({ theme }) => theme.color.bg.darkNorm};
  &:hover {
    background: ${({ theme }) => theme.color.bg.white};
  }
  &:focus {
    background: ${({ theme }) => theme.color.bg.white};
    border-color: ${({ theme }) => theme.color.border.active};
  }
  &::placeholder {
    color: ${({ theme }) => theme.color.primary.black3};
  }
  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }
  &.hidden {
    display: none;
  }
  &.currency {
    padding-left: 32px;
    position: relative;
    width: 200px;
  }
`
