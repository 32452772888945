import styled from 'styled-components'
import { FlexContainer } from 'components/styled/Containers'

const ToggleSetNav = styled(FlexContainer)`
  width: 100%;
  margin: 16px 0;
  text-align: left;

  .top-toggle-label {
    margin: 0 32px 0 0;
    transition: all 300ms ease-out;
    opacity: 0.8;
    cursor: pointer;
    h4 {
      color: ${({ theme }) => theme.color.primary.black3};
    }

    &:after {
      display: block;
      content: '';
      height: 100%;
      border-bottom: 2px solid ${({ theme }) => theme.color.primary.yellow5};
      transform: scaleX(0);
      transition: transform 250ms ease-in-out;
    }

    &:hover,
    &.active {
      opacity: 1;

      &:after {
        transform: scaleX(1);
      }

      h4 {
        color: ${({ theme }) => theme.color.primary.black5};
      }
    }
  }

  @media (max-width: 768px) {
    margin-bottom: 0;
    .top-toggle-label {
      width: 100%;
      text-align: center;
      margin: 0;
      &:after {
        display: block;
        content: '';
        padding-bottom: 12px;
        border-bottom: 3px solid ${({ theme }) => theme.color.primary.yellow5};
        transform: scaleX(0);
        transition: transform 250ms ease-in-out;
      }
    }
  }
`

export default ToggleSetNav
