import React from 'react'

const experienceLevelIcon = (
  <svg width="14px" height="13px" viewBox="0 0 14 13" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        transform="translate(-574.000000, -165.000000)"
        fill="#7F7F7F"
        fillRule="nonzero"
      >
        <g transform="translate(153.000000, 117.000000)">
          <g transform="translate(111.468060, 4.000000)">
            <g transform="translate(309.531940, 41.938272)">
              <g transform="translate(7.000000, 8.159295) rotate(-180.000000) translate(-7.000000, -8.159295) translate(0.000000, 2.061728)">
                <path d="M5.03420242,3.39417558 C4.97998666,3.2999042 4.97998666,3.18506744 5.03420242,3.09111633 L6.73730942,0.140703632 C6.84548931,-0.0469012105 7.1540159,-0.0469012105 7.26217291,0.140703632 L8.96555442,3.09111633 C9.01977018,3.18504456 9.01977018,3.2999042 8.96555442,3.39417558 C8.91133866,3.48810382 8.81132544,3.54570521 8.7030998,3.54570521 L5.29665704,3.54570521 C5.1884314,3.54570521 5.08846394,3.48810382 5.03420242,3.39417558 Z M2.90834973,7.6822307 L11.0917274,7.6822307 C11.2002275,7.6822307 11.3002179,7.62462931 11.354182,7.53070107 C11.4084206,7.43677283 11.4084206,7.32157006 11.354182,7.22764182 L9.86807531,4.65362835 C9.81385955,4.55970011 9.71384633,4.50209872 9.60562069,4.50209872 L4.39509694,4.50209872 C4.28691705,4.50209872 4.18658357,4.55970011 4.13264232,4.65362835 L2.64621537,7.22764182 C2.59197673,7.32157006 2.59197673,7.43677283 2.64621537,7.53070107 C2.69988211,7.62430905 2.79989532,7.6822307 2.90834973,7.6822307 Z M13.9594668,11.7405437 L12.2560852,8.79040548 C12.2018466,8.69647724 12.1018563,8.63887585 11.9936077,8.63887585 L2.00651511,8.63887585 C1.89833522,8.63887585 1.79800174,8.69647724 1.74406049,8.79040548 L0.0406789794,11.7405437 C-0.0135596598,11.8344719 -0.0135596598,11.9496747 0.0406789794,12.0436029 C0.0949176187,12.1375312 0.194907957,12.1951325 0.303133601,12.1951325 L13.6969893,12.1951325 C13.8051691,12.1951325 13.9055026,12.1375312 13.9594668,12.0436029 C14.0133394,11.9496747 14.0136825,11.8342203 13.9594668,11.7405437 Z"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default experienceLevelIcon
