import React from 'react'
import styled from 'styled-components'
import Project from 'containers/ProfilePage/components/Project'
import FormHelper from 'containers/ProfilePage/components/profileModal/FormHelper'
import FirstProject from 'containers/ProfilePage/components/barebones/FirstProject'
import { ContentWrapper } from 'components/styled/Wrappers'
import useProfileContext from 'services/context/profileContext/useProfileContext'

const Projects = ({ profileData }) => {
  const { projects } = profileData
  const {
    profileState: { formData }
  } = useProfileContext()

  const orderProjects = () => {
    const projCopy = [...projects]

    projCopy.sort((a, b) => {
      if (a.is_featured && !b.is_featured) {
        return -1
      } else if (!a.is_featured && b.is_featured) {
        return 1
      } else if (a.is_featured && b.is_featured) {
        return a.featured_order - b.featured_order
      }
      return new Date(b.created_at) - new Date(a.created_at)
    })

    return projCopy
  }

  const myProjects = () => {
    if (projects.length > 0) {
      return orderProjects(projects).map(project => {
        return (
          <ProjectContainer key={'project-' + project.id}>
            <Project {...project} />
          </ProjectContainer>
        )
      })
    } else {
      return <FirstProject />
    }
  }

  return (
    <ContentWrapper>
      <ProjectsWrapper>{myProjects()}</ProjectsWrapper>
      {formData && <FormHelper />}
    </ContentWrapper>
  )
}

export default Projects

const ProjectsWrapper = styled.div`
  display: grid;
  gap: 18px;
  grid-template-columns: repeat(3, 1fr);
`

const ProjectContainer = styled.div`
  height: 217px;
  min-width: 368px;
`
