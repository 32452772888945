import React, { useState } from 'react'
import styled from 'styled-components'
import { useFormContext } from 'react-hook-form'
import PropTypes from 'prop-types'
import Checkbox from 'components/forms/Checkbox'
import { FlexContainer, Container } from 'components/styled/Containers'
import { ErrorMessage, InputLabel, InputCaption } from 'components/styled/Text'

const Checkboxes = ({
  vertical,
  label,
  questionName,
  options,
  required,
  moreOptions,
  subText,
  isPreCheckedData
}) => {
  const form = useFormContext()
  const [showOps, setShowOps] = useState(false)

  // Toggles checked
  const toggleShowMoreOptions = e => {
    setShowOps(!showOps)
  }

  const isOptionPreChecked = option =>
    isPreCheckedData &&
    ((Array.isArray(isPreCheckedData) &&
      isPreCheckedData.includes(option.optionValue)) ||
      isPreCheckedData[option.optionValue])

  return (
    <Container margin="0 0 16px 0">
      {label && (
        <InputLabel
          className={required}
          marginBottom={subText ? '0px' : '16px'}
        >
          {label}
        </InputLabel>
      )}
      {subText && <InputCaption>{subText}</InputCaption>}
      <CheckboxesFlex
        direction={vertical ? 'column' : 'row'}
        wrap={vertical ? 'nowrap' : 'wrap'}
      >
        {options &&
          options.map(option => {
            const isPreChecked = isOptionPreChecked(option)

            return (
              <Checkbox
                key={option.id}
                optionName={option.optionText}
                questionName={questionName}
                optionValue={option.optionValue}
                isPreChecked={isPreChecked}
              />
            )
          })}
        {moreOptions &&
          moreOptions.map(option => {
            const isPreChecked = isOptionPreChecked(option)

            return (
              <Checkbox
                key={option.id}
                optionName={option.optionText}
                questionName={questionName}
                optionValue={option.optionValue}
                isPreChecked={isPreChecked}
                isHidden={showOps}
              />
            )
          })}
      </CheckboxesFlex>
      {moreOptions && !showOps && (
        <MoreLinkStyled onClick={toggleShowMoreOptions}>
          Show more
          <span>⌄</span>
        </MoreLinkStyled>
      )}
      {form && form.errors[questionName] && (
        <ErrorMessage>{form.errors[questionName].message}</ErrorMessage>
      )}
    </Container>
  )
}

// -- PROP DESCRIPTIONS -- //
// label: label above input -- this can be a sentence, not just one word
// questionName: name associated with react-hook-form
// options: list of options

Checkboxes.propTypes = {
  label: PropTypes.string,
  questionName: PropTypes.string,
  options: PropTypes.array
}

Checkboxes.defaultProps = {
  questionName: 'checkbox'
}

export default Checkboxes

const CheckboxesFlex = styled(FlexContainer)`
  .radios:last-child {
    margin-bottom: 0;
  }
`

const MoreLinkStyled = styled.a`
  color: #dfa228;
  font-family: ${({ theme }) => theme.font.family};
  font-size: 16px;
  font-weight: normal;
  height: 20px;
  letter-spacing: 0.5px;
  text-decoration: underline;
  text-transform: none;
  position: relative;
  margin-bottom: 16px;
  margin-top: 8px;
  display: inline-block;
  span {
    font-family: monospace;
    position: absolute;
    font-weight: bold;
    right: -15px;
    top: -2px;
  }
  &:hover {
    color: #b88319;
  }
`
