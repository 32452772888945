import React from 'react'
import { CardBody, Description } from 'components/styled/Cards'

// Re-order dates chronologically
export const reorderChronologically = (dataArr, type) => {
  if (type === 'awards') {
    return [...dataArr].sort((a, b) => {
      return new Date(b.year) - new Date(a.year)
    })
  }

  return [...dataArr].sort((a, b) => {
    const endDateA = a.end_date && new Date(a.end_date)
    const endDateB = b.end_date && new Date(b.end_date)

    if (!endDateA && endDateB) {
      return -1
    } else if (endDateA && !endDateB) {
      return 1
    } else if ((!endDateA && !endDateB) || endDateB === endDateA) {
      // If end dates the same use start dates
      // If both end dates are 'present'
      return new Date(b.start_date) - new Date(a.start_date)
    } else {
      return endDateB - endDateA
    }
  })
}

export const handleDescription = description => {
  if (description.split('\n').length > 1) {
    return description.split('\n').map((item, index) => {
      return <Description key={index}>{item}</Description>
    })
  } else {
    return <CardBody>{description}</CardBody>
  }
}

export const formatDescription = desc => {
  if (desc.includes('•')) {
    // Formats to list if • is found in text
    const descArray = desc.split('•')
    descArray.shift()
    return (
      <ul>
        {descArray.map((listText, ind) => (
          <li key={`list-item-${ind}`}>{listText}</li>
        ))}
      </ul>
    )
  } else {
    // Default paragraph body
    return <p>{desc}</p>
  }
}

// Handle Formatting for Projects that requires a JSON payload
export const handleProjectFormatting = data => {
  const { project_skills, project_assets } = data

  if (project_assets.length) {
    data['project_assets'] = JSON.parse(project_assets)
  }

  if (project_skills.length) {
    const skills = []
    project_skills.forEach((skill, index) => {
      if (skill.value !== '') {
        skills.push({ order: index, name: skill.value.toString() })
      }
    }, [])
    data['project_skills'] = skills
  }

  return data
}

export const flattenArraysInData = (key, data, previousData) => {
  Object.keys(previousData).forEach(function(keyName) {
    data[keyName] = false
  })
  for (let i = 0; i < data[key].length; i++) {
    data[data[key][i]] = true
  }
  return data
}

export const getFormattedArrayFormData = (lookupValue, preferenceData) => {
  let all = {}
  let selected = []
  let display = []
  Object.keys(preferenceData).forEach(function(keyName) {
    if (
      keyName.indexOf(lookupValue) === 0 &&
      keyName !== lookupValue + '_importance'
    ) {
      all[keyName] = preferenceData[keyName]
      if (preferenceData[keyName] === true) {
        selected.push(keyName)
        let sel =
          lookupValue === 'team_size'
            ? keyName
                .substring(lookupValue.length + 1, keyName.length)
                .replace(/_/g, '-')
            : keyName
                .substring(lookupValue.length + 1, keyName.length)
                .replace(/_/g, ' ')

        if (sel === 'washington dc metro') {
          sel = 'washington DC metro'
        }
        if (sel === 'retail e commerce') {
          sel = 'retail / e-commerce'
        }
        if (sel === '50-plus') {
          sel = '50+'
        }
        display.push(sel)
      }
    }
  })
  display = display.join(', ')
  return { all: all, selected: selected, display: display }
}

export const importanceDisplayTranslation = importanceValue => {
  switch (importanceValue) {
    case 'Critical':
      return 'Critically Important'
    case 'Moderate':
      return 'Moderately Important'
    case 'Not':
      return 'Not Important'
    default:
      return importanceValue
  }
}
