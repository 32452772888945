import React, { useState } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import Input from 'components/forms/Input'
import InputSubmit from 'components/forms/InputSubmit'
import { FormContainer } from 'components/styled/Containers'
import {
  AuthFormTitle,
  ErrorMessage,
  AuthFormLinkText
} from 'components/styled/Text'
import FormDivider from 'containers/AuthPage/styled/FormDivider'
import useAppContext from 'services/context/appContext/useAppContext'
import useSearchParams from 'services/hooks/useSearchParams'
import { passwordResetValidation } from 'services/validations/authValidation'

const PasswordReset = ({ formErrorMessage, setFormErrorMessage }) => {
  const [passwordReset, setPasswordReset] = useState(false)
  const { api, requests } = useAppContext()
  const methods = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(passwordResetValidation),
    shouldFocusError: false
  })
  const token = useSearchParams('token')

  // Remove error state when page changes
  const handleRemoveErrors = () => {
    setFormErrorMessage(null)
  }

  // On submission failure
  const onFailure = () => {
    setFormErrorMessage(
      "We couldn't reset your password at this time. Please request another password reset."
    )
  }

  // On submission success
  const onSuccess = response => {
    handleRemoveErrors()
    setPasswordReset(true)
  }

  // Submit password reset form
  const onSubmit = async data => {
    const payload = { ...data }
    delete payload.confirmPassword

    await api.request(
      requests(token).resetPassword,
      payload,
      response => onSuccess(response),
      () => onFailure()
    )
  }

  const passWordResetText = passwordReset
    ? `Password reset successful:`
    : `Don't need to reset?`

  const passWordResetLink = passwordReset ? `Log In` : `Back to Log In`

  return (
    <FormProvider {...methods}>
      <FormContainer onSubmit={methods.handleSubmit(onSubmit)}>
        <AuthFormTitle>Password Reset</AuthFormTitle>
        <Input type="password" name="password" placeholder="Password" />
        <Input
          type="password"
          name="confirmPassword"
          placeholder="Confirm Password"
        />
        <SubmitBtnContainer>
          <InputSubmit
            disabled={passwordReset}
            color="#373440"
            background="#FFFFFF"
            border="#373440"
            text="Reset Password"
          />
        </SubmitBtnContainer>
        {formErrorMessage && <ErrorMessage>{formErrorMessage}</ErrorMessage>}
        {passwordReset && (
          <SuccessSpanContainer>
            <SuccessSpan>Your password has been reset!</SuccessSpan>
          </SuccessSpanContainer>
        )}
        <FormDivider />
        <AuthFormLinkText>
          {passWordResetText} <Link to="/login">{passWordResetLink}</Link>
        </AuthFormLinkText>
      </FormContainer>
    </FormProvider>
  )
}

export default PasswordReset

const SubmitBtnContainer = styled.div`
  margin: 28px 0;
`

const SuccessSpanContainer = styled.div`
  width: 100%;
  text-align: left;
`

const SuccessSpan = styled.span`
  color: ${({ theme }) => theme.color.system.green4};
  font-size: 12px;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  letter-spacing: 0.4px;
  line-height: 16px;
`
