import React, { useEffect } from 'react'
import styled from 'styled-components'
import { FormProvider, useForm } from 'react-hook-form'
import InputSubmit from 'components/forms/InputSubmit'
import Radios from 'components/forms/Radios'
import ExpandableSettingsContent from 'containers/SettingsPage/components/ExpandableSettingsContent'
import { Container, Divider } from 'components/styled/Containers'
import { SurveyTitle } from 'components/styled/Text'
import { settings } from 'containers/SettingsPage/messages/messages'
import useAppContext from 'services/context/appContext/useAppContext'
import useSettingsContext from 'services/context/settingsContext/useSettingsContext'

const GeneralPreferenceSettings = () => {
  const methods = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    shouldFocusError: true
  })

  const { api, requests, state, appDispatchActions } = useAppContext()
  const {
    job_search_status,
    employment_type,
    remote_work_status
  } = state.generalPreferencesData

  const { settingsDispatchActions } = useSettingsContext()
  const { handleSubmit, formState } = methods

  const handleTransformRadioBooleans = data => {
    const dataCopy = { ...data }
    const booleansToUpate = ['us_work_authorization']

    booleansToUpate.forEach(item => {
      dataCopy[item] = dataCopy[item] ? 'Yes' : 'No'
    })

    return dataCopy
  }

  useEffect(() => {
    if (state.generalPreferencesData) {
      const transformedData = handleTransformRadioBooleans(
        state.generalPreferencesData
      )
      methods.reset(transformedData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.generalPreferencesData])

  // Destructure questionnaire data
  const { question1, question2, question3 } = settings.generalPrefs

  if (!state.generalPreferencesData) {
    return <div>loading...</div>
  }

  const onFailure = error => {
    console.log(error)
  }

  const onSuccess = response => {
    appDispatchActions.setGeneralPreferencesData({
      generalPreferences: response.data
    })
  }

  // Submit general preferences form
  const onSubmit = async data => {
    settingsDispatchActions.setFormExpanded(null)
    api.request(
      requests().updateGeneralPreferences,
      data,
      response => {
        onSuccess(response)
      },
      error => onFailure(error)
    )
  }

  return (
    <Container>
      <SurveyTitle>General Preferences</SurveyTitle>
      <ExpandableSettingsContent
        header={'Work Availability'}
        displayValue={job_search_status}
        content="job_search_status"
      >
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Radios
              vertical={question1.vertical}
              width={question1.width}
              question={question1.question}
              groupName={question1.groupName}
              options={question1.options}
              required="required"
            />
            <FitContentContainer>
              <InputSubmit
                background="#FFFFFF"
                color="#373440"
                border="#363540"
                testId="input-submit"
                text="Save"
                formState={formState}
              />
            </FitContentContainer>
          </form>
        </FormProvider>
      </ExpandableSettingsContent>
      <Divider />
      <ExpandableSettingsContent
        header={'Employment type'}
        displayValue={employment_type}
        content="employment_type"
      >
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Radios
              vertical={question2.vertical}
              question={question2.question}
              groupName={question2.groupName}
              options={question2.options}
              required="required"
            />
            <FitContentContainer>
              <InputSubmit
                background="#FFFFFF"
                color="#373440"
                border="#363540"
                testId="input-submit"
                text="Save"
                formState={formState}
              />
            </FitContentContainer>
          </form>
        </FormProvider>
      </ExpandableSettingsContent>
      <Divider />
      <ExpandableSettingsContent
        header={'Interested in working remotely?'}
        displayValue={remote_work_status}
        content="remote_work_status"
      >
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Radios
              vertical={question3.vertical}
              question={question3.question}
              groupName={question3.groupName}
              options={question3.options}
            />
            <FitContentContainer>
              <InputSubmit
                background="#FFFFFF"
                color="#373440"
                border="#363540"
                testId="input-submit"
                text="Save"
                formState={formState}
              />
            </FitContentContainer>
          </form>
        </FormProvider>
      </ExpandableSettingsContent>
      <Divider />
    </Container>
  )
}

export default GeneralPreferenceSettings

const FitContentContainer = styled(Container)`
  width: fit-content;
`
