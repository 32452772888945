import React from 'react'
import styled from 'styled-components'
import { FlexContainer } from 'components/styled/Containers'
import resumeIcon from 'containers/ProfilePage/assets/resumeIcon'
import websiteIcon from 'containers/ProfilePage/assets/websiteIcon'

//TODO: Profile should return User Social links

const UserSocial = ({ profileData }) => {
  const {
    linkedin_link,
    github_link,
    resume,
    personal_website_link
  } = profileData

  return (
    <SocialLinksWrapper className="pointer">
      {linkedin_link && (
        <a
          className="social-link"
          href={linkedin_link}
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="mdi mdi-linkedin"></i>
        </a>
      )}
      {github_link && (
        <a
          className="social-link"
          href={github_link}
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="mdi mdi-github"></i>
        </a>
      )}
      {personal_website_link && (
        <a
          className="social-link website"
          href={personal_website_link}
          target="_blank"
          rel="noopener noreferrer"
        >
          {websiteIcon}
        </a>
      )}
      {resume && (
        <a
          className="social-link resume resume-icon"
          href={resume.file_path}
          target="_blank"
          rel="noopener noreferrer"
        >
          {resumeIcon}
        </a>
      )}
    </SocialLinksWrapper>
  )
}

export default UserSocial

const SocialLinksWrapper = styled(FlexContainer)`
  padding-top: 4px;
  margin-left: 12px;
  .resume {
    padding-top: 3px;
  }
  .website {
    padding-top: 2px;
  }

  .social-link {
    svg {
      fill: ${({ theme }) => theme.color.primary.black3};
      margin: 0 8px 4px 0;
    }
    i {
      color: ${({ theme }) => theme.color.primary.black3};
      margin: 0 8px 4px 0;
      padding-top: 1px;
      transition: all 300ms ease-out;
      font-size: 1.15em;
    }

    &:hover {
      i {
        color: ${({ theme }) => theme.color.primary.black4};
      }
      svg {
        fill: ${({ theme }) => theme.color.primary.black4};
      }
    }
  }
  @media (max-width: 768px) {
    .resume {
      padding-top: 5px;
    }
    .social-link {
      margin: 0 8px;
    }
    svg,
    i {
      transform: scale(1.6);
    }
  }
`
