import React from 'react'

const deleteIcon = (
  <svg
    width="30px"
    height="32px"
    viewBox="0 0 32 32"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Engineer-files"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="regular-icon-hover" transform="translate(-62.000000, -325.000000)">
        <g id="delete" transform="translate(62.000000, 325.000000)">
          <rect
            id="Rectangle"
            x="0"
            y="0"
            width="32"
            height="32"
            rx="16"
          ></rect>
          <path
            d="M13.2499999,22 L18.7499998,22 C19.3015135,22 19.7499998,21.5515137 19.7499998,21 L19.7499998,15 L20.5,15 C20.7760007,15 21,14.776001 21,14.5 L21,13 C21,12.4484863 20.5515134,12 20,12 L18,12 L18,11 C18,10.4484863 17.5515135,10 17,10 L15,10 C14.4484862,10 14,10.4484863 14,11 L14,12 L12,12 C11.4484863,12 11,12.4484863 11,13 L11,14.5 C11,14.776001 11.223999,15 11.5,15 L12.25,15 L12.25,21 C12.25,21.5515137 12.6984863,22 13.2499999,22 Z M18.7499999,21 L13.2499999,21 L13.2499999,15 L18.7499999,15 L18.7499999,21 Z M14.9999999,11 L16.9999999,11 L16.9999999,12 L14.9999999,12 L14.9999999,11 Z M11.9995117,13 L19.9999998,13 L19.9999998,14 L11.9997559,14 L11.9995117,13 Z"
            id="Shape"
            fill="#5F5D66"
            fillRule="nonzero"
          ></path>
          <path
            d="M17.2499999,20 C17.5260008,20 17.7499999,19.776001 17.7499999,19.5 L17.7499999,16.5 C17.7499999,16.223999 17.5260008,16 17.2499999,16 C16.9739989,16 16.7499999,16.223999 16.7499999,16.5 L16.7499999,19.5 C16.7499999,19.776001 16.9739989,20 17.2499999,20 Z"
            id="Path"
            fill="#5F5D66"
            fillRule="nonzero"
          ></path>
          <path
            d="M14.7499999,20 C15.0260009,20 15.2499999,19.776001 15.2499999,19.5 L15.2499999,16.5 C15.2499999,16.223999 15.0260009,16 14.7499999,16 C14.4739989,16 14.2499999,16.223999 14.2499999,16.5 L14.2499999,19.5 C14.2499999,19.776001 14.4739989,20 14.7499999,20 Z"
            id="Path"
            fill="#5F5D66"
            fillRule="nonzero"
          ></path>
        </g>
      </g>
    </g>
  </svg>
)

export default deleteIcon
