import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import {
  Container,
  FlexContainer,
  AbsoluteContainer
} from 'components/styled/Containers'
import { IconAction } from 'components/styled/Buttons'
import useClickedOutsideEvent from 'services/hooks/useClickedOutsideEvent'
import hamburgerIcon from 'containers/ProfilePage/assets/hamburgerIcon'

const MobileNav = ({ isAuthenticated }) => {
  const [isDropdownShowing, setIsDropdownShowing] = useState(false)

  // Route options
  const routeThree = isAuthenticated
    ? { id: 3, route: '/settings/basic-profile', text: 'Settings' }
    : { id: 3, route: '/login', text: 'Login' }
  const routeFour = {
    id: 4,
    route: '/profile/project',
    text: 'Add new Project'
  }

  const navLinks = [
    { id: 1, route: '/jobs', text: 'Job Board' },
    { id: 2, route: '/showcase', text: 'Build your portfolio' },
    routeThree,
    ...(isAuthenticated ? [routeFour] : [])
  ]

  // Handle clicks dropdown
  const dropdownRef = useRef(null)
  useClickedOutsideEvent(dropdownRef, () => setIsDropdownShowing(false))

  const handleDropdown = () => {
    setIsDropdownShowing(!isDropdownShowing)
  }

  return (
    <MobileContainer ref={dropdownRef} height="100%">
      <FlexContainer height="100%" align="center">
        <IconAction color="#ffffff" onClick={handleDropdown}>
          <Container padding="4px 0 0">{hamburgerIcon}</Container>
        </IconAction>
      </FlexContainer>
      {isDropdownShowing && (
        <DropdownNav top="100%" right="-20px">
          {navLinks.map(link => {
            return (
              <NavItem
                key={link.id}
                onClick={() => setIsDropdownShowing(false)}
              >
                <Link to={link.route}>{link.text}</Link>
              </NavItem>
            )
          })}
        </DropdownNav>
      )}
    </MobileContainer>
  )
}

export default MobileNav

const MobileContainer = styled(Container)`
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
`

const DropdownNav = styled(AbsoluteContainer)`
  background: #ffffff;
  padding: 20px;
  border-radius: 0 0 0 4px;
  box-shadow: 0 0.5px 0 0 #ffffff inset, 0 1px 2px 0 #b3b3b3;
  width: 100vw;
`

const NavItem = styled(Container)`
  margin: 0 0 8px 0;
  a {
    text-transform: none;
    color: #373440;
    border-bottom: 2px solid transparent;
  }
  a:hover {
    transition: 200ms ease-out;
    border-bottom: 2px solid ${({ theme }) => theme.color.primary.yellow5};
  }
`
