import React from 'react'
import styled from 'styled-components'
import ErrorPage from './index'

const InternalErrorPage = () => {
  const Message = () => {
    return (
      <StyledMessage>
        Something went wrong. Please contact{' '}
        <StyledLink href="mailto:recruitment@correlation-one.com">
          recruitment@correlation-one.com
        </StyledLink>{' '}
        for help.
      </StyledMessage>
    )
  }

  return <ErrorPage subtitle={<Message />} errorCode={500} />
}

const StyledMessage = styled.p`
  color: ${({ theme }) => theme.color.text.white};
  font-size: 28px;
  font-weight: 400;
  letter-spacing: 0.15px;
  line-height: 36px;
`

const StyledLink = styled.a`
  color: ${({ theme }) => theme.color.text.white};
  text-decoration: none;
`

export default InternalErrorPage
