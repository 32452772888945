import React, { useEffect } from 'react'
import WelcomeAnimation from 'containers/ProfilePage/components/welcomeModal/WelcomeAnimation'
import useAppContext from 'services/context/appContext/useAppContext'

const WelcomeHelper = () => {
  const { api, requests, appDispatchActions } = useAppContext()

  useEffect(() => {
    const timer = setTimeout(() => {
      api.request(
        requests().updateProfileConfiguration,
        { show_welcome_screen: false },
        response => {
          appDispatchActions.updatedProfile()
        },
        error => console.log(error)
      )
    }, 4000)

    return () => clearTimeout(timer)
  }, [api, requests, appDispatchActions])

  return <WelcomeAnimation />
}

export default WelcomeHelper
