const connectTheme = {
  media: {
    desktop: '1200px',
    tablet: '900px',
    mobile: '768px',
    mobileSm: '420px'
  },
  font: {
    family: 'Roboto',
    headFamily: 'DM Serif Text',
    weight: {
      regular: 400,
      medium: 500,
      bold: 700,
      black: 900
    }
  },
  color: {
    primary: {
      black6: '#363540',
      black5: '#2C2A33',
      black4: '#373440',
      black3: '#5F5D66',
      black2: '#87858C',
      black1: '#AFAEB3',
      black0: '#D7D6D9',
      black: '#000000',
      yellow5: '#DFA228',
      yellow4: '#FFC043',
      yellow3: '#FFCD69',
      yellow2: '#FFD98E',
      yellow1: '#FFE6B4',
      yellow0: '#FFF2D9'
    },
    system: {
      blue5: '#6180AD',
      blue4: '#79A0D8',
      blue3: '#94B3E0',
      blue2: '#AfC6E8',
      blue1: '#C9D9Ef',
      blue0: '#E4ECf7',
      green5: '#589678',
      green4: '#6EBC96',
      green3: '#8BC9AB',
      green2: '#A8D7C0',
      green1: '#C5E4D5',
      green0: '#E2f2EA',
      red5: '#AA3E3D',
      red4: '#F79181',
      red3: '#DD7170',
      red2: '#E69594',
      red1: '#EEB8B7',
      red0: '#F7DCDB'
    },
    text: {
      dark: '#2C2C2C',
      darkGray: '#2a2a3b',
      white: '#FFFFFF',
      lite: '#C9C9C9',
      error: '#E12916',
      disabled: '#A2A2A2',
      tag: '#845E12',
      danger: '#D54E4B',
      inactive: '#DADADA',
      fade: '#DDCEBB',
      link: '#5870CC',
      link2: '#7594C7',
      black: '#383440',
      subtitle: '#8A8476',
      subText: '#7c7c7c',
      suvaGray: '#87858c'
    },
    bg: {
      norm: '#FFFFFF',
      oldLace: '#FFF9E7',
      darkNorm: '#F2F2F2',
      gray: '#F8F8F8',
      liteGray: '#e2e2e2',
      liteYellow: '#F9F5EA',
      liteYellowMatch: '#F3EAD5',
      darkGray: '#D7D7D7',
      inputHover: '#DFDFDF',
      buttonHover: '#E5E5E5',
      showcasePurple: '#3f3c4b',
      black: '#000000',
      lite: '#F3EBD5',
      dark: '#373440',
      white: '#FFFFFF',
      disabled: '#DDDDDD',
      borderless: '#E1E1E1',
      green: '#E2f2E9',
      liteGreen: '#DEF1DC',
      lightPurp: '#F8F0FF',
      modal: '#2C2A33ED',
      darkModal: '#5D5C62',
      warningRed: '#D54E4C',
      warningRedHover: '#AE2B29',
      featuredYellow: '#ffefce',
      blueMagenta: '#676783'
    },
    border: {
      norm: '#F2F2F2',
      active: '#E0A128',
      lite: '#C9C9C9',
      error: '#DC675C',
      input: '#E1E1E1',
      tag: '#CA9E47',
      purp: '#DACCF4',
      gray: '#dbdbdb',
      white: '#ffffff',
      veryLiteGray: '#CDCDCD'
    }
  },
  order: {
    front: 1,
    navbar: 10,
    projectPage: 15,
    projectPageNav: 25,
    modal: 30,
    welcomeAnimation: 35,
    dropdownOptions: 40,
    modalMobileNav: 45,
    zoomProjectModal: 50
  }
}

export default connectTheme

/* 

- Font weights will determine whether it is Roboto Medium / Regular / Bold / Black
- Color convention: 5 is DARKEST, 0 is LIGHTEST / most faded of that color.
- Order superFront is only to be used for debugging

*/
