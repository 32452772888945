import React, { useState, useEffect, useCallback, useRef } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import CallToAction from 'containers/JobListingPage/components/CallToAction'
import JobListing from 'containers/JobListingPage/components/JobListing'
import ShareProfileModal from 'containers/JobListingPage/components/ShareProfileModal'
import { PageWrapper } from 'components/styled/Wrappers'
import useAppContext from 'services/context/appContext/useAppContext'
import useAuth from 'services/hooks/useAuth'
import useClickedOutsideEvent from 'services/hooks/useClickedOutsideEvent'
import backArrowIcon from 'containers/ProjectPage/assets/backArrowIcon'

const JobListingPage = ({ location: { pathname } }) => {
  const { isAuthenticated } = useAuth()
  const location = useLocation()
  const history = useHistory()
  const modalRef = useRef(null)
  const [{ api, requests }] = useState(useAppContext())
  const [jobListing, setJobListing] = useState(null)
  const [togglePrompt, setTogglePrompt] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [showUndoNotification, setShowUndoNotification] = useState(false)
  const jobListingId = pathname.split('/').pop()

  const fetchJobListing = useCallback(async () => {
    await api.request(
      requests({ jobListingId: jobListingId, authed: isAuthenticated })
        .getJobListing,
      {},
      response => {
        setJobListing(response.data)
      }
    )
  }, [api, requests, jobListingId, isAuthenticated])

  useEffect(() => {
    fetchJobListing()
  }, [fetchJobListing, togglePrompt])

  const handleUndoNotification = () => {
    return (
      <UndoNotification>
        <p>
          Your application is completed{' '}
          <UndoButton onClick={() => handleUndo()}>undo</UndoButton>
        </p>
        <CloseNotification onClick={() => setShowUndoNotification(false)}>
          x
        </CloseNotification>
      </UndoNotification>
    )
  }

  const handleBackButton = () => {
    return location.state?.fromJobs ? (
      <BackButton onClick={() => history.goBack()}>
        {backArrowIcon} Back to all jobs
      </BackButton>
    ) : (
      <Link to="/jobs">
        <BackButton>{backArrowIcon} Back to all jobs</BackButton>
      </Link>
    )
  }

  const handleUndo = async () => {
    await api.request(requests({ jobListingId }).undoExternalApplication, {})
    setShowUndoNotification(false)
    setTogglePrompt(!togglePrompt)
  }

  useClickedOutsideEvent(modalRef, () => setShowModal(false))

  return (
    <PageWrapper>
      {jobListing && (
        <ContentWrapper>
          {showUndoNotification && handleUndoNotification()}
          {handleBackButton()}
          <JobListingCard>
            <JobListing jobListing={jobListing} />
            <CallToAction
              jobListingId={jobListingId}
              isSaved={jobListing.is_saved}
              isQuickApply={jobListing.quick_apply}
              applicationLink={jobListing.application_link}
              completedExternalApplication={
                jobListing.completed_external_application
              }
              hasSharedProfile={jobListing.has_shared_profile}
              hasApplicationLink={!!jobListing.application_link}
              jobListing={jobListing}
              setShowModal={setShowModal}
              setShowUndoNotification={setShowUndoNotification}
              togglePrompt={togglePrompt}
              setTogglePrompt={setTogglePrompt}
            />
          </JobListingCard>
          {showModal && (
            <ShareProfileModal
              setShowModal={setShowModal}
              setTogglePrompt={setTogglePrompt}
              applicationLink={jobListing.application_link}
              jobListingId={jobListing.id}
            />
          )}
        </ContentWrapper>
      )}
    </PageWrapper>
  )
}

export default JobListingPage

const ContentWrapper = styled.div`
  width: 100%;
  max-width: 1140px;
  margin: 0 auto;
  padding: 0;
  display: block;
  height: auto;
  position: relative;
  @media screen and (max-width: 768px) {
    padding: 0 16px;
  }
  @media screen and (max-width: 420px) {
    padding: 0 0;
  }
`

const UndoNotification = styled.div`
  align-items: center;
  background: #f3ebd5;
  border: 1px solid #ddcebb;
  border-radius: 2px;
  display: flex;
  height: 49px;
  justify-content: space-between;
  left: 50%;
  margin-left: -210px;
  padding: 0 24px;
  position: absolute;
  top: -12px;
  width: 420px;
  p {
    font-size: 14px;
    font-weight: 500;
  }
`

const CloseNotification = styled.b`
  cursor: pointer;
`

const UndoButton = styled(CloseNotification)`
  border-bottom: 1px solid #979797;
  letter-spacing: 1px;
  padding-bottom: 3px;
  text-transform: uppercase;
`

const BackButton = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.color.bg.dark};
  cursor: pointer;
  display: flex;
  font-weight: 500;
  letter-spacing: 0.46px;
  margin-bottom: 64px;
  text-transform: none;
  svg {
    margin-right: 4px;
  }
`

const JobListingCard = styled.div`
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  margin: 33px 0 0;
`
