import styled from 'styled-components'

const Subtitle = styled.h4`
  margin: 0;
  padding: 0;
  margin-bottom: 16px;
  font-weight: ${({ theme }) => theme.font.weight.medium};
  font-size: 16px;
  letter-spacing: 0.15px;
  line-height: 24px;
`

export default Subtitle
