import React from 'react'

const yellowStarIcon = (
  <svg width="13px" height="12px" viewBox="0 0 13 12" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        transform="translate(-350.000000, -851.000000)"
        stroke="#FFC043"
        strokeWidth="3.5"
      >
        <g transform="translate(84.000000, 833.000000)">
          <g transform="translate(256.000000, 8.000000)">
            <g transform="translate(10.000000, 10.000000)">
              <path
                d="M6.5,3.86062584 L7.3613014,5.55429424 L9.15940385,5.80939699 L7.86263894,
              7.03536061 L8.18211101,8.84335341 L6.5,7.98472171 L4.81788899,8.84335341 L5.13736106,
              7.03536061 L3.84059615,5.80939699 L5.6386986,5.55429424 L6.5,3.86062584 Z"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default yellowStarIcon
