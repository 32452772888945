import React from 'react'
import styled from 'styled-components'
import { FlexContainer } from 'components/styled/Containers'
import { ContentWrapper } from 'components/styled/Wrappers'

const FooterCTA = ({ children, className, bgColor }) => (
  <CTAWrapper
    align="center"
    justify="center"
    {...bgColor}
    className={className}
    width="100%"
    height="auto"
  >
    <ContentWrapper className="slim">
      <FlexContainer
        align="center"
        justify="center"
        direction="column"
        width="100%"
        height="auto"
      >
        {children}
      </FlexContainer>
    </ContentWrapper>
  </CTAWrapper>
)

export default FooterCTA

const CTAWrapper = styled(FlexContainer)`
  position: relative;
  background: ${({ bgColor }) => bgColor || '#2c2a33'};
  padding: 0 16px;
  min-height: 180px;
  h1 {
    font-family: ${({ theme }) => theme.font.headFamily};
    font-size: 28px;
    font-weight: normal;
    letter-spacing: 0.09px;
    line-height: 41px;
    text-align: center;
    color: #fff;
    margin: 0 auto 16px;
  }
  &.faded {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100vw;
    min-height: 25vh;
    background: linear-gradient(
      to bottom,
      transparent 0%,
      ${({ bgColor }) => bgColor || '#f3ebd5'} 50%
    );
    padding: 64px 16px;
    h1 {
      color: ${({ theme }) => theme.color.text.black};
    }
  }
  @media (max-width: 420px) {
    padding: 20px;
  }
`
