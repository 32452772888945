import React from 'react'

const crossMark = (
  <svg width="6px" height="7px" viewBox="0 0 6 7" version="1.1">
    <g
      id="Engineer-files"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="project-creation_filled"
        transform="translate(-1080.000000, -440.000000)"
      >
        <g id="Group-Copy-4" transform="translate(919.000000, 401.000000)">
          <g id="Group-28-Copy" transform="translate(98.000000, 31.000000)">
            <g
              id="+"
              transform="translate(66.000000, 12.000000) rotate(-315.000000) translate(-66.000000, -12.000000) translate(58.000000, 4.000000)"
            >
              <circle id="Oval" cx="8" cy="8" r="8"></circle>
              <g
                id="Group-5"
                transform="translate(5.000000, 5.500000)"
                className="icon"
                stroke="#373440"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
              >
                <path
                  d="M0.103125,2.46710526 L5.259375,2.46710526"
                  id="Line"
                ></path>
                <path d="M2.68125,0 L2.68125,4.93421053" id="Line-Copy"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default crossMark
