import React from 'react'

const incomeIcon = (
  <svg
    width="16px"
    height="16px"
    viewBox="0 0 16 16"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      opacity="0.5"
    >
      <g
        transform="translate(-562.000000, -244.000000)"
        fill="#000000"
        fillRule="nonzero"
      >
        <g transform="translate(153.000000, 165.000000)">
          <g transform="translate(112.000000, 5.000000)">
            <g transform="translate(133.000000, 73.938272)">
              <g transform="translate(164.000000, 0.061728)">
                <path
                  d="M9.45028125,7.91625 C11.6327813,7.91625 
                13.4080937,6.140625 13.4080937,3.958125 C13.4080937,
                1.775625 11.6327813,0 9.45028125,0 C7.26778125,0 5.49215625,
                1.775625 5.49215625,3.958125 C5.49215625,6.140625 7.26778125,
                7.91625 9.45028125,7.91625 Z M8.98153125,1.6315625 L8.98153125,
                1.5240625 C8.98153125,1.265 9.19121875,1.0553125 9.45028125,1.0553125 
                C9.70903125,1.0553125 9.91903125,1.265 9.91903125,1.5240625 L9.91903125,
                1.6321875 C10.4768438,1.751875 10.8965312,2.24875 10.8965312,2.841875 
                C10.8965312,3.100625 10.6865312,3.310625 10.4277812,3.310625 C10.1687188,
                3.310625 9.95903125,3.100625 9.95903125,2.841875 C9.95903125,2.6765625 
                9.82434375,2.541875 9.65903125,2.541875 L9.23871875,2.541875 C9.07340625,
                2.541875 8.93871875,2.6765625 8.93871875,2.841875 C8.93871875,2.9365625 
                8.98403125,3.0265625 9.06028125,3.0828125 L9.45028125,3.3715625 L10.3977812,
                4.0728125 C10.7090312,4.303125 10.8965312,4.6715625 10.8990591,5.0590625 
                L10.8990591,5.0634375 C10.9012187,5.3940625 10.7746562,5.7059375 
                10.5421562,5.94125 C10.3696562,6.1159375 10.1540312,6.233125 
                9.91903125,6.2825 L9.91903125,6.3921875 C9.91903125,6.65125 9.70903125,
                6.8609375 9.45028125,6.8609375 C9.19121875,6.8609375 8.98153125,6.65125 
                8.98153125,6.3921875 L8.98153125,6.2840625 C8.75278125,6.2353125 8.54246875,
                6.1228125 8.37246875,5.9546875 C8.13715625,5.7225 8.00653125,5.4125 
                8.00465625,5.081875 C8.00309375,4.823125 8.21153125,4.611875 8.47028125,
                4.6103125 L8.47340625,4.6103125 C8.73090625,4.6103125 8.94059375,4.8184375 
                8.94215625,5.07625 C8.94278125,5.23803125 9.0729375,5.374375 9.24371875,
                5.374375 C9.50271875,5.3728125 9.40625,5.373375 9.66340625,5.371875 
                C9.82978125,5.3705625 9.9628125,5.23553125 9.96153125,5.0696875 
                L9.96153125,5.0653125 C9.96090625,4.9715625 9.91559375,4.8821875 
                9.83996875,4.82625 L9.45028125,4.5378125 L8.50246875,3.8365625 
                C8.18871875,3.6040625 8.00121875,3.2321875 8.00121875,2.841875 
                C8.00121875,2.2475 8.42215625,1.7503125 8.98153125,1.6315625 
                L8.98153125,1.6315625 Z"
                ></path>
                <path
                  d="M3.037125,10.1685 C2.91696875,9.9601875 
                2.6500625,9.88771875 2.44046875,10.008625 L0.23553125,
                11.2814375 C0.02675,11.4022813 -0.044875,11.6693125 0.07565625,
                11.878375 L2.32921875,15.7815625 C2.44984375,15.9902812 
                2.71671875,16.0619687 2.925875,15.9414375 L5.1308125,14.6683125 
                C5.33990625,14.54775 5.41125,14.2807187 5.2906875,14.0716562 
                L3.037125,10.1685 Z"
                ></path>
                <path
                  d="M15.6711875,9.389375 C15.459,9.0946875 
                15.048375,9.028125 14.754,9.2403125 C13.860875,9.88375 
                12.145875,11.119375 12.009625,11.2178125 C11.9468125,11.27125 
                11.880875,11.3196875 11.812125,11.3625 C11.5424375,11.5321875 
                11.229625,11.6228125 10.9030625,11.6228125 L8.65775,11.6228125 
                C8.399,11.6228125 8.189,11.413125 8.189,11.1540625 C8.189,10.8946875 
                8.3993125,10.6853125 8.65775,10.6853125 L11.059,10.6853125 
                C11.4118125,10.6853125 11.6943125,10.3915625 11.679625,10.038125 
                C11.665875,9.7028125 11.379625,9.4428125 11.044,9.4428125 
                L9.2161875,9.4428125 C9.0924375,9.311875 8.9568125,9.191875 
                8.8115,9.0846875 C8.3118125,8.71625 7.6943125,8.4984375 
                7.025875,8.4984375 C5.8374375,8.4984375 4.6549375,9.2471875 
                4.164625,10.2465625 L6.082125,13.5675 L9.679,13.5675 C10.38525,
                13.5675 11.080875,13.386875 11.6930625,13.0346875 C11.90525,
                12.9128125 12.1324375,12.76625 12.3786875,12.589375 C13.4105625,
                11.848125 15.52025,10.308125 15.5218125,10.3071875 C15.8168125,
                10.0953125 15.8836875,9.6840625 15.6711875,9.389375 L15.6711875,
                9.389375 Z"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default incomeIcon
