import React from 'react'

const pinIcon = (
  <svg
    width="13px"
    height="17px"
    viewBox="0 0 13 17"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      opacity="0.5"
    >
      <g
        transform="translate(-105.000000, -84.000000)"
        fill="#000000"
        fillRule="nonzero"
      >
        <g transform="translate(105.000000, 37.000000)">
          <g transform="translate(0.468060, 47.787941)">
            <path
              d="M5.58027345,7.80708831e-13 C2.51367187,7.80708831e-13 0.01875,
              2.49492188 0.01875,5.56152345 C0.01875,9.41591798 5.58027344,15.6232168 
              5.58027344,15.6232168 C5.58027344,15.6232168 11.1417969,9.2263672 
              11.1417969,5.56152345 C11.1417969,2.49492188 8.64697265,7.80708831e-13 
              5.58027345,7.80708831e-13 Z M7.25830077,7.1899414 C6.79560547,7.65253905 
              6.18798827,7.88388673 5.58027345,7.88388673 C4.97265625,7.88388673 
              4.36484375,7.65253905 3.90234375,7.1899414 C2.97705077,6.2647461 2.97705077,
              4.75927735 3.90234375,3.83398438 C4.35039062,3.3857422 4.94638672,3.1388672 
              5.58027345,3.1388672 C6.21416015,3.1388672 6.8100586,3.38583985 7.25830077,
              3.83398438 C8.18359375,4.75927735 8.18359375,6.2647461 7.25830077,7.1899414 Z"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default pinIcon
