import { useEffect, useState } from 'react'
import useAppContext from 'services/context/appContext/useAppContext'

const useFetchInspirationSlides = () => {
  const [slides, setSlides] = useState(null)
  const { state, api, requests } = useAppContext()
  const { isAuthenticated } = state

  useEffect(() => {
    // On fetch success
    const onSuccess = response => {
      setSlides(response.data)
    }

    const inspirationSource = !isAuthenticated
      ? 'getPublicInspirationPieces'
      : 'getInspirationPieces'

    const fetchInspirationSlides = async () => {
      await api.request(requests()[inspirationSource], {}, response =>
        onSuccess(response)
      )
    }

    fetchInspirationSlides()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { slides, isAuthenticated }
}

export default useFetchInspirationSlides
