import React, { useState, useEffect, useRef } from 'react'
import { useFormContext } from 'react-hook-form'
import styled from 'styled-components'
import useFetchSuggestableSkills from 'services/hooks/useFetchSuggestableSkills'
import starOutlineIcon from '../../assets/starOutlineIcon'
import starFilledIcon from '../../assets/starFilledIcon'
import close from '../../assets/close'

const SkillForm = ({ data }) => {
  const { suggestableSkillList } = useFetchSuggestableSkills()
  const [skillText, setSkillText] = useState('')
  const [skills, setSkills] = useState([])
  const [cursor, setCursor] = useState(-1)
  const [suggestionSkills, setSuggestionSkills] = useState([])
  const skillInput = useRef()
  const suggestionBoxRef = useRef()
  const form = useFormContext()

  const [featuredCount, setFeaturedCount] = useState(0)

  useEffect(() => {
    if (data.length) {
      const s = []
      data.forEach((skill, index) => {
        s.push({ id: index, name: skill.name, featured: skill.is_featured })
      })
      setSkills(s)
    }
  }, [data])

  useEffect(() => {
    setFeaturedCount(skills.filter(skill => skill.featured).length)
  }, [skills])

  const handleKeyDown = e => {
    if (e.keyCode === 32 && e.target.value.trim().length === 0) {
      e.preventDefault()
    } else if (e.key === 'Escape') {
      setSuggestionSkills([])
      setCursor(-1)
      e.preventDefault()
    } else if (e.key === 'Backspace') {
      setCursor(-1)
    } else if (e.keyCode === 38 && cursor > 0) {
      setCursor(cursor - 1)
      setSkillText(suggestionSkills[cursor - 1])
      suggestionBoxRef.current.scrollTop =
        suggestionBoxRef.current.scrollTop - 25
    } else if (e.keyCode === 40 && cursor < suggestionSkills.length - 1) {
      setCursor(cursor + 1)
      setSkillText(suggestionSkills[cursor + 1])
      suggestionBoxRef.current.scrollTop =
        suggestionBoxRef.current.scrollTop + 25
    } else if (e.key === 'Enter') {
      if (
        skills.length < 15 &&
        !skills.some(
          skill =>
            skill.name.toLowerCase() === e.target.value.toLowerCase().trim()
        )
      ) {
        setSkills(skills => [
          ...skills,
          { id: skills.length, name: skillText, featured: false }
        ])
      }
      setSuggestionSkills([])
      setSkillText('')
      setCursor(-1)
      e.preventDefault()
    }
  }

  const handleSkillOnClick = (id, isTopSkill) => {
    if (isTopSkill && skills.filter(f => f.featured).length === 5) {
      return
    }
    const updatingSkills = skills.map(data =>
      data.id === id ? { ...data, featured: !data.featured } : data
    )
    setSkills(updatingSkills)
  }

  const handleRemoveSkillOnClick = id => {
    const updatingSkills = skills.filter(skill => {
      return skill.id !== id
    })
    setSkills(updatingSkills)
  }

  const handleOnChange = e => {
    const value = e.target.value.trim()
    let suggestions = []
    if (value.length > 0) {
      suggestions = suggestableSkillList.filter(skill => {
        if (!skills.length) {
          return skill.toLowerCase().startsWith(value.toLowerCase())
        } else {
          for (let index = 0; index < skills.length; index++) {
            if (
              skills[index].name.toLowerCase() === skill.toLowerCase() ||
              !skill.toLowerCase().startsWith(value.toLowerCase())
            ) {
              return false
            }
          }
          return true
        }
      })
    }
    setSkillText(e.target.value)
    setSuggestionSkills(suggestions)
  }

  const handleSuggestionSelected = value => {
    if (skills.length < 15) {
      setSkills(skills => [
        ...skills,
        { id: skills.length, name: value, featured: false }
      ])
    }
    setSkillText('')
    setSuggestionSkills([])
    setCursor(-1)
    skillInput.current.focus()
  }

  return (
    <>
      <SuggesstionInputWrapper skillLength={skills.length}>
        <label>Type in your skills, languages, frameworks and libraries</label>
        <SuggestionBoxWrapper>
          <input
            type="text"
            placeholder="Ex: Javascript"
            maxLength={32}
            ref={skillInput}
            value={skillText}
            onKeyDown={handleKeyDown}
            onChange={handleOnChange}
          />
          {!suggestionSkills.length ? null : (
            <SuggestionBox ref={suggestionBoxRef}>
              {suggestionSkills.map((skill, i) => (
                <SuggestionItem
                  className={cursor === i ? 'active' : null}
                  key={`si_${skill}`}
                  onClick={() => handleSuggestionSelected(skill)}
                >
                  {skill}
                </SuggestionItem>
              ))}
            </SuggestionBox>
          )}
        </SuggestionBoxWrapper>
        {skills.map((skill, index) => {
          return (
            <React.Fragment key={`${index}-value`}>
              <input
                type="hidden"
                name={`skills[${index}].value`}
                value={skill.name}
                ref={form.register()}
              ></input>
              <input
                type="hidden"
                name={`skills[${index}].is_featured`}
                value={skill.featured}
                ref={form.register()}
              ></input>
            </React.Fragment>
          )
        })}
        {!skills.length ? (
          <React.Fragment key={`0-value`}>
            <input
              type="hidden"
              name={`skills[0].value`}
              value={''}
              ref={form.register()}
            ></input>
            <input
              type="hidden"
              name={`skills[0].is_featured`}
              value={false}
              ref={form.register()}
            ></input>
          </React.Fragment>
        ) : null}

        <span>
          {skills.length < 15
            ? 'You can add up to 15 skills.'
            : 'You have reached the max number of skills allowed.'}
        </span>
      </SuggesstionInputWrapper>

      {skills.filter(skill => skill.featured === true).length ? (
        <SkillsWraper>
          <Title>
            <label>Top skills selected</label>
            <Tooltip>
              !<span>Highlight your top skills on your profile.</span>
            </Tooltip>
          </Title>
          <Skills>
            {skills
              .filter(skill => skill.featured === true)
              .map((skill, index) => {
                return (
                  <Skill key={index}>
                    <button
                      type="button"
                      className="isActive"
                      onClick={() => handleSkillOnClick(skill.id, false)}
                    >
                      {starOutlineIcon} {starFilledIcon}
                    </button>
                    {skill.name}
                    <button
                      type="button"
                      onClick={() => handleRemoveSkillOnClick(skill.id)}
                    >
                      {close}
                    </button>
                  </Skill>
                )
              })}
          </Skills>
        </SkillsWraper>
      ) : null}

      {skills.filter(skill => skill.featured === false).length ? (
        <SkillsWraper>
          <LabelWrapper>
            <label>Additional skills added:</label>
            <label>(mark {starFilledIcon} a maximum of 5 top skills)</label>
          </LabelWrapper>
          <Skills>
            {skills
              .filter(skill => skill.featured === false)
              .map((skill, index) => {
                return (
                  <Skill key={index} disabled={featuredCount >= 5}>
                    <button
                      type="button"
                      onClick={() => handleSkillOnClick(skill.id, true)}
                    >
                      {starOutlineIcon} {starFilledIcon}
                    </button>
                    {skill.name}
                    <button
                      type="button"
                      onClick={() => handleRemoveSkillOnClick(skill.id)}
                    >
                      {close}
                    </button>
                  </Skill>
                )
              })}
          </Skills>
        </SkillsWraper>
      ) : null}
    </>
  )
}

export default SkillForm

const LabelWrapper = styled.div`
  display: flex;
  label:last-child {
    font-style: italic;
    margin-left: 2px;
  }
`

const Skills = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -3.5px;
  max-width: 436px;
`

const Skill = styled.span`
  align-items: center;
  background: ${({ theme }) => theme.color.bg.lite};
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.color.border.tag};
  color: ${({ theme }) => theme.color.text.tag};
  display: flex;
  font-size: 12px;
  font-weight: bold;
  height: 24px;
  letter-spacing: 0.4px;
  margin: 3.5px;
  padding: 4px 24px 4px 28px;
  position: relative;
  width: max-content;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  button:first-of-type {
    border: 0px;
    padding: 0px;
    margin: 0px;
    outline: 0px;
    appearance: none;
    background-color: transparent;
    position: absolute;
    left: 8px;
    margin-top: -1px;
    pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
    svg:last-child {
      display: none;
    }
    &.isActive svg:last-child,
    &:hover svg:last-child {
      display: block;
    }
    &.isActive svg:first-child,
    &:hover svg:first-child {
      display: none;
    }
  }

  button:last-of-type {
    border: 0px;
    padding: 0px;
    margin: 0px;
    outline: 0px;
    appearance: none;
    background-color: transparent;
    position: absolute;
    right: 12.8px;
    transition: all 300ms ease-out 0s;
    opacity: 0.5;
    svg {
      width: 8px;
    }
    &:hover {
      opacity: 1;
    }
  }
`

const Title = styled.div`
  display: flex;
`

const Tooltip = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.color.bg.lite};
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  font-size: 9px;
  height: 12px;
  justify-content: center;
  left: 7px;
  position: relative;
  top: -5px;
  width: 12px;
  span {
    visibility: hidden;
    position: absolute;
    z-index: 1000;
    background: ${({ theme }) => theme.color.bg.black};
    border-radius: 4px;
    border: 1px solid ${({ theme }) => theme.color.border.veryLiteGray};
    height: 24px;
    width: 230px;
    color: ${({ theme }) => theme.color.primary.black2};
    font-size: 12px;
    letter-spacing: 0.18px;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 80%;
    bottom: 50%;
  }
  &:hover span {
    visibility: visible;
  }
`

const SkillsWraper = styled.div`
  padding-top: 11px;
  & + & {
    margin-top: 24px;
  }
  label {
    color: ${({ theme }) => theme.color.primary.black5};
    display: block;
    letter-spacing: 0.25px;
    line-height: 17px;
    margin-bottom: 3px;
    text-align: left;
    svg {
      display: inline;
      vertical-align: middle;
    }
  }
`

const SuggesstionInputWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;

  label {
    color: ${({ theme }) => theme.color.primary.black5};
    letter-spacing: 0.25px;
    line-height: 17px;
    margin-bottom: 4px;
  }

  input {
    appearance: none;
    background: ${({ theme }) => theme.color.bg.darkNorm};
    border-radius: 4px;
    border: 1px solid ${({ theme }) => theme.color.border.input};
    color: rgb(44, 42, 51);
    cursor: text;
    height: 40px;
    margin-bottom: 4px;
    opacity: 1;
    outline: none;
    padding: 12px 14px;
    width: 100%;
    &:focus {
      background: transparent;
      border-color: ${({ theme }) => theme.color.border.active};
    }
  }

  span {
    align-self: flex-end;
    color: ${({ theme, skillLength }) =>
      skillLength === 15
        ? theme.color.bg.warningRed
        : theme.color.primary.black5};
    font-size: 10px;
    font-style: italic;
    letter-spacing: 0.18px;
    line-height: 17px;
  }
`

const SuggestionBoxWrapper = styled.div`
  position: relative;
  width: 100%;
`

const SuggestionBox = styled.div`
  background: ${({ theme }) => theme.color.bg.white};
  border-radius: 0px 0px 4px 4px;
  border: 1px solid ${({ theme }) => theme.color.border.veryLiteGray};
  left: 0px;
  max-height: 200px;
  overflow-y: scroll;
  padding: 8px;
  position: absolute;
  scroll-behavior: smooth;
  top: 100%;
  width: 100%;
  z-index: 40;
`

const SuggestionItem = styled.div`
  border-radius: 2px;
  color: ${({ theme }) => theme.color.primary.black3};
  cursor: pointer;
  font-size: 14px;
  padding: 8px;
  text-align: left;
  transition: all 200ms ease-out 0s;
  &:hover {
    background: ${({ theme }) => theme.color.bg.borderless};
  }
  &.active {
    background: ${({ theme }) => theme.color.bg.borderless};
  }
`
