import { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { AppContext } from 'services/context/appContext/AppContext'
import appDispatchActions from 'services/context/appContext/dispatchActions'
import useApi from 'services/hooks/useApi'

const useAppContext = () => {
  const { state, dispatch } = useContext(AppContext)
  const { api, requests } = useApi()
  const history = useHistory()

  return {
    state,
    appDispatchActions: appDispatchActions(dispatch),
    history,
    api,
    requests
  }
}

export default useAppContext
