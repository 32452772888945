import React from 'react'
import styled from 'styled-components'
import Input from 'components/forms/Input'
import Textarea from 'components/forms/Textarea'
import Select from 'components/forms/Select'
import DropdownSelect from 'components/forms/DropdownSelect'
import { FlexContainer, Container } from 'components/styled/Containers'
import {
  isStartYearValid,
  isEndYearValid,
  useSetStartDateErrors,
  useSetEndDateErrors
} from 'containers/ProfilePage/helpers/areYearsValidInForm'
import { useSetInvalidInputError } from 'containers/ProfilePage/helpers/areYearsValidInForm'
import { months, populateYears } from 'containers/ProfilePage/helpers/datetime'

const degreeOptions = ['Associate', 'Bachelors', 'Masters', 'Doctoral']

const EducationForm = ({
  formProps,
  setProjectAssociationData,
  projectAssociationData,
  data,
  formActionType
}) => {
  const {
    register,
    errors,
    watch,
    setError,
    clearErrors,
    formState
  } = formProps
  const yearsForEndDate = populateYears()
  const yearsForStartDate = populateYears(new Date().getFullYear())
  const { start_month, start_year, end_month, end_year, gpa } = watch()
  const startDate = isStartYearValid(start_month, start_year)
  const endDate = isEndYearValid(start_month, start_year, end_month, end_year)

  useSetStartDateErrors(startDate, setError, clearErrors)
  useSetEndDateErrors(endDate, setError, clearErrors)
  useSetInvalidInputError(
    setError,
    clearErrors,
    watch,
    formState,
    formActionType
  )

  return (
    <>
      <Input
        label="School"
        testId="education-input-school-name"
        type="text"
        name="school_name"
        placeholder="School Name"
        register={register}
        errors={errors}
        required="required"
      />
      <MobileFlexContainer justify="space-between">
        <MobileContainer width="48%">
          <Select
            label="Degree"
            testId="education-input-degree-type"
            type="text"
            name="degree_type"
            options={degreeOptions}
            placeholder="Ex: Bachelors"
            register={register}
            errors={errors}
            initialValue={data && data.degree_type}
            required="required"
          />
        </MobileContainer>
        <MobileContainer width="48%">
          <Input
            label="Major"
            testId="education-input-degree"
            type="text"
            name="degree"
            placeholder="Ex: MS in Computer Science"
            register={register}
            errors={errors}
            required="required"
          />
        </MobileContainer>
      </MobileFlexContainer>
      <MobileFlexContainer
        justify="space-between"
        className="outer-container-flex"
      >
        <MobileContainer width="48%" className="outer-container">
          <MobileFlexContainer justify="space-between">
            <MobileContainer width="48%">
              <Select
                label="Start Date"
                type="text"
                name="start_month"
                options={months}
                placeholder="Month"
                register={register}
                errors={errors}
                initialValue={data && data.start_month}
              />
            </MobileContainer>
            <MobileContainer width="48%">
              <Select
                blankLabel={true}
                label="Year"
                type="text"
                name="start_year"
                options={yearsForStartDate}
                placeholder="Year"
                register={register}
                errors={errors}
                initialValue={data && data.start_year}
              />
            </MobileContainer>
          </MobileFlexContainer>
        </MobileContainer>
        <MobileContainer width="48%" className="outer-container">
          <MobileFlexContainer justify="space-between">
            <MobileContainer width="48%">
              <Select
                label="End Date"
                type="text"
                name="end_month"
                options={months}
                placeholder="Month"
                register={register}
                errors={errors}
                initialValue={data && data.end_month}
              />
            </MobileContainer>
            <MobileContainer width="48%">
              <Select
                blankLabel={true}
                label="Year"
                type="text"
                name="end_year"
                options={yearsForEndDate}
                placeholder="Year"
                register={register}
                errors={errors}
                initialValue={data && data.end_year}
              />
            </MobileContainer>
          </MobileFlexContainer>
        </MobileContainer>
      </MobileFlexContainer>
      <MobileFlexContainer justify="space-between">
        <MobileContainer width="48%">
          <Input
            label="GPA"
            testId="education-input-gpa"
            type="number"
            step="any"
            name="gpa"
            placeholder="Ex: 3.8"
            register={register}
            errors={errors}
          />
        </MobileContainer>
        <GPAOutOfMobileContainer width="48%">
          {gpa && (
            <Input
              label="GPA Out Of"
              testId="education-input-gpa-out-of"
              type="number"
              step="any"
              name="gpa_out_of"
              placeholder="Ex: 4.0"
              register={register}
              errors={errors}
            />
          )}
        </GPAOutOfMobileContainer>
      </MobileFlexContainer>
      <Textarea
        label="Accomplishments"
        testId="education-textarea-accomplishment"
        type="text"
        name="description"
        placeholder="Briefly describe your accomplishments"
        register={register}
        errors={errors}
        showCaption={true}
        maxlength="280"
      />
      <DropdownSelect
        placeholder="Click to select"
        label="Projects associated"
        setProjectAssociationData={setProjectAssociationData}
        projectAssociationData={projectAssociationData}
      />
      <Input
        className="hidden"
        testId="education-input-order"
        type="number"
        name="order"
        register={register}
        errors={errors}
      />
    </>
  )
}

export default EducationForm

const MobileFlexContainer = styled(FlexContainer)`
  &.outer-container-flex {
    @media (max-width: 768px) {
      justify-content: flex-start;
      flex-direction: column;
    }
  }
  @media (max-width: 420px) {
    justify-content: flex-start;
    flex-direction: column;
  }
`

const MobileContainer = styled(Container)`
  &.outer-container {
    @media (max-width: 768px) {
      width: 100%;
    }
  }
  @media (max-width: 420px) {
    width: 100%;
  }
`

const GPAOutOfMobileContainer = styled(MobileContainer)`
  p {
    width: 210px;
  }
`
