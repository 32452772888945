import React from 'react'

const removeItem = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#d54e4c"
  >
    <path
      d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 
    12 12 12-5.373 12-12-5.373-12-12-12zm6 
    13h-12v-2h12v2z"
    />
  </svg>
)

export default removeItem
