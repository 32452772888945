import React from 'react'
import ErrorPage from './index'

const NotFoundPage = () => {
  return (
    <ErrorPage
      subtitle={'We can’t seem to find the page you’re looking for.'}
      errorCode={404}
    />
  )
}

export default NotFoundPage
