import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useFormContext } from 'react-hook-form'
import { ErrorMessage, InputLabel } from 'components/styled/Text'
import { Container } from 'components/styled/Containers'

const Textarea = ({
  type,
  placeholder,
  name,
  value,
  disabled,
  testId,
  className,
  label,
  subText,
  rows,
  maxlength,
  showCaption,
  required,
  tooltipText
}) => {
  const form = useFormContext()

  const charName = `${name}-chars`
  const charLength = form.watch(name) && form.watch(name).length
  const charRemaining = maxlength - charLength
  const renderAmount = isNaN(charRemaining) ? maxlength : charRemaining
  const errorCheck = renderAmount < 0
  const charMessage =
    renderAmount.toString() === maxlength
      ? 'character limit'
      : 'characters left'

  const handleOnChange = () => {
    if (form && errorCheck && !form.errors[charName]) {
      form.setError(charName, {
        type: 'manual',
        message: 'You have gone over the character limit'
      })
    } else if (form && !errorCheck && form.errors[charName]) {
      form.clearErrors(charName)
    }
  }

  return (
    <TextAreaContainer>
      {label && (
        <InputLabelContainer>
          <InputLabel className={required}>{label}</InputLabel>
          {tooltipText && (
            <Tooltip required={required}>
              !<TooltipText>{tooltipText}</TooltipText>
            </Tooltip>
          )}
        </InputLabelContainer>
      )}
      {subText && <TextAreaCaption>{subText}</TextAreaCaption>}
      <TextContainer>
        <TextAreaStyled
          className={className}
          data-testid={testId}
          type={type}
          name={name}
          placeholder={placeholder}
          ref={form && form.register}
          errors={form && form.errors[name]}
          defaultValue={value}
          disabled={disabled}
          rows={rows}
          maxlength={maxlength}
          onChange={handleOnChange}
        />
        {showCaption && (
          <CharLengthText errorCheck={errorCheck} className="caption">
            {renderAmount} {charMessage}
          </CharLengthText>
        )}
      </TextContainer>
      {form && form.errors[name] ? (
        <ErrorMessage className={className}>
          {form.errors[name].message || ''}
        </ErrorMessage>
      ) : (
        form.errors[charName] && (
          <ErrorMessage className={className}>
            {form.errors[charName].message || ''}
          </ErrorMessage>
        )
      )}
    </TextAreaContainer>
  )
}

// -- PROP DESCRIPTIONS -- //
// type: input type (text, password, etc...)
// placeholder: placeholder text
// name: name associated with react-hook-form
// testId: testId used for testing with react-testing-library
// label: label above input
// value: set a default value
// className: add a classname
// subtext: adding small subtext under the label
// disabled: sets input unmanipulatable by user
// rows: sets the rows of textarea, default is 3
// maxlength: max characters allowed in textarea
// showCaption: show caption revealing max character count

Textarea.propTypes = {
  type: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  testId: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.any,
  className: PropTypes.string,
  subtext: PropTypes.string,
  disabled: PropTypes.bool,
  rows: PropTypes.string,
  maxlength: PropTypes.string,
  showCaption: PropTypes.bool,
  tooltipText: PropTypes.string
}

Textarea.defaultProps = {
  type: 'text',
  placeholder: 'Placeholder',
  name: 'input',
  disabled: false,
  rows: '3',
  maxlength: '80',
  showCaption: false
}

export default Textarea

const CharLengthText = styled.span`
  line-height: normal;
  color: ${({ theme, errorCheck }) =>
    errorCheck ? theme.color.text.error : theme.color.primary.black2};
`
const TextContainer = styled(Container)`
  position: relative;
  .caption {
    position: absolute;
    bottom: 8px;
    right: 4px;
    pointer-events: none;
    padding: 4px;
  }
`

const TextAreaContainer = styled(Container)`
  margin-bottom: 16px;
  position: relative;
  text-align: left;
`

const TextAreaCaption = styled.p`
  font-weight: ${({ theme }) => theme.font.weight.regular};
  color: rgba(0, 0, 0, 0.5);
  font-size: 12px;
  letter-spacing: 0.4px;
  line-height: 16px;
`

const TextAreaStyled = styled.textarea`
  width: 100%;
  outline: none;
  min-height: 40px;
  height: auto;
  border-radius: 4px;
  margin: 8px 0 0;
  color: ${({ theme }) => theme.color.primary.black5};
  border: 1px solid
    ${({ theme, errors }) =>
      errors ? theme.color.border.error : theme.color.border.input};
  padding: 12px 14px;
  appearance: none;
  /* stylelint-disable-next-line */
  -moz-appearance: textfield;
  background: ${({ theme }) => theme.color.bg.darkNorm};
  resize: none;
  &::placeholder {
    color: ${({ theme }) => theme.color.primary.black3};
  }
  &:hover {
    background: transparent;
  }
  &:focus {
    background: transparent;
    border-color: ${({ theme }) => theme.color.border.active};
  }
  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }
  &.hidden {
    display: none;
  }
`

const InputLabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const Tooltip = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.color.bg.lite};
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  font-size: 9px;
  height: 12px;
  justify-content: center;
  left: 7px;
  margin-left: ${({ required }) => (required ? '5px' : '0px')};
  position: relative;
  top: -5px;
  width: 12px;
  &:hover span {
    visibility: visible;
  }
`

const TooltipText = styled.span`
  align-items: center;
  background: ${({ theme }) => theme.color.bg.norm};
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.color.border.gray};
  bottom: 12px;
  color: ${({ theme }) => theme.color.primary.black2};
  display: flex;
  font-size: 12px;
  justify-content: center;
  left: 12px;
  letter-spacing: 0.18px;
  padding: 3px 5px 3px 5px;
  position: absolute;
  visibility: hidden;
  width: 230px;
  z-index: 1000;
`
