import React from 'react'
import styled from 'styled-components'
import Input from 'components/forms/Input'
import Textarea from 'components/forms/Textarea'
import DropdownSelect from 'components/forms/DropdownSelect'
import Checkbox from 'components/forms/Checkbox'
import Select from 'components/forms/Select'
import {
  FlexContainer,
  Container,
  AbsoluteContainer
} from 'components/styled/Containers'
import {
  isStartYearValid,
  isEndYearValid,
  useSetStartDateErrors,
  useSetEndDateErrors
} from 'containers/ProfilePage/helpers/areYearsValidInForm'
import { months, populateYears } from 'containers/ProfilePage/helpers/datetime'

const WorkExperienceForm = ({
  formProps,
  setProjectAssociationData,
  projectAssociationData,
  data
}) => {
  const { register, errors, watch, setError, clearErrors } = formProps
  const { start_month, start_year, end_month, end_year } = watch()
  const disableEndDate = Boolean(watch('current'))
  const isChecked = data && !Boolean(data.end_date)
  const yearsForStartAndEndDate = populateYears(new Date().getFullYear())
  const startDate = isStartYearValid(start_month, start_year)
  const endDate = isEndYearValid(start_month, start_year, end_month, end_year)

  useSetStartDateErrors(startDate, setError, clearErrors)
  useSetEndDateErrors(endDate, setError, clearErrors)

  return (
    <>
      <Input
        label="Title"
        testId="we-input-role"
        type="text"
        name="role"
        placeholder="Ex: Data Engineer"
        register={register}
        errors={errors}
        required="required"
      />
      <Input
        label="Company"
        testId="we-input-company-name"
        type="text"
        name="company_name"
        placeholder="Ex: Microsoft"
        register={register}
        errors={errors}
        required="required"
      />
      <MobileFlexContainer justify="space-between">
        <MobileContainer width="48%">
          <Input
            label="Location"
            testId="we-input-location"
            type="text"
            name="location"
            placeholder="Ex: London, United Kingdom"
            register={register}
            errors={errors}
            required="required"
          />
        </MobileContainer>
        <MobileContainer width="48%">
          <Input
            label="Company Website"
            testId="we-input-website"
            type="text"
            name="company_website"
            placeholder="https://google.com"
            register={register}
            errors={errors}
          />
        </MobileContainer>
      </MobileFlexContainer>
      <DateContainer
        className="outer-container-flex"
        margin="0 0 28px"
        justify="space-between"
      >
        <MobileContainer width="48%" className="outer-container">
          <MobileFlexContainer justify="space-between">
            <MobileContainer height="100%" width="48%">
              <Select
                label="Start Date"
                type="text"
                name="start_month"
                options={months}
                placeholder="Month"
                register={register}
                errors={errors}
                initialValue={data && data.start_month}
                required="required"
              />
            </MobileContainer>
            <MobileContainer height="100%" width="48%">
              <Select
                blankLabel={true}
                label="Year"
                type="text"
                name="start_year"
                options={yearsForStartAndEndDate}
                placeholder="Year"
                register={register}
                errors={errors}
                initialValue={data && data.start_year}
              />
            </MobileContainer>
          </MobileFlexContainer>
        </MobileContainer>
        <MobileContainer width="48%" className="outer-container">
          <MobileFlexContainer justify="space-between">
            <MobileContainer width="48%">
              <Select
                label="End Date"
                type="text"
                name="end_month"
                options={months}
                placeholder="Month"
                register={register}
                errors={errors}
                initialValue={
                  disableEndDate ? 'Present' : data && data.end_month
                }
                disabled={disableEndDate}
              />
            </MobileContainer>
            <MobileContainer width="48%">
              <Select
                blankLabel={true}
                label="Year"
                type="text"
                name="end_year"
                options={yearsForStartAndEndDate}
                placeholder="Year"
                register={register}
                errors={errors}
                initialValue={
                  disableEndDate ? 'Present' : data && data.end_year
                }
                disabled={disableEndDate}
              />
            </MobileContainer>
          </MobileFlexContainer>
        </MobileContainer>
        <CheckContainer className="pointer" top="84%" left="0">
          <Checkbox
            className="current-employee"
            optionName="I am currently working in this role"
            questionName="current"
            optionValue=""
            isPreChecked={isChecked}
          />
        </CheckContainer>
      </DateContainer>
      <Textarea
        label="Responsibilities and Accomplishments"
        testId="we-textarea-summary"
        type="text"
        name="description"
        placeholder="Description"
        register={register}
        errors={errors}
        showCaption={true}
        maxlength="280"
        required="required"
      />
      <DropdownSelect
        setProjectAssociationData={setProjectAssociationData}
        projectAssociationData={projectAssociationData}
        placeholder="Click to select"
        label="Projects associated"
      />
      <Input
        className="hidden"
        testId="we-input-order"
        type="number"
        name="order"
        placeholder="Order"
        register={register}
        errors={errors}
      />
    </>
  )
}

export default WorkExperienceForm

const CheckContainer = styled(AbsoluteContainer)`
  @media (max-width: 768px) {
    top: 90%;
  }
  @media (max-width: 420px) {
    top: 95%;
  }
`

const DateContainer = styled(FlexContainer)`
  .current-employee {
    background: transparent;
    border: none;
  }
  .current-employee:hover {
    background: transparent;
    border: none;
  }
  .current-employee label {
    padding-left: 0;
    color: #87858c;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.4px;
  }
  input {
    left: 0;
  }
  @media (max-width: 768px) {
    justify-content: flex-start;
    flex-direction: column;
  }
`

const MobileFlexContainer = styled(FlexContainer)`
  &.outer-container-flex {
    @media (max-width: 768px) {
      justify-content: flex-start;
      flex-direction: column;
    }
  }
  @media (max-width: 420px) {
    justify-content: flex-start;
    flex-direction: column;
  }
`

const MobileContainer = styled(Container)`
  &.outer-container {
    @media (max-width: 768px) {
      width: 100%;
    }
  }
  @media (max-width: 420px) {
    width: 100%;
  }
`
