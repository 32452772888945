import { useEffect, useCallback, useState } from 'react'
import { questionnaire } from 'containers/QuestionnairePage/messages/messages'
import useAppContext from 'services/context/appContext/useAppContext'

const useEmployerPreferences = () => {
  const { question1 } = questionnaire.employerPrefs
  const [{ api, requests }] = useState(useAppContext())
  const [locationList, setLocationList] = useState([])
  const [industryList, setIndustryList] = useState([])
  const [professionList, setProfessionList] = useState([])

  const fetchLocations = useCallback(async () => {
    await api.request(
      requests({ authed: false }).getLocationList,
      {},
      response => {
        const {
          data: { locations }
        } = response

        const mostPopularLocations = question1.options.map(
          ({ optionValue }) => optionValue
        )

        const lessPopularLocations = locations.filter(
          location => !mostPopularLocations.includes(location)
        )

        const tempLocationList = []

        lessPopularLocations.forEach((location, index) => {
          tempLocationList.push({
            id: 10 + (index + 1),
            optionText: location,
            optionValue: location
          })
        })

        setLocationList(tempLocationList)
      }
    )
  }, [api, requests, question1])

  const fetchIndustries = useCallback(async () => {
    await api.request(
      requests({ authed: false }).getEmployerIndustryList,
      {},
      response => {
        const {
          data: { industries }
        } = response

        const tempIndustryList = []

        industries.forEach((industry, index) => {
          tempIndustryList.push({
            id: index + 1,
            optionText: industry,
            optionValue: industry
          })
        })

        setIndustryList(tempIndustryList)
      }
    )
  }, [api, requests])

  const fetchProfessions = useCallback(async () => {
    await api.request(
      requests({ authed: false }).getProfessionList,
      {},
      response => {
        const {
          data: { profession_types }
        } = response

        const tempProfessionList = []

        profession_types.forEach((professionType, index) => {
          tempProfessionList.push({
            id: index + 1,
            optionText: professionType,
            optionValue: professionType
          })
        })

        setProfessionList(tempProfessionList)
      }
    )
  }, [api, requests])

  useEffect(() => {
    fetchLocations()
    fetchIndustries()
    fetchProfessions()
  }, [fetchLocations, fetchIndustries, fetchProfessions])

  return { locationList, industryList, professionList }
}

export default useEmployerPreferences
