import React from 'react'

const whiteStarIcon = (
  <svg width="32px" height="32px" viewBox="0 0 32 32" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-408.000000, -337.000000)">
        <g transform="translate(152.000000, 329.000000)">
          <g transform="translate(256.000000, 8.000000)">
            <rect
              opacity="0.2"
              x="0"
              y="0"
              width="32"
              height="32"
              rx="16"
            ></rect>
            <g
              transform="translate(10.000000, 10.000000)"
              stroke="#FFFFFF"
              strokeWidth="3.5"
            >
              <path
                d="M6.5,3.86062584 L7.3613014,5.55429424 L9.15940385,5.80939699 L7.86263894,
              7.03536061 L8.18211101,8.84335341 L6.5,7.98472171 L4.81788899,8.84335341 L5.13736106,
              7.03536061 L3.84059615,5.80939699 L5.6386986,5.55429424 L6.5,3.86062584 Z"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default whiteStarIcon
