import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { FlexContainer } from 'components/styled/Containers'
import useAppContext from 'services/context/appContext/useAppContext'
import { starGroup } from 'containers/ProfilePage/assets/barebonesAssets'

const FirstFeaturedProject = () => {
  const history = useHistory()
  const { state } = useAppContext()
  const { profileData, publicProfileData } = state
  const userSlug = profileData?.slug || publicProfileData.slug

  const jumpToMyProjects = () => {
    history.push(`/profile/${userSlug}/projects`)
  }

  return (
    <BareFeatureWrapper
      justify="center"
      align="center"
      padding="32px"
      direction="column"
      onClick={jumpToMyProjects}
    >
      <FlexContainer
        justify="center"
        align="center"
        margin="0 0 16px"
        className="icon"
      >
        {starGroup}
      </FlexContainer>
      {!publicProfileData ? (
        <div className="content">
          <p className="sub">
            Select your favorite <br /> projects and present here
          </p>
          <span className="overline">Select Here &#8250;</span>
        </div>
      ) : (
        <div className="content">
          <p className="sub">No projects have been featured yet</p>
        </div>
      )}
    </BareFeatureWrapper>
  )
}

export default FirstFeaturedProject

const BareFeatureWrapper = styled(FlexContainer)`
  border-radius: 4px;
  border: 1px dashed ${({ theme }) => theme.color.border.lite};
  height: auto;
  width: 100%;
  transition: all 300ms ease-out;
  .content {
    text-align: center;
    p {
      margin: 0 0 8px;
    }
    .overline {
      text-decoration: underline;
    }
  }
  cursor: pointer;
  &:hover {
    border-color: ${({ theme }) => theme.color.border.dark};
    background: rgba(0, 0, 0, 0.1);
  }
`
