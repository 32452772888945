import styled from 'styled-components'

const NavBar = styled.div`
  width: 100vw;
  height: 72px;
  padding: 15px 32px 17px;
  background: ${({ theme }) => theme.color.bg.dark};
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${({ theme }) => theme.order.projectPageNav};
  overflow: hidden;
  white-space: nowrap;
  margin: 0;
  .row {
    max-width: 1140px;
    margin: 0 auto;
  }
  .sub {
    color: #fff;
  }
  .links {
    margin: 0 16px 0 0;
  }
  .actions {
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    &.border:before {
      content: '';
      position: absolute;
      left: 0;
      height: 50%;
      top: 50%;
      transform: translate(0, -50%);
      border-left: 1px solid ${({ theme }) => theme.color.border.lite};
    }
    .link,
    button {
      margin: 0 0 0 16px;
    }
  }
  .link {
    margin: 0 8px;
    font-size: 14px;
    letter-spacing: 0.25px;
    line-height: 17px;
    font-weight: ${({ theme }) => theme.font.weight.regular};
    text-transform: capitalize;
    color: ${({ theme }) => theme.color.text.inactive};
    transition: all 300ms ease-in-out;
    position: relative;
    &:after {
      content: '';
      bottom: -26px;
      left: 0;
      position: absolute;
      width: 100%;
      border-bottom: 4px solid ${({ theme }) => theme.color.primary.yellow4};
      transform-origin: center;
      transform: scaleX(0);
      transition: all 300ms ease-out;
    }
    &:hover,
    &.active {
      color: ${({ theme }) => theme.color.text.white};
      &:after {
        transform: scaleX(1);
      }
    }
  }

  @media screen and (max-width: 992px) {
    padding: 8px 16px 6px;
    .sub {
      display: none;
    }
  }
`
export default NavBar
