import * as yup from 'yup'

export const basicInfoValidation = yup.object().shape({
  name: yup
    .string()
    .required('Required')
    .max(128, 'Name cannot be more than 128 characters'),
  location: yup
    .string()
    .required('Required')
    .max(64, 'Location cannot be more than 64 characters'),
  role: yup
    .string()
    .typeError('Must be a string')
    .max(64, 'Title cannot be more than 64 characters')
})

export const generalPreferencesValidation = yup.object().shape({
  job_search_status: yup.string().required('Required'),
  employment_type: yup.string().required('Required'),
  remote_work_status: yup.string().required('Required')
})

export const employerPreferencesValidation = yup.object().shape({
  locations: yup.array().required('Required: Select at least one option'),
  location_importance: yup.string().required('Required'),
  industries: yup.array().required('Required: Select at least one option'),
  industry_importance: yup.string().required('Required'),
  sizes: yup.array().required('Required: Select at least one option'),
  size_importance: yup.string().required('Required'),
  profession_types: yup
    .array()
    .required('Required: Select at least one option'),
  role_importance: yup.string().required('Required')
})
