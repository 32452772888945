import React, { Fragment } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import moment from 'moment'
import ExpandableContent from 'components/general/ContentContainers'
import Segment from 'components/styled/Segments'
import EducationExperience from 'containers/ProfilePage/components/EducationExperience'
import useProfileContext from 'services/context/profileContext/useProfileContext'
import useIsPublic from 'services/hooks/useIsPublic'
import { reorderChronologically } from 'services/helpers/formatters'

const Education = ({ educations }) => {
  const { profileDispatchActions } = useProfileContext()
  const { isPublic } = useIsPublic()

  const handleSetCreateForm = () => {
    profileDispatchActions.setCreateForm('educations')
  }

  if (isPublic && !educations.length) return null

  return (
    <Segment>
      <ExpandableContent
        header={'education'}
        addFormAction={handleSetCreateForm}
        actionType="add"
        hasContent={educations.length > 0}
      >
        {reorderChronologically(educations, 'educations').map(
          (education, index) => {
            const startDate = education.start_date
              ? moment(education.start_date).format('MMM YYYY')
              : ''
            const endDate =
              education.end_date && education.end_date !== 'Current'
                ? moment(education.end_date).format('MMM YYYY')
                : 'Present'

            return (
              <Fragment key={education.id}>
                <EducationExperience
                  education={education}
                  startDate={startDate}
                  endDate={endDate}
                />
                {educations.length - 1 !== index && <Divider />}
              </Fragment>
            )
          }
        )}
      </ExpandableContent>
    </Segment>
  )
}

export default Education

Education.propTypes = {
  educations: PropTypes.array
}

const Divider = styled.div`
  background: ${({ theme }) => theme.color.border.norm};
  height: 1px;
  margin-bottom: 32px;
`
