import React from 'react'

const editIcon = (
  <svg width="12px" height="12px" viewBox="0 0 12 12" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        transform="translate(-1241.000000, -162.000000)"
        fill="#000000"
        fillRule="nonzero"
      >
        <g transform="translate(1231.000000, 152.000000)">
          <g transform="translate(10.000000, 10.000000)">
            <polygon points="1.23167155 8.18181818 0 12 3.81818182 10.7683284"></polygon>
            <polygon
              transform="translate(6.242932, 5.763191) rotate(-45.000000) translate(-6.242932, -5.763191) "
              points="2.10300646 3.96321997 10.3828567 3.96321997 10.3828567 7.56316201 2.10300646 7.56316201"
            ></polygon>
            <path d="M11.8181818,1.87878788 L10.1212121,0.181818182 C9.87878788,-0.0606060606 9.51515152,-0.0606060606 9.27272727,0.181818182 L8.72727273,0.727272727 L11.2727273,3.27272727 L11.8181818,2.72727273 C12.0606061,2.48484848 12.0606061,2.12121212 11.8181818,1.87878788 Z"></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default editIcon
