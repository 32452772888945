import { useState, useEffect } from 'react'
import useAppContext from 'services/context/appContext/useAppContext'

const useAuth = () => {
  const [isAuthenticating, setIsAuthenticating] = useState(true)
  const { state, appDispatchActions } = useAppContext()
  const { isAuthenticated } = state

  useEffect(() => {
    const access = localStorage.getItem('access')
    const refresh = localStorage.getItem('refresh')

    if (access && refresh && !isAuthenticated)
      appDispatchActions.setAuthenticationStatus({ status: true })

    setIsAuthenticating(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { isAuthenticating, isAuthenticated }
}

export default useAuth
