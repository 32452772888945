import { useContext } from 'react'
import { ProfileContext } from 'services/context/profileContext/ProfileContext'
import profileDispatchActions from './profileDispatchActions'

const useProfileContext = () => {
  const { state, dispatch } = useContext(ProfileContext)

  return {
    profileState: state,
    profileDispatchActions: profileDispatchActions(dispatch)
  }
}

export default useProfileContext
