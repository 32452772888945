import * as yup from 'yup'

export const dataSettingsValidation = yup
  .object({
    geo_locations: yup.mixed()
  })
  .transform(function(current) {
    if (!current.hasOwnProperty('geo_locations')) {
      this.fields.geo_locations = yup.mixed().nullable()
    } else {
      this.fields.geo_locations = yup
        .array()
        .required('Job locations field is required')
    }
    return current
  })
