import React from 'react'
import styled from 'styled-components'
import { useDrag } from 'react-dnd'
import {
  FlexContainer,
  AbsoluteContainer,
  Container
} from 'components/styled/Containers'
import removeItem from 'components/forms/assets/removeItem'
import dragIcon from 'containers/ProfilePage/assets/dragIcon'

const DraggableItem = ({
  project,
  index,
  projectAssociationData,
  setProjectAssociationData
}) => {
  // Drag and drop logic
  const [collectedProps, dragRef] = useDrag({
    item: { id: index, type: 'project' }
  })

  // handle remove associated project
  const handleRemoveAssociatedProject = projectId => {
    const newProjects = projectAssociationData.filter(proj => {
      return proj.id !== projectId
    })

    setProjectAssociationData(newProjects)
  }

  return (
    <DragContainer
      collectProps={collectedProps}
      ref={dragRef}
      align="center"
      key={project.id}
    >
      <AbsoluteContainer className="drag-icon" left="-20px" top="-3px">
        {dragIcon}
      </AbsoluteContainer>
      <DropdownOption className="list">
        <FlexContainer className="pointer" align="center">
          <ProjectImg
            src="https://storage.googleapis.com/c1-assessments-proctoring-binaries/tenant-logo-placeholder.png"
            alt="project img"
          />
          <Container width="100%">{project.title}</Container>
          <Container
            onClick={() => handleRemoveAssociatedProject(project.id)}
            className="pointer"
            margin="0 16px 0"
            padding="2px 0 0"
          >
            {removeItem}
          </Container>
        </FlexContainer>
      </DropdownOption>
    </DragContainer>
  )
}

export default DraggableItem

const DragContainer = styled(Container)`
  .drag-icon {
    opacity: 0;
  }
  &:hover {
    .drag-icon {
      opacity: 1;
    }
  }
`

const ProjectImg = styled.img`
  width: 36px;
  margin-right: 12px;
  text-transform: none;
`

const DropdownOption = styled(Container)`
  padding: 12px 8px;
  border-radius: 8px;
  transition: 200ms ease-out;
  text-align: left;
  width: 100%;
  &.list {
    padding: 4px 8px;
  }
  &.icon {
    svg {
      opacity: 1;
    }
  }
  svg {
    opacity: 0;
  }
  &:hover {
    transition: 200ms ease-out;
    background: #e1e1e1;
    svg {
      opacity: 1;
    }
  }
`
