import settingsActionTypes from './settingsActionTypes'

const settingsDispatchActions = dispatch => {
  return {
    setFormExpanded: data => {
      dispatch({
        type: settingsActionTypes.FORM_EXPANDED,
        payload: data
      })
    },
    setUpdateForm: (data, dataType) => {
      dispatch({
        type: settingsActionTypes.SET_UPDATE_FORM,
        payload: {
          data,
          formType: dataType,
          formActionType: 'update'
        }
      })
    },
    formSubmitted: () => {
      dispatch({
        type: settingsActionTypes.FORM_SUBMITTED
      })
    },
    removeFormData: () => {
      dispatch({
        type: settingsActionTypes.REMOVE_FORM_DATA
      })
    }
  }
}

export default settingsDispatchActions
