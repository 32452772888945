import styled, { keyframes } from 'styled-components'
import { AbsoluteContainer } from 'components/styled/Containers'
import { overlay } from 'styles/mixins'

const rotatingLoader = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

const ProjectWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.color.border.input};
  border-radius: 8px;
  display: block;
  height: 0;
  padding: 0 0 57.5%;
  position: relative;
  width: 100%;
  &.requesting {
    opacity: 0.5;
    pointer-events: none;
    &:after {
      animation: ${rotatingLoader} 1.2s linear infinite;
      border: 6px solid #fff;
      border-color: #fff transparent #fff transparent;
      border-radius: 50%;
      content: ' ';
      display: block;
      height: 32px;
      margin: 8px;
      position: absolute;
      right: 5%;
      top: 5%;
      width: 32px;
    }
  }
  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  @media screen and (min-width: 992px) {
    &.hideActions {
      .project-header-actions button.active {
        visibility: hidden;
        opacity: 0;
        height: 0;
        transition: opacity 300ms ease-out;
      }
      &:hover {
        .project-header-actions button.active {
          visibility: visible;
          opacity: 1;
          height: auto;
        }
      }
    }
  }

  .project-header-actions {
    position: absolute;
    top: 8px;
    left: 8px;
    background: transparent;
    button {
      margin: 0 8px 0 0;
    }
    button:not(.active) {
      visibility: hidden;
      opacity: 0;
      height: 0;
      transition: opacity 300ms ease-out;
    }
  }

  @media screen and (max-width: 992px) {
    .project-header-actions {
      button:not(.active) {
        visibility: visible;
        opacity: 1;
        height: auto;
        transition: opacity 300ms ease-out;
      }
    }
  }

  @media screen and (max-width: 420px) {
    padding: 0 0 67.5%;
    .project-header-actions {
      padding: 0 8px 0 0;
      width: 100%;
      display: flex;
      justify-content: space-between;
      button {
        .action-list {
          left: unset;
          right: 0;
        }
      }
    }
  }

  @media screen and (min-width: 992px) {
    &:hover {
      .project-header-actions button:not(.active),
      .project_description,
      .project-skills {
        transition: opacity 300ms ease-out;
        visibility: visible;
        opacity: 1;
        height: auto;
      }
      .project_description,
      .project-skills {
        margin: 8px 0 0;
      }
    }
  }
`

const ProjectBanner = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: ${({ theme, file_path }) =>
    file_path ? `url(${file_path}) no-repeat` : theme.color.bg.liteGray};
  background-size: cover !important;
  margin: 0 auto;
  transition: all 300ms ease-out;
  cursor: pointer;
  ${overlay}
  ${({ file_path }) =>
    !file_path &&
    `
      &::before {
        content: '';
        background: url('https://storage.googleapis.com/connect-internal-assets/projects/defaultProjectImage.png')
          center center no-repeat;
        background-size: 45px 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: calc(100% - 49px);
        width: 100%;
      }
  `}
`

const ProjectDetails = styled(AbsoluteContainer)`
  width: 100%;
  background: ${({ theme }) => theme.color.bg.white};
  transition: height 300ms ease-out;
  padding: 16px;
  .project_description,
  .project-skills {
    width: 100%;
    padding: 0 16px 0 0;
    margin: 0;
    visibility: hidden;
    opacity: 0;
    height: 0;
    transition: opacity 300ms ease-out 150ms;
  }
  .project_description {
    p {
      color: ${({ theme }) => theme.color.primary.black2};
      overflow: hidden;
      display: flex;
      -webkit-line-clamp: 1;
      flex-direction: vertical;
    }
  }

  @media screen and (max-width: 768px) {
    padding: 8px;
  }
`

export { ProjectWrapper, ProjectBanner, ProjectDetails }
