import React from 'react'
import PublicProfile from './containers/PublicProfile'
import PrivateProfile from './containers/PrivateProfile'
import useScrollToTop from 'services/hooks/useScrollToTop'
import useIsPublic from 'services/hooks/useIsPublic'

const ProfilePage = ({ isProjects }) => {
  useScrollToTop()
  const { isAuthenticated, isPublic } = useIsPublic()

  if (!isAuthenticated || isPublic)
    return <PublicProfile isProjects={isProjects} />

  return <PrivateProfile isProjects={isProjects} />
}

export default ProfilePage
