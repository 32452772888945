export const questionnaire = {
  basicInfo: {
    question1: {
      testId: 'input-fullName',
      type: 'text',
      placeholder: 'Ex: John Doe',
      questionName: 'name',
      label: 'Full Name'
    },
    question2: {
      testId: 'input-location',
      type: 'text',
      placeholder: 'Ex: London, United Kingdom',
      questionName: 'location',
      label: 'Location'
    },
    question3: {
      testId: 'input-role',
      type: 'text',
      placeholder: 'Ex: AI researcher, ML Engineer',
      questionName: 'role',
      label: 'Title'
    }
  },
  generalPrefs: {
    question1: {
      question: 'I am...',
      groupName: 'job_search_status',
      vertical: true,
      options: [
        {
          id: 1,
          optionText: 'Actively looking for new job opportunities.',
          value: 'Active'
        },
        {
          id: 2,
          optionText:
            'Not actively looking, but am open to new job opportunities.',
          value: 'Open'
        },
        {
          id: 3,
          optionText: 'Not interested in new job opportunities at this time.',
          value: 'Not interested'
        }
      ]
    },
    question2: {
      question: 'What type of employment are you looking for?',
      groupName: 'employment_type',
      vertical: false,
      options: [
        {
          id: 1,
          optionText: 'Full-time',
          width: '130px',
          value: 'Full-time'
        },
        {
          id: 2,
          optionText: 'Internship',
          width: '140px',
          value: 'Internship'
        }
      ]
    },
    question3: {
      question: 'Are you interested in working remotely?',
      groupName: 'remote_work_status',
      vertical: false,
      options: [
        {
          id: 1,
          optionText: 'Yes',
          width: '90px',
          value: 'Yes'
        },
        {
          id: 2,
          optionText: 'Remote only',
          value: 'Remote only'
        },
        {
          id: 3,
          optionText: 'No',
          width: '85px',
          value: 'No'
        }
      ]
    }
  },
  employerPrefs: {
    question1: {
      questionName: 'locations',
      label: 'Please indicate your job location preferences:',
      subText: 'Choose as many as you want',
      options: [
        { id: 1, optionText: 'Austin', optionValue: 'Austin' },
        { id: 2, optionText: 'Boston', optionValue: 'Boston' },
        { id: 3, optionText: 'Chicago', optionValue: 'Chicago' },
        {
          id: 4,
          optionText: 'Los Angeles',
          optionValue: 'Los Angeles'
        },
        { id: 5, optionText: 'London', optionValue: 'London' },
        { id: 6, optionText: 'New York', optionValue: 'New York' },
        {
          id: 7,
          optionText: 'San Francisco Bay Area',
          optionValue: 'San Francisco Bay Area'
        },
        { id: 8, optionText: 'Seattle', optionValue: 'Seattle' },
        { id: 9, optionText: 'Toronto', optionValue: 'Toronto' },
        {
          id: 10,
          optionText: 'Washington D.C.',
          optionValue: 'Washington D.C.'
        }
      ],
      moreOptions: []
    },
    question1b: {
      question: 'How important is location to you?',
      groupName: 'location_importance',
      vertical: true,
      options: [
        {
          id: 1,
          optionText:
            'Critical (I will only work in the location(s) indicated)',
          value: 'Critical'
        },
        {
          id: 2,
          optionText: 'Moderately important',
          value: 'Moderate'
        },
        {
          id: 3,
          optionText: 'Not important (I will work in any location)',
          value: 'Not'
        }
      ]
    },
    question2: {
      questionName: 'industries',
      label: 'Please indicate your industry preferences:',
      subText: 'Choose as many as you want',
      vertical: true,
      options: []
    },
    question2b: {
      question: 'How important is industry to you?',
      groupName: 'industry_importance',
      vertical: true,
      options: [
        {
          id: 1,
          optionText: 'Critical (I will only work in the industries indicated)',
          value: 'Critical'
        },
        {
          id: 2,
          optionText: 'Moderately important',
          value: 'Moderate'
        },
        {
          id: 3,
          optionText: 'Not important (I will work in any industry)',
          value: 'Not'
        }
      ]
    },
    question3: {
      questionName: 'sizes',
      label: 'Please indicate your company size preferences:',
      subText: 'Choose as many as you want',
      vertical: true,
      options: [
        {
          id: 1,
          optionText: 'Small (0-100 employees)',
          optionValue: 'size_small'
        },
        {
          id: 2,
          optionText: 'Medium (100-1,000 employees)',
          optionValue: 'size_medium'
        },
        {
          id: 3,
          optionText: 'Large (1,000+ employees)',
          optionValue: 'size_large'
        }
      ]
    },
    question3b: {
      question: 'How important is company size to you?',
      groupName: 'size_importance',
      vertical: true,
      options: [
        {
          id: 1,
          optionText: 'Critical (I will only work at companies this size)',
          value: 'Critical'
        },
        {
          id: 2,
          optionText: 'Moderately important',
          value: 'Moderate'
        },
        {
          id: 3,
          optionText: 'Not important (I will work at any size company)',
          value: 'Not'
        }
      ]
    },
    question4: {
      questionName: 'profession_types',
      label: 'Please indicate the types of data roles that interest you:',
      options: [],
      vertical: true
    },
    question4b: {
      question: 'How important is the type of data role to you?',
      groupName: 'role_importance',
      vertical: true,
      options: [
        {
          id: 1,
          optionText: 'Critical (I will only work in this data role)',
          value: 'Critical'
        },
        {
          id: 2,
          optionText: 'Moderately important',
          value: 'Moderate'
        },
        {
          id: 3,
          optionText:
            'Not important (I will consider an alternative data role)',
          value: 'Not'
        }
      ]
    }
  }
}
