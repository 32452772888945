import React from 'react'
import styled from 'styled-components'

export const ChevronLeft = ({ width, height }) => (
  <ChevronStyled
    width={width || '32px'}
    height={height || '32px'}
    viewBox={width && height ? `0 0 ${width} ${height}` : '0 0 32 32'}
    version="1.1"
  >
    <g
      id="Engineer-files"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="homepage_logout" transform="translate(-285.000000, -2084.000000)">
        <g id="3" transform="translate(0.000000, 1451.000000)">
          <g
            id="Group-30-Copy"
            transform="translate(301.000000, 649.000000) rotate(-180.000000) translate(-301.000000, -649.000000) translate(285.000000, 633.000000)"
          >
            <circle
              id="Oval"
              className="circle circle-fill"
              cx="16"
              cy="16"
              r="15.5"
            ></circle>
            <polyline
              id="Rectangle"
              className="border"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              transform="translate(15.000000, 16.000000) rotate(-45.000000) translate(-15.000000, -16.000000) "
              points="17 14 17 18 13 18"
            ></polyline>
          </g>
        </g>
      </g>
    </g>
  </ChevronStyled>
)

export const ChevronRight = ({ width, height }) => (
  <ChevronStyled
    width={width || '32px'}
    height={height || '32px'}
    viewBox={width && height ? `0 0 ${width} ${height}` : '0 0 32 32'}
    version="1.1"
  >
    <g
      id="Engineer-files"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="homepage_logout" transform="translate(-1124.000000, -2084.000000)">
        <g id="3" transform="translate(0.000000, 1451.000000)">
          <g id="Group-30" transform="translate(1124.000000, 633.000000)">
            <circle
              id="Oval"
              className="circle circle-fill"
              cx="16"
              cy="16"
              r="15.5"
            ></circle>
            <polyline
              id="Rectangle"
              className="border"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              transform="translate(15.000000, 16.000000) rotate(-45.000000) translate(-15.000000, -16.000000) "
              points="17 14 17 18 13 18"
            ></polyline>
          </g>
        </g>
      </g>
    </g>
  </ChevronStyled>
)

export const ChevronDown = ({ width, height }) => (
  <ChevronStyled
    width={width || '32px'}
    height={height || '32px'}
    viewBox={width && height ? `0 0 ${width} ${height}` : '0 0 32 32'}
    version="1.1"
  >
    <g
      id="Engineer-files"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="homepage_logout" transform="translate(-704.000000, -818.000000)">
        <g
          id="Group-30"
          transform="translate(720.000000, 834.000000) rotate(-270.000000) translate(-720.000000, -834.000000) translate(704.000000, 818.000000)"
        >
          <circle
            id="Oval"
            className="circle"
            cx="16"
            cy="16"
            r="15.5"
          ></circle>
          <polyline
            id="Rectangle"
            className="border-inverse"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            transform="translate(15.000000, 16.000000) rotate(-45.000000) translate(-15.000000, -16.000000) "
            points="17 14 17 18 13 18"
          ></polyline>
        </g>
      </g>
    </g>
  </ChevronStyled>
)

const ChevronStyled = styled.svg`
  .circle {
    stroke: #87858c;
  }
  .border {
    stroke: #ffffff;
    transition: all 300ms ease-out;
    &-inverse {
      stroke: #373440;
    }
  }
  &:hover {
    .circle-fill {
      fill: #fff;
    }
    .border {
      stroke: #373440;
    }
  }
`
