import React from 'react'

const file = (
  <svg width="15px" height="19px" viewBox="0 0 15 19" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-441.000000, -456.000000)">
        <g transform="translate(440.000000, 152.000000)">
          <g transform="translate(0.000000, 238.000000)">
            <g transform="translate(0.000000, 18.000000)">
              <g transform="translate(1.000000, 46.000000)">
                <g transform="translate(0.000000, 1.000000)">
                  <g transform="translate(0.000000, 1.000000)">
                    <path
                      d="M13.8541543,4.75 L9.8958457,4.75 C9.6773457,4.75 9.5,4.571875 9.5,4.3541543 
                      L9.5,0.395845703 C9.5,0.177345703 9.3226543,0 9.1041543,0 L1.1875,0 C0.532779297,
                      0 0,0.532779297 0,1.1875 L0,17.8125 C0,18.4672207 0.532779297,19 1.1875,19 L13.0625,
                      19 C13.7172207,19 14.25,18.4672207 14.25,17.8125 L14.25,5.1458457 C14.25,4.9273457 
                      14.0726543,4.75 13.8541543,4.75 Z M13.4583457,17.8125 C13.4583457,18.0302207 13.281,
                      18.2083457 13.0625,18.2083457 L1.1875,18.2083457 C0.969,18.2083457 0.791654297,
                      18.0302207 0.791654297,17.8125 L0.791654297,1.1875 C0.791654297,0.969779297 
                      0.969,0.791654297 1.1875,0.791654297 L8.7083457,0.791654297 L8.7083457,4.3541543 
                      C8.7083457,5.008875 9.241125,5.5416543 9.8958457,5.5416543 L13.4583457,5.5416543 
                      L13.4583457,17.8125 L13.4583457,17.8125 Z"
                      fill="#373440"
                      fillRule="nonzero"
                    ></path>
                    <line
                      x1="3.5"
                      y1="8.5"
                      x2="10.5"
                      y2="8.5"
                      stroke="#373440"
                      strokeLinecap="round"
                    ></line>
                    <line
                      x1="3.5"
                      y1="10.5"
                      x2="10.5"
                      y2="10.5"
                      stroke="#373440"
                      strokeLinecap="round"
                    ></line>
                    <line
                      x1="3.5"
                      y1="12.5"
                      x2="8.5"
                      y2="12.5"
                      stroke="#373440"
                      strokeLinecap="round"
                    ></line>
                    <path
                      d="M14.133625,4.866375 L9.383625,0.116375 C9.22925,-0.038 8.9782793,-0.038 8.8239043,
                      0.116375 C8.6695293,0.27075 8.6695293,0.521720703 8.8239043,0.676095703 L13.5739043,
                      5.4260957 C13.6515,5.502875 13.7528086,5.54169141 13.8541543,5.54169141 C13.9555,
                      5.54169141 14.0568086,5.50291211 14.133625,5.4260957 C14.288,5.2717207 14.288,5.02075 
                      14.133625,4.866375 Z"
                      fill="#373440"
                      fillRule="nonzero"
                    ></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default file
