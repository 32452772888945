import React, { useState } from 'react'
import styled from 'styled-components'
import { Link, useHistory } from 'react-router-dom'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import Subtitle from 'components/styled/titles/Subtitle'
import Input from 'components/forms/Input'
import InputSubmit from 'components/forms/InputSubmit'
import { Container, FormContainer } from 'components/styled/Containers'
import {
  AuthFormTitle,
  ErrorMessage,
  AuthFormLinkText
} from 'components/styled/Text'
import FormDivider from 'containers/AuthPage/styled/FormDivider'
import { signupValidation } from 'services/validations/authValidation'
import useAppContext from 'services/context/appContext/useAppContext'
import useSearchParams from 'services/hooks/useSearchParams'

const Signup = ({ formErrorMessage, setFormErrorMessage }) => {
  const { api, requests, appDispatchActions } = useAppContext()
  const [checked, setChecked] = useState(false)
  const email = useSearchParams('email')
  const activationToken = useSearchParams('token')
  const source = useSearchParams('source')
  const history = useHistory()
  const methods = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(signupValidation),
    defaultValues: {
      privacy_consent: false
    },
    shouldFocusError: false
  })

  const handleRemoveErrors = () => {
    setFormErrorMessage(null)
  }

  const handleCheck = () => {
    setChecked(!checked)
  }

  // On submission failure
  const onFailure = () => {
    setFormErrorMessage(
      'It looks like there is a user already associated with this account. Please try again or log in with these credentials.'
    )
  }

  if (email == null || activationToken == null) {
    history.push('/not-found-page')
  }

  // On submission success
  const onSuccess = response => {
    appDispatchActions.resetAppState()
    handleRemoveErrors()
    api.setTokens(response)
    appDispatchActions.setAuthenticationStatus({ status: true })
  }

  // Submit login form
  const onSubmit = async data => {
    const payload = { ...data }
    delete payload.confirmPassword

    if (source) {
      payload.source = source
    }

    await api.request(
      requests(activationToken).activate,
      payload,
      async () => {
        const tokenPayload = { ...payload }
        delete tokenPayload.source
        tokenPayload.email = email

        await api.request(requests().token, tokenPayload, response =>
          onSuccess(response)
        )
      },
      () => onFailure()
    )
  }

  return (
    <FormProvider {...methods}>
      <FormContainer onSubmit={methods.handleSubmit(onSubmit)}>
        <AuthFormTitle>Activate an Account</AuthFormTitle>
        <Subtitle>{email}</Subtitle>
        <Input
          type="password"
          name="password"
          placeholder="Password"
          autoComplete="new-password"
        />
        <Input
          type="password"
          name="confirmPassword"
          placeholder="Confirm Password"
          autoComplete="confirm-password"
        />
        <PrivacyConsentWrapper>
          <CheckboxStyled
            name="privacy_consent"
            type="checkbox"
            onChange={handleCheck}
          />
          <CheckboxMock checked={checked}>
            <Check checked={checked} />
          </CheckboxMock>
          <PrivacyConsentText>
            I agree to Correlation One's{' '}
            <a href="https://www.correlation-one.com/terms" target="blank">
              Terms of Service
            </a>{' '}
            and{' '}
            <a href="https://www.correlation-one.com/privacy" target="blank">
              Privacy Policy
            </a>
          </PrivacyConsentText>
        </PrivacyConsentWrapper>
        <SubmitBtnContainer>
          <InputSubmit
            color="#373440"
            background="#FFFFFF"
            border="#373440"
            text="Sign Up"
          />
        </SubmitBtnContainer>
        {formErrorMessage && (
          <SignUpErrorMessage checked={checked}>
            {formErrorMessage}
          </SignUpErrorMessage>
        )}
        <ExtendFormDivider />
        <AuthFormLinkText>
          Have an account?{' '}
          <Link onClick={handleRemoveErrors} to="/login">
            Log In
          </Link>
        </AuthFormLinkText>
      </FormContainer>
    </FormProvider>
  )
}

export default Signup

const PrivacyConsentWrapper = styled.div`
  display: flex;
  align-items: center;
  p {
    margin-top: 20px;
    white-space: nowrap;
  }
`

const PrivacyConsentText = styled.span`
  color: ${({ theme }) => theme.color.primary.black4};
  font-size: 12px;
  font-weight: ${({ theme }) => theme.font.weight.medium};
  letter-spacing: 0.1px;
  line-height: 24px;
  a {
    font-size: 12px;
    font-weight: ${({ theme }) => theme.font.weight.medium};
    letter-spacing: 0.1px;
    line-height: 24px;
    text-transform: capitalize;
    text-decoration: none;
    color: ${({ theme }) => theme.color.primary.yellow5};
  }
`

const CheckboxStyled = styled(Input)`
  cursor: pointer;
  margin-top: 1px;
  padding: 0;
  pointer-events: all;
  position: absolute;
  width: 15px;
  height: 15px;
  &:checked {
    outline: 0;
    &:after {
      border-color: ${({ theme }) => theme.color.primary.black0};
      background: ${({ theme }) => theme.color.bg.white};
    }
  }
`

const CheckboxMock = styled(Container)`
  background: ${({ theme, checked }) =>
    checked ? theme.color.primary.yellow4 : theme.color.bg.white};
  border: 1px solid
    ${({ theme, checked }) =>
      checked ? theme.color.primary.yellow4 : theme.color.border.lite};
  border-radius: 2px;
  margin-right: 5px;
  min-height: 15px;
  min-width: 15px;
  pointer-events: none;
`

const Check = styled.div`
  border-bottom: 2px solid
    ${({ theme, checked }) =>
      checked ? theme.color.primary.black4 : theme.color.bg.white};
  border-right: 2px solid
    ${({ theme, checked }) =>
      checked ? theme.color.primary.black4 : theme.color.bg.white};
  cursor: pointer;
  height: 8px;
  left: 28%;
  position: absolute;
  top: 50%;
  transform: rotate(45deg) translate(-50%, -50%);
  width: 5px;
`

const SubmitBtnContainer = styled.div`
  margin: 28px 0;
`

const SignUpErrorMessage = styled(ErrorMessage)`
  padding: 0 0 28px;
`

const ExtendFormDivider = styled(FormDivider)`
  margin-top: 0;
`
