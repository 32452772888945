import React from 'react'
import styled from 'styled-components'
import { useDropzone } from 'react-dropzone'
import { Container } from 'components/styled/Containers'
import plusIcon from 'components/forms/assets/plusIcon'

const AddExtraImage = ({ onDrop }) => {
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    noDrag: true
  })

  return (
    <SmallUploadContainer {...getRootProps()} className="pointer">
      <AddSmallImageUploadWrappers className="pointer dashed">
        <input hidden={true} {...getInputProps()} />
        Add Image
        <AddImageIcon>{plusIcon}</AddImageIcon>
      </AddSmallImageUploadWrappers>
    </SmallUploadContainer>
  )
}

export default AddExtraImage

const SmallUploadContainer = styled(Container)`
  transition: 200ms ease-out;
  outline: none;
`

const SmallImageUploadWrappers = styled.div`
  position: relative;
  border-radius: 8px;
  background: #ffc043;
  outline: none;
  overflow: hidden;
  height: 70px;
  width: 80px;
  margin-right: 16px;
  margin-bottom: 16px;
  color: #373440;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding: 20px;
  padding-top: 28px;
  &:hover {
    background: #ffd175;
  }
`

const AddSmallImageUploadWrappers = styled(SmallImageUploadWrappers)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const AddImageIcon = styled.div`
  svg {
    height: 12px;
    width: 12px;
  }
`
