import React from 'react'

const resumeIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 24 24"
    fill=""
  >
    <path
      d="M14.568.075c2.202 1.174 5.938 4.883 7.432 
    6.881-1.286-.9-4.044-1.657-6.091-1.179.222-1.468-.185-4.534-1.341-5.702zm7.432 
    10.925v13h-20v-24h8.409c4.857 0 3.335 8 3.335 8 3.009-.745 8.256-.419 
    8.256 3zm-16 5h5v-4h-5v4zm12 
    2h-12v1h12v-1zm0-3h-5v1h5v-1zm0-3h-5v1h5v-1z"
    />
  </svg>
)

export default resumeIcon
