import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { FlexContainer, Divider, Container } from 'components/styled/Containers'
import { SocialButton } from 'components/styled/Buttons'
import linkedin from 'containers/AuthPage/assets/linkedin'
import google from 'containers/AuthPage/assets/google'
import github from 'containers/AuthPage/assets/github'

const SocialButtons = ({ formType }) => {
  // Handle O-auth login
  const handleLogin = () => {
    // TODO: remove console log and set up auth
    console.log('Loggin in with social...')
  }

  return (
    <FlexContainer direction="column">
      <SocialButton
        onClick={handleLogin}
        className="subtitle1"
        bgColor="#0177b5"
        marginBottom="12px"
      >
        <SocialIcon>{linkedin}</SocialIcon>
        <BtnText>
          {formType} <LowercaseSpan>with</LowercaseSpan> LinkedIn
        </BtnText>
      </SocialButton>
      <SocialButton
        onClick={handleLogin}
        className="subtitle1"
        bgColor="#000000"
        marginBottom="12px"
      >
        <SocialIcon>{github}</SocialIcon>
        <BtnText>
          {formType} <LowercaseSpan>with</LowercaseSpan> GitHub
        </BtnText>
      </SocialButton>
      <SocialButton
        onClick={handleLogin}
        className="subtitle1"
        bgColor="#ea4436"
      >
        <SocialIcon>{google}</SocialIcon>
        <BtnText>
          {formType} <LowercaseSpan>with</LowercaseSpan> Google
        </BtnText>
      </SocialButton>
      <FlexContainer align="center" width="100%" margin="20px 0 12px 0">
        <Divider />
        <OrSpan className="overline">Or</OrSpan>
        <Divider />
      </FlexContainer>
    </FlexContainer>
  )
}

// -- PROP DESCRIPTIONS -- //
// formType: string - determines what renders on the login and sign up page

SocialButtons.propTypes = {
  formType: PropTypes.oneOf(['login', 'signup'])
}

SocialButtons.defaultProps = {
  formType: 'signup'
}

export default SocialButtons

const OrSpan = styled.span`
  margin: 0 8px;
`

const LowercaseSpan = styled.span`
  text-transform: lowercase;
`

const SocialIcon = styled(Container)`
  margin-right: 16px;
`

const BtnText = styled.span`
  transform: translate(0px, -1px);
`
