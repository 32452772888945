import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import moment from 'moment'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import Modal from 'components/styled/Modal'
import InputSubmit from 'components/forms/InputSubmit'
import SkillForm from 'containers/ProfilePage/components/profileModal/SkillForm'
import AwardForm from 'containers/ProfilePage/components/profileModal/AwardForm'
import CertificateForm from 'containers/ProfilePage/components/profileModal/CertificateForm'
import PublicationForm from 'containers/ProfilePage/components/profileModal/PublicationForm'
import WorkExperienceForm from 'containers/ProfilePage/components/profileModal/WorkExperienceForm'
import EducationForm from 'containers/ProfilePage/components/profileModal/EducationForm'
import {
  Container,
  FormContainer,
  FlexContainer,
  AbsoluteContainer
} from 'components/styled/Containers'
import { ErrorMessage } from 'components/styled/Text'
import { Button } from 'components/styled/Buttons'
import Navbar from 'components/nav/Navbar'
import useAppContext from 'services/context/appContext/useAppContext'
import useProfileContext from 'services/context/profileContext/useProfileContext'
import {
  awardValidations,
  educationValidations,
  experienceValidations,
  certificateValidations,
  skillsValidations,
  publicationValidations
} from 'services/validations/resumeValidations'
import close from 'containers/ProfilePage/assets/close'
import deleteIcon from 'containers/ProfilePage/assets/deleteIcon'
import connectTheme from 'styles/themes'

const FormHelper = ({ btnText = 'submit' }) => {
  const { api, requests, appDispatchActions } = useAppContext()
  const { profileState, profileDispatchActions } = useProfileContext()
  const { data, formType, formActionType } = profileState.formData
  const [experienceData, setExperienceData] = useState(data)
  const [certificateData, setCertificateData] = useState(data)
  const [publicationData, setPublicationData] = useState(data)
  const [educationData, setEducationData] = useState(data)
  const validations = {
    work_experiences: experienceValidations,
    certificates: certificateValidations,
    publications: publicationValidations,
    awards: awardValidations,
    educations: educationValidations,
    skills: skillsValidations
  }
  const formProps = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(validations[formType]),
    shouldFocusError: false
  })
  const [projectAssociationData, setProjectAssociationData] = useState([])

  // set projects
  const handlePreFormatProjects = projects => {
    const orderedProjects = projects.sort((a, b) => {
      return a.order - b.order
    })
    return orderedProjects.map(project => project.project)
  }

  // set skills
  const handlePreFormatSkills = data => {
    formProps.reset({ skills: [...data, ''] })
  }

  // set experiences
  const handlePreFormatExperiences = data => {
    const dataCopy = { ...data }

    // parse & set start dates
    const startDate = moment(dataCopy.start_date)
    const startMonth = startDate.format('MMM')
    const startYear = startDate.format('YYYY')
    dataCopy.start_month = startMonth
    dataCopy.start_year = startYear
    delete dataCopy.start_date

    // parse & set end dates
    if (!dataCopy.end_date) {
      dataCopy.current = 'current'
    } else {
      const endDate = moment(dataCopy.end_date)
      const endMonth = endDate.format('MMM')
      const endYear = endDate.format('YYYY')
      dataCopy.end_month = endMonth
      dataCopy.end_year = endYear
    }

    delete dataCopy.end_date

    setExperienceData(dataCopy)
    formProps.reset(dataCopy)
  }

  // set certificates
  const handlePreFormatCertificates = data => {
    const dataCopy = { ...data }

    // parse & set start dates
    const startDate = moment(dataCopy.issue_date)
    const startMonth = startDate.format('MMM')
    const startYear = startDate.format('YYYY')
    dataCopy.start_month = startMonth
    dataCopy.start_year = startYear
    delete dataCopy.issue_date

    // parse & set end dates
    if (dataCopy.expiration_date === null) {
      dataCopy.is_expiration = true
    } else {
      const endDate = moment(dataCopy.expiration_date)
      const endMonth = endDate.format('MMM')
      const endYear = endDate.format('YYYY')
      dataCopy.end_month = endMonth
      dataCopy.end_year = endYear
    }

    delete dataCopy.expiration_date

    setCertificateData(dataCopy)
    formProps.reset(dataCopy)
  }

  const handlePreFormatPublications = data => {
    const publicationDate = moment(new Date(data.year, data.month - 1))
    const { year, month, ...dataCopy } = {
      ...data,
      authors: data.authors.join(', '),
      publication_month: publicationDate.format('MMM'),
      publication_year: publicationDate.format('YYYY')
    }
    setPublicationData(dataCopy)
    formProps.reset(dataCopy)
  }

  // set experiences
  const handlePreFormatEducations = data => {
    const dataCopy = { ...data }

    // parse & set start dates
    const startDate = dataCopy.start_date && moment(dataCopy.start_date)
    const startMonth = startDate && startDate.format('MMM')
    const startYear = startDate && startDate.format('YYYY')
    dataCopy.start_month = startMonth
    dataCopy.start_year = startYear
    delete dataCopy.start_date

    // parse & set end dates
    const endDate = dataCopy.end_date && moment(dataCopy.end_date)
    const endMonth = endDate && endDate.format('MMM')
    const endYear = endDate && endDate.format('YYYY')
    dataCopy.end_month = endMonth
    dataCopy.end_year = endYear
    delete dataCopy.end_date

    setEducationData(dataCopy)
    formProps.reset(dataCopy)
  }

  useEffect(() => {
    if (formActionType === 'update') {
      switch (formType) {
        case 'skills':
        case 'awards':
        case 'certificates':
        case 'publications':
        case 'work_experiences':
          if (data.work_experience_projects) {
            setProjectAssociationData(
              handlePreFormatProjects(data.work_experience_projects)
            )
          } else {
            setProjectAssociationData([])
          }
          break
        case 'educations':
          if (data.education_projects) {
            setProjectAssociationData(
              handlePreFormatProjects(data.education_projects)
            )
          } else {
            setProjectAssociationData([])
          }
          break
        default:
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const closeModal = () => {
    profileDispatchActions.removeFormData()
  }

  const deleteModal = (id, type) => {
    profileDispatchActions.setDeleteModal(id, type)
    appDispatchActions.updatedProfile()
  }

  // Reset form each time state updates
  useEffect(() => {
    const dataCopy = { ...data }
    delete dataCopy.id

    if (formType === 'skills') {
      handlePreFormatSkills(data)
    } else if (formType === 'certificates' && formActionType === 'update') {
      handlePreFormatCertificates(dataCopy)
    } else if (formType === 'publications' && formActionType === 'update') {
      handlePreFormatPublications(dataCopy)
    } else if (formType === 'work_experiences' && formActionType === 'update') {
      handlePreFormatExperiences(dataCopy)
    } else if (formType === 'educations' && formActionType === 'update') {
      handlePreFormatEducations(dataCopy)
    } else {
      formProps.reset(dataCopy)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileState])

  // Configure form options
  const isUpdate = formActionType === 'update'
  const ctaActionWord = isUpdate ? 'Update' : 'Add'
  const requestsConfig = isUpdate ? () => requests(data.id) : () => requests()
  const requestProperty = isUpdate ? 'update' : 'create'

  // Form options
  // Project Form is styled as a full screen Modal.
  // All other Forms are normal Modals.
  const forms = {
    skills: {
      config: requestsConfig()['patchSkills'],
      component: <SkillForm formProps={formProps} data={data} />,
      cta: `Skills`
    },
    certificates: {
      config: requestsConfig()[`${requestProperty}Certificate`],
      component: (
        <CertificateForm formProps={formProps} data={certificateData} />
      ),
      cta: `${ctaActionWord} Certificate`
    },
    publications: {
      config: requestsConfig()[`${requestProperty}Publication`],
      component: (
        <PublicationForm formProps={formProps} data={publicationData} />
      ),
      cta: `${ctaActionWord} Publication`
    },
    awards: {
      config: requestsConfig()[`${requestProperty}Award`],
      component: <AwardForm formProps={formProps} />,
      cta: `${ctaActionWord} Award`
    },
    work_experiences: {
      config: requestsConfig()[`${requestProperty}WorkExperience`],
      component: (
        <WorkExperienceForm
          formProps={formProps}
          setProjectAssociationData={setProjectAssociationData}
          projectAssociationData={projectAssociationData}
          data={experienceData}
        />
      ),
      cta: `${ctaActionWord} Experience`
    },
    educations: {
      config: requestsConfig()[`${requestProperty}Education`],
      component: (
        <EducationForm
          formProps={formProps}
          setProjectAssociationData={setProjectAssociationData}
          projectAssociationData={projectAssociationData}
          data={educationData}
          formActionType={formActionType}
        />
      ),
      cta: `${ctaActionWord} Education`
    }
  }

  // Set Form
  const form = forms[formType]

  // Expierences formatter
  const formatWorkExperience = payload => {
    const projects = projectAssociationData.map((data, index) => {
      return { project_id: data.id, order: index }
    })
    payload.work_experience_projects = projects

    payload.start_date = moment(
      new Date(`${payload.start_month} 1, ${payload.start_year}`)
    ).format('YYYY-MM-DD')
    delete payload.start_month
    delete payload.start_year

    if (payload.current) {
      payload.end_date = null
    } else {
      payload.end_date = moment(
        new Date(`${payload.end_month} 1, ${payload.end_year}`)
      ).format('YYYY-MM-DD')
    }

    delete payload.end_month
    delete payload.end_year

    if (payload.current) {
      payload.end_date = null
      delete payload.current
    }

    return payload
  }

  // Certificates formatter
  const formatCertificate = payload => {
    payload.issue_date = moment(
      new Date(`${payload.start_month} 1, ${payload.start_year}`)
    ).format('YYYY-MM-DD')
    delete payload.start_month
    delete payload.start_year

    if (payload.is_expiration) {
      payload.expiration_date = null
    } else {
      payload.expiration_date = moment(
        new Date(`${payload.end_month} 1, ${payload.end_year}`)
      ).format('YYYY-MM-DD')
    }

    delete payload.end_month
    delete payload.end_year

    if (payload.current) {
      payload.end_date = null
      delete payload.current
    }

    return payload
  }

  // Skills formatter
  const formatSkills = data => {
    const filteredSkills = data.skills.filter(skill => Boolean(skill.value))

    const filtered = filteredSkills.map((skill, index) => {
      return {
        name: skill.value,
        order: index,
        is_featured: skill.is_featured
      }
    })

    return { skills: filtered }
  }

  // Expierences formatter
  const formatEducation = payload => {
    const projects = projectAssociationData.map((data, index) => {
      return { project_id: data.id, order: index }
    })
    payload.education_projects = projects

    if (payload.start_month && payload.start_year) {
      payload.start_date = moment(
        new Date(`${payload.start_month} 1, ${payload.start_year}`)
      ).format('YYYY-MM-DD')
    } else {
      payload.start_date = null
    }

    delete payload.start_month
    delete payload.start_year

    if (payload.end_month && payload.end_year) {
      payload.end_date = moment(
        new Date(`${payload.end_month} 1, ${payload.end_year}`)
      ).format('YYYY-MM-DD')
    } else {
      payload.end_date = null
    }

    delete payload.end_month
    delete payload.end_year

    if (!payload.gpa) {
      payload.gpa = null
      payload.gpa_out_of = null
    }

    return payload
  }

  const formatPublication = payload => {
    payload.authors = payload.authors.length
      ? payload.authors.split(',').map(author => author.trim())
      : []
    const publicationDate = moment(
      new Date(`${payload.publication_month} 01, ${payload.publication_year}`)
    )
    payload.month = publicationDate.format('M')
    payload.year = publicationDate.format('YYYY')
    delete payload.publication_month
    delete payload.publication_year
    return payload
  }

  // Addition data handler
  const handleAdditionalFormatting = (payload, formType) => {
    const payloadCopy = { ...payload }

    switch (formType) {
      case 'work_experiences':
        return formatWorkExperience(payloadCopy)
      case 'certificates':
        return formatCertificate(payloadCopy)
      case 'publications':
        return formatPublication(payloadCopy)
      case 'educations':
        return formatEducation(payloadCopy)
      case 'skills':
        return formatSkills(payloadCopy)
      default:
        return payload
    }
  }

  // Generic submission handler
  const onSubmit = async data => {
    api.setState().requesting()
    let payload = handleAdditionalFormatting(data, formType)
    await api.request(form.config, payload, response => {
      api.setState().requested()
      profileDispatchActions.formSubmitted()
      profileDispatchActions.removeFormData()
      appDispatchActions.updatedProfile()
    })
  }

  const dateErrors =
    formProps.errors.startDate ||
    formProps.errors.endDate ||
    formProps.errors.inputInvalid

  return (
    <Modal
      width="100%"
      height="100%"
      align="flex-start"
      justify="center"
      className={`form-${formType}`}
    >
      <DndProvider backend={HTML5Backend}>
        <ModalPopupContainer background="#fff" padding="32px">
          <TopNavContainer>
            <Navbar indexing={connectTheme.order.modalMobileNav} />
          </TopNavContainer>
          <ModalContentContainer>
            <FlexContainer justify="space-between" margin="0 0 28px">
              <h6>{form.cta}</h6>
              <AbsoluteContainer top="-8px" right="0">
                <CloseSVG
                  className="pointer"
                  justify="center"
                  align="center"
                  onClick={() => closeModal()}
                >
                  {close}
                </CloseSVG>
              </AbsoluteContainer>
            </FlexContainer>
            <FormProvider {...formProps}>
              <FormContainer onSubmit={formProps.handleSubmit(onSubmit)}>
                {form.component}
                {dateErrors && (
                  <>
                    <ErrorMessage>
                      Please correct before submitting:
                    </ErrorMessage>
                    {formProps.errors.inputInvalid && (
                      <ErrorMessage>
                        - {formProps.errors.inputInvalid.message}
                      </ErrorMessage>
                    )}
                    {formProps.errors.startDate && (
                      <ErrorMessage>
                        - {formProps.errors.startDate.message}
                      </ErrorMessage>
                    )}
                    {formProps.errors.endDate && (
                      <ErrorMessage>
                        - {formProps.errors.endDate.message}
                      </ErrorMessage>
                    )}
                  </>
                )}
                <FlexContainer justify="space-between" margin="40px 0 0">
                  <FlexContainer>
                    <SubmitClickContainer
                      saving={api.getState() === 'requesting'}
                      width="fit-content"
                    >
                      <InputSubmit
                        testId="form-helper-input"
                        disabled={api.getState() === 'requesting'}
                        text={
                          api.getState() === 'requesting' ? 'Saving...' : 'Save'
                        }
                        formState={formProps.formState}
                      />
                    </SubmitClickContainer>
                    <Container margin="0 12px 0" width="fit-content">
                      <CloseButton
                        type="button"
                        onClick={() => closeModal()}
                        bgColor="#ffffff"
                        textColor="#5f5d66"
                      >
                        Cancel
                      </CloseButton>
                    </Container>
                  </FlexContainer>
                  {formActionType === 'update' && formType !== 'skills' && (
                    <Container margin="0 12px 0" width="fit-content">
                      <DeleteButton
                        onClick={() => deleteModal(data.id, formType)}
                        bgColor="#black"
                        textColor="#5f5d66"
                      >
                        <Container margin="0 0 0 -10px" padding="4px 0 0">
                          {deleteIcon}
                        </Container>
                        Delete
                      </DeleteButton>
                    </Container>
                  )}
                </FlexContainer>
              </FormContainer>
            </FormProvider>
          </ModalContentContainer>
        </ModalPopupContainer>
      </DndProvider>
    </Modal>
  )
}

export default FormHelper

const SubmitClickContainer = styled(Container)`
  pointer-events: ${({ saving }) => saving && 'none'};
  cursor: ${({ saving }) => saving && 'not-allowed'};
`

const ModalPopupContainer = styled(Container)`
  border-radius: 8px;
  min-width: 500px;
  input[type='submit']:hover {
    background: #ffd175;
    border: 1px solid #ffd175;
    color: ${({ theme, color }) => color || theme.color.primary.black4};
  }
  @media (max-width: 768px) {
    min-width: 100%;
    border-radius: 0;
    padding: 72px 0 0;
  }
`
const TopNavContainer = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
`

const ModalContentContainer = styled.div`
  @media (max-width: 768px) {
    padding: 32px 20px;
  }
`

const CloseSVG = styled(FlexContainer)`
  width: 30px;
  height: 30px;
  border-radius: 100%;
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
`

const CloseButton = styled(Button)`
  height: 36px;
  width: 105px;
  &:hover {
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(0, 0, 0, 0.1);
    color: currentColor;
    transition: all 300ms ease-out;
  }
`

const DeleteButton = styled(Button)`
  height: 36px;
  width: 120px;
  display: flex;
  justify-content: center;
  border: 1px solid #5f5d66;
  border-radius: 4px;
  background: #ffffff;
  &:hover {
    border-radius: 4px;
    border: 1px solid ${({ theme }) => theme.color.border.error};
    transition: all 300ms ease-out;
    color: ${({ theme }) => theme.color.border.error};
    path {
      transition: all 300ms ease-out;
      fill: ${({ theme }) => theme.color.border.error};
    }
  }
`
