import profileActionTypes from './profileActionTypes'

const profileDispatchActions = dispatch => {
  return {
    setCreateForm: dataType => {
      dispatch({
        type: profileActionTypes.SET_CREATE_FORM,
        payload: {
          data: null,
          formType: dataType,
          formActionType: 'create'
        }
      })
    },
    setUpdateForm: (data, dataType) => {
      dispatch({
        type: profileActionTypes.SET_UPDATE_FORM,
        payload: {
          data,
          formType: dataType,
          formActionType: 'update'
        }
      })
    },
    formSubmitted: () => {
      dispatch({
        type: profileActionTypes.FORM_SUBMITTED
      })
    },
    removeFormData: () => {
      dispatch({
        type: profileActionTypes.REMOVE_FORM_DATA
      })
    },
    setDeleteModal: (deleteModalId, deleteModalType) => {
      dispatch({
        type: profileActionTypes.SET_DELETE_MODAL,
        payload: {
          deleteModalId,
          deleteModalType
        }
      })
    },
    setProfileTab: tab => {
      dispatch({
        type: profileActionTypes.PROFILE_TAB_STATUS,
        payload: {
          tab
        }
      })
    },
    removeDeleteModal: () => {
      dispatch({
        type: profileActionTypes.REMOVE_DELETE_MODAL_DATA
      })
    },
    setCreateProjectInForm: formData => {
      dispatch({
        type: profileActionTypes.CREATING_PROJECT_IN_FORM,
        payload: {
          formData
        }
      })
    }
  }
}

export default profileDispatchActions
