import React, { createContext, useReducer } from 'react'
import appActionTypes from './actionTypes'

// Set object with initial state
// TODO: Remove state items that no longer belong
const initialAppState = {
  isAuthenticated: false,
  onboarding: false,
  profileData: null,
  publicProfileData: null,
  generalPreferencesData: null,
  jobBoardSettingsData: null,
  employerPreferencesData: null,
  updatedProfile: 0,
  zoomIn: false,
  zoomImgSrc: null
}

// Create context
export const AppContext = createContext(initialAppState)

// Set Reducer
const appReducer = (state, action) => {
  const { type, payload } = action

  const {
    SET_AUTHENTICATION,
    SET_ONBOARDING,
    SET_PROFILE_DATA,
    SET_PUBLIC_PROFILE_DATA,
    SET_JOB_BOARD_SETTINGS_DATA,
    SET_GENERAL_PREFERENCES_DATA,
    SET_EMPLOYER_PREFERENCES_DATA,
    UPDATED_PROFILE,
    SET_PROFILE_STATUS,
    ZOOM_IN,
    ZOOM_OUT,
    RESET_STATE
  } = appActionTypes

  switch (type) {
    case SET_AUTHENTICATION:
      return { ...state, isAuthenticated: payload.status }
    case SET_ONBOARDING:
      return { ...state, onboarding: payload.status }
    case RESET_STATE:
      return { ...state, ...initialAppState }
    case SET_PROFILE_DATA:
      return { ...state, profileData: payload.profile }
    case SET_PUBLIC_PROFILE_DATA:
      return { ...state, publicProfileData: payload.profile }
    case SET_GENERAL_PREFERENCES_DATA:
      return { ...state, generalPreferencesData: payload.generalPreferences }
    case SET_JOB_BOARD_SETTINGS_DATA:
      return { ...state, jobBoardSettingsData: payload.jobBoardSettings }
    case SET_EMPLOYER_PREFERENCES_DATA:
      return { ...state, employerPreferencesData: payload.employerPreferences }
    case UPDATED_PROFILE:
      return { ...state, updatedProfile: state.updatedProfile + 1 }
    case SET_PROFILE_STATUS:
      return { ...state, isProfilePrivate: payload.status }
    case ZOOM_IN:
      return { ...state, zoomIn: true, zoomImgSrc: payload.src }
    case ZOOM_OUT:
      return { ...state, zoomIn: false, zoomImgSrc: '' }
    default:
      return state
  }
}

// Create app provider
export const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(appReducer, initialAppState)

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  )
}
