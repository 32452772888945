import axios from 'axios'
import jwt from 'jsonwebtoken'
import { requests } from 'services/requests/requests'

export const apiConfig = (requestActions, requestState, location) => {
  const requestLogic = {
    setRequestUrl: function(config) {
      if (config.externalEndpoint) {
        return config.externalEndpoint
      } else {
        return process.env.REACT_APP_API_URL + config.endpoint
      }
    },
    setAuth: async function(config) {
      if (config.auth) {
        await this.refreshTokens()
        return {
          Authorization: `Bearer ${localStorage.getItem('access')}`
        }
      } else {
        return {}
      }
    },
    request: async function(
      config,
      payload = undefined,
      onSuccess = null,
      onError = null
    ) {
      const url = this.setRequestUrl(config)
      const headers = await this.setAuth(config)
      const method = config.method

      try {
        let response
        if (method === 'get' || method === 'delete') {
          response = await axios[method](url, { headers })
        } else {
          response = await axios[method](url, payload, { headers })
        }
        return onSuccess && onSuccess(response)
      } catch (error) {
        return onError && onError(error)
      }
    },
    getState: function() {
      return requestState
    },
    setState: function() {
      return requestActions
    },
    setTokens: function(token) {
      localStorage.setItem('access', token.data.access)
      localStorage.setItem('refresh', token.data.refresh)
    },
    removeTokens: function() {
      localStorage.removeItem('access')
      localStorage.removeItem('refresh')
    },
    refreshTokens: async function() {
      const token = jwt.decode(localStorage.getItem('access'))
      const currentTime = new Date().getTime() / 1000

      if (token && currentTime > token.exp) {
        const payload = { refresh: localStorage.getItem('refresh') }
        await this.request(requests().refreshTokens, payload, response => {
          localStorage.setItem('refresh', response.data.refresh)
          localStorage.setItem('access', response.data.access)
        })
      }

      return null
    }
  }

  return requestLogic
}
