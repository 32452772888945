import React, { useRef } from 'react'
import styled from 'styled-components'
import Modal from 'components/styled/Modal'
import {
  FlexContainer,
  Container,
  AbsoluteContainer
} from 'components/styled/Containers'
import { Button } from 'components/styled/Buttons'
import useAppContext from 'services/context/appContext/useAppContext'
import useProfileContext from 'services/context/profileContext/useProfileContext'
import useClickedOutsideEvent from 'services/hooks/useClickedOutsideEvent'
import close from 'containers/ProfilePage/assets/close'
import deleteIcon from 'containers/ProfilePage/assets/deleteIcon'

const DeleteModal = () => {
  const { state, api, requests, appDispatchActions, history } = useAppContext()
  const { profileState, profileDispatchActions } = useProfileContext()
  const { deleteModalType, deleteModalId } = profileState

  // Text options
  const textOptions = {
    educations: 'education',
    certificates: 'certificate',
    publications: 'publication',
    work_experiences: 'work experience',
    awards: 'award',
    project: 'project'
  }

  // Close modal
  const handleCloseModal = () => {
    profileDispatchActions.removeDeleteModal()
  }

  // Delete Item
  const handleDeleteItem = async () => {
    let delAction
    switch (deleteModalType) {
      case 'awards':
        delAction = 'deleteAward'
        break
      case 'certificates':
        delAction = 'deleteCertificate'
        break
      case 'publications':
        delAction = 'deletePublication'
        break
      case 'work_experiences':
        delAction = 'deleteWorkExperience'
        break
      case 'educations':
        delAction = 'deleteEducation'
        break
      case 'project':
        delAction = 'deleteProject'
        break
      case 'profile':
        delAction = 'deleteProject'
        break
      default:
        break
    }

    await api.request(requests(deleteModalId)[delAction], {}, response => {
      profileDispatchActions.removeDeleteModal()
      if (deleteModalType === 'project') {
        profileDispatchActions.setProfileTab('projects')
        history.push(`/profile/${state.profileData.slug}/projects`)
      } else {
        profileDispatchActions.setProfileTab('resume')
        history.push(`/profile/${state.profileData.slug}`)
      }
      appDispatchActions.updatedProfile()
    })
  }

  // Handle clicks outside modal
  const modalRef = useRef(null)
  useClickedOutsideEvent(modalRef, () => handleCloseModal())

  return (
    <Modal width="100%" height="100%" align="center" justify="center">
      <ModalPopupContainer ref={modalRef} background="#fff" padding="32px">
        <AbsoluteContainer top="24px" right="24px">
          <CloseSVG
            className="pointer"
            justify="center"
            align="center"
            onClick={handleCloseModal}
          >
            {close}
          </CloseSVG>
        </AbsoluteContainer>
        <h6>Delete {textOptions[deleteModalType]}</h6>
        <Container margin="8px 0 32px 0">
          <p>
            Are you sure you want to delete this {textOptions[deleteModalType]}?
          </p>
        </Container>
        <FlexContainer>
          <DeleteButton onClick={handleDeleteItem}>
            <Container margin="0 0 0 -10px" padding="4px 0 0">
              {deleteIcon}
            </Container>
            Delete
          </DeleteButton>
          <CancelButton onClick={handleCloseModal}>Cancel</CancelButton>
        </FlexContainer>
      </ModalPopupContainer>
    </Modal>
  )
}

export default DeleteModal

const CloseSVG = styled(FlexContainer)`
  width: 30px;
  height: 30px;
  border-radius: 100%;
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
`

const ModalPopupContainer = styled(Container)`
  border-radius: 8px;
  min-width: 500px;
  text-align: left;
  @media (max-width: 768px) {
    min-width: 0;
    width: 100%;
  }
`

const DeleteButton = styled(Button)`
  background: #d54e4c;
  border: 1px solid #d54e4c;
  height: 36px;
  color: #ffffff;
  border-radius: 4px;
  width: 120px;
  margin-right: 12px;
  &:hover {
    background: #da6462;
    border: 1px solid #da6462;
    color: #ffffff;
  }
  path {
    fill: #ffffff;
  }
`

const CancelButton = styled(Button)`
  background: transparent;
  border: 1px solid transparent;
  color: #5f5d66;
  height: 36px;
  border-radius: 4px;
  &:hover {
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(0, 0, 0, 0.1);
    color: currentColor;
    transition: all 300ms ease-out;
  }
`
