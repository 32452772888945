import styled from 'styled-components'
import { FlexContainer } from 'components/styled/Containers'

const Modal = styled(FlexContainer)`
  width: 100vw;
  height: 100%;
  min-height: calc(100vh - 72px);
  overflow: auto;
  overflow-x: hidden;
  text-align: center;
  z-index: ${({ theme }) => theme.order.modal};
  position: fixed;
  padding: 64px 64px 128px;
  top: 0;
  left: 0;
  background: ${({ theme }) => theme.color.bg.modal};
  &.form-projects {
    background: ${({ theme }) => theme.color.bg.norm};
    .form-container {
      width: 100%;
      height: 100%;
      padding: 137px 0;
      overflow: auto;
      overflow-x: hidden;
    }
  }
  .form-container {
    width: 400px;
    position: relative;
    margin: 100px 0px;
  }
  .project-container {
    width: 60vw;
    max-width: 943px;
    position: relative;
  }
  .close-btn {
    position: absolute;
    right: -64px;
    top: -48px;
    z-index: ${({ theme }) => theme.order.modal};
    svg {
      width: 20px;
      height: 20px;
      transition: all 300ms ease-out;
      .icon {
        transition: stroke 300ms ease-out;
        stroke: ${({ theme }) => theme.color.primary.black4};
      }
    }
    &:hover {
      svg {
        transform: rotate(90deg);
        .icon {
          stroke: ${({ theme }) => theme.color.primary.black3};
        }
      }
    }
  }
  @media screen and (max-height: 865px) {
    padding: 140px 64px;
  }
  @media (max-width: 768px) {
    overflow: scroll;
    padding: 0;
    background: #ffffff;
    opacity: 1;
    .project-container {
      width: 100%;
    }

    .close-btn {
      top: 88px;
      left: unset;
      right: 16px;
    }
  }
  @media screen and (max-height: 680px) and (min-width: 667px) {
    .close-btn {
      top: 50%;
      left: -64px;
      transform: translate(0, -50%);
    }
  }
`
export default Modal
