import React from 'react'
import styled from 'styled-components'
import { ContentWrapper } from 'components/styled/Wrappers'
import { FlexContainer, Container } from 'components/styled/Containers'
import TitleH4 from 'components/styled/titles/TitleH4'
import Subtitle from 'components/styled/titles/Subtitle'
import { ChevronDown } from 'containers/HomePage/assets/chevrons'
import { heroCoffee, heroDesktopBg } from 'containers/HomePage/assets/graphics'

const HomeHero = ({ slidesRef }) => {
  const jumpToCarousel = () => {
    slidesRef.current.scrollIntoView({ block: 'start', behavior: 'smooth' })
  }

  return (
    <HomeHeroWrapper>
      <ContentContainer>
        <HeroArtwork>
          <HeroBackground>{heroDesktopBg}</HeroBackground>
          <HeroPerson>{heroCoffee}</HeroPerson>
        </HeroArtwork>
        <MobileContainer>
          <ContentWrapper>
            <ExtendTitleH4>
              We make it easy for data professionals to showcase their work and
              find jobs.
            </ExtendTitleH4>
            <Subtitle>
              C1 Connect is a platform for building your data portfolio.
            </Subtitle>
            <Scroller onClick={e => jumpToCarousel()}>
              <ChevronDown />
            </Scroller>
          </ContentWrapper>
        </MobileContainer>
      </ContentContainer>
    </HomeHeroWrapper>
  )
}

export default HomeHero

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
`

const MobileContainer = styled(Container)`
  @media (max-width: ${({ theme }) => theme.media.mobile}) {
    padding: 0 20px;
  }
`

const ExtendTitleH4 = styled(TitleH4)`
  @media (max-width: ${({ theme }) => theme.media.mobileSm}) {
    font-size: 40px;
    line-height: 48px;
  }
`

const Scroller = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding-bottom: 20px;
`

const HomeHeroWrapper = styled(Container)`
  position: relative;
  text-align: center;
  min-height: 900px;
  background: ${({ theme }) => theme.color.bg.lite};
  width: 100%;
  padding: 110px 16px 64px;
  button {
    margin: 32px auto;
  }
  @media (max-width: ${({ theme }) => theme.media.mobile}) {
    padding: 0;
  }
`

const HeroBackground = styled.div`
  width: 100%;
  height: auto;
`

const HeroPerson = styled.div`
  width: auto;
  height: auto;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 0);
  img {
    width: auto;
    height: 100%;
  }
  @media (max-width: ${({ theme }) => theme.media.mobileSm}) {
    transform: translate(-50%, 0) scale(0.7);
  }
`

const HeroArtwork = styled(FlexContainer)`
  width: 100%;
  height: auto;
  overflow: hidden;
  position: relative;
  justify-content: center;
  align-items: center;
`
