import React, { useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useFormContext } from 'react-hook-form'
import { Container } from 'components/styled/Containers'
import { InputLabel } from 'components/styled/Text'

const Checkbox = ({
  questionName,
  optionName,
  optionValue,
  isPreChecked,
  isHidden = true,
  className
}) => {
  const [checked, setChecked] = useState(isPreChecked)
  const form = useFormContext()

  // Sets to checked or unchecked
  const handleCheck = () => {
    setChecked(!checked)
  }

  return (
    <CheckboxContainer
      className={className}
      checked={checked}
      isHidden={isHidden}
    >
      <CheckboxStyled
        ref={form && form.register}
        type="checkbox"
        name={questionName}
        defaultChecked={checked}
        value={optionValue}
        onChange={handleCheck}
        readOnly={false}
      />
      <CheckboxLabel htmlFor={optionName}>
        <CheckboxMock checked={checked}>
          <Check checked={checked} />
        </CheckboxMock>
        {optionName}
      </CheckboxLabel>
    </CheckboxContainer>
  )
}

// -- PROP DESCRIPTIONS -- //
// questionName: name associated with react-hook-form
// optionName: text rendered next to checkbox

Checkbox.propTypes = {
  optionName: PropTypes.string,
  questionName: PropTypes.string,
  optionValue: PropTypes.string
}

Checkbox.defaultProps = {
  optionName: 'item',
  questionName: 'checkboxes'
}

export default Checkbox

const Check = styled.div`
  position: absolute;
  top: 50%;
  left: 28%;
  width: 5px;
  height: 8px;
  border-right: 2px solid
    ${({ theme, checked }) =>
      checked ? theme.color.primary.black4 : theme.color.bg.white};
  border-bottom: 2px solid
    ${({ theme, checked }) =>
      checked ? theme.color.primary.black4 : theme.color.bg.white};
  transform: rotate(45deg) translate(-50%, -50%);
  cursor: pointer;
`

const CheckboxMock = styled(Container)`
  height: 16px;
  width: 16px;
  min-height: 16px;
  min-width: 16px;
  background: ${({ theme, checked }) =>
    checked ? theme.color.primary.yellow4 : theme.color.bg.white};
  border-radius: 2px;
  margin-right: 10px;
  border: 1px solid
    ${({ theme, checked }) =>
      checked ? theme.color.primary.yellow4 : theme.color.border.lite};
  pointer-events: none;
`

const CheckboxContainer = styled(Container)`
  background: ${({ theme, checked }) =>
    checked ? theme.color.bg.white : theme.color.bg.gray};
  border: 1px solid
    ${({ theme, checked }) =>
      checked ? theme.color.primary.black0 : theme.color.bg.gray};
  border-radius: 4px;
  cursor: pointer;
  display: ${({ isHidden }) => (isHidden ? 'block' : 'none')};
  height: 33px;
  margin-bottom: 4px;
  margin-right: 4px;
  padding: 0px;
  pointer-events: none;
  &:hover {
    background: ${({ theme }) => theme.color.bg.borderless};
    border-color: ${({ theme, checked }) =>
      checked ? theme.color.bg.borderless : theme.color.bg.borderless};
  }
`

const CheckboxLabel = styled(InputLabel)`
  display: flex;
  align-items: center;
  height: 100%;
  white-space: nowrap;
  margin-left: 0px;
  padding: 7px 15px;
  pointer-events: none;
  line-height: 21px;
  @media (max-width: 768px) {
    white-space: normal;
  }
`

const CheckboxStyled = styled.input`
  appearance: none;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  position: absolute;
  opacity: 0;
  pointer-events: all;
  &:after {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    content: '';
    position: absolute;
    border: 1px solid
      ${({ theme, checked }) =>
        checked ? theme.color.bg.white : theme.color.bg.gray};
    background: ${({ theme, checked }) =>
      checked ? theme.color.bg.white : theme.color.bg.gray};
  }
  &:hover {
    cursor: pointer;
    &:after {
      background: #e1e1e1;
      color: #363540;
      cursor: pointer;
      border-color: #e1e1e1;
    }
  }
  &:focus {
    outline: 0;
  }
  &:active {
    outline: 0;
  }
  &:checked {
    outline: 0;
    &:after {
      border-color: ${({ theme }) => theme.color.primary.black0};
      background: ${({ theme }) => theme.color.bg.white};
    }
  }
`
