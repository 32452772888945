import React from 'react'
import styled from 'styled-components'
import DeleteModal from 'containers/ProfilePage/components/deleteModal/DeleteModal'
import Profile from 'containers/ProfilePage/containers/Profile'
import { PageWrapper } from 'components/styled/Wrappers'
import useProfileContext from 'services/context/profileContext/useProfileContext'
import useFetchPublicProfile from 'services/hooks/useFetchPublicProfile'
import ImageZoomModal from 'components/general/ImageZoomModal'
import notFoundProfileIcon from '../assets/notFoundProfileIcon'
import LoadingSpinner from 'components/general/LoadingSpinner'

const PublicProfile = ({ isProjects }) => {
  const { state, loading } = useFetchPublicProfile(true)
  const { profileState } = useProfileContext()
  const { publicProfileData, zoomIn, zoomImgSrc } = state

  if (loading) return <LoadingSpinner />

  return publicProfileData !== null ? (
    <ProfilePageWrapper background="#f8f8f8">
      {zoomIn && <ImageZoomModal imgSrc={zoomImgSrc} />}
      {profileState.deleteModal && <DeleteModal />}
      <Profile profileData={publicProfileData} isProjects={isProjects} />
    </ProfilePageWrapper>
  ) : (
    <ProfilePageWrapper background="#f8f8f8">
      <NotFoundContainer>
        <NotFoundProfileImage>{notFoundProfileIcon}</NotFoundProfileImage>
        <NotFoundProfileTitle>Nothing to see here</NotFoundProfileTitle>
        <NotFoundProfileDescription>
          Sorry, this profile has been taken down and is no longer available to
          the public.
        </NotFoundProfileDescription>
      </NotFoundContainer>
    </ProfilePageWrapper>
  )
}

export default PublicProfile

const ProfilePageWrapper = styled(PageWrapper)`
  @media (max-width: 768px) {
    padding-left: 0;
    padding-right: 0;
    padding-top: 104px;
  }
`

const NotFoundContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 141px;
`

const NotFoundProfileImage = styled.div`
  margin-bottom: 52px;
`

const NotFoundProfileTitle = styled.div`
  color: ${({ theme }) => theme.color.text.black};
  font-size: 48px;
  font-weight: normal;
  text-align: center;
  letter-spacing: -0.62px;
  margin-bottom: 45px;
  line-height: 48px;
`

const NotFoundProfileDescription = styled.div`
  color: ${({ theme }) => theme.color.text.black};
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  letter-spacing: 0.11px;
`
