import React, { useRef } from 'react'
import styled from 'styled-components'
import ProfileCompletionNotificaiton from 'containers/JobListingPage/components/ProfileCompletionNotification'
import useAppContext from 'services/context/appContext/useAppContext'
import useFetchAppData from 'services/hooks/useFetchAppData'
import useOnboarding from 'services/hooks/useOnboarding'
import Modal from 'components/styled/Modal'
import useClickedOutsideEvent from 'services/hooks/useClickedOutsideEvent'
import linkedin from 'containers/AuthPage/assets/linkedin'
import github from 'containers/AuthPage/assets/github'

const JobListingPage = ({
  setShowModal,
  setTogglePrompt,
  applicationLink,
  jobListingId
}) => {
  const modalRef = useRef(null)
  const { api, requests } = useAppContext()
  const { totalNotifications, actionItemsRemaining } = useOnboarding()
  const {
    state: { profileData }
  } = useFetchAppData()
  const {
    name,
    photo,
    role,
    location,
    github_link,
    linkedin_link
  } = profileData
  const firstAndLast = name.split(' ')
  const initials = `${firstAndLast[0][0]}${
    firstAndLast[firstAndLast.length - 1][0]
  }`

  useClickedOutsideEvent(modalRef, () => setShowModal(false))

  const handleContinue = () => {
    shareProfile()
    setShowModal(false)
    setTogglePrompt(true)
  }

  const shareProfile = async () => {
    await api.request(requests().shareProfile, {
      job_listing_id: jobListingId
    })
  }

  return (
    <ExtendModal>
      <ModalContainer ref={modalRef}>
        <HeaderText>Share your profile</HeaderText>
        <ProfilePreview>
          <ProfilePicture backgroundImage={photo && photo.file_path}>
            {!photo && initials}
          </ProfilePicture>
          <ProfileInfo>
            <div style={{ display: 'flex' }}>
              <Name>{name}</Name>
              {linkedin_link && <SocialIcon>{linkedin}</SocialIcon>}
              {github_link && <SocialIcon>{github}</SocialIcon>}
            </div>
            <Role>{role}</Role>
            <Location>{location}</Location>
          </ProfileInfo>
        </ProfilePreview>
        <SharingDisclaimer>
          Giving the employer access to your Connect profile increases your
          chance of getting an interview!
        </SharingDisclaimer>
        {!!actionItemsRemaining && (
          <ProfileCompletionNotificaiton
            actionItemsRemaining={actionItemsRemaining}
            totalSteps={totalNotifications - 1}
          />
        )}
        <ExternalLink
          target="blank"
          href={applicationLink}
          onClick={() => handleContinue()}
        >
          <ContinueButton>Continue</ContinueButton>
        </ExternalLink>
      </ModalContainer>
    </ExtendModal>
  )
}

export default JobListingPage

const ExtendModal = styled(Modal)`
  width: 100%;
  height: 100%;
  align-items: flex-start;
  justify-content: center;
  padding-top: 15%;
`

const ModalContainer = styled.div`
  background: ${({ theme }) => theme.color.bg.white};
  border-radius: 4px;
  padding: 32px;
  text-align: left;
  width: 556px;
`

const HeaderText = styled.h6`
  color: ${({ theme }) => theme.color.primary.black2};
  font-size: 18px;
  font-weight: 500;
  text-transform: uppercase;
`

const ProfilePreview = styled.div`
  display: flex;
  margin: 32px 0;
`

const ProfilePicture = styled.div`
  align-items: center;
  background: ${({ backgroundImage, theme }) =>
    backgroundImage
      ? `url(${backgroundImage}) center center no-repeat`
      : theme.color.primary.black2};
  background-size: cover;
  border-radius: 50%;
  color: ${({ theme }) => theme.color.bg.white};
  display: flex;
  font-size: 24px;
  font-weight: 500;
  height: 80px;
  justify-content: center;
  width: 80px;
`

const ProfileInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
`

const Name = styled.div`
  font-size: 24px;
  font-weight: 500;
  margin: 0 14px 8px 0;
`

const SocialIcon = styled.div`
  margin: 3px 2px 0 0;
  svg {
    height: 15px;
    path,
    polygon {
      fill: ${({ theme }) => theme.color.primary.black3};
    }
  }
`

const Role = styled.p`
  color: ${({ theme }) => theme.color.primary.black5};
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.25px;
  line-height: 17px;
`

const Location = styled(Role)`
  opacity: 50%;
`

const SharingDisclaimer = styled.p`
  color: ${({ theme }) => theme.color.primary.black5};
  font-size: 16px;
  margin-top: 16px;
  width: 394px;
`

const ExternalLink = styled.a`
  display: inline-block;
  margin-top: 32px;
`

const ContinueButton = styled.div`
  background: #ffc043;
  border-radius: 4px;
  color: #373440;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  padding: 7px 0;
  text-align: center;
  text-transform: uppercase;
  width: 150px;
`
