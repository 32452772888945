import styled from 'styled-components'

/* General styles in a card that set header / subheader spaces
.header-actions acts as a wrapper for the Edit action */

const Card = styled.div`
  width: 100%;
  display: block;
  position: relative;
  &:hover {
    .header-actions {
      opacity: 1;
    }
  }
  .header {
    width: 100%;
    display: block;
    margin: 0 auto;
    orphans: 2;

    p {
      color: ${({ theme }) => theme.color.text.dark};
    }

    &-actions {
      position: absolute;
      width: auto;
      top: 0;
      right: 0;
      opacity: 0;
      cursor: pointer;
      transition: all 300ms ease-out;
    }
  }

  .subheader {
    orphans: 2;
    width: 100%;
    display: block;
    margin: 0 auto;

    p {
      &:not(.sub) {
        color: ${({ theme }) => theme.color.text.dark};
      }
      &.sub {
        color: ${({ theme }) => theme.color.primary.black2};
      }
    }
  }
`

// This styling account for a logo placement / icon placeholder

const LogoInsetCard = styled(Card)`
  .logo {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    width: 32px;
    height: 32px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    img,
    svg,
    i {
      margin: 0;
    }
    i {
      font-size: 1.25em;
      color: ${({ theme }) => theme.color.primary.black0};
    }
  }
`

const Description = styled.p`
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 0.5px;
  margin: 0 0 4px;
`

const CardBody = styled.div`
  orphans: 3;
  width: 100%;
  display: block;
  margin: 12px auto;
  padding: 0 0 16px;
  color: #373440;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 0.5px;
  margin-bottom: 0px;
  ul,
  ol {
    margin: 8px auto;
    padding: 0 0 0 32px;
  }
  @media screen and (max-width: 768px) {
    padding: 0 0 0 0;
  }
`

const CardTags = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: left;
  padding: 8px 16px;
  .label {
    margin: 0 8px 8px 0;
  }
`
export { Card, LogoInsetCard, CardBody, CardTags, Description }
