import { useEffect } from 'react'
import useAppContext from 'services/context/appContext/useAppContext'
import isProfileEmpty from 'containers/QuestionnairePage/helpers/isProfileEmpty'

// FETCH GENENRAL PREFS
const fetchGeneralPreferences = async (
  state,
  api,
  requests,
  appDispatchActions,
  history
) => {
  return await api.request(requests().getGeneralPreferences, {}, response => {
    appDispatchActions.setGeneralPreferencesData({
      generalPreferences: response.data
    })
    if (!response.data.has_seen_form) {
      appDispatchActions.setOnboardingStatus({ status: true })
      history.push('/questionnaire/general-preferences')
    }
    return response.data
  })
}

// FETCH EMPLOYER PREFS
const fetchEmployerPreferences = async (
  state,
  api,
  requests,
  appDispatchActions,
  history,
  generalPrefData
) => {
  await api.request(requests().getEmployerPreferences, {}, response => {
    appDispatchActions.setEmployerPreferencesData({
      employerPreferences: response.data
    })
    if (
      generalPrefData &&
      generalPrefData.has_seen_form &&
      !response.data.has_seen_form
    ) {
      appDispatchActions.setOnboardingStatus({ status: true })
      history.push('/questionnaire/employer-preferences')
    }
  })
}

const useFetchProfile = (state, api, requests, appDispatchActions, history) => {
  useEffect(() => {
    const startOnboarding = () => {
      appDispatchActions.setOnboardingStatus({ status: true })
      history.push('/questionnaire/basic-info')
    }

    const onSuccess = async response => {
      appDispatchActions.setProfileData({ profile: response.data })

      if (isProfileEmpty(response.data)) {
        startOnboarding()
      } else {
        const generalPrefData = await fetchGeneralPreferences(
          state,
          api,
          requests,
          appDispatchActions,
          history
        )

        await fetchEmployerPreferences(
          state,
          api,
          requests,
          appDispatchActions,
          history,
          generalPrefData
        )
      }
    }

    const onFailure = error => {
      if (error.response.status === 401) {
        api.removeTokens()
        return window.location.reload()
      }
      startOnboarding()
    }

    const fetchAppData = async () => {
      await api.request(requests().profile, {}, onSuccess, onFailure)
    }

    fetchAppData()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.updatedProfile])
}

const useFetchAppData = () => {
  const { state, api, requests, appDispatchActions, history } = useAppContext()
  useFetchProfile(state, api, requests, appDispatchActions, history)

  return { state }
}

export default useFetchAppData
