import React from 'react'
import styled from 'styled-components'
import { FlexContainer, Container } from 'components/styled/Containers'
import { Button } from 'components/styled/Buttons'
import Segment from 'components/styled/Segments'
import useAppContext from 'services/context/appContext/useAppContext'
import { rocketShip } from 'containers/ProfilePage/assets/barebonesAssets'

const FirstProject = () => {
  const { state, history } = useAppContext()
  const { publicProfileData } = state

  // Render create form
  const uploadProject = () => {
    history.push(`/profile/project`)
  }

  return (
    <Container width="100%" height="auto">
      <FlexContainer>
        <Segment className="shadow" />
        <DesktopContainer>
          <Segment className="shadow" />
        </DesktopContainer>
        <DesktopContainer>
          <Segment className="shadow" />
        </DesktopContainer>
      </FlexContainer>
      <PlaceholderFirstProject
        justify="center"
        align="center"
        direction="column"
        className="rocket-cover"
      >
        <div className="icon">{rocketShip}</div>
        {!publicProfileData ? (
          <div>
            <h5>You haven't uploaded any projects yet</h5>
            <Button onClick={uploadProject}>Upload Your First Project</Button>
          </div>
        ) : (
          <div>
            <h5>No projects have been uploaded yet</h5>
          </div>
        )}
      </PlaceholderFirstProject>
    </Container>
  )
}

export default FirstProject

const DesktopContainer = styled(Container)`
  width: 100%;
  display: block;
  @media (max-width: 768px) {
    display: none;
  }
`

const PlaceholderFirstProject = styled(FlexContainer)`
  position: absolute;
  top: 15%;
  left: 0;
  width: 100%;
  background: linear-gradient(
    180deg,
    transparent 15%,
    ${({ theme }) => theme.color.bg.gray} 40%
  );
  height: 100%;
  padding: 64px 0;
  .icon {
    margin: 0 auto 16px;
  }
  button {
    margin: 16px auto;
  }
  @media (max-width: 768px) {
    text-align: center;
    padding: 64px 20px;
  }
`
