import React from 'react'

const whiteGearIcon = (
  <svg width="12px" height="12px" viewBox="0 0 12 12" version="1.1">
    <g stroke="#ffffff" strokeWidth="1" fill="#ffffff" fillRule="evenodd">
      <g
        transform="translate(-122.000000, -22.000000)"
        className="icon"
        fillRule="nonzero"
      >
        <g>
          <g transform="translate(114.000000, 16.000000)">
            <path
              d="M18.9031606,12 C18.9031606,11.704934 18.8713021,11.4162189 18.8176991,
              11.1358085 L20,10.4763068 C19.71378,9.42452369 19.1443742,8.48363459 
              18.3681416,7.74010747 L17.1833123,8.40058622 C16.7307206,8.02686859 
              16.2073325,7.73375672 15.6343869,7.53786028 L15.6343869,6.2173913 
              C15.1125158,6.07914021 14.565866,6 14,6 C13.4336283,6 12.8869785,
              6.08011724 12.3656131,6.2173913 L12.3656131,7.53786028 C11.7926675,
              7.73375672 11.2692794,8.02686859 10.8166877,8.40058622 L9.63185841,
              7.74010747 C8.85562579,8.48363459 8.28621997,9.42452369 8,10.4763068 
              L9.18230088,11.1358085 C9.12920354,11.4167074 9.09683944,11.7044455 
              9.09683944,12 C9.09683944,12.295066 9.12869785,12.5837811 9.18230088,
              12.8641915 L8,13.5236932 C8.28621997,14.5754763 8.85562579,15.5168539 
              9.63185841,16.2598925 L10.8166877,15.5994138 C11.2692794,15.9731314 
              11.7926675,16.2662433 12.3656131,16.4621397 L12.3656131,17.7826087 
              C12.8874842,17.9208598 13.434134,18 14,18 C14.5663717,18 15.1130215,
              17.9198828 15.6343869,17.7826087 L15.6343869,16.4621397 C16.2073325,
              16.2662433 16.7307206,15.9731314 17.1833123,15.5994138 L18.3681416,
              16.2598925 C19.1443742,15.5163654 19.71378,14.5754763 20,13.5236932 
              L18.8176991,12.8641915 C18.8707965,12.5832926 18.9031606,12.295066 
              18.9031606,12 Z M14.0005057,14.210552 C12.7362832,14.210552 11.7112516,
              13.2208109 11.7112516,12 C11.7112516,10.7791891 12.7362832,9.78944797 
              14.0005057,9.78944797 C15.2632111,9.78944797 16.2882427,10.7791891 
              16.2882427,12 C16.2882427,13.2208109 15.2627054,14.210552 14.0005057,
              14.210552 Z"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default whiteGearIcon
