import React from 'react'

const backArrowIcon = (
  <svg width="32px" height="32px" viewBox="0 0 32 32" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-152.000000, -120.000000)">
        <g
          transform="translate(168.000000, 136.000000) rotate(-180.000000) translate(-168.000000, -136.000000) 
        translate(152.000000, 120.000000)"
        >
          <circle cx="16" cy="16" r="16"></circle>
          <g
            transform="translate(10.000000, 11.000000)"
            stroke="#373440"
            strokeLinecap="round"
            strokeWidth="2.25"
          >
            <polyline
              strokeLinejoin="round"
              transform="translate(7.664375, 4.500000) rotate(-45.000000) translate(-7.664375, -4.500000) "
              points="10.8279342 1.33644068 10.7301248 7.56574994 4.50081553 7.66355932"
            ></polyline>
            <line x1="11.1133435" y1="4.5" x2="0.383218743" y2="4.5"></line>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default backArrowIcon
