import styled from 'styled-components'
import { FlexContainer } from 'components/styled/Containers'

const ProfileContainer = styled(FlexContainer)`
  position: relative;
  overflow: hidden;
  padding: 0;
  margin: 0;
`

const ProfileHeader = styled(FlexContainer)`
  width: auto;
  padding: 0 0 0 0;
  text-align: left;
`

const ProfileDescription = styled.div`
  width: 100%;
  text-align: left;
  display: block;
  margin: 0 0 16px;
  padding: 0 32px 0 0;
  .location,
  .title {
    text-align: left;
    display: block;
  }
  .title {
    color: ${({ theme }) => theme.color.primary.black5};
  }
  .location {
    color: ${({ theme }) => theme.color.primary.black2};
    margin: 0 0 4px;
  }
  .body {
    color: ${({ theme }) => theme.color.text.dark};
  }
  @media screen and (max-width: 992px) {
    padding: 16px 8px;
  }
`

const ProfileTags = styled.div`
  width: calc(100% - 16px);
  padding: 0;
  text-align: left;
  display: block;
  margin: 0 auto 16px;
`

const UserProfileContainer = styled(FlexContainer)`
  width: 100%;
  text-align: left;
  .details {
    width: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px 0 0;
    .name {
      margin: 0 0 4px;
      white-space: nowrap;
    }
  }
  @media screen and (max-width: 992px) {
    justify-content: center;
    .details {
      width: auto;
      padding: 0 8px;
    }
  }
  @media screen and (max-width: 568px) {
    flex-direction: column;
    align-items: center;
    .details {
      width: 100%;
      justify-content: center;
      align-items: center;
      .name {
        margin-top: 16px;
      }
    }
  }
`

export {
  ProfileContainer,
  ProfileHeader,
  ProfileDescription,
  ProfileTags,
  UserProfileContainer
}
