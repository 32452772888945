import React, { useState, useEffect, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import queryString from 'query-string'
import InfiniteScroll from 'react-infinite-scroll-component'
import styled from 'styled-components'
import useAppContext from 'services/context/appContext/useAppContext'
import useAuth from 'services/hooks/useAuth'
import { Container } from 'components/styled/Containers'
import { PageWrapper } from 'components/styled/Wrappers'
import JobListingCard from './components/JobListingCard'
import {
  DropdownCheckboxList,
  ClearButton
} from 'components/forms/DropdownCheckboxList'
import { InputStyled } from 'components/forms/Input'
import LocationFilter from 'containers/JobBoardPage/components/LocationFilter'
import magnifyingGlassIcon from './assets/magnifyingGlassIcon'

const INITAL_LOCATIONS = Object.freeze([
  { value: 'open-to-remote', checked: false }
])

const JobBoardPage = () => {
  const { isAuthenticated } = useAuth()
  const history = useHistory()
  const [{ api, requests }] = useState(useAppContext())
  const [jobListings, setJobListings] = useState(null)
  const [myJobListings, setMyJobListings] = useState(null)
  const [experienceList, setExperienceList] = useState([])
  const [professionList, setProfessionList] = useState([])
  const [industryList, setIndustryList] = useState([])
  const [locationList, setLocationList] = useState(INITAL_LOCATIONS)
  const [searchText, setSearchText] = useState('')
  const [isClearButtonShowing, setIsClearButtonShowing] = useState(false)
  const [selectedFilterCount, setSelectedFilterCount] = useState(0)
  const [isUpdatedScrollPosition, setIsUpdatedScrollPosition] = useState(false)
  const [isMyJobsActive, setIsMyJobsActive] = useState(false)
  const [isMySavedJobs, setIsMySavedJobs] = useState(true)

  const fetchProfessionList = useCallback(async () => {
    const parsedParams = queryString.parse(window.location.search)
    await api.request(
      requests({ authed: isAuthenticated }).getProfessionList,
      {},
      response => {
        const {
          data: { profession_types }
        } = response
        const tempProfessionList = []

        profession_types.forEach(item => {
          const profession = {
            value: item,
            checked: parsedParams?.profession_type?.includes(item) || false
          }
          tempProfessionList.push(profession)
        })

        setProfessionList(tempProfessionList)
      }
    )
  }, [api, requests, isAuthenticated])

  const fetchExperienceList = useCallback(async () => {
    const parsedParams = queryString.parse(window.location.search)
    await api.request(
      requests({ authed: isAuthenticated }).getExperienceList,
      {},
      response => {
        const {
          data: { experience_levels }
        } = response
        const tempExperienceList = []

        experience_levels.forEach(item => {
          const experience = {
            value: item,
            checked: parsedParams?.experience_level?.includes(item) || null
          }
          tempExperienceList.push(experience)
        })

        setExperienceList(tempExperienceList)
      }
    )
  }, [api, requests, isAuthenticated])

  const fetchEmployerIndustryList = useCallback(async () => {
    const parsedParams = queryString.parse(window.location.search)
    await api.request(
      requests({ authed: isAuthenticated }).getEmployerIndustryList,
      {},
      response => {
        const {
          data: { industries }
        } = response
        const tempIndustryList = []

        industries.forEach(item => {
          const industry = {
            value: item,
            checked: parsedParams?.industry_any?.includes(item) || false
          }
          tempIndustryList.push(industry)
        })

        setIndustryList(tempIndustryList)
      }
    )
  }, [api, requests, isAuthenticated])

  const fetchLocationList = useCallback(() => {
    const parsedParams = queryString.parse(window.location.search)
    const { remote, geo_location } = parsedParams

    const currentLocations = [...locationList]
    const openToRemote = currentLocations.find(
      ({ value }) => value === 'open-to-remote'
    )
    openToRemote.checked = remote

    let geoLocations = []
    if (Array.isArray(geo_location)) {
      geoLocations = [...geo_location]
    } else if (geo_location) {
      geoLocations = [geo_location]
    }

    for (const location of geoLocations) {
      const [label, , long, lat] = location.split(',')
      currentLocations.push({
        label: decodeURIComponent(label),
        geometry: { coordinates: [long, lat] }
      })
    }
    setLocationList(currentLocations)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchJobListings = useCallback(
    async next => {
      await api.request(
        requests({ ...next, authed: isAuthenticated }).getJobListings,
        {},
        response => {
          const {
            data: { count, next: nextURL, results }
          } = response

          setJobListings(jobListings => ({
            count: count,
            next: nextURL && nextURL.replace(process.env.REACT_APP_API_URL, ''),
            results: jobListings
              ? [...jobListings.results, ...results]
              : results
          }))
        }
      )
    },
    [api, requests, isAuthenticated]
  )

  const fetchMyJobListings = useCallback(
    async next => {
      await api.request(
        requests({ authed: isAuthenticated }).getMyJobs,
        {},
        response => {
          setMyJobListings(response.data)
        }
      )
    },
    [api, requests, isAuthenticated]
  )

  const fetchDropdownData = useCallback(async () => {
    fetchProfessionList()
    fetchExperienceList()
    fetchEmployerIndustryList()
    fetchLocationList()
  }, [
    fetchProfessionList,
    fetchExperienceList,
    fetchEmployerIndustryList,
    fetchLocationList
  ])

  useEffect(() => {
    const allFilterList = [
      ...experienceList,
      ...professionList,
      ...industryList,
      ...locationList
    ]

    setSelectedFilterCount(
      allFilterList.filter(({ checked }) => checked).length
    )

    if (
      allFilterList.some(({ checked }) => checked) ||
      locationList.filter(({ label = null }) => label).length
    ) {
      setIsClearButtonShowing(true)
    } else {
      setIsClearButtonShowing(false)
    }
  }, [experienceList, professionList, industryList, locationList])

  useEffect(() => {
    const parsedParams = queryString.parse(window.location.search)
    if (!isUpdatedScrollPosition && jobListings?.results?.length) {
      if (parsedParams?.scroll_pos) {
        window.scrollTo(0, parsedParams.scroll_pos)
      }
      setIsUpdatedScrollPosition(true)
    }
  }, [jobListings, isUpdatedScrollPosition])

  useEffect(() => {
    const parsedParams = queryString.parse(window.location.search)
    if (parsedParams?.search) {
      setSearchText(parsedParams.search)
    }

    if (parsedParams?.page) {
      parsedParams.all = true
    }

    fetchJobListings({
      query: '?' + queryString.stringify(parsedParams)
    })
  }, [fetchJobListings])

  useEffect(() => {
    fetchDropdownData()
  }, [fetchDropdownData])

  useEffect(() => {
    if (isMyJobsActive && !myJobListings) {
      fetchMyJobListings()
    }
  }, [fetchMyJobListings, myJobListings, isMyJobsActive])

  const handleSaveJob = (jobId, isSaved) => {
    const job = jobListings.results.find(({ id }) => id === jobId)
    job.is_saved = !isSaved
    const foundIndex = jobListings.results.findIndex(({ id }) => id === jobId)
    jobListings.results[foundIndex] = job

    if (myJobListings) {
      const myJob = myJobListings.find(({ id }) => id === jobId)
      if (myJob) {
        myJob.is_saved = !isSaved
        const foundIndexMyJob = myJobListings.findIndex(
          ({ id }) => id === jobId
        )
        myJobListings[foundIndexMyJob] = myJob
      } else {
        myJobListings.push(job)
      }
    }

    if (!isSaved) {
      api.request(requests({ authed: isAuthenticated }).saveJob, {
        job_listing_id: jobId
      })
    } else {
      api.request(
        requests({ jobListingId: jobId, authed: isAuthenticated }).deleteJob,
        {}
      )
    }
  }

  const filterAndSearch = (modifiedLocationList = null) => {
    setJobListings(null)

    const getCheckedItems = list =>
      list.filter(({ checked }) => checked).map(({ value }) => value)

    const getSelectedLocations = () => {
      const selectedLocations = modifiedLocationList || locationList
      return selectedLocations
        .filter(({ value = null }) => !value)
        .map(
          ({ label, geometry: { coordinates } }) =>
            `${encodeURIComponent(label)},${0},${coordinates.join(',')}`
        )
    }

    let selectedFilterValues = {
      experience_level: getCheckedItems(experienceList),
      profession_type: getCheckedItems(professionList),
      industry_any: getCheckedItems(industryList),
      geo_location: getSelectedLocations()
    }

    if (searchText.trim().length > 0) {
      let searchParam = { search: [searchText.trim()] }
      selectedFilterValues = { ...selectedFilterValues, ...searchParam }
      setIsClearButtonShowing(true)
    } else {
      if (!selectedFilterCount) setIsClearButtonShowing(false)
    }

    if (
      locationList.some(
        ({ value, checked }) => value === 'open-to-remote' && checked
      )
    ) {
      let searchParam = { remote: [true] }

      selectedFilterValues = { ...selectedFilterValues, ...searchParam }
    }

    const params = []

    for (const [key, value] of Object.entries(selectedFilterValues)) {
      value.forEach(val => {
        if (val === 'open-to-remote') return
        params.push(`${encodeURIComponent(key)}=${encodeURIComponent(val)}`)
      })
    }

    history.push({ search: params.join('&') })

    fetchJobListings({
      query: '?' + params.join('&')
    })
  }

  const handleSearchInputKeyDown = e => {
    if (e.key === 'Enter') {
      filterAndSearch()
    }
  }

  const handleClearSelectedOptions = (name, event) => {
    event.stopPropagation()
    let clearLocations = [...locationList]
    switch (name) {
      case 'experience_level': {
        experienceList.forEach(item => {
          if (item.checked) item.checked = false
        })
        setExperienceList([...experienceList])
        break
      }
      case 'profession_type': {
        professionList.forEach(item => {
          if (item.checked) item.checked = false
        })
        setProfessionList([...professionList])
        break
      }
      case 'industry': {
        industryList.forEach(item => {
          if (item.checked) item.checked = false
        })
        setIndustryList([...industryList])
        break
      }
      default: {
        clearLocations = INITAL_LOCATIONS
        setLocationList(clearLocations)
        break
      }
    }
    filterAndSearch(clearLocations)
  }

  const handleCheckFilterOption = (name, value, isChecked) => {
    let locationsFilters = [...locationList]
    switch (name) {
      case 'experience_level': {
        const experience = experienceList.find(
          experience => experience.value === value
        )
        experience.checked = isChecked
        setExperienceList([...experienceList])
        break
      }
      case 'profession_type': {
        const profession = professionList.find(
          profession => profession.value === value
        )
        profession.checked = isChecked
        setProfessionList([...professionList])
        break
      }
      case 'industry': {
        const industry = industryList.find(industry => industry.value === value)
        industry.checked = isChecked
        setIndustryList([...industryList])
        break
      }
      case 'open-to-remote': {
        const location = locationsFilters.find(
          location => location.value === value
        )
        location.checked = isChecked
        setLocationList([...locationsFilters])
        break
      }
      default: {
        const openToRemote = locationList.find(
          location => location.value === 'open-to-remote'
        )
        locationsFilters = [openToRemote, ...value]
        setLocationList(locationsFilters)
        break
      }
    }
    filterAndSearch(locationsFilters)
  }

  const handleNextInfiniteScroll = next => {
    fetchJobListings({ next: next })
    const nextParsedParams = queryString.parse(next.split('?')[1])
    const parsedParams = queryString.parse(window.location.search)
    parsedParams.page = nextParsedParams.page
    const stringified = queryString.stringify(parsedParams)
    history.push({ search: stringified })
  }

  const clearFilters = () => {
    setJobListings(null)

    experienceList.forEach(item => {
      if (item.checked) item.checked = false
    })
    setExperienceList([...experienceList])

    professionList.forEach(item => {
      if (item.checked) item.checked = false
    })
    setProfessionList([...professionList])

    industryList.forEach(item => {
      if (item.checked) item.checked = false
    })
    setIndustryList([...industryList])

    setLocationList(INITAL_LOCATIONS)

    setSearchText('')

    fetchJobListings()

    history.push({ search: '' })
  }

  const countMySavedJobs = () =>
    myJobListings?.filter(({ is_saved }) => is_saved).length

  const countAppliedJobs = () =>
    myJobListings?.filter(({ has_shared_profile }) => has_shared_profile).length

  const renderFilterAndSearch = () => {
    return (
      <FilterAndSearchContainer>
        <FilterContainer>
          <LocationFilter
            handleClear={handleClearSelectedOptions}
            handleCheck={handleCheckFilterOption}
            locations={locationList}
          />
          <DropdownCheckboxList
            title="Industry"
            name="industry"
            options={industryList}
            handleCheck={handleCheckFilterOption}
            handleClear={handleClearSelectedOptions}
          />
          <DropdownCheckboxList
            title="Role"
            name="profession_type"
            options={professionList}
            handleCheck={handleCheckFilterOption}
            handleClear={handleClearSelectedOptions}
          />
          <DropdownCheckboxList
            title="Experience Level"
            name="experience_level"
            options={experienceList}
            handleCheck={handleCheckFilterOption}
            handleClear={handleClearSelectedOptions}
          />
          {isClearButtonShowing && (
            <ClearAllButton onClick={clearFilters}>Clear all</ClearAllButton>
          )}
        </FilterContainer>
        <SearchContainer>
          {magnifyingGlassIcon}
          <SearchInput
            placeholder="Search"
            value={searchText}
            onChange={event => {
              setSearchText(event.target.value)
            }}
            onKeyDown={handleSearchInputKeyDown}
          ></SearchInput>
        </SearchContainer>
      </FilterAndSearchContainer>
    )
  }

  const renderJobListings = () => {
    return jobListings.results.map(jobListing => (
      <JobListingCard
        key={jobListing.id}
        jobListing={jobListing}
        handleSaveJob={handleSaveJob}
      />
    ))
  }

  const handleMyJobListings = isSaved => {
    const myJobs = isSaved
      ? myJobListings.filter(({ is_saved }) => is_saved)
      : myJobListings.filter(({ has_shared_profile }) => has_shared_profile)

    return myJobs.length === 0 ? (
      <JobListings>
        <EmptyContainer>
          <EmptyTitle>
            {isMySavedJobs
              ? 'You have not saved any jobs yet.'
              : 'You have not applied to any jobs yet.'}
          </EmptyTitle>
        </EmptyContainer>
      </JobListings>
    ) : (
      <JobListings>
        {myJobs.map(jobListing => (
          <JobListingCard
            key={jobListing.id}
            jobListing={jobListing}
            handleSaveJob={handleSaveJob}
          />
        ))}
      </JobListings>
    )
  }

  const handleListings = () => {
    const { count, next, results } = jobListings

    return (
      <JobListings>
        {results?.length ? (
          <>
            <TotalResults>{count} results found</TotalResults>
            <InfiniteScroll
              dataLength={results.length}
              next={() => {
                handleNextInfiniteScroll(next)
              }}
              hasMore={next}
              loader={<TextUnderListings>Loading...</TextUnderListings>}
              endMessage={
                <TextUnderListings>
                  You have reached the end of this page!
                </TextUnderListings>
              }
            >
              {renderJobListings()}
            </InfiniteScroll>
          </>
        ) : (
          <EmptyContainer>
            <EmptyTitle>No results were found.</EmptyTitle>
            <EmptyMessage>
              Click the button below to try your search again.
            </EmptyMessage>
            <EmptyClearButton onClick={clearFilters}>
              Clear All Filters
            </EmptyClearButton>
          </EmptyContainer>
        )}
      </JobListings>
    )
  }

  const renderContentHeader = () => {
    return (
      isAuthenticated && (
        <ContentHeader>
          <Menu>
            <MenuItem>
              <MenuLink
                active={!isMyJobsActive}
                onClick={() => {
                  setIsMyJobsActive(false)
                }}
              >
                All
              </MenuLink>
            </MenuItem>
            <MenuItem>
              <MenuLink
                active={isMyJobsActive}
                onClick={() => {
                  setIsMyJobsActive(true)
                }}
              >
                My Jobs
              </MenuLink>
            </MenuItem>
          </Menu>
          <Line></Line>
        </ContentHeader>
      )
    )
  }

  const renderToggleButtons = () => {
    return (
      <FilterMyJobsContainer>
        <FilterToggleButton
          toggle={isMySavedJobs}
          onClick={() => setIsMySavedJobs(true)}
        >
          Saved ({countMySavedJobs()})
        </FilterToggleButton>
        <FilterToggleButton
          toggle={!isMySavedJobs}
          onClick={() => setIsMySavedJobs(false)}
        >
          Applied ({countAppliedJobs()})
        </FilterToggleButton>
      </FilterMyJobsContainer>
    )
  }

  return (
    <JobBoardPageWrapper>
      <ContentWrapper>
        {renderContentHeader()}
        {isMyJobsActive ? (
          <>
            {renderToggleButtons()}
            {myJobListings && handleMyJobListings(isMySavedJobs)}
          </>
        ) : (
          <>
            {renderFilterAndSearch()}
            {jobListings && handleListings()}
          </>
        )}
      </ContentWrapper>
    </JobBoardPageWrapper>
  )
}

export default JobBoardPage

const JobBoardPageWrapper = styled(PageWrapper)`
  @media screen and (max-width: 768px) {
    padding: 92px 20px 60px;
  }
`

const ContentWrapper = styled.div`
  display: block;
  height: auto;
  margin: 0 auto;
  max-width: 1140px;
  padding: 0;
  position: relative;
  width: 100%;
  @media screen and (max-width: 768px) {
    padding: 0 16px;
  }
  @media screen and (max-width: 420px) {
    padding: 0 0;
  }
`

const TotalResults = styled(Container)`
  color: ${({ theme }) => theme.color.primary.black2};
  font-size: 12px;
  font-weight: bold;
`

const JobListings = styled(Container)`
  align-items: center;
  justify-content: center;
  @media (max-width: ${({ theme }) => theme.media.mobileSm}) {
    height: 700px;
  }
  .infinite-scroll-component {
    overflow: revert !important;
  }
`

const FilterAndSearchContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 5px;
  justify-content: space-between;
  margin-bottom: 36px;
  width: 100%;
  @media screen and (max-width: ${({ theme }) => theme.media.tablet}) {
    flex-direction: column;
  }
`

const FilterContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 5px;
  width: 100%;
  @media screen and (max-width: ${({ theme }) => theme.media.mobile}) {
    flex-direction: column;
  }
`

const SearchContainer = styled.div`
  display: flex;
  position: relative;
  width: 328px;
  @media screen and (max-width: ${({ theme }) => theme.media.tablet}) {
    width: 100%;
  }
  svg {
    left: 14px;
    position: absolute;
    top: 8px;
  }
`

const TextUnderListings = styled.p`
  font-size: 14px;
  margin: 32px 0 0;
  opacity: 50%;
  text-align: center;
`

const EmptyContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 221px;
`

const EmptyTitle = styled.h1`
  color: ${({ theme }) => theme.color.primary.black4};
  font-size: 36px;
  font-weight: ${({ theme }) => theme.font.weight.medium};
  letter-spacing: 0px;
  text-align: center;
`

const EmptyMessage = styled.p`
  color: ${({ theme }) => theme.color.primary.black4};
  font-size: 16px;
  font-weight: ${({ theme }) => theme.font.weight.medium};
  letter-spacing: 0.11px;
  line-height: 24px;
  margin-top: 16px;
  text-align: center;
`

const EmptyClearButton = styled.div`
  background: ${({ theme }) => theme.color.primary.yellow4};
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.color.primary.yellow4};
  color: ${({ theme }) => theme.color.primary.black4};
  cursor: pointer;
  font-size: 14px;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  letter-spacing: 0px;
  margin-top: 48px;
  padding: 8px 24px;
  text-align: center;
  text-transform: uppercase;
  transition: all 300ms ease-out;
  :hover {
    opacity: 0.8;
  }
`

const SearchInput = styled(InputStyled)`
  height: 32px;
  margin: 0px;
  padding-bottom: 12px;
  padding-left: 40px;
  padding-top: 12px;
  @media screen and (max-width: ${({ theme }) => theme.media.tablet}) {
    margin-right: initial;
  }
`

const ClearAllButton = styled(ClearButton)`
  margin-right: 101px;
  max-width: 70px;
  min-width: 70px;
  padding-left: 16px;
  @media screen and (max-width: ${({ theme }) => theme.media.desktop}) {
    margin-right: initial;
    padding-left: 16px;
  }
`

const ContentHeader = styled.div`
  margin-bottom: 36px;
`

const Menu = styled.ul`
  list-style-type: none;
  margin: 0px;
  overflow: hidden;
  padding: 0px;
`

const MenuItem = styled.li`
  float: left;
  :first-of-type {
    width: 60px;
    > :first-child {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
`
const MenuLink = styled.a`
  color: ${({ theme }) => theme.color.primary.black4};
  display: block;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0.25px;
  line-height: 17px;
  padding-bottom: 10px;
  position: relative;
  text-transform: none;
  width: fit-content;
  :after {
    background-color: ${({ theme }) => theme.color.primary.yellow4};
    bottom: 0;
    content: '';
    height: 4px;
    left: ${({ active }) => (active ? '0' : '50%')};
    position: absolute;
    transition: all ease-in-out 0.2s;
    width: ${({ active }) => (active ? '100%' : '0')};
  }
  :hover::after {
    left: 0;
    width: 100%;
  }
`

const Line = styled.div`
  background-color: ${({ theme }) => theme.color.border.input};
  height: 1px;
`

const FilterMyJobsContainer = styled.div`
  display: flex;
  margin-bottom: 36px;
  div:nth-child(2) {
    border-left: none;
  }
`

const FilterToggleButton = styled.div`
  align-items: center;
  background: ${({ toggle, theme }) =>
    toggle ? theme.color.bg.oldLace : theme.color.bg.white};
  border: 1px solid
    ${({ toggle, theme }) =>
      toggle ? theme.color.text.fade : theme.color.border.input};
  color: ${({ theme }) => theme.color.primary.black4};
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-weight: 500;
  height: 32px;
  justify-content: center;
  letter-spacing: 0.1px;
  line-height: 24px;
  width: 156px;
`
