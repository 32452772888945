import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

// This hook grabs search params from a url
// Ex: localhost:3020/password-reset?token=l19892ims1
// Pass in the name of the param ('token') you want the value of
const useSearchParams = param => {
  const [params, setParams] = useState('')
  const history = useHistory()

  useEffect(() => {
    const paramsObj = new URLSearchParams(history.location.search)
    setParams(paramsObj.get(param))
  }, [history, param])

  return params
}

export default useSearchParams
