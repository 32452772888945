import React from 'react'
import styled from 'styled-components'
import Project from 'containers/ProfilePage/components/Project'
import FirstFeaturedProject from 'containers/ProfilePage/components/barebones/FirstFeaturedProject'

const FeaturedProjects = ({ profileData }) => {
  const { featured_projects } = profileData

  const myFeaturedProjects = () => {
    if (featured_projects.length) {
      return featured_projects.map(project => (
        <ProjectContainer key={'project-' + project.id}>
          <Project {...project} fromPage="profile" />
        </ProjectContainer>
      ))
    } else {
      return <FirstFeaturedProject />
    }
  }

  return (
    <FeaturedWrapper>
      <Title>Featured Projects</Title> {myFeaturedProjects()}
    </FeaturedWrapper>
  )
}

export default FeaturedProjects

const FeaturedWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  @media (max-width: 420px) {
    padding: 0 20px;
  }
`

const ProjectContainer = styled.div`
  height: 227px;
  width: 368px;
`

const Title = styled.h6`
  margin-bottom: 6px;
`
