import { useContext } from 'react'
import { SettingsContext } from 'services/context/settingsContext/SettingsContext'
import settingsDispatchActions from 'services/context/settingsContext/settingsDispatchActions'

const useSettingsContext = () => {
  const { state, dispatch } = useContext(SettingsContext)

  return {
    settingsState: state,
    settingsDispatchActions: settingsDispatchActions(dispatch)
  }
}

export default useSettingsContext
