import React, { createContext, useReducer } from 'react'
import styled from 'styled-components'
import profileActionTypes from './profileActionTypes'

// Set object with initial state
const initialProfileState = {
  profileTab: 'resume',
  deleteModal: false,
  deleteModalId: null,
  deleteModalType: null,
  formModal: false,
  formData: null,
  formSubmissions: 0,
  logoutModal: false
}

// Create context
export const ProfileContext = createContext(initialProfileState)

// Set Reducer
const profileReducer = (state, action) => {
  const { type, payload } = action
  const {
    REMOVE_DELETE_MODAL_DATA,
    SET_LOGOUT_MODAL,
    SET_DELETE_MODAL,
    SET_UPDATE_FORM,
    SET_CREATE_FORM,
    FORM_SUBMITTED,
    REMOVE_FORM_DATA,
    PROFILE_TAB_STATUS,
    CREATING_PROJECT_IN_FORM
  } = profileActionTypes

  switch (type) {
    case SET_DELETE_MODAL:
      return {
        ...state,
        deleteModalId: payload.deleteModalId,
        deleteModalType: payload.deleteModalType,
        deleteModal: true
      }
    case SET_UPDATE_FORM:
      return { ...state, formData: payload, formModal: true }
    case SET_CREATE_FORM:
      return { ...state, formData: payload, formModal: true }
    case FORM_SUBMITTED:
      return {
        ...state,
        formSubmissions: state.formSubmissions + 1,
        formModal: false
      }
    case REMOVE_FORM_DATA:
      return { ...state, formData: null, formModal: false }
    case PROFILE_TAB_STATUS:
      return { ...state, profileTab: payload.tab }
    case REMOVE_DELETE_MODAL_DATA:
      return {
        ...state,
        deleteModal: false,
        deleteModalId: null,
        deleteModalType: null
      }
    case CREATING_PROJECT_IN_FORM:
      return {
        ...state,
        formData: payload.formData
      }
    case SET_LOGOUT_MODAL:
      return {
        ...state,
        logoutModal: payload.logoutModalStatus
      }
    default:
      return state
  }
}

// Create profile provider
export const ProfileProvider = ({ children }) => {
  const [state, dispatch] = useReducer(profileReducer, initialProfileState)

  return (
    <ProfileWrapper>
      <ProfileContext.Provider value={{ state, dispatch }}>
        {children}
      </ProfileContext.Provider>
    </ProfileWrapper>
  )
}

const ProfileWrapper = styled.div`
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.color.bg.gray};
`
