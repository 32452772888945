import styled from 'styled-components'

const TitleH2 = styled.h2`
  padding: 0;
  font-family: ${({ theme }) => theme.font.headFamily};
  font-size: 39px;
  font-weight: normal;
  letter-spacing: -0.5px;
  line-height: 41px;
  margin: 0 0 16px;
`

export default TitleH2
