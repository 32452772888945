import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { FlexContainer } from 'components/styled/Containers'

const QuestionnaireToggle = () => {
  const [currentToggle, setCurrentToggle] = useState(null)
  const history = useHistory()

  // Set survey options to iterate through
  const surveyOptions = [
    {
      num: 1,
      text: 'Basic Info',
      url: '/questionnaire/basic-info'
    },
    {
      num: 2,
      text: 'General Preferences',
      url: '/questionnaire/general-preferences'
    },
    {
      num: 3,
      text: 'Employer Preferences',
      url: '/questionnaire/employer-preferences'
    },
    {
      num: 4,
      text: 'Resume Upload',
      url: '/questionnaire/resume-upload'
    }
  ]

  // Sets the current toggle based on what url is
  useEffect(() => {
    const findToggle = () => {
      const findCurrentToggle = surveyOptions.find(option => {
        return option.url === history.location.pathname
      })

      setCurrentToggle(findCurrentToggle)
    }

    findToggle()

    return history.listen(location => {
      findToggle()
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, history.location.pathname])

  // Check if number option should render check
  const isChecked = option => {
    if (option.num < currentToggle.num) {
      return true
    }

    return false
  }

  return (
    <LeftContainer>
      {currentToggle &&
        surveyOptions.map(option => {
          const highlighted = currentToggle.text === option.text
          const checked = isChecked(option)

          return (
            <NumberContainer key={option.text}>
              <Numbers checked={checked}>
                {checked ? <Check /> : option.num}
              </Numbers>
              <Option highlighted={highlighted}>{option.text}</Option>
            </NumberContainer>
          )
        })}
    </LeftContainer>
  )
}

export default QuestionnaireToggle

const Option = styled.div`
  color: ${({ theme, higlighted }) =>
    higlighted ? theme.color.text.norm : theme.color.primary.black5};
  font-size: 14px;
  font-weight: 500;
`

const Check = styled.div`
  width: 5px;
  height: 10px;
  border-right: 2px solid #589677;
  border-bottom: 2px solid #589677;
  transform: rotate(45deg) translate(-1px, -0px);
  cursor: pointer;
`

const Numbers = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  font-weight: bold;
  font-family: ${({ theme }) => theme.font.family};
  min-height: 24px;
  min-width: 24px;
  color: ${({ theme }) => theme.color.primary.black5};
  font-size: 14px;
  letter-spacing: 0.15px;
  margin-right: 8px;
  background: ${({ checked, theme }) =>
    checked ? theme.color.bg.green : theme.color.bg.borderless};
`

const NumberContainer = styled(FlexContainer)`
  margin-bottom: 16px;
  align-items: center;
`

const LeftContainer = styled(FlexContainer)`
  flex-direction: column;
  @media (max-width: ${({ theme }) => theme.media.tablet}) {
    margin-left: 0px;
    margin-bottom: 40px;
  }
  @media (max-width: ${({ theme }) => theme.media.mobile}) {
    margin-bottom: 20px;
  }
`
