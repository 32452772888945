import React from 'react'

export const awardIcon = (
  <svg width="22px" height="32px" viewBox="0 0 22 32" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        transform="translate(-189.000000, -2504.000000)"
        fill="#D7D6D9"
        fillRule="nonzero"
      >
        <g transform="translate(152.000000, 2422.000000)">
          <g transform="translate(37.000000, 82.000000)">
            <path d="M11,7 C8.24317436,7 6,9.24317436 6,12 C6,14.7568256 8.24317436,17 11,17 C13.7568256,17 16,14.7568256 16,12 C16,9.24317436 13.7568256,7 11,7 Z"></path>
            <polygon points="10.2493303 25.105733 10.2189911 25.0793014 9.49930607 24.4591438 7.80566762 23 5.96414163 23.1887089 4.83720105 23.3036553 4.57002227 23.3313162 3.70961495 23.4192165 1 28.9022853 5.19610754 28.9551483 7.91211309 32 11 25.7523222"></polygon>
            <polygon points="19.290385 23.4192165 18.4299777 23.3313162 18.162799 23.30427 17.0358584 23.1887089 15.1943324 23 13.5006939 24.4591438 12.7810089 25.0793014 12.7506697 25.105733 12 25.7523222 15.0878869 32 17.8038925 28.9551483 22 28.9022853"></polygon>
            <path d="M20.3986987,12.0000636 L22,8.2919064 L18.6037128,6.26850001 L17.7984929,2.29181095 L13.9043175,2.72572529 L10.9999693,0 L8.09562122,2.72578892 L4.20144575,2.29187458 L3.39622589,6.26850001 L0,8.29184276 L1.60117868,12 L0,15.7081572 L3.39622589,17.7315 L4.20144575,21.7081891 L4.49828969,21.6750998 L5.56962646,21.5554695 L6.64034992,21.4364756 L6.92738084,21.404659 L6.92799416,21.404659 L8.09562122,21.2742111 L8.97382543,22.0981965 L9.90048145,22.9679978 L10.1893523,23.239075 L10.6137042,23.6373548 L11.0000307,24 L11.3863571,23.6373548 L11.810709,23.239075 L12.0995799,22.9679978 L12.8201624,22.2922776 L12.8207757,22.2916413 L13.9043788,21.2742747 L15.7968816,21.4849004 L15.7974949,21.4849004 L15.9900755,21.5065357 L16.4303735,21.5555332 L16.4309868,21.5555332 L17.5017103,21.6751635 L17.7985543,21.7082527 L18.6037741,17.7315636 L22,15.7082209 L20.3986987,12.0000636 Z M10.9999693,19.4768296 C7.02667637,19.4768296 3.79359198,16.1230453 3.79359198,12 C3.79359198,7.87759106 7.02667637,4.52317041 10.9999693,4.52317041 C14.9732623,4.52317041 18.2063467,7.87759106 18.2063467,12 C18.2063467,16.1230453 14.9732623,19.4768296 10.9999693,19.4768296 Z"></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default awardIcon
