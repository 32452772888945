import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import { Link, useLocation } from 'react-router-dom'
import { Container, FlexContainer } from 'components/styled/Containers'
import { Button, IconAction } from 'components/styled/Buttons'
import accountIcon from 'components/forms/assets/accountIcon'
import LogoutModal from 'containers/SettingsPage/components/LogoutModal'
import useClickedOutsideEvent from 'services/hooks/useClickedOutsideEvent'

const DesktopNav = ({ isAuthenticated }) => {
  const [isDropdownShown, setIsDropdownShown] = useState(false)
  const dropdownRef = useRef(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const handleLogoutModal = () => setIsModalOpen(true)
  const toggleModal = () => setIsModalOpen(!isModalOpen)
  const location = useLocation()
  const pathname = location.pathname
  const actionButtonText = isAuthenticated ? '+ New Project' : 'Signup'
  const actionButtonRoute = isAuthenticated ? '/profile/project' : '/signup'

  useClickedOutsideEvent(dropdownRef, () => setIsDropdownShown(false))

  const actionLink = isAuthenticated ? (
    <DropdownButton
      onClick={() => setIsDropdownShown(!isDropdownShown)}
      ref={dropdownRef}
    >
      <IconAction variant="settings" color="#87858c">
        <Container padding="4px 0 0">{accountIcon}</Container>
      </IconAction>
      {isDropdownShown && (
        <DropdownContainer>
          <Link to="/profile">
            <DropdownOptionText>Profile</DropdownOptionText>
          </Link>
          <Link to="/settings/basic-profile">
            <DropdownOptionText>Settings</DropdownOptionText>
          </Link>
          <DropdownOptionText onClick={handleLogoutModal}>
            Logout
          </DropdownOptionText>
        </DropdownContainer>
      )}
    </DropdownButton>
  ) : (
    <LinkWithUnderline to="/login">
      <LinkContainer isSelected={pathname === '/login'}>
        <LinkText>Login</LinkText>
      </LinkContainer>
    </LinkWithUnderline>
  )

  return (
    <DesktopContainer align="center" height="100%">
      {isModalOpen && <LogoutModal toggleModal={toggleModal} />}
      <LinkWithUnderline to="/jobs">
        <LinkContainer isSelected={pathname.includes('/jobs')}>
          <LinkText isSelected={pathname.includes('/jobs')}>Job Board</LinkText>
        </LinkContainer>
      </LinkWithUnderline>
      <LinkWithUnderline to="/showcase">
        <LinkContainer isSelected={pathname.includes('/showcase')}>
          <LinkText isSelected={pathname.includes('/showcase')}>
            Build your portfolio
          </LinkText>
        </LinkContainer>
      </LinkWithUnderline>
      <Container
        height="17px"
        width="1px"
        background="#5f5d66"
        margin="0 8px 3px"
      ></Container>
      {actionLink}
      {isAuthenticated && (
        <ActionButtonContainer>
          <Link to={actionButtonRoute}>
            <Button>{actionButtonText}</Button>
          </Link>
        </ActionButtonContainer>
      )}
    </DesktopContainer>
  )
}

export default DesktopNav

const DesktopContainer = styled(FlexContainer)`
  display: flex;
  @media (max-width: 768px) {
    display: none;
  }
`
const LinkContainer = styled(Container)`
  align-items: center;
  border-bottom: 4px solid
    ${({ isSelected, theme }) =>
      isSelected ? theme.color.primary.yellow4 : 'transparent'};
  display: flex;
  height: 100%;
  justify-content: center;
  margin: 0 8px;
  transition: 200ms ease-out;
  &:after {
    background: ${({ theme }) => theme.color.primary.yellow4};
    content: '';
    height: 4px;
    margin: 0 auto;
    position: absolute;
    top: 100%;
    width: 0%;
  }
  &:hover:after {
    transition: 250ms ease-out;
    width: 100%;
  }
`

const DropdownButton = styled(Container)`
  align-items: center;
  border-bottom: 4px solid
    ${({ isSelected, theme }) =>
      isSelected ? theme.color.primary.yellow4 : 'transparent'};
  display: flex;
  height: 100%;
  justify-content: center;
  margin: 0 8px;
  transition: 200ms ease-out;
`

const DropdownContainer = styled.div`
  background: #ffffff;
  border: 1px solid #d7d6d9;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  left: 5px;
  padding: 4px;
  position: absolute;
  top: 60px;
  width: 156px;
`

const DropdownOptionText = styled.p`
  border-radius: 2px;
  color: #5f5d66;
  cursor: pointer;
  font-size: 14px;
  padding: 4px 0 4px 16px;
  text-transform: capitalize;
  :hover {
    background: #e5e5e5;
  }
`

const LinkWithUnderline = styled(Link)`
  height: inherit;
`

const LinkText = styled.p`
  color: ${({ isSelected, theme }) =>
    isSelected ? theme.color.text.white : theme.color.border.lite};
  font-size: 14px;
  font-weight: ${({ theme }) => theme.font.weight.regular};
  letter-spacing: 0.25px;
  line-height: 17px;
  text-decoration: none;
  text-transform: capitalize;
  transition: all 200ms ease-in-out;
`

const ActionButtonContainer = styled(LinkContainer)`
  &:after {
    background: transparent;
  }
`
