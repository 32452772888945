import React, { useState } from 'react'
import styled from 'styled-components'
import ExpandableContent from 'components/general/ContentContainers'
import Segment from 'components/styled/Segments'
import { FlexContainer, Container } from 'components/styled/Containers'
import { LogoInsetCard, CardBody } from 'components/styled/Cards'
import { IconAction } from 'components/styled/Buttons'
import useProfileContext from 'services/context/profileContext/useProfileContext'
import {
  handleDescription,
  reorderChronologically
} from 'services/helpers/formatters'
import useIsPublic from 'services/hooks/useIsPublic'
import awardIcon from '../assets/awardIcon'
import editIcon from '../assets/editIcon'

const Awards = ({ awards }) => {
  const { profileDispatchActions } = useProfileContext()
  const { isAuthenticated, isPublic } = useIsPublic()
  const [editShowing, setEditShowing] = useState(false)

  // Render create form
  const handleSetCreateForm = () => {
    profileDispatchActions.setCreateForm('awards')
  }

  // Render update form
  const handleSetUpdateForm = award => {
    profileDispatchActions.setUpdateForm(award, 'awards')
  }

  if (isPublic && !awards.length) return null

  return (
    <Segment>
      <ExpandableContent
        header="awards"
        addFormAction={handleSetCreateForm}
        actionType="add"
        hasContent={awards.length > 0}
      >
        {reorderChronologically(awards, 'awards').map((award, index) => {
          return (
            <LogoInsetCard
              onMouseOver={() => setEditShowing(true)}
              onMouseLeave={() => setEditShowing(false)}
              key={`award-${award.id}`}
            >
              <FlexContainer justify="space-between">
                <FlexContainer>
                  <Container margin="0 20px 0 0">{awardIcon}</Container>
                  <Container>
                    <div className="header">
                      <p className="award">
                        <b>{award.name}</b>
                      </p>
                    </div>
                    <div className="subheader">
                      <p className="sub">{award.year && `${award.year}`}</p>
                    </div>
                    {award.description && (
                      <CardBody>
                        {handleDescription(award.description)}
                      </CardBody>
                    )}
                  </Container>
                </FlexContainer>
                {isAuthenticated && !isPublic && editShowing && (
                  <Container>
                    <IconAction onClick={() => handleSetUpdateForm(award)}>
                      {editIcon}
                    </IconAction>
                  </Container>
                )}
              </FlexContainer>
              {awards.length - 1 !== index && <Divider />}
            </LogoInsetCard>
          )
        })}
      </ExpandableContent>
    </Segment>
  )
}

export default Awards

const Divider = styled.div`
  background: ${({ theme }) => theme.color.border.norm};
  height: 1px;
  margin: 18px 0;
`
