import React from 'react'

const linkedin = (
  <svg
    width="20px"
    height="20px"
    viewBox="0 0 24 24"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        transform="translate(-552.000000, -208.000000)"
        fill="#FFFFFF"
        fillRule="nonzero"
      >
        <g transform="translate(535.000000, 134.000000)">
          <g transform="translate(1.000000, 62.000000)">
            <g transform="translate(16.000000, 12.000000)">
              <path
                d="M23.994,24 L23.994,23.999 L24,23.999 L24,15.197 C24,10.891 23.073,7.574 
                18.039,7.574 C15.619,7.574 13.995,8.902 13.332,10.161 L13.262,10.161 
                L13.262,7.976 L8.489,7.976 L8.489,23.999 L13.459,23.999 L13.459,16.065 
                C13.459,13.976 13.855,11.956 16.442,11.956 C18.991,11.956 19.029,14.34 
                19.029,16.199 L19.029,24 L23.994,24 Z"
              ></path>
              <polygon points="0.396 7.977 5.372 7.977 5.372 24 0.396 24"></polygon>
              <path
                d="M2.882,0 C1.291,0 0,1.291 0,2.882 C0,4.473 1.291,5.791 2.882,5.791 
                C4.473,5.791 5.764,4.473 5.764,2.882 C5.763,1.291 4.472,0 2.882,0 Z"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default linkedin
