import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'

const Checkbox = ({
  name,
  value,
  isChecked,
  onCheck = () => null,
  className = '',
  ...inputProps
}) => {
  return (
    <CheckboxStyled className={className}>
      <Checkmark checked={isChecked} />
      <input
        name={name}
        type="checkbox"
        value={value}
        checked={isChecked}
        onChange={onCheck}
        {...inputProps}
      />
      <Label>{value}</Label>
    </CheckboxStyled>
  )
}

const DropdownCheckboxList = ({
  title,
  name,
  options,
  handleCheck,
  handleClear
}) => {
  const [show, setShow] = useState(false)
  const [checkedCount, setCheckedCount] = useState(0)
  const dropdownRef = useRef(null)

  const outsideEventListener = e => {
    const elements = e.composedPath()
    if (
      dropdownRef.current === e.target ||
      elements.includes(dropdownRef.current)
    )
      return
    setShow(false)
  }

  useEffect(() => {
    setCheckedCount(options.filter(({ checked }) => checked).length)
  }, [options])

  useEffect(() => {
    window.addEventListener('click', outsideEventListener, { passive: true })
    return () => {
      window.removeEventListener('click', outsideEventListener)
    }
  }, [])

  return (
    <Dropdown
      ref={dropdownRef}
      anyChecked={checkedCount}
      onClick={() => {
        setShow(!show)
      }}
      show={show}
    >
      <DropdownTitle>
        {title}
        {checkedCount ? <DropdownInfo>{checkedCount}</DropdownInfo> : null}
      </DropdownTitle>
      <DropdownIcon></DropdownIcon>
      <CheckBoxList show={show}>
        <CheckboxListContent>
          <CheckboxListItems>
            {options.map((option, index) => (
              <Checkbox
                key={index}
                name={name}
                value={option.value}
                isChecked={option.checked}
                onCheck={e => handleCheck(name, option.value, e.target.checked)}
              />
            ))}
          </CheckboxListItems>
          <CheckboxListFooter>
            <ClearButton onClick={e => handleClear(name, e)}>Clear</ClearButton>
          </CheckboxListFooter>
        </CheckboxListContent>
      </CheckBoxList>
    </Dropdown>
  )
}

const CheckboxStyled = styled.label`
  align-items: center;
  cursor: 'pointer';
  display: flex;
  gap: 5px;
  height: 32px;
  position: relative;
  :hover {
    background-color: ${({ theme }) => theme.color.bg.gray};
  }
  input {
    cursor: pointer;
    height: 0;
    opacity: 0;
    position: absolute;
    width: 0;
  }
`

const CheckBoxList = styled.div`
  margin-top: 3px;
  position: absolute;
  right: 0;
  top: 0;
  visibility: ${({ show }) => (show ? 'visible' : 'hidden')};
  width: 100%;
  z-index: ${({ theme }) => theme.order.front};
`

const CheckboxListItems = styled.div`
  background-color: ${({ theme }) => theme.color.bg.white};
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border: 1px solid ${({ theme }) => theme.color.border.input};
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  max-height: 220px;
  overflow-y: auto;
  width: 100%;
  ::-webkit-scrollbar {
    background-color: ${({ theme }) => theme.color.bg.white};
    width: 16px;
  }
  ::-webkit-scrollbar-track {
    background-color: ${({ theme }) => theme.color.bg.white};
  }
  ::-webkit-scrollbar-track:hover {
    background-color: ${({ theme }) => theme.color.bg.gray};
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.color.bg.liteGray};
    border-radius: 16px;
    border: 5px solid ${({ theme }) => theme.color.border.white};
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: ${({ theme }) => theme.color.bg.darkGray};
    border: 4px solid ${({ theme }) => theme.color.bg.gray};
  }
  ::-webkit-scrollbar-button {
    display: none;
  }
`

const CheckboxListContent = styled.div`
  min-width: 238px;
  width: max-content;
`

const CheckboxListFooter = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.color.bg.white};
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border: 1px solid ${({ theme }) => theme.color.border.input};
  border-top: none;
  display: flex;
  height: 40px;
  justify-content: flex-end;
  padding-right: 16px;
  width: 100%;
`

const Checkmark = styled.span`
  align-items: center;
  background-color: ${({ checked, theme }) =>
    checked ? theme.color.primary.yellow5 : theme.color.bg.darkGray};
  border-radius: 5px;
  display: flex;
  height: 20px;
  left: 16px;
  position: absolute;
  width: 20px;
  :after {
    content: '';
    display: ${({ checked }) => (checked ? 'block' : 'none')};
    position: absolute;
    left: 7px;
    top: 2px;
    width: 5px;
    height: 10px;
    border: solid ${({ theme }) => theme.color.border.white};
    border-width: 0px 2px 2px 0;
    transform: rotate(45deg);
  }
`

const Dropdown = styled.div`
  align-items: center;
  background-color: ${({ anyChecked, theme, show }) =>
    show
      ? theme.color.bg.white
      : anyChecked
      ? theme.color.bg.lite
      : theme.color.border.norm};
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.color.border.input};
  color: ${({ theme }) => theme.color.text.dark};
  cursor: pointer;
  display: flex;
  flex: 1;
  font-size: 14px;
  font-weight: ${({ theme }) => theme.font.weight.regular};
  height: 32px;
  justify-content: space-between;
  letter-spacing: 0.44px;
  max-width: 160px;
  min-width: 160px;
  padding: 6px 8px 6px 8px;
  position: relative;
  transition: all 100ms ease 0s;
  width: 160px;
  :hover {
    border: 1px solid ${({ theme }) => theme.color.primary.yellow4};
  }
  @media screen and (max-width: 768px) {
    max-width: initial;
    min-width: initial;
    width: 100%;
  }
`

const DropdownTitle = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.color.primary.black3};
  display: flex;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0.25px;
  line-height: 17px;
`

const DropdownInfo = styled.span`
  align-items: center;
  background: ${({ theme }) => theme.color.primary.yellow5};
  border-radius: 50%;
  color: ${({ theme }) => theme.color.text.white};
  display: flex;
  font-size: 11px;
  font-weight: ${({ theme }) => theme.font.weight.regular};
  height: 18px;
  justify-content: center;
  letter-spacing: 0.2px;
  margin-left: 5px;
  padding-top: 1px;
  width: 18px;
`

const DropdownIcon = styled.div`
  display: inline-block;
  margin-bottom: 3px;
  padding: 3px;
  border: solid ${({ theme }) => theme.color.primary.black};
  border-width: 0 1px 1px 0;
  transform: rotate(45deg);
`

const Label = styled.span`
  color: ${({ theme }) => theme.color.primary.black5};
  font-size: 14px;
  font-weight: ${({ theme }) => theme.font.weight.regular};
  letter-spacing: 0.25px;
  line-height: 17px;
  margin-bottom: 8px;
  margin-left: 48px;
  margin-top: 8px;
`

const ClearButton = styled.div`
  color: ${({ theme }) => theme.color.text.link2};
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.25px;
  line-height: 17px;
`
export { DropdownCheckboxList, ClearButton, Checkbox }
