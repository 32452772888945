import React from 'react'

const github = (
  <svg
    width="20px"
    height="20px"
    viewBox="0 0 24 25"
    xmlns="http://www.w3.org/1999/xlink"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        transform="translate(-552.000000, -267.000000)"
        fill="#FFFFFF"
        fillRule="nonzero"
      >
        <g transform="translate(535.000000, 134.000000)">
          <g transform="translate(1.000000, 62.000000)">
            <g transform="translate(0.000000, 59.000000)">
              <g transform="translate(16.000000, 12.500000)">
                <path
                  d="M12,0 C5.40893555,0 0,5.40893555 0,12 C0,17.6244507 
                  3.94665525,22.7336426 9.1875,24 L9.1875,20.0299073 C8.66821289,20.1436157 
                  8.19030759,20.1469116 7.66168214,19.9910888 C6.95251463,19.7817993 
                  6.37628175,19.3093872 5.94854738,18.5890503 C5.67590334,18.1290893 5.192688,17.63031 
                  4.68859866,17.6669311 L4.56500245,16.2661743 C5.6550293,16.1727905 6.59802244,16.9302979 
                  7.15777589,17.8707276 C7.40643309,18.2889404 7.69262695,18.5341186 8.0599365,18.6425171 
                  C8.41497802,18.7470703 8.79620363,18.6968994 9.24041747,18.539978 C9.35192869,17.6506348 
                  9.75933839,17.317749 10.0671387,16.848999 L10.0671387,16.8482666 C6.94152834,16.3820801 
                  5.69586183,14.723877 5.20147706,13.4150391 C4.54632567,11.677185 4.89788817,9.50610352 
                  6.05639648,8.13409425 C6.07891847,8.10736083 6.11956786,8.03741456 6.10382081,7.98852539 
                  C5.57263181,6.38397216 6.21990966,5.05664063 6.24371339,4.91583253 C6.8567505,5.09710692 
                  6.95635988,4.73345948 8.90661619,5.91833494 L9.24371339,6.12084961 C9.38470458,6.20489503 
                  9.34039308,6.15692138 9.48138427,6.14630128 C10.2956543,5.92510988 11.1540527,5.80297852 
                  11.9998169,5.79199219 C12.8519897,5.80297852 13.704895,5.92510988 14.5528565,6.15527344 
                  L14.6619873,6.16625977 C14.6524658,6.16479492 14.6916504,6.15930178 14.7573853,6.12011719 
                  C17.1936035,4.6444702 17.1060791,5.12677003 17.7593994,4.91436769 C17.78302,5.05535888 
                  18.4216919,6.40429688 17.8961792,7.98852539 C17.8253174,8.20678711 20.0083008,10.2057495 
                  18.7983398,13.4144897 C18.3039551,14.723877 17.0584717,16.3820801 13.9328613,16.8482666 
                  L13.9328613,16.848999 C14.3334961,17.4596558 14.8148804,17.7844849 14.8123169,19.0442505 
                  L14.8123169,24 C20.0533448,22.7336426 23.9998169,17.6244507 23.9998169,12 
                  C23.9998169,5.40893555 18.5910645,0 12,0 Z"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default github
