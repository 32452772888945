import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import Project from 'containers/ProfilePage/components/Project'

const ProjectCarousel = ({ projects, isPublic }) => {
  const [direction, setDirection] = useState('')
  const [carouselWidth, setCarouselWidth] = useState(0)
  const [currentIndex, setCurrentIndex] = useState(0)
  const [capacity, setCapacity] = useState(0)
  const [items, setItems] = useState([])
  const refCarouselContainer = useRef(null)

  useEffect(() => {
    const _items = refCarouselContainer.current.querySelectorAll(
      'div[data-carousel=true]'
    )
    const _capacity = Math.floor(
      refCarouselContainer.current.offsetWidth / _items[0]?.offsetWidth
    )
    setCarouselWidth(refCarouselContainer.current.offsetWidth)
    setCurrentIndex(0)
    setItems(_items)
    setCapacity(_capacity)
    refCarouselContainer.current.scroll(0, 0)
  }, [projects])

  useEffect(() => {
    const handleResize = () => {
      if (
        carouselWidth !==
        (refCarouselContainer.current
          ? refCarouselContainer.current.offsetWidth
          : 0)
      ) {
        setCarouselWidth(refCarouselContainer.current.offsetWidth)
        setCurrentIndex(0)
        const _capacity = Math.floor(
          refCarouselContainer.current.offsetWidth / items[0]?.offsetWidth
        )
        setCapacity(_capacity)
        refCarouselContainer.current.scroll(0, 0)
      }
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [carouselWidth, items])

  const handleNext = () => {
    let nextIndex
    if (currentIndex === 0 || direction !== 'right') {
      nextIndex = currentIndex + capacity
    } else {
      nextIndex = currentIndex + 1
    }
    const nextItem = items[nextIndex]
    const leftPosition = nextItem.offsetWidth * nextIndex
    const offset = carouselWidth - nextItem.offsetWidth
    const finalPosition = leftPosition - offset - 10
    refCarouselContainer.current.scroll(finalPosition, 0)
    setCurrentIndex(nextIndex)
    setDirection('right')
  }

  const handlePrev = () => {
    let prevIndex
    if (direction !== 'left') {
      prevIndex = currentIndex - capacity
    } else {
      prevIndex = currentIndex - 1
    }
    prevIndex = prevIndex < 0 ? 0 : prevIndex
    const prevItem = items[prevIndex]
    refCarouselContainer.current.scroll(prevItem.offsetWidth * prevIndex, 0)
    setCurrentIndex(prevIndex)
    setDirection('left')
  }

  return (
    <Carousel>
      {capacity < projects.length && currentIndex < projects.length - 1 && (
        <NextButton onClick={handleNext}>
          <RightArrow />
        </NextButton>
      )}
      {currentIndex > 0 && (
        <PrevButton onClick={handlePrev}>
          <LeftArrow />
        </PrevButton>
      )}
      <CarouselProjectsContainer ref={refCarouselContainer}>
        {projects
          .sort((a, b) => a.order - b.order)
          .map(({ project }) => {
            return (
              ((isPublic && project.is_public) || !isPublic) && (
                <ProjectWrapper
                  data-carousel={true}
                  key={'carousel-item-' + project.id}
                >
                  <Project {...project} fromPage="profile" />
                </ProjectWrapper>
              )
            )
          })}
      </CarouselProjectsContainer>
    </Carousel>
  )
}

export default ProjectCarousel

const Carousel = styled.div`
  position: relative;
  width: 100%;
  @media screen and (max-width: 768px) {
    margin-top: 20px;
  }
`

const CarouselProjectsContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  overflow: hidden;
  position: relative;
  scroll-behavior: smooth;
  width: 100%;
`

const ProjectWrapper = styled.div`
  height: 227px;
  max-width: 368px;
  min-width: 368px;
  padding-right: 10px;
`

const DirectionButton = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.color.bg.white};
  border: 1px solid rgba(95, 93, 102, 0.5);
  border-radius: 100px;
  box-shadow: rgb(0 0 0 / 50%) 0px 0px 10px;
  cursor: pointer;
  display: flex;
  height: 32px;
  justify-content: center;
  margin-top: -24px;
  outline: none;
  position: absolute;
  top: 50%;
  width: 32px;
  z-index: 5;
  &:hover {
    transition: all 250ms ease-out 0s;
    box-shadow: rgb(0 0 0 / 80%) 0px 0px 14px;
  }
`

const NextButton = styled(DirectionButton)`
  right: 10px;
  padding-right: 4px;
`

const PrevButton = styled(DirectionButton)`
  left: 10px;
  padding-right: -4px;
`

const Arrow = styled.div`
  border: solid black;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 3px;
`

const RightArrow = styled(Arrow)`
  transform: rotate(-45deg);
`

const LeftArrow = styled(Arrow)`
  transform: rotate(135deg);
`
