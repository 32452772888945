import React from 'react'
import styled, { keyframes } from 'styled-components'
import C1LogoDark from 'components/graphics/C1LogoDark'
import { CheckIcon } from 'components/graphics/Icons'
import { FlexContainer } from 'components/styled/Containers'

const WelcomeAnimation = () => {
  return (
    <SubmissionAnimation>
      <C1ConnectLogoContainer>
        <C1LogoDark color="#FFFFFF" />
      </C1ConnectLogoContainer>
      <LoadingContainer>
        <LoadingWrapper direction="column" justify="center">
          <LoadingItem align="center">
            <CheckIcon />
            <LoadingText>BASIC INFORMATION...</LoadingText>
          </LoadingItem>
          <LoadingItem align="center" delay="1.4s">
            <CheckIcon />
            <LoadingText>GENERAL PREFERENCES...</LoadingText>
          </LoadingItem>
          <LoadingItem align="center" delay="2.4s">
            <CheckIcon />
            <LoadingText>EMPLOYER PREFERENCES...</LoadingText>
          </LoadingItem>
        </LoadingWrapper>
      </LoadingContainer>
    </SubmissionAnimation>
  )
}

export default WelcomeAnimation

const fadeInOut = keyframes`
  0% {
    opacity: 0;
  }
  5% {
    opacity: 1;
  }
  95% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`
const slideBottomToTop = keyframes`
  25%{bottom: -50px}
  50%{bottom: 10px}
  75%{bottom: 70px}
  100%{bottom: 140px}
`
const fadeInOut2 = keyframes`
  25%,80%{opacity: 1}
  10%{opacity:0.4}
  100%{opacity:0}
`

const fillBackground = keyframes`
  30%{
      fill:rgba(255,255,255,0);
  }
  100%{
      fill:rgba(255,255,255,1);
  }
`

const fillCheck = keyframes`
  30%{
      fill:rgba(255,255,255,1);
  }
  100%{
      fill:rgba(65,60,80,1);
  }
`
const C1ConnectLogoContainer = styled.div`
  height: 53px;
  left: 5vw;
  position: absolute;
  top: 30px;
  width: 190px;
  svg {
    path,
    circle,
    text {
      fill: ${({ theme }) => theme.color.text.white};
    }
    rect {
      stroke: ${({ theme }) => theme.color.text.white};
    }
  }
`

const SubmissionAnimation = styled.div`
  align-items: center;
  animation: ${fadeInOut} 4.5s;
  background: linear-gradient(
    to top right,
    #24222f 10%,
    #484257 65%,
    #504c5f 125%
  );
  bottom: 0px;
  display: flex;
  justify-content: center;
  left: 0;
  opacity: 1;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  transition: opacity 1s;
  z-index: ${({ theme }) => theme.order.welcomeAnimation};
`

const LoadingContainer = styled.div`
  height: 150px;
  overflow: hidden;
  position: relative;
  width: 280px;
`

const LoadingWrapper = styled(FlexContainer)`
  animation: ${slideBottomToTop} 5s;
  bottom: -90px;
  height: 100px;
  position: absolute;
`

const LoadingItem = styled(FlexContainer)`
  animation: ${fadeInOut2} 2s ${({ delay }) => delay};
  color: ${({ theme }) => theme.color.text.white};
  margin-bottom: 24px;
  opacity: 0;
  svg {
    circle {
      animation: ${fillBackground} 2s ${({ delay }) => delay};
    }
    polygon {
      animation: ${fillCheck} 2s ${({ delay }) => (delay ? delay : '0s')};
    }
  }
`

const LoadingText = styled.span`
  margin-left: 16px;
`
