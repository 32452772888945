import React from 'react'
import styled from 'styled-components'
import FormHelper from 'containers/ProfilePage/components/profileModal/FormHelper'
import FeaturedProjects from './FeaturedProjects'
import Resume from './Resume'
import { FlexContainer, Container } from 'components/styled/Containers'
import { ContentWrapper } from 'components/styled/Wrappers'
import useProfileContext from 'services/context/profileContext/useProfileContext'
const UserProfileLayout = ({ profileData }) => {
  const {
    profileState: { formData, formModal }
  } = useProfileContext()

  return (
    <>
      <ContentWrapper>
        <MainProfileContainer>
          <div className="row">
            <div className="col-lg-8 col-xs-12">
              <Resume profileData={profileData} />
            </div>
            <div className="col-lg-4 col-xs-12">
              <FeatureContainer width="100%">
                <FeaturedProjects profileData={profileData} />
              </FeatureContainer>
            </div>
          </div>
        </MainProfileContainer>
        {formModal && formData && <FormHelper />}
      </ContentWrapper>
    </>
  )
}

export default UserProfileLayout

const MainProfileContainer = styled(FlexContainer)`
  margin: 0 auto;
  .row {
    width: 100%;
  }
`

const FeatureContainer = styled(Container)`
  @media (max-width: 1200px) {
    margin-left: 0;
  }
`
