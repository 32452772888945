import { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router'
import { useAppContext } from 'services/modules/helpers'

const ConfirmEmailAddress = () => {
  const { api, requests } = useAppContext()
  const history = useHistory()
  const search = useLocation().search
  const token = new URLSearchParams(search).get('token')

  useEffect(() => {
    const fetchAccountActivationData = async (api, requests) => {
      await api.request(
        requests({ token: token.replace(/\/+$/, '') }).confirmEmailChange,
        {},
        ({ data }) => data,
        error => console.error(error)
      )

      history.push({
        pathname: '/settings/basic-profile',
        activatedAccount: true
      })
    }
    fetchAccountActivationData(api, requests)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return null
}

export default ConfirmEmailAddress
