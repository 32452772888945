import React, { useEffect } from 'react'
import { PropTypes } from 'prop-types'
import styled from 'styled-components'
import equal from 'fast-deep-equal'
import { components } from 'react-select'
import AsyncSelect from 'react-select/async'
import { Container } from 'components/styled/Containers'
import { ErrorMessage, InputLabel } from 'components/styled/Text'
import debounce from 'services/helpers/debounce'
import { useFormContext } from 'react-hook-form'
import { Controller } from 'react-hook-form'
import usePrevious from 'services/hooks/usePrevious'

const AutoComplete = ({
  debounceDelayMS = 250,
  defaultOptions = [],
  defaultValue = [],
  isMulti,
  label,
  name,
  placeholder,
  requestFunc,
  required,
  limitSelectedOptions,
  onChange = () => null
}) => {
  const form = useFormContext()
  const previousValue = usePrevious(defaultValue)
  const { control, errors } = form

  useEffect(() => {
    if (defaultValue && !equal(defaultValue, previousValue)) {
      control.setValue(name, defaultValue)
    }
  }, [control, name, defaultValue, previousValue])

  const components = {
    DropdownIndicator,
    Input,
    MultiValue
  }

  return (
    <InputContainer>
      {label && (
        <InputLabel className={required && 'required'}>{label}</InputLabel>
      )}
      <Controller
        defaultValue={defaultValue}
        control={control}
        name={name}
        render={({ onChange: onChangeHookForm, onBlur, ref }) => (
          <AsyncSelect
            cacheOptions
            isClearable={!isMulti}
            label={label}
            styles={customStyles}
            ref={ref}
            components={components}
            placeholder={placeholder}
            name={name}
            defaultOptions={defaultOptions}
            defaultValue={defaultValue}
            isMulti={isMulti}
            onBlur={onBlur}
            getOptionValue={option => option}
            onChange={optionValue => {
              onChangeHookForm(optionValue)
              onChange(optionValue)
            }}
            loadOptions={debounce(requestFunc, debounceDelayMS)}
            isOptionDisabled={() =>
              isMulti &&
              limitSelectedOptions &&
              control.getValues(name)?.length >= limitSelectedOptions
            }
          />
        )}
      />
      {form && errors[name] && (
        <ErrorMessage>{errors[name].message || ''}</ErrorMessage>
      )}
    </InputContainer>
  )
}

const DropdownIndicator = ({ ...props }) => (
  <components.DropdownIndicator {...props} isDisabled={true} />
)

const Input = ({ register, required, name, ...props }) => (
  <components.Input {...props} />
)

const MultiValue = ({ children, ...props }) => (
  <components.MultiValue {...props}>{children}</components.MultiValue>
)
const customStyles = {
  multiValue: provided => ({
    ...provided,
    backgroundColor: '#ffffff',
    border: '1px solid #d7d6d9',
    borderRadius: '4px',
    color: '#373440',
    display: 'flex',
    flexShrink: 0,
    listStyleType: 'none',
    margin: '2px',
    maxWidth: '100%',
    padding: '5px 16px',
    div: {
      fontSize: '16px',
      padding: 0
    }
  }),
  indicatorsContainer: provided => ({ ...provided, cursor: 'pointer' }),
  multiValueRemove: provided => ({
    ...provided,
    cursor: 'pointer',
    color: '#ffc043',
    left: '10px',
    position: 'relative',
    ':hover': {
      background: '#ffc043',
      color: '#ffffff'
    }
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  dropdownIndicator: () => ({
    display: 'none'
  }),
  menu: provided => ({
    ...provided,
    zIndex: 100
  }),
  control: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? 'transparent' : '#F2F2F2',
    border: state.isFocused ? '1px solid #E0A128' : '1px solid #E1E1E1',
    boxShadow: 'none',
    cursor: 'text',
    height: 'auto',
    minHeight: '40px',
    outline: 'none',
    '&:hover': {
      backgroundColor: 'transparent'
    }
  })
}

AutoComplete.propTypes = {
  debounceDelayMS: PropTypes.number,
  isMulti: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  requestFunc: PropTypes.func.isRequired,
  required: PropTypes.bool,
  limitSelectedOptions: PropTypes.number
}

export default AutoComplete

const InputContainer = styled(Container)`
  margin-bottom: 16px;
  text-align: left;
  span.prefix {
    bottom: 10px;
    color: #87858c;
    font-family: ${({ theme }) => theme.font.family};
    font-size: 18px;
    left: 12px;
    position: absolute;
    z-index: ${({ theme }) => theme.order.front};
  }
`
