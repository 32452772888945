import React from 'react'
import {
  desktopIcon,
  lightBulbIcon,
  dataGroupIcon
} from 'containers/HowToShowcasePage/assets/datafolioIcons'

export const downloadDatafolio = `https://storage.googleapis.com/connect-internal-assets/showcase/c1connect-datafolio_guidelines_v3.pdf`

export const whyMakeDatafolios = [
  {
    asset: desktopIcon,
    sub: 'Visual storytelling over text-heavy explanation'
  },
  {
    asset: lightBulbIcon,
    sub: 'Easy to quickly get the gist of your work'
  },
  {
    asset: dataGroupIcon,
    sub: 'Accessible to non-technical audiences'
  }
]

export const datafolioSteps = [
  {
    num: '1',
    head: 'Get acquainted',
    copy: (
      <>
        <p>
          We put together a small library of templates and guidelines to get you
          started. Be sure to also scroll down and browse through the gallery
          for inspiration!
        </p>
        <a
          href="https://docs.google.com/presentation/d/13Gj6Q1XqOKeN9suGobAVYfrTXWERKH7qJ7CLSY-TOMA/edit#slide=id.g8974eb5619_0_1"
          target="_blank"
          rel="noopener noreferrer"
        >
          Access templates
        </a>
      </>
    )
  },
  {
    num: '2',
    head: 'Plan it out',
    copy: (
      <p>
        Define the audience you’re designing for and the narrative you want to
        communicate. Outline the main points and experiment with different
        layouts.
      </p>
    )
  },
  {
    num: '3',
    head: 'Put it together',
    copy: (
      <p>
        Create graphics, flowcharts, and visualizations to compliment your
        narrative. Assemble the datafolio and get early feedback!
      </p>
    )
  }
]

export const datafolioArt = `https://storage.googleapis.com/connect-internal-assets/showcase/datafolio.png`
export const datafolioArtMobile = `https://storage.googleapis.com/connect-internal-assets/showcase/datafolio_mobile.png`
