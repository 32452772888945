import appActionTypes from './actionTypes'

const appDispatchActions = dispatch => {
  return {
    setAuthenticationStatus: payload => {
      dispatch({ type: appActionTypes.SET_AUTHENTICATION, payload })
    },
    setOnboardingStatus: payload => {
      dispatch({ type: appActionTypes.SET_ONBOARDING, payload })
    },
    setProfileData: payload => {
      dispatch({ type: appActionTypes.SET_PROFILE_DATA, payload })
    },
    setPublicProfileData: payload => {
      dispatch({ type: appActionTypes.SET_PUBLIC_PROFILE_DATA, payload })
    },
    setJobBoardSettingsData: payload => {
      dispatch({ type: appActionTypes.SET_JOB_BOARD_SETTINGS_DATA, payload })
    },
    setGeneralPreferencesData: payload => {
      dispatch({ type: appActionTypes.SET_GENERAL_PREFERENCES_DATA, payload })
    },
    setEmployerPreferencesData: payload => {
      dispatch({ type: appActionTypes.SET_EMPLOYER_PREFERENCES_DATA, payload })
    },
    updatedProfile: () => {
      dispatch({ type: appActionTypes.UPDATED_PROFILE })
    },
    setProfileStatus: payload => {
      dispatch({ type: appActionTypes.SET_PROFILE_STATUS, payload })
    },
    zoomIn: payload => {
      dispatch({ type: appActionTypes.ZOOM_IN, payload })
    },
    zoomOut: payload => {
      dispatch({ type: appActionTypes.ZOOM_OUT, payload })
    },
    resetAppState: () => {
      dispatch({ type: appActionTypes.RESET_STATE })
    }
  }
}

export default appDispatchActions
