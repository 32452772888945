import React from 'react'
import styled from 'styled-components'
import { ContentWrapper } from 'components/styled/Wrappers'
import { FlexContainer, Container } from 'components/styled/Containers'
import TitleH4 from 'components/styled/titles/TitleH4'
import {
  datafolioArt,
  datafolioArtMobile
} from 'containers/HowToShowcasePage/helpers/content'

const ShowcaseHero = () => {
  return (
    <ShowcaseHeroWrapper>
      <ContentWrapper>
        <ContentContainer>
          <ExtendTitleH4>
            Datafolios are a visual snapshot for your data project
          </ExtendTitleH4>
        </ContentContainer>
      </ContentWrapper>
      <Artwork>
        <DataFolio />
      </Artwork>
    </ShowcaseHeroWrapper>
  )
}

export default ShowcaseHero

const ShowcaseHeroWrapper = styled(Container)`
  position: relative;
  height: 900px;
  background: ${({ theme }) => theme.color.primary.black4};
  width: 100%;
  padding: 110px 16px 64px;
  justify-content: center;
  align-items: center;
  ${ContentWrapper} {
    z-index: ${({ theme }) => theme.order.front};
  }
  @media (max-width: ${({ theme }) => theme.media.mobileSm}) {
    height: 700px;
  }
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
`

const ExtendTitleH4 = styled(TitleH4)`
  text-align: center;
  color: ${({ theme }) => theme.color.bg.norm};
  @media (max-width: ${({ theme }) => theme.media.mobileSm}) {
    font-size: 40px;
    line-height: 48px;
  }
`

const Artwork = styled(FlexContainer)`
  width: 100%;
  height: 100%;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 0;
  @media (max-width: ${({ theme }) => theme.media.desktop}) {
    height: 90%;
  }
  @media (max-width: ${({ theme }) => theme.media.tablet}) {
    height: 80%;
  }
  @media (max-width: ${({ theme }) => theme.media.mobile}) {
    position: relative;
    left: unset;
    top: unset;
    padding: 64px 8px;
  }
`

const DataFolio = styled(Container)`
  height: 100%;
  background: url(${datafolioArt}) bottom center no-repeat;
  width: 100%;
  background-size: contain;
  @media (max-width: ${({ theme }) => theme.media.mobile}) {
    width: 100%;
    height: 100%;
    background: url(${datafolioArtMobile}) center center no-repeat;
    background-size: contain;
  }
`
