import React from 'react'

const dragIcon = (
  <svg
    width="10px"
    height="52px"
    viewBox="0 0 10 52"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Engineer-files"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="add-experience_project-associated"
        transform="translate(-18.000000, -674.000000)"
        fill="#AFAEB3"
      >
        <g id="Group-5" transform="translate(18.000000, 89.000000)">
          <g id="Group-30" transform="translate(0.000000, 548.000000)">
            <g id="Group-34" transform="translate(0.000000, 31.000000)">
              <g id="Group-35" transform="translate(0.000000, 6.000000)">
                <rect
                  id="Rectangle-Copy-9"
                  x="0"
                  y="12"
                  width="4"
                  height="4"
                  rx="2"
                ></rect>
                <rect
                  id="Rectangle-Copy-15"
                  x="6"
                  y="12"
                  width="4"
                  height="4"
                  rx="2"
                ></rect>
                <rect
                  id="Rectangle-Copy-11"
                  x="0"
                  y="18"
                  width="4"
                  height="4"
                  rx="2"
                ></rect>
                <rect
                  id="Rectangle-Copy-17"
                  x="0"
                  y="24"
                  width="4"
                  height="4"
                  rx="2"
                ></rect>
                <rect
                  id="Rectangle-Copy-19"
                  x="0"
                  y="30"
                  width="4"
                  height="4"
                  rx="2"
                ></rect>
                <rect
                  id="Rectangle-Copy-21"
                  x="0"
                  y="36"
                  width="4"
                  height="4"
                  rx="2"
                ></rect>
                <rect
                  id="Rectangle-Copy-16"
                  x="6"
                  y="18"
                  width="4"
                  height="4"
                  rx="2"
                ></rect>
                <rect
                  id="Rectangle-Copy-18"
                  x="6"
                  y="24"
                  width="4"
                  height="4"
                  rx="2"
                ></rect>
                <rect
                  id="Rectangle-Copy-20"
                  x="6"
                  y="30"
                  width="4"
                  height="4"
                  rx="2"
                ></rect>
                <rect
                  id="Rectangle-Copy-22"
                  x="6"
                  y="36"
                  width="4"
                  height="4"
                  rx="2"
                ></rect>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default dragIcon
