import React from 'react'

const thumbnailIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      d="M6.166 16.943l1.4 1.4-4.622 
    4.657h-2.944l6.166-6.057zm11.768-6.012c2.322-2.322 
    4.482-.457 6.066-1.931l-8-8c-1.474 1.584.142 3.494-2.18 5.817-3.016 
    3.016-4.861-.625-10.228 4.742l9.6 9.6c5.367-5.367 1.725-7.211 4.742-10.228z"
    />
  </svg>
)

export default thumbnailIcon
