import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { FormProvider, useForm } from 'react-hook-form'
import Input from 'components/forms/Input'
import InputSubmit from 'components/forms/InputSubmit'
import ExpandableSettingsContent from 'containers/SettingsPage/components/ExpandableSettingsContent'
import PopupNotification from 'components/general/NotificationPopup'
import { FlexContainer, Container } from 'components/styled/Containers'
import { InputLabel, SurveyTitle } from 'components/styled/Text'
import { settings } from 'containers/SettingsPage/messages/messages'
import useAppContext from 'services/context/appContext/useAppContext'
import useSettingsContext from 'services/context/settingsContext/useSettingsContext'
import warning from 'containers/SettingsPage/assets/warning'
import { useLocation } from 'react-router'

const BasicProfileSettings = () => {
  const { api, requests, state } = useAppContext()
  const { settingsDispatchActions } = useSettingsContext()
  const [isEmailActive, setIsEmailActive] = useState(true)
  const [showPopup, setShowPopup] = useState('')
  const [currentEmail, setCurrentEmail] = useState(
    state.profileData.target_email
  )
  const location = useLocation()

  const methods = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    shouldFocusError: true
  })

  const {
    register,
    handleSubmit,
    errors,
    formState,
    setError,
    getValues,
    clearErrors
  } = methods

  useEffect(() => {
    const fetchAccountActivationData = async (api, requests) => {
      await api.request(
        requests({ currentEmail }).getEmailActivationState,
        {},
        ({ data }) => setIsEmailActive(data.is_active),
        error => console.error(error)
      )
    }

    if (location.activatedAccount) {
      setShowPopup('Success! Your new email has been verified.')
    }
    fetchAccountActivationData(api, requests)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  useEffect(() => {
    if (
      state.profileData &&
      state.employerPreferencesData &&
      state.generalPreferencesData
    ) {
      const combinedData = {
        ...state.profileData,
        ...state.employerPreferencesData,
        ...state.generalPreferencesData
      }
      methods.reset(combinedData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    state.profileData,
    state.employerPreferencesData,
    state.generalPreferencesData
  ])

  const onEmailSubmit = () => {
    const values = getValues()
    const [emailValue, confirmEmailValue] = Object.values(values)

    if (emailValue !== confirmEmailValue) {
      setError('change-email-confirm', {
        type: 'manual',
        message: 'Emails must match'
      })
    } else if (emailValue === currentEmail) {
      setError('change-email-confirm', {
        type: 'manual',
        message:
          'You are currently using this account or it is pending to be activated'
      })
    } else {
      settingsDispatchActions.setFormExpanded(null)
      api.request(
        requests().changeEmail,
        { email: emailValue || currentEmail },
        () => {
          setIsEmailActive(false)
          setCurrentEmail(emailValue || currentEmail)
          setShowPopup('Confirmation email sent.')
        },
        error => console.error(error)
      )
    }
  }

  if (
    !state.profileData ||
    !state.generalPreferencesData ||
    !state.employerPreferencesData
  ) {
    return <div>loading...</div>
  }

  const renderEmailPreviewComponent = () => (
    <FlexContainer direction="column">
      <FlexContainer align="center">
        <EmailLabel>{currentEmail}</EmailLabel>
        {!isEmailActive && (
          <Tooltip>
            {warning}
            <span>Not verified</span>
          </Tooltip>
        )}
      </FlexContainer>

      {!isEmailActive && (
        <InformationBox>
          Look for a confirmation email in your inbox to verify your new email.
          Didn’t see it?
          <button onClick={onEmailSubmit}>Click here to resend</button>.
        </InformationBox>
      )}
    </FlexContainer>
  )

  const renderPopup = () => {
    return (
      showPopup && (
        <PopupNotification
          content={showPopup}
          cleanHandler={() => setShowPopup('')}
        />
      )
    )
  }

  // Destructure questionnaire data
  const { question1 } = settings.basicInfo
  const [newEmailInput, confirmNewEmailInput] = question1.inputs

  return (
    <Container>
      {renderPopup()}
      <SurveyTitle>Profile Settings</SurveyTitle>
      <ExpandableSettingsContent
        header={'Email'}
        displayValue={renderEmailPreviewComponent()}
        content="email"
      >
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onEmailSubmit)}>
            <BetweenContainer>
              <FlexContainer direction="column">
                <FullWidthContainer key={newEmailInput.questionName}>
                  <CustomInputLabel htmlFor={newEmailInput.questionName}>
                    {newEmailInput.label}
                  </CustomInputLabel>
                  <Input
                    onChange={() =>
                      clearErrors(confirmNewEmailInput.questionName)
                    }
                    type={newEmailInput.type}
                    name={newEmailInput.questionName}
                    register={register}
                    errors={errors}
                    value={currentEmail}
                    required="required"
                  />
                </FullWidthContainer>
                <FullWidthContainer key={confirmNewEmailInput.questionName}>
                  <CustomInputLabel htmlFor={confirmNewEmailInput.questionName}>
                    {confirmNewEmailInput.label}
                  </CustomInputLabel>
                  <Input
                    onChange={() =>
                      clearErrors(confirmNewEmailInput.questionName)
                    }
                    type={confirmNewEmailInput.type}
                    name={confirmNewEmailInput.questionName}
                    register={register}
                    errors={errors}
                    value={currentEmail}
                    required="required"
                  />
                </FullWidthContainer>
              </FlexContainer>
            </BetweenContainer>
            <FitContentContainer>
              <InputSubmit
                background="#FFFFFF"
                color="#373440"
                border="#363540"
                testId="input-submit"
                text="Save"
                formState={formState}
              />
            </FitContentContainer>
          </form>
        </FormProvider>
      </ExpandableSettingsContent>
    </Container>
  )
}

export default BasicProfileSettings

const FullWidthContainer = styled(Container)`
  width: 100%;
`

const FitContentContainer = styled(Container)`
  width: fit-content;
`

const BetweenContainer = styled(FlexContainer)`
  justify-content: space-between;
`

const CustomInputLabel = styled(InputLabel)`
  color: #5f5d66;
`

const EmailLabel = styled.label`
  color: ${({ theme }) => theme.color.system.blue5};
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.5px;
  text-decoration: none;
  text-transform: none;
`
const Tooltip = styled.div`
  cursor: default;
  display: flex;
  margin-left: 4px;
  position: relative;
  span {
    align-items: center;
    background: ${({ theme }) => theme.color.bg.black};
    border-radius: 4px;
    border: 1px solid ${({ theme }) => theme.color.border.veryLiteGray};
    bottom: 100%;
    color: ${({ theme }) => theme.color.text.lite};
    display: flex;
    font-size: 12px;
    justify-content: center;
    left: 50%;
    min-width: 90px;
    letter-spacing: 0.18px;
    margin-bottom: 5px;
    padding: 6px 10px;
    position: absolute;
    transform: translateX(-50%);
    visibility: hidden;
    z-index: 100;
  }
  &:hover span {
    visibility: visible;
  }
`

const InformationBox = styled.div`
  background: rgba(121, 160, 216, 0.1);
  border: 1px solid ${({ theme }) => theme.color.system.blue3};
  border-radius: 4px;
  color: #5f5d66;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.1px;
  line-height: 18px;
  margin-top: 14px;
  padding: 16px;
  text-transform: none;
  button {
    background: 0;
    border: 0;
    color: ${({ theme }) => theme.color.system.blue5};
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.1px;
    line-height: 18px;
    margin: 0;
    padding: 0;
    text-decoration: underline;
    text-transform: none;
  }
`
