import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useFormContext } from 'react-hook-form'
import { ErrorMessage, InputLabel, InputCaption } from 'components/styled/Text'
import { Container } from 'components/styled/Containers'

const Input = ({
  type,
  placeholder,
  autoComplete,
  name,
  value,
  disabled,
  testId,
  className,
  label,
  subText,
  autoFocus,
  required,
  prefix,
  onChange,
  step
}) => {
  const form = useFormContext()

  return (
    <InputContainer>
      {label && <InputLabel className={required}>{label}</InputLabel>}
      {subText && <InputCaption>{subText}</InputCaption>}
      <Container>
        {prefix && <span className="prefix">$</span>}
        <InputStyled
          className={className}
          data-testid={testId}
          type={type}
          name={name}
          isDisabled={disabled}
          placeholder={placeholder}
          autoComplete={autoComplete}
          ref={form && form.register}
          errors={form && form.errors[name]}
          defaultValue={value}
          autoFocus={autoFocus}
          onChange={onChange}
          step={step}
        />
      </Container>
      {form && form.errors[name] && (
        <ErrorMessage className={className}>
          {form.errors[name].message || ''}
        </ErrorMessage>
      )}
    </InputContainer>
  )
}

// -- PROP DESCRIPTIONS -- //
// type: input type (text, password, etc...)
// placeholder: placeholder text
// name: name associated with react-hook-form
// testId: testId used for testing with react-testing-library
// label: label above input
// value: set a default value
// className: add a classname
// subtext: adding small subtext under the label
// disabled: sets input unmanipulatable by user

Input.propTypes = {
  type: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  testId: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.any,
  className: PropTypes.string,
  subtext: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  step: PropTypes.string
}

Input.defaultProps = {
  type: 'text',
  placeholder: 'Placeholder',
  name: 'input',
  disabled: false
}

export default Input

const InputContainer = styled(Container)`
  margin-bottom: 16px;
  text-align: left;
  span.prefix {
    position: absolute;
    z-index: ${({ theme }) => theme.order.front};
    bottom: 10px;
    left: 12px;
    color: #87858c;
    font-family: ${({ theme }) => theme.font.family};
    font-size: 18px;
  }
`

export const InputStyled = styled.input`
  width: 100%;
  outline: none;
  height: 40px;
  border-radius: 4px;
  margin: 8px 0 0;
  border: 1px solid
    ${({ theme, errors }) =>
      errors ? theme.color.border.error : theme.color.border.input};
  padding: 12px 14px;
  color: ${({ theme }) => theme.color.primary.black5};
  appearance: none;
  /* stylelint-disable-next-line */
  -moz-appearance: textfield;
  background: ${({ theme }) => theme.color.bg.darkNorm};
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'text')};
  opacity: ${({ isDisabled }) => (isDisabled ? 0.4 : 1)};
  pointer-events: ${({ isDisabled }) => isDisabled && 'none'};
  &:hover {
    background: transparent;
  }
  &:focus {
    background: transparent;
    border-color: ${({ theme }) => theme.color.border.active};
  }
  &::placeholder {
    color: ${({ theme }) => theme.color.primary.black3};
  }
  &.hidden {
    display: none;
  }
  &.currency {
    padding-left: 32px;
    position: relative;
    width: 200px;
  }
`
