import React from 'react'
import styled from 'styled-components'
import { Route, Switch, useLocation } from 'react-router-dom'
import SettingsToggle from 'containers/SettingsPage/components/SettingsToggle'
import BasicProfileSettings from 'containers/SettingsPage/components/BasicProfileSettings'
import GeneralPreferenceSettings from 'containers/SettingsPage/components/GeneralPreferenceSettings'
import EmployerPreferenceSettings from 'containers/SettingsPage/components/EmployerPreferenceSettings'
import SettingsPreferenceInfo from 'containers/SettingsPage/components/SettingsPreferenceInfo'
import { PageWrapper } from 'components/styled/Wrappers'
import { Container, FlexContainer } from 'components/styled/Containers'

const SettingsPage = () => {
  const { pathname } = useLocation()
  const isPreferencesPage = pathname.split('-').pop() === 'preferences'

  return (
    <SettingsPageWrapper>
      <MaxWidthContainer>
        {renderToggleAndPreferences()}
        <FlexContainer>
          <ToggleContainer>
            <SettingsToggle />
          </ToggleContainer>
          <ResponsiveContainer>
            <Switch>
              <Route
                exact
                path="/settings/basic-profile"
                component={BasicProfileSettings}
              />
              <Route
                exact
                path="/settings/general-preferences"
                component={GeneralPreferenceSettings}
              />
              <Route
                exact
                path="/settings/employer-preferences"
                component={EmployerPreferenceSettings}
              />
            </Switch>
          </ResponsiveContainer>
          {isPreferencesPage && (
            <PreferenceInfoContainer>
              <SettingsPreferenceInfo />
            </PreferenceInfoContainer>
          )}
        </FlexContainer>
      </MaxWidthContainer>
    </SettingsPageWrapper>
  )
}

export default SettingsPage

const renderToggleAndPreferences = () => {
  return (
    <FlexBetween>
      <SettingsToggle />
      <SettingsPreferenceInfo />
    </FlexBetween>
  )
}

const SettingsPageWrapper = styled(PageWrapper)`
  padding-left: 20px;
  padding-right: 20px;
  @media (max-width: 768px) {
    padding-top: 104px;
  }
`

const ResponsiveContainer = styled(Container)`
  margin: 0 40px;
  width: 90%;
  max-width: 600px;
  @media (max-width: ${({ theme }) => theme.media.tablet}) {
    max-width: none;
    width: 100%;
    margin: 0;
  }
`

const MaxWidthContainer = styled.div`
  margin: 0 auto;
  max-width: 1140px;
`

const ToggleContainer = styled.div`
  min-width: 200px;
  @media (max-width: ${({ theme }) => theme.media.tablet}) {
    display: none;
  }
`

const PreferenceInfoContainer = styled.div`
  min-width: 300px;
  @media (max-width: ${({ theme }) => theme.media.tablet}) {
    display: none;
  }
`

const FlexBetween = styled(FlexContainer)`
  display: none;
  @media (max-width: ${({ theme }) => theme.media.tablet}) {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
`
