import * as yup from 'yup'

export const projectValidations = yup.object().shape({
  title: yup
    .string()
    .strict(false)
    .trim()
    .required('Required')
    .max(80, 'Project title cannot be more than 80 characters'),
  summary: yup
    .string()
    .strict(false)
    .trim(),
  link: yup
    .string()
    .strict(false)
    .trim()
    .test(
      'Valid URL',
      "URL must begin with 'https://' or 'http:// and  should be valid.",
      value => {
        var expression = new RegExp(
          '^(https?:\\/\\/)' + // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$',
          'i'
        )
        const regex = new RegExp(expression)
        return value.match(regex) || value === ''
      }
    ),
  description: yup
    .string()
    .strict(false)
    .trim()
    .required('Required')
})
