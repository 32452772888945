import React from 'react'

const lightningIcon = (
  <svg
    width="11px"
    height="15px"
    viewBox="0 0 11 15"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-2.000000, 0.000000)">
        <rect x="0" y="0" width="15" height="15"></rect>
        <path
          d="M5.58705528,15 C5.47318088,14.9977379 5.3610924,14.9709312 5.25826764,14.9213681 C4.97245076,14.7714727 4.81893881,14.4484929 4.88165635,14.1290008 L5.71857034,9.8949771 L2.46656169,8.66106161 C2.24085812,8.57734173 2.07220986,8.38394818 2.01821491,8.14693016 C1.96504464,7.91168533 2.0298993,7.66504044 2.19157566,7.48763218 L8.88688759,0.229305779 C9.10797278,-0.00581304736 9.45492435,-0.0671857541 9.74173545,0.0780906458 C10.0251007,0.227299645 10.1781819,0.546914535 10.1183467,0.864409339 L9.28143275,5.09843307 L12.5334414,6.33234856 C12.759145,6.41606844 12.9277932,6.60946199 12.9817882,6.84648001 C13.034924,7.08360084 12.9701937,7.33203135 12.8084274,7.5118266 L6.11311551,14.770153 C5.97609839,14.9161798 5.78605701,14.9992129 5.58705528,15 Z M3.97300687,7.68118755 L6.79460261,8.75179069 C7.12189011,8.87972057 7.31276301,9.22531923 7.24892735,9.57440102 L6.78862465,11.9091627 L11.0269962,7.31222262 L8.20540048,6.24161948 C7.87643178,6.11565587 7.68470395,5.76850926 7.75107574,5.41900915 L8.21137844,3.0842475 L3.97300687,7.68118755 Z"
          fill="#2A2A3B"
          fillRule="nonzero"
        ></path>
      </g>
    </g>
  </svg>
)

export default lightningIcon
