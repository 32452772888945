import React from 'react'
import styled from 'styled-components'
import { PageWrapper } from 'components/styled/Wrappers'
import ShowcaseHero from './components/ShowcaseHero'
import DatafolioSetup from './components/DatafolioSetup'
import DatafolioInspiration from './components/DatafolioInspiration'
import FooterCTA from 'components/general/FooterCTA'
import ImageZoomModal from 'components/general/ImageZoomModal'
import useAppContext from 'services/context/appContext/useAppContext'
import useScrollToTop from 'services/hooks/useScrollToTop'

const HowToShowcasePage = () => {
  useScrollToTop()
  const { state } = useAppContext()
  const { zoomIn, zoomImgSrc, isAuthenticated } = state

  return (
    <ShowcasePageWrapper>
      {zoomIn && <ImageZoomModal imgSrc={zoomImgSrc} />}
      <ShowcaseHero />
      <DatafolioSetup />
      <DatafolioInspiration />
      {!isAuthenticated && (
        <FooterCTA bgColor="#f3ebd5" className="faded"></FooterCTA>
      )}
    </ShowcasePageWrapper>
  )
}

export default HowToShowcasePage

const ShowcasePageWrapper = styled(PageWrapper)`
  background: ${({ theme }) => theme.color.bg.gray};
  padding: 0;
  @media screen and (max-width: ${({ theme }) => theme.media.tablet}) {
    padding: 0;
  }
`
