import React, { useState } from 'react'
import moment from 'moment'
import styled from 'styled-components'
import useProfileContext from 'services/context/profileContext/useProfileContext'
import useIsPublic from 'services/hooks/useIsPublic'
import certificateIcon from '../assets/certificateIcon'
import editIcon from '../assets/editIcon'
import addIcon from '../assets/addIcon'
import hookIcon from '../assets/hookIcon'

const Certificate = ({ certificate }) => {
  const { profileDispatchActions } = useProfileContext()
  const { isAuthenticated, isPublic } = useIsPublic()
  const [showEditButton, setShowEditButton] = useState(false)
  const [certificateId, setCertificateId] = useState(-1)

  const handleSetUpdateForm = certificate => {
    profileDispatchActions.setUpdateForm(certificate, 'certificates')
  }

  return (
    <ProfileCardItem
      onMouseOver={() => {
        setShowEditButton(true)
        setCertificateId(certificate.id)
      }}
      onMouseLeave={() => {
        setShowEditButton(false)
        setCertificateId(-1)
      }}
    >
      <ProfileCardItemIcon>{certificateIcon}</ProfileCardItemIcon>
      <ProfileCardItemContent>
        <Name>
          {certificate.name}
          {certificate.credential_url && (
            <a href={certificate.credential_url} target="blank">
              {hookIcon}
            </a>
          )}
        </Name>
        <IssuingOrganization>{certificate.issuing_org}</IssuingOrganization>
        <IssueDate>
          {`Issued ${moment(certificate.issue_date).format('MMM YYYY')} ${
            certificate.expiration_date === null
              ? ` - No Expiration Date`
              : ` - ${moment(certificate.expiration_date).format('MMM YYYY')}`
          } `}
        </IssueDate>
      </ProfileCardItemContent>
      {isAuthenticated &&
        !isPublic &&
        showEditButton &&
        certificateId === certificate.id && (
          <EditButton onClick={() => handleSetUpdateForm(certificate)}>
            {editIcon}
          </EditButton>
        )}
    </ProfileCardItem>
  )
}

const Certificates = ({ certificates }) => {
  const { profileDispatchActions } = useProfileContext()
  const { isAuthenticated, isPublic } = useIsPublic()

  const handleSetCreateForm = () => {
    profileDispatchActions.setCreateForm('certificates')
  }

  if (isPublic && !certificates.length) return null

  const renderCerticates = () => (
    <ProfileCardBody>
      {certificates.map(certificate => {
        return (
          <Certificate
            key={`certificate-${certificate.id}`}
            certificate={certificate}
          />
        )
      })}
    </ProfileCardBody>
  )

  return (
    <ProfileCard>
      <ProfileCardHeader>
        <h6>Certifications</h6>
        {isAuthenticated && !isPublic && (
          <AddButton onClick={() => handleSetCreateForm()}>{addIcon}</AddButton>
        )}
      </ProfileCardHeader>
      {certificates.length ? renderCerticates() : null}
    </ProfileCard>
  )
}

export default Certificates

const ProfileCard = styled.div`
  background: ${({ theme }) => theme.color.bg.white};
  border: 1px solid ${({ theme }) => theme.color.border.norm};
  border-radius: 4px;
  display: block;
  height: auto;
  margin: 0 0 16px;
  overflow: hidden;
  padding: 18px 32px;
  width: ${({ width }) => width || 'calc(100% - 32px)'};
  @media screen and (max-width: 1200px) {
    width: 100%;
  }
  @media screen and (max-width: 568px) {
    padding: 16px;
  }
`

const ProfileCardHeader = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`

const ProfileCardBody = styled.div`
  margin-top: 25px;
`

const ProfileCardItem = styled.div`
  display: flex;
  margin-bottom: 16px;
  position: relative;
  &:last-child {
    margin-bottom: 0;
  }
  &:last-of-type {
    div:nth-child(2) {
      border: none;
    }
  }
`

const ProfileCardItemIcon = styled.div`
  margin-right: 22px;
`

const ProfileCardItemContent = styled.div`
  border-bottom: 1px solid #f2f2f2;
  padding-bottom: 16px;
  width: 100%;
`

const Name = styled.p`
  align-items: center;
  color: rgb(44, 44, 44);
  display: flex;
  font-size: 16px;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  letter-spacing: 0.5px;
  line-height: 20px;
  margin-bottom: 3px;
  width: 100%;
  a {
    margin-left: 5px;
  }
`

const IssuingOrganization = styled.p`
  color: rgb(135, 133, 140);
  font-size: 14px;
  font-weight: ${({ theme }) => theme.font.weight.regular};
  letter-spacing: 0.25px;
  line-height: 17px;
`

const IssueDate = styled(IssuingOrganization)``

const ActionButton = styled.button`
  align-items: center;
  background: none;
  border: none;
  border-radius: 100%;
  cursor: pointer;
  display: flex;
  height: 32px;
  justify-content: center;
  padding: 7px;
  transition: all 300ms ease-out 0s;
  width: 32px;
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
`

const AddButton = styled(ActionButton)``

const EditButton = styled(ActionButton)`
  position: absolute;
  right: 0px;
  top: 0px;
`
