import React, { useState, useCallback } from 'react'
import styled from 'styled-components'
import Dropzone from 'react-dropzone'
import { FormProvider, useForm } from 'react-hook-form'
import InputSubmit from 'components/forms/InputSubmit'
import {
  SurveyTitle,
  InputLabel,
  InputCaption,
  ErrorMessage
} from 'components/styled/Text'
import useAppContext from 'services/context/appContext/useAppContext'
import uploadResume from 'components/forms/assets/uploadResume'
import file from 'components/forms/assets/file'

const ResumeUpload = () => {
  const [currentResumeFile, setCurrentResumeFile] = useState(null)
  const [isFileRejected, setIsFileRejected] = useState(false)

  const methods = useForm({
    mode: 'onSubmit',
    shouldFocusError: true
  })
  const { state, api, requests, appDispatchActions, history } = useAppContext()
  const { handleSubmit, formState } = methods

  // On submission failure
  const onFailure = error => {
    console.log(error)
  }

  // On submission success
  const onSuccess = response => {
    if (currentResumeFile && !isFileRejected) {
      appDispatchActions.setProfileData({ profile: response.data })
      localStorage.removeItem('slug')
      appDispatchActions.setOnboardingStatus({ status: false })
      appDispatchActions.updatedProfile()
      history.push(`/profile/${state.profileData.slug}`)
    }
  }

  // Submit basic info form
  const onSubmit = async data => {
    const payload = { ...data }

    if (currentResumeFile) {
      payload.resume = currentResumeFile
    }

    await api.request(
      requests().patchProfile,
      payload,
      response => {
        appDispatchActions.updatedProfile()
        onSuccess(response)
      },
      error => onFailure(error)
    )
  }

  const onDrop = useCallback(
    acceptedFiles => {
      setIsFileRejected(false)
      let formData = new FormData()

      formData.append('file_path', acceptedFiles[0])
      formData.append('convert_pdfs', false)

      api.request(requests().uploadAsset, formData, response => {
        setCurrentResumeFile({
          id: response.data[0].id,
          name: acceptedFiles[0].name
        })
      })
    },
    [api, requests]
  )

  const rejectFile = () => {
    setIsFileRejected(true)
  }

  const clearResumeFile = () => {
    setIsFileRejected(false)
    setCurrentResumeFile(null)
  }

  const renderCurrentResume = () => {
    return (
      <CurrentResumePreview>
        <CurrentResumeName>
          {file}
          {currentResumeFile.name}
        </CurrentResumeName>
        <RemoveButton onClick={clearResumeFile}>Remove</RemoveButton>
      </CurrentResumePreview>
    )
  }

  const renderResumeUploader = () => {
    return (
      <Dropzone
        onDropAccepted={acceptedFiles => onDrop(acceptedFiles)}
        accept="application/pdf"
        maxSize={33554432}
        onDropRejected={file => rejectFile(file[0])}
      >
        {({ getRootProps, getInputProps, isDragActive }) => (
          <CustomSection isDragActive={isDragActive}>
            <DragContainer {...getRootProps()}>
              <input {...getInputProps()} />
              {uploadResume}
              <ChooseFileButton>Choose file</ChooseFileButton>
              <p>Or drag and drop your resume</p>
            </DragContainer>
          </CustomSection>
        )}
      </Dropzone>
    )
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <SurveyTitle>Resume Upload</SurveyTitle>
        <InputLabel className="required">Resume</InputLabel>
        <InputCaption>Files accepted: .pdf - Max file size: 32MB</InputCaption>
        {currentResumeFile ? renderCurrentResume() : renderResumeUploader()}
        {isFileRejected && (
          <ErrorMessage>Please upload a PDF smaller than 32 MB</ErrorMessage>
        )}
        <SubmitBtnContainer>
          <InputSubmit
            background="#FFFFFF"
            color="#373440"
            width="100px"
            border="#363540"
            testId="input-submit"
            text="Save"
            formState={formState}
            disabled={isFileRejected}
          />
          <EditMessageText>
            These preferences may be edited later.
          </EditMessageText>
        </SubmitBtnContainer>
      </form>
    </FormProvider>
  )
}

export default ResumeUpload

const SubmitBtnContainer = styled.div`
  align-items: center;
  display: flex;
  margin: 32px 0 0;
`

const EditMessageText = styled.p`
  color: #3b3b3b;
  font-size: 14px;
  font-weight: 500;
  margin: 0 0 0 16px;
`

const RemoveButton = styled.div`
  align-items: center;
  background: #ffffff;
  border: 1px solid #373440;
  border-radius: 2px;
  color: #373440;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  height: 24px;
  justify-content: center;
  outline: none;
  transition: all 300ms ease-out;
  width: 84px;
  &:hover {
    background: ${({ theme }) => theme.color.primary.black4};
    border-color: ${({ theme }) => theme.color.primary.black4};
    color: ${({ theme }) => theme.color.text.white};
  }
`

const CurrentResumePreview = styled.div`
  align-items: center;
  border-bottom: 1px solid #e1e1e1;
  border-top: 1px solid #e1e1e1;
  display: flex;
  justify-content: space-between;
  padding: 16px 0;
`

const CurrentResumeName = styled.div`
  display: flex;
  svg {
    margin: 0 8px 0 0;
  }
`

const CustomSection = styled.div`
  background: #f2f2f2;
  border: 2px dashed
    ${({ isDragActive }) => (isDragActive ? '#dfa228' : '#afaeb3')};
  border-radius: 8px;
  height: 280px;
  margin-bottom: 16px;
  outline: none;
  overflow: hidden;
  padding: 28px 0 14px;
  .image-container {
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.color.primary.black3};
    overflow: hidden;
    img {
      width: 100%;
      height: auto;
    }
  }
  svg {
    filter: grayscale(1) opacity(0.4);
  }
  &:hover {
    svg {
      filter: none;
    }
    background: none;
    border-color: #ffc043;
    .main {
      opacity: 1;
    }
    .mask {
      &-1,
      &-3,
      &-4 {
        fill: #ffc043;
      }
    }
  }
`

const ChooseFileButton = styled.div`
  align-items: center;
  background: #383440;
  border-radius: 4px;
  color: white;
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin: 28px 0 10px;
  outline: none;
  padding: 5px 20px 3px;
`

const DragContainer = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  outline: none;
`
