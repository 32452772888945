import React from 'react'
import styled from 'styled-components'
import ExpandableContent from 'components/general/ContentContainers'
import Segment from 'components/styled/Segments'
import Tag from 'components/styled/Tags'
import { FlexContainer } from 'components/styled/Containers'
import { Card } from 'components/styled/Cards'
import useProfileContext from 'services/context/profileContext/useProfileContext'
import useIsPublic from 'services/hooks/useIsPublic'
import starFilledIcon from '../assets/starFilledIcon'

// TODO: Organize Skills, Either Add/Remove (No Edit)

const Skills = ({ skills }) => {
  const { profileDispatchActions } = useProfileContext()
  const { isPublic } = useIsPublic()

  // Render create form
  const handleSetUpdateForm = () => {
    profileDispatchActions.setUpdateForm(skills, 'skills')
  }

  if (isPublic && !skills.length) return null

  return (
    <Segment>
      <ExpandableContent
        header="skills"
        addFormAction={handleSetUpdateForm}
        actionType={skills.length ? 'edit' : 'add'}
        hasContent={skills.length > 0}
      >
        <Card>
          {skills.filter(skill => skill.is_featured === true).length > 0 ? (
            <>
              <FlexContainer wrap="wrap">
                <Title topSkill={true}>Top skills selected</Title>
                {skills
                  .filter(skill => skill.is_featured === true)
                  .map((skill, index) => {
                    return (
                      <Skill key={`skill-${skill.id || index}`}>
                        {starFilledIcon} {skill.name}
                      </Skill>
                    )
                  })}
              </FlexContainer>
            </>
          ) : null}

          <FlexContainer wrap="wrap">
            <Title
              topSkill={
                !skills.filter(skill => skill.is_featured === true).length
              }
            >
              Additional skills, languages, frameworks and libraries added
            </Title>
            {skills
              .filter(skill => skill.is_featured === false)
              .map((skill, index) => {
                return (
                  <Skill key={`skill-${skill.id || index}`}>{skill.name}</Skill>
                )
              })}
          </FlexContainer>
        </Card>
      </ExpandableContent>
    </Segment>
  )
}

export default Skills

const Title = styled.div`
  color: rgb(55, 52, 64);
  font-size: 14px;
  letter-spacing: 0.25px;
  line-height: 17px;
  margin-bottom: 11px;
  margin-top: ${({ topSkill }) => (topSkill ? '0px' : '24px')};
  width: 100%;
`

const Skill = styled(Tag)`
  svg {
    display: inline;
    margin-right: 4px;
    margin-top: -1px;
    vertical-align: middle;
  }
`
