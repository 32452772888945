import React, { useEffect } from 'react'
import styled from 'styled-components'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import AutoComplete from 'components/forms/AutoComplete'
import Checkboxes from 'components/forms/Checkboxes'
import InputSubmit from 'components/forms/InputSubmit'
import Radios from 'components/forms/Radios'
import ExpandableSettingsContent from 'containers/SettingsPage/components/ExpandableSettingsContent'
import { Container, Divider } from 'components/styled/Containers'
import { SurveyTitle } from 'components/styled/Text'
import { settings } from 'containers/SettingsPage/messages/messages'
import useAppContext from 'services/context/appContext/useAppContext'
import useSettingsContext from 'services/context/settingsContext/useSettingsContext'
import { dataSettingsValidation } from 'services/validations/settingsValidations'
import {
  flattenArraysInData,
  getFormattedArrayFormData,
  importanceDisplayTranslation
} from 'services/helpers/formatters'
import filterLocationResults from 'containers/QuestionnairePage/helpers/filterLocationResults'
import useEmployerPreferences from 'services/hooks/useEmployerPreferences'
import useFetchLocations from 'containers/QuestionnairePage/hooks/useFetchLocations'

const EmployerPreferenceSettings = () => {
  const methods = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(dataSettingsValidation),
    shouldFocusError: true
  })

  const {
    question1,
    question1b,
    question2,
    question2b,
    question3,
    question3b,
    question4,
    question4b
  } = settings.employerPrefs

  const { api, requests, state, appDispatchActions } = useAppContext()
  const { settingsDispatchActions } = useSettingsContext()
  const { handleSubmit, formState } = methods
  const fetchLocations = useFetchLocations({
    excludeValues: {
      getUsedValues: methods.getValues,
      inputName: 'geo_locations'
    }
  })
  const { industryList, professionList } = useEmployerPreferences()

  useEffect(() => {
    if (state.employerPreferencesData) {
      const data = { ...state.employerPreferencesData }
      methods.reset(data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.employerPreferencesData])

  if (!state.employerPreferencesData) {
    return <div>loading...</div>
  }

  const {
    location_importance,
    role_importance,
    industry_importance,
    size_importance,
    geo_locations
  } = state.employerPreferencesData

  const industriesDisplay = state.employerPreferencesData.industries.join(', ')
  const rolesDisplay = state.employerPreferencesData.profession_types.join(', ')

  const sizesFormattedData = getFormattedArrayFormData(
    'size',
    state.employerPreferencesData
  )

  const onFailure = error => {
    console.log(error)
  }

  const onSuccess = response => {
    appDispatchActions.setEmployerPreferencesData({
      employerPreferences: response.data
    })
  }

  // Submit employer preferences form
  const onSubmit = async data => {
    settingsDispatchActions.setFormExpanded(null)
    if (data.sizes) {
      data = flattenArraysInData('sizes', data, sizesFormattedData.all)
    }
    if (data?.geo_locations?.features) {
      data.geo_locations = filterLocationResults(data.geo_locations)
    }
    api.request(
      requests().updateEmployerPreferences,
      data,
      response => {
        onSuccess(response)
      },
      error => onFailure(error)
    )
  }

  const getLocationDisplays = () => (
    <LocationsList>
      {filterLocationResults(geo_locations).map(({ label }) => (
        <LocationItem key={label}>{label}</LocationItem>
      ))}
    </LocationsList>
  )

  return (
    <Container>
      <SurveyTitle>Employer Preferences</SurveyTitle>
      <ExpandableSettingsContent
        header={question1.label}
        extraLabelInformation={question1.extraLabelInformation}
        displayValue={getLocationDisplays()}
        content="location_preferences"
        displayImportance={importanceDisplayTranslation(location_importance)}
        required="required"
      >
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <AutoComplete
              name="geo_locations"
              defaultValue={filterLocationResults(geo_locations)}
              requestFunc={fetchLocations}
              placeholder="Enter city name"
              isMulti
              required
              limitSelectedOptions={10}
            />
            <Radios
              vertical={question1b.vertical}
              question={question1b.question}
              groupName={question1b.groupName}
              options={question1b.options}
            />
            <FitContentContainer>
              <InputSubmit
                background="#FFFFFF"
                color="#373440"
                border="#363540"
                testId="input-submit"
                text="Save"
                formState={formState}
              />
            </FitContentContainer>
          </form>
        </FormProvider>
      </ExpandableSettingsContent>
      <Divider />
      <ExpandableSettingsContent
        header={'Please indicate your industry preferences'}
        displayValue={industriesDisplay}
        content="industry_preferences"
        displayImportance={importanceDisplayTranslation(industry_importance)}
      >
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Checkboxes
              questionName={question2.questionName}
              options={industryList}
              required="required"
              subText={question2.subText}
              vertical={question2.vertical}
              isPreCheckedData={state.employerPreferencesData.industries}
            />
            <Radios
              vertical={question2b.vertical}
              question={question2b.question}
              groupName={question2b.groupName}
              options={question2b.options}
              required="required"
            />
            <FitContentContainer>
              <InputSubmit
                background="#FFFFFF"
                color="#373440"
                border="#363540"
                testId="input-submit"
                text="Save"
                formState={formState}
              />
            </FitContentContainer>
          </form>
        </FormProvider>
      </ExpandableSettingsContent>
      <Divider />
      <ExpandableSettingsContent
        header={'Please indicate your company size preferences'}
        displayValue={sizesFormattedData.display}
        content="size_preferences"
        displayImportance={importanceDisplayTranslation(size_importance)}
      >
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Checkboxes
              questionName={question3.questionName}
              options={question3.options}
              required="required"
              subText={question3.subText}
              vertical={question3.vertical}
              isPreCheckedData={state.employerPreferencesData}
            />
            <Radios
              vertical={question3b.vertical}
              question={question3b.question}
              groupName={question3b.groupName}
              options={question3b.options}
              required="required"
            />
            <FitContentContainer>
              <InputSubmit
                background="#FFFFFF"
                color="#373440"
                border="#363540"
                testId="input-submit"
                text="Save"
                formState={formState}
              />
            </FitContentContainer>
          </form>
        </FormProvider>
      </ExpandableSettingsContent>
      <Divider />
      <ExpandableSettingsContent
        header={'Please indicate your preference for data role'}
        displayValue={rolesDisplay}
        content="roles_preferences"
        displayImportance={importanceDisplayTranslation(role_importance)}
      >
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Checkboxes
              questionName={question4.questionName}
              options={professionList}
              required="required"
              vertical={question4.vertical}
              isPreCheckedData={state.employerPreferencesData.profession_types}
            />
            <Radios
              vertical={question4b.vertical}
              question={question4b.question}
              groupName={question4b.groupName}
              options={question4b.options}
              required="required"
            />
            <FitContentContainer>
              <InputSubmit
                background="#FFFFFF"
                color="#373440"
                border="#363540"
                testId="input-submit"
                text="Save"
                formState={formState}
              />
            </FitContentContainer>
          </form>
        </FormProvider>
      </ExpandableSettingsContent>
      <Divider />
    </Container>
  )
}

export default EmployerPreferenceSettings

const FitContentContainer = styled(Container)`
  width: fit-content;
`

const LocationsList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
`

const LocationItem = styled.li`
  background: #ffffff;
  border: 1px solid ${({ theme }) => theme.color.primary.black0};
  border-radius: 4px;
  color: #373440;
  display: inline;
  font-size: 16px;
  flex-shrink: 0;
  list-style-type: none;
  margin: 0 4px 4px 0;
  padding: 5px 16px;
`
