import * as yup from 'yup'

const emailExpression = new RegExp(
  '^(https?:\\/\\/)' + // protocol
  '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
  '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
  '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
  '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$',
  'i'
)

export const experienceValidations = yup.object().shape({
  current: yup.boolean(),
  role: yup
    .string()
    .strict(false)
    .trim()
    .required('Required')
    .max(64, 'Title cannot be more than 64 characters'),
  company_name: yup
    .string()
    .strict(false)
    .trim()
    .required('Required')
    .max(64, 'Company cannot be more than 64 characters'),
  location: yup
    .string()
    .strict(false)
    .trim()
    .required('Required')
    .max(64, 'Location cannot be more than 64 characters'),
  description: yup
    .string()
    .strict(false)
    .trim()
    .required('Required'),
  start_month: yup
    .string()
    .strict(false)
    .trim()
    .required('Required'),
  start_year: yup
    .string()
    .strict(false)
    .trim()
    .required('Required'),
  end_year: yup
    .string()
    .strict(false)
    .trim()
    .when('current', {
      is: false,
      then: yup.string().required('Required')
    }),
  end_month: yup
    .string()
    .strict(false)
    .trim()
    .when('current', {
      is: false,
      then: yup.string().required('Required')
    }),
  company_website: yup
    .string()
    .strict(false)
    .trim()
    .test('Valid URL', `Company website url should be valid.`, value => {
      const regex = new RegExp(emailExpression)
      return value.match(regex) || value === ''
    })
})

export const awardValidations = yup.object().shape({
  year: yup
    .number()
    .required('Required')
    .typeError('Required: Must be a number')
    .strict(false)
    .positive('Value must be positive')
    .integer('Must be an integer')
    .min(1940, 'Please offer a resonable timeline')
    .max(new Date().getFullYear(), 'Year cannot be in the future'),
  name: yup
    .string()
    .strict(false)
    .trim()
    .required('Required')
    .max(128, 'Name cannot be more than 128 characters')
})

export const certificateValidations = yup.object().shape({
  is_expiration: yup.boolean(),
  name: yup
    .string()
    .strict(false)
    .trim()
    .required('Required')
    .max(64, 'Name cannot be more than 64 characters'),
  issuing_org: yup
    .string()
    .strict(false)
    .trim()
    .required('Required')
    .max(64, 'Name cannot be more than 64 characters'),
  start_year: yup
    .string()
    .strict(false)
    .trim()
    .required('Required'),
  start_month: yup
    .string()
    .strict(false)
    .trim()
    .required('Required'),
  end_year: yup
    .string()
    .strict(false)
    .trim()
    .when('is_expiration', {
      is: false,
      then: yup.string().required('Required')
    }),
  end_month: yup
    .string()
    .strict(false)
    .trim()
    .when('is_expiration', {
      is: false,
      then: yup.string().required('Required')
    }),
  credential_url: yup
    .string()
    .strict(false)
    .trim()
    .test('Valid URL', `Credential url should be valid.`, value => {
      const regex = new RegExp(emailExpression)
      return value.match(regex) || value === ''
    })
})

export const publicationValidations = yup.object().shape({
  title: yup
    .string()
    .strict(false)
    .trim()
    .required('Required')
    .max(128, 'Name cannot be more than 128 characters'),
  publisher: yup
    .string()
    .strict(false)
    .trim()
    .max(64, 'Name cannot be more than 128 characters'),
  description: yup
    .string()
    .strict(false)
    .trim()
    .max(280, 'Description cannot be more than 280 characters'),
  publication_month: yup
    .string()
    .strict(false)
    .trim()
    .required('Required'),
  publication_year: yup
    .string()
    .strict(false)
    .trim()
    .required('Required'),
  link: yup
    .string()
    .strict(false)
    .trim()
    .test('Valid URL', `Publication url should be valid.`, value => {
      const regex = new RegExp(emailExpression)
      return value.match(regex) || value === ''
    })
})

export const educationValidations = yup.object().shape({
  school_name: yup
    .string()
    .strict(false)
    .trim()
    .required('Required')
    .max(64, 'School Name cannot be more than 64 characters'),
  degree_type: yup
    .string()
    .strict(false)
    .trim()
    .required('Required'),
  degree: yup
    .string()
    .strict(false)
    .trim()
    .required('Required')
    .max(64, 'Degree cannot be more than 64 characters'),
  gpa_out_of: yup
    .number()
    .strict(false)
    .when('gpa', {
      is: gpa => gpa?.length,
      then: yup
        .number()
        .required('Required')
        .typeError('Required')
        .min(
          yup.ref('gpa'),
          'Your GPA Out Of value must be larger or equal to your GPA'
        )
    })
})

export const skillsValidations = yup.object().shape({
  skills: yup
    .array()
    .strict(false)
    .required('Required')
})

export const basicProfileInfoValidations = yup.object().shape({
  name: yup
    .string()
    .strict(false)
    .trim()
    .required('Required')
    .max(128, 'Name cannot be more than 128 characters'),
  role: yup
    .string()
    .trim()
    .max(64, 'Title cannot be more than 64 characters'),
  location: yup
    .string()
    .strict(false)
    .trim()
    // .required('Required')
    .max(64, 'Location cannot be more than 64 characters'),
  github_link: yup
    .string()
    .strict(false)
    .trim()
    .test('Valid Email', 'Url must begin with: https://github.com/', value => {
      const expression = /^((https):\/\/)((github.com)\/)/
      const regex = new RegExp(expression)
      return value.match(regex) || value === ''
    }),
  linkedin_link: yup
    .string()
    .strict(false)
    .trim()
    .test(
      'Valid Email',
      'Url must begin with: https://www.linkedin.com/in/',
      value => {
        const expression = /^((https):\/\/)((www.linkedin.com)\/)((in)\/)/
        const regex = new RegExp(expression)
        return value.match(regex) || value === ''
      }
    ),
  personal_website_link: yup
    .string()
    .strict(false)
    .trim()
    .test(
      'Valid URL',
      "URL must begin with 'https://' or 'http:// and  should be valid.",
      value => {
        const regex = new RegExp(emailExpression)
        return value.match(regex) || value === ''
      }
    ),
  geo_location: yup
    .object()
    .required('Location field is required')
    .typeError('Location field is required')
})
