import React, { useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useFormContext } from 'react-hook-form'
import close from 'containers/ProfilePage/assets/close'

const TagInput = ({
  index,
  name,
  fields,
  field,
  append,
  remove,
  currentFilter,
  setCurrentFilter,
  skills
}) => {
  const { register, watch, reset, getValues } = useFormContext()
  const [focusState, setFocusState] = useState(false)
  const [pasted, setPasted] = useState(false)

  // Removes input if close button clicked
  const handleRemoveRecipient = index => {
    remove(index)
  }

  const valueChanging = e => {
    if (pasted) {
      if (e.target.value.split(',').length > 1) {
        const currentSkillsArr = getValues()[name]
        const finalArr = []
        currentSkillsArr.forEach((word, index) => {
          if (index !== currentSkillsArr.length - 1) {
            finalArr.push({ name: word.value })
          }
        })
        e.target.value.split(',').forEach(word => {
          finalArr.push({ name: word.trim() })
        })
        if (name === 'skills') {
          reset({ [name]: [...finalArr, ''] })
        } else {
          const currentVals = getValues()
          currentVals[name] = [...finalArr, '']
          reset(currentVals)
        }
      }
      setPasted(false)
    }
    setCurrentFilter(e.target.value)
  }

  // Handle pasting logic
  const handleOnPaste = () => {
    setPasted(true)
  }

  // Handles key down logic
  // 'Enter' - add an input to array
  // 'Backspace' - remove one
  const onKeyDown = e => {
    if (e.keyCode === 32 && e.target.value.trim().length === 0) {
      e.preventDefault()
    }

    if (e.key === 'Enter' || e.key === 'Tab' || e.key === ',') {
      if (!e.target.value.trim().length) {
        e.preventDefault()
        return
      }

      e.preventDefault()
      append('')
    }
    if (e.key === 'Backspace') {
      if (fields.length === 1) {
        !e.target.value.length && remove(fields.length - 1)
      } else {
        !e.target.value.length && remove(fields.length - 2)
      }
    }
  }

  const isLast = fields.length - 1 === index
  const valueSize =
    watch(`${name}[${index}].value`) && watch(`${name}[${index}].value`).length

  return (
    <TagWrapper isLast={isLast}>
      <TagInputWrapper
        autoComplete="off"
        id={`${name}[${index}]`}
        onFocus={() => setFocusState(true)}
        onBlur={() => setFocusState(false)}
        onChange={e => valueChanging(e)}
        focusState={focusState}
        key={field.id}
        isLast={isLast}
        autoFocus={isLast}
        onPaste={handleOnPaste}
        onKeyDown={e => onKeyDown(e)}
        name={`${name}[${index}].value`}
        ref={register()}
        size={valueSize ? valueSize + 1 : 12}
        type="text"
        maxLength="32"
        defaultValue={field.name}
      />
      {!isLast && (
        <span className="action" onClick={() => handleRemoveRecipient(index)}>
          {close}
        </span>
      )}
    </TagWrapper>
  )
}

// -- PROP DESCRIPTIONS -- //
// index: index of input in an array of total options selected
// name: name of form, assigned to input
// fields: all fields in form to be submitted
// field: this specific field in form
// append: add to the field in form
// remove: remove from the field in form
// emitChange: onChange event emitter

TagInput.propTypes = {
  index: PropTypes.number,
  name: PropTypes.string,
  fields: PropTypes.any,
  field: PropTypes.any,
  append: PropTypes.func,
  remove: PropTypes.func,
  setCurrentFilter: PropTypes.func,
  skills: PropTypes.array
}

export default TagInput

const TagInputWrapper = styled.input`
  background: transparent;
  border: none;
  color: ${({ theme, isLast }) => (isLast ? '#000000' : theme.color.text.tag)};
  outline: none;
  min-width: 16px;
  width: 100%;
  padding: 0;
`

const TagWrapper = styled.span`
  font-size: 12px;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  letter-spacing: 0.4px;
  line-height: 16px;
  border: 1px solid
    ${({ theme, isLast, focusState }) =>
      !isLast && focusState
        ? 'transparent'
        : isLast
        ? 'transparent'
        : theme.color.border.tag};
  border-radius: 12px;
  padding: ${({ isLast }) => (isLast ? '4px 8px 4px' : '4px 32px 4px 8px')};
  margin: 0 8px 8px 0;
  text-align: center;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: visible;
  opacity: 1;
  width: auto;
  background: ${({ theme, isLast, focusState }) =>
    !isLast && focusState
      ? 'transparent'
      : isLast
      ? 'transparent'
      : theme.color.bg.lite};
  .action {
    position: absolute;
    right: 0;
    padding: 4px 8px;
    font-size: 10px;
    line-height: 15px;
    transition: all 300ms ease-out;
    opacity: 0.5;
    cursor: pointer;
    &:hover {
      opacity: 1;
    }
  }
  svg {
    width: 6px;
    height: 6px;
    fill: ${({ theme }) => theme.color.primary.black4};
  }
`
