import React from 'react'

const hookIcon = (
  <svg width="14px" height="14px" viewBox="0 0 14 14">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        transform="translate(-340.000000, -2309.000000)"
        fill="#5F5D66"
        fillRule="nonzero"
      >
        <g transform="translate(340.000000, 2309.000000)">
          <path d="M13.0389492,0.961078125 C11.7575117,-0.320359375 9.67998828,-0.320359375 8.39855078,0.961050781 L5.65613672,3.70346484 C4.33064844,5.02900781 4.45336719,7.14103906 5.65613672,8.34386328 C5.85755078,8.54527734 6.08040234,8.70950391 6.31572266,8.84439062 L6.81625,8.34386328 C7.14453906,8.01551953 7.02898438,7.63169531 7.02307813,7.35473047 C6.95102734,7.30365234 6.88094531,7.24844531 6.81625,7.18375 C6.19901953,6.56657422 6.17126563,5.50853516 6.81625,4.86355078 C6.91203516,4.76776563 9.50047656,2.17932422 9.55860937,2.12119141 C10.1984258,1.481375 11.2389922,1.481375 11.8788086,2.12119141 C12.518625,2.76100781 12.518625,3.80157422 11.8788086,4.44139063 L10.0661094,6.25408984 C10.1185273,6.544125 10.4331172,7.23116406 10.2712969,8.36450781 C10.2791992,8.35674219 10.2886602,8.35173828 10.2965352,8.34389062 L13.0389492,5.60147656 C14.320332,4.32003906 14.320332,2.24251563 13.0389492,0.961078125 Z"></path>
          <path d="M8.55635156,5.44362109 C8.35496484,5.24220703 8.13208594,5.07798047 7.89682031,4.94309375 L7.39629297,5.44362109 C7.06794922,5.77191016 7.18350391,6.15573438 7.18941016,6.43269922 C7.26151563,6.48377734 7.33159766,6.53898438 7.39629297,6.60367969 C8.01352344,7.22091016 8.04127734,8.27889453 7.39629297,8.92387891 C7.30028906,9.01988281 4.49744531,11.8227266 4.44136328,11.8788086 C3.80154688,12.518625 2.76098047,12.518625 2.12116406,11.8788086 C1.48134766,11.2389922 1.48134766,10.1984258 2.12116406,9.55860937 L4.14643359,7.53333984 C4.09401562,7.24330469 3.77939844,6.55626562 3.94124609,5.42292187 C3.93334375,5.4306875 3.92382812,5.43574609 3.91595312,5.44359375 L0.961050781,8.39855078 C-0.320332031,9.67998828 -0.320332031,11.7575117 0.961050781,13.0389492 C2.24248828,14.320332 4.31998437,14.320332 5.60142187,13.0389492 L8.55635156,10.0840195 C9.85725781,8.78311328 9.78616406,6.67340625 8.55635156,5.44362109 Z"></path>
        </g>
      </g>
    </g>
  </svg>
)

export default hookIcon
