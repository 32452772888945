import React from 'react'

const starOutlineIcon = (
  <svg
    width="14px"
    height="13px"
    viewBox="0 0 14 13"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      opacity="0.4"
    >
      <g
        transform="translate(-39.000000, -206.000000)"
        fill="none"
        fillRule="nonzero"
        stroke="#845E12"
      >
        <g transform="translate(32.000000, 201.000000)">
          <g transform="translate(8.000000, 6.000000)">
            <path d="M11.9686041,4.221421 C11.8895929,3.9853509 11.6740745,3.8182161 11.4201033,3.79588995 L7.9560612,3.49035084 L6.58705229,0.376431314 C6.48597653,0.147655005 6.25599255,0 6,0 C5.74402186,0 5.51394631,0.147655005 5.41351143,0.376431314 L4.04450253,3.49035084 L0.57991113,3.79588995 C0.32593996,3.81866084 0.110879314,3.98579564 0.0314103283,4.221421 C-0.0476008922,4.45749112 0.025367744,4.71642108 0.217448322,4.88008686 L2.835988,7.11083378 L2.06391203,10.41457 C2.00742313,10.6574892 2.10447049,10.9086806 2.31193216,11.0543787 C2.4234451,11.1330984 2.55445905,11.1724138 2.68593078,11.1724138 C2.79890859,11.1724138 2.91197794,11.1432386 3.01296213,11.0845324 L6,9.34931923 L8.98650294,11.0845324 C9.20559197,11.2117292 9.48107834,11.2000769 9.68808223,11.0543787 C9.8955439,10.9086806 9.99259128,10.6574892 9.93610239,10.41457 L9.16402641,7.11083378 L11.7825661,4.88008686 C11.9745551,4.71642108 12.0476153,4.45802482 11.9686041,4.221421 Z"></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default starOutlineIcon
