import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useFormContext, useFieldArray } from 'react-hook-form'
import {
  FlexContainer,
  AbsoluteContainer,
  Container
} from 'components/styled/Containers'
import { ErrorMessage, InputLabel } from 'components/styled/Text'
import TagInput from 'components/forms/TagInput'
import useClickedOutsideEvent from 'services/hooks/useClickedOutsideEvent'
const MultiSelect = ({
  inline,
  label,
  options,
  name,
  placeholder,
  required
}) => {
  const [currentFilter, setCurrentFilter] = useState('')
  const [dropdownState, setDropdownState] = useState(name === 'skills')

  const skills = [
    'Python',
    'R',
    'SQL',
    'C++',
    'Java',
    'Javascript',
    'Swift',
    'Julia',
    'Scala',
    'Ruby',
    'Rust',
    'MATLAB/Octave',
    'C#',
    'C',
    'Clojure',
    'VBA',
    'React',
    'Vue',
    'Shiny',
    'D3',
    'ChartJS',
    'Matplotlib',
    'Plotly',
    'Seaborn',
    'GGplot',
    'Dash',
    'Bokeh',
    'NumPy',
    'Pandas',
    'Scrapy',
    'Dplyr',
    'Scikit-Learn',
    'Keras',
    'Tensor-Flow',
    'PyTorch',
    'XGBoost',
    'Hadoop',
    'Spark',
    'Storm',
    'Pentaho',
    'MongoDB',
    'Django',
    'Flask',
    'SciPy',
    'SQLAlchemy',
    'Dask',
    'Numba',
    'A/B Testing',
    'AWS',
    'Big Data',
    'Data Warehousing',
    'Data Visualization',
    'Financial Analysis',
    'Financial Modeling',
    'Git',
    'Game Theory',
    'Linux',
    'NLP',
    'SEO',
    'Tableau',
    'Azure',
    'Cassandra',
    'Database Administration',
    'Deep Learning',
    'Docker',
    'Facial Recognition',
    'GraphQL',
    'Google Cloud',
    'Kafka',
    'mariaDB',
    'Neural Networks',
    'NoSQL',
    'PSQL',
    'Selenium'
  ]

  const form = useFormContext()

  const { fields, append, remove } = useFieldArray({ name })

  const skillsLength = form.watch()[name] ? form.watch()[name].length : 0

  useEffect(() => {
    const skills = form.watch()[name]
    if (skills && skills.length > 0) {
      let updatedASkill = false

      skills.forEach(skill => {
        if (skill.value.length > 32 && !form.errors.skill) {
          updatedASkill = true
          form.setError('skill', {
            type: 'manual',
            message: 'Remove any skills over 32 characters'
          })
        }
      })

      if (!updatedASkill) form.clearErrors('skill')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skillsLength])

  // These three methods ensure that skills that
  // skills that have already been selected dont appear in the dropdown
  const consolidateFormValues =
    form.getValues()[name] &&
    form.getValues()[name].map(skill => {
      return skill.value.toLowerCase()
    })

  const updatedSkills = consolidateFormValues
    ? skills.filter(skill => {
        if (
          skill.toLowerCase() ===
          consolidateFormValues[consolidateFormValues.length - 1]
        ) {
          return true
        } else {
          return !consolidateFormValues.includes(skill.toLowerCase())
        }
      })
    : skills

  const filteredSkillsBySearch = updatedSkills.filter(skill => {
    return skill.toLowerCase().includes(currentFilter.toLowerCase())
  })

  // Handle clicks dropdown
  const dropdownRef = useRef(null)
  useClickedOutsideEvent(dropdownRef, () => setDropdownState(false))

  const selectOption = skill => {
    const last = form.getValues()[name].length - 1
    form.setValue(`${name}[${last}].value`, skill)
    append('')
  }

  const handleInput = dropdownRef => {
    // Focus on last input
    if (fields.length) {
      const spans = dropdownRef.current.children[0].children
      const input = spans[spans.length - 1].children[0]
      input.focus()
    }

    if (!fields.length) {
      setDropdownState(true)
      append('')
    } else {
      setDropdownState(true)
    }
  }

  const renderSkills = () => {
    if (fields.length) {
      return (
        <>
          <Selection
            justify="flex-start"
            align="flex-start"
            wrap="wrap"
            className="sub"
          >
            {fields.map((field, index) => {
              return (
                <TagInput
                  key={field.id}
                  index={index}
                  append={append}
                  remove={remove}
                  fields={fields}
                  field={field}
                  name={name}
                  setCurrentFilter={setCurrentFilter}
                  currentFilter={currentFilter}
                  skills={skills}
                  autocomplete="off"
                />
              )
            })}
          </Selection>
        </>
      )
    } else {
      return <>{placeholder}</>
    }
  }

  const renderSkillOptions = () => {
    return (
      <DropdownOptions width="100%" top="100%" left="0">
        {filteredSkillsBySearch.map((skill, index) => {
          return (
            <DropdownOption key={skill} onClick={() => selectOption(skill)}>
              {skill}
            </DropdownOption>
          )
        })}
      </DropdownOptions>
    )
  }

  const renderDropdown = () => {
    if (filteredSkillsBySearch.length) {
      return renderSkillOptions()
    } else {
      return (
        <DropdownOptions width="100%" top="100%" left="0">
          <Container>
            <p className="subtitle2">No skills currently match</p>
          </Container>
        </DropdownOptions>
      )
    }
  }

  return (
    <Container margin="0 0 16px">
      <FlexContainer
        width="100%"
        direction={inline ? 'row' : 'column'}
        align={inline ? 'center' : 'flex-start'}
      >
        <LabelContainer inline={inline}>
          {label && <InputLabel className={required}>{label}</InputLabel>}
        </LabelContainer>
        <SelectContainer
          ref={dropdownRef}
          active={dropdownState}
          skillError={form.errors.skill && form.errors.skill}
          width="100%"
          align="center"
          onClick={() => handleInput(dropdownRef)}
        >
          {renderSkills()}
          {dropdownState && Boolean(currentFilter) && renderDropdown()}
        </SelectContainer>
      </FlexContainer>
      {form && form.errors['skill'] && (
        <ErrorMessage>{form.errors['skill'].message}</ErrorMessage>
      )}
    </Container>
  )
}

// -- PROP DESCRIPTIONS -- //
// inline: when set to true dropdown question is inline with dropdown
// label: the question text that gets rendered
// options: list of dropdown options
// name: name associated with react-hook-form
// placeholder: initial text showing in dropdown

MultiSelect.propTypes = {
  inline: PropTypes.bool,
  label: PropTypes.string,
  options: PropTypes.array,
  name: PropTypes.string,
  placeholder: PropTypes.string
}

MultiSelect.defaultProps = {
  inline: false,
  label: 'This is sample dropdown text',
  name: 'select'
}

export default MultiSelect

const LabelContainer = styled(Container)`
  padding-top: ${({ inline }) => (inline ? '5px' : '0px')};
  margin-right: 32px;
`

const SelectContainer = styled(FlexContainer)`
  cursor: text;
  background: ${({ theme }) => theme.color.bg.darkNorm};
  height: auto;
  min-height: 40px;
  max-width: 450px;
  border-radius: 4px;
  margin: 8px 0 0;
  font-size: 14px;
  color: ${({ theme }) => theme.color.primary.black3};
  border: 1px solid
    ${({ theme, skillError, active }) =>
      active
        ? theme.color.border.active
        : skillError
        ? theme.color.border.error
        : theme.color.border.input};
  &:hover {
    background: transparent;
  }
  &:focus {
    background: transparent;
    border-color: ${({ theme }) => theme.color.border.active};
  }
  padding: 12px 14px;
`

const Selection = styled(FlexContainer)`
  text-transform: capitalize;
  margin-right: 16px;
  text-align: left;
  height: auto;
`

const DropdownOptions = styled(AbsoluteContainer)`
  background: #ffffff;
  border-radius: 0px 0px 4px 4px;
  border: 1px solid #cbcbcb;
  padding: 8px;
  z-index: ${({ theme }) => theme.order.dropdownOptions};
  max-height: 200px;
  overflow: scroll;
`

const DropdownOption = styled(Container)`
  padding: 8px 8px;
  border-radius: 2px;
  transition: 200ms ease-out;
  color: ${({ theme }) => theme.color.primary.black3};
  font-size: 14px;
  text-align: left;
  cursor: pointer;
  &:hover {
    background: #e1e1e1;
  }
`
