import React from 'react'
import styled from 'styled-components'
import Input from 'components/forms/Input'
import Checkbox from 'components/forms/Checkbox'
import Select from 'components/forms/Select'
import {
  isStartYearValid,
  isEndYearValid,
  useSetStartDateErrors,
  useSetEndDateErrors
} from 'containers/ProfilePage/helpers/areYearsValidInForm'
import { months, populateYears } from 'containers/ProfilePage/helpers/datetime'

const CertificateForm = ({ formProps, data }) => {
  const { register, errors, watch, setError, clearErrors } = formProps
  const { start_month, start_year, end_month, end_year } = watch()
  const disableEndDate = Boolean(watch('is_expiration'))
  const isChecked = data && !Boolean(data.expiration_date)
  const yearsForEndDate = populateYears()
  const yearsForStartDate = populateYears(new Date().getFullYear())
  const startDate = isStartYearValid(start_month, start_year)
  const endDate = isEndYearValid(start_month, start_year, end_month, end_year)

  useSetStartDateErrors(
    startDate,
    setError,
    clearErrors,
    'Issue date cannot be in the future'
  )
  useSetEndDateErrors(
    endDate,
    setError,
    clearErrors,
    'Expiration date cannot be earlier than issue date'
  )

  return (
    <>
      <Input
        label="Name"
        testId="certificate-input-name"
        type="text"
        name="name"
        placeholder="Ex: DS4A Women's Summit"
        register={register}
        errors={errors}
        required="required"
      />
      <Input
        label="Issuing Organization"
        testId="certificate-input-issuing_org"
        type="text"
        name="issuing_org"
        placeholder="Ex: Correlation One"
        register={register}
        errors={errors}
        required="required"
      />
      <DateWrapper>
        <CheckboxWrapper>
          <DateContainer>
            <DateContainerField>
              <Select
                label="Issue Date"
                type="text"
                name="start_month"
                options={months}
                placeholder="Month"
                register={register}
                errors={errors}
                initialValue={data && data.start_month}
                required="required"
              />
            </DateContainerField>
            <DateContainerField>
              <Select
                blankLabel={true}
                label="Year"
                type="text"
                name="start_year"
                options={yearsForStartDate}
                placeholder="Year"
                register={register}
                errors={errors}
                initialValue={data && data.start_year}
              />
            </DateContainerField>
          </DateContainer>
          <CheckboxContainer>
            <Checkbox
              optionName="No Expiration Date"
              questionName="is_expiration"
              optionValue=""
              isPreChecked={isChecked}
            />
          </CheckboxContainer>
        </CheckboxWrapper>
        <DateContainer>
          <DateContainerField>
            <Select
              label="Expiration Date"
              type="text"
              name="end_month"
              options={months}
              placeholder="Month"
              register={register}
              errors={errors}
              initialValue={disableEndDate ? 'Month' : data && data.end_month}
              disabled={disableEndDate}
            />
          </DateContainerField>
          <DateContainerField>
            <Select
              blankLabel={true}
              label="Year"
              type="text"
              name="end_year"
              options={yearsForEndDate}
              placeholder="Year"
              register={register}
              errors={errors}
              initialValue={disableEndDate ? 'Year' : data && data.end_year}
              disabled={disableEndDate}
            />
          </DateContainerField>
        </DateContainer>
      </DateWrapper>
      <Input
        label="Credential Url"
        testId="certificate-input-credential_url"
        type="text"
        name="credential_url"
        placeholder=""
        register={register}
        errors={errors}
      />
    </>
  )
}

export default CertificateForm

const DateWrapper = styled.div`
  display: flex;
  flex: 1 1 auto;
  gap: 15px;
  width: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 0px;
  }
`
const CheckboxWrapper = styled.div`
  width: 100%;
`

const DateContainer = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
  @media (max-width: 768px) {
    align-items: flex-end;
  }
  @media (max-width: 420px) {
    flex-direction: column;
    gap: 0px;
  }
  :last-child > :first-child > :first-child > :first-child > :first-child {
    margin-right: 0px;
  }
`

const DateContainerField = styled.div`
  width: 100%;
`

const CheckboxContainer = styled.div`
  margin-top: -16px;
  > :first-child {
    background: transparent;
    border: none;
    input {
      left: 0;
    }
    :hover {
      background: transparent;
      border: none;
    }
    label {
      color: #87858c;
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 0.4px;
      padding-left: 0;
    }
  }
`
