import React from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import { IconAction } from 'components/styled/Buttons'
import { InputLabel } from 'components/styled/Text'
import { Container } from 'components/styled/Containers'
import useSettingsContext from 'services/context/settingsContext/useSettingsContext'

const ExpandableSettingsContent = ({
  header,
  children,
  displayValue,
  displayType,
  content,
  displayImportance,
  extraLabelInformation,
  required
}) => {
  const { settingsState, settingsDispatchActions } = useSettingsContext()
  const showContent = content => {
    return settingsState.formExpanded === content
  }

  const handleEditClick = content => {
    settingsDispatchActions.setFormExpanded(content)
  }

  return (
    <ExpandableContentWrapper>
      <ContentHeading showContent={showContent(content)}>
        <section>
          <InputLabel className={required}>{header}</InputLabel>
          <ExtraLabelInformation>{extraLabelInformation}</ExtraLabelInformation>
        </section>
        {!showContent(content) && (
          <Container onClick={() => handleEditClick(content)}>
            <StyledIconAction>
              <StyledSpan>Edit</StyledSpan>
            </StyledIconAction>
          </Container>
        )}
        {showContent(content) && (
          <Container
            onClick={() => settingsDispatchActions.setFormExpanded(null)}
          >
            <StyledIconAction>
              <StyledSpan>Cancel</StyledSpan>
            </StyledIconAction>
          </Container>
        )}
      </ContentHeading>

      {!showContent(content) &&
        (displayType === 'link' ? (
          <ValueDisplay>
            <a href={displayValue}>{displayValue}</a>
          </ValueDisplay>
        ) : (
          <ValueDisplay>{displayValue}</ValueDisplay>
        ))}
      {!showContent(content) && displayImportance && (
        <ImportanceDisplay>{displayImportance}</ImportanceDisplay>
      )}
      {showContent(content) && children}
    </ExpandableContentWrapper>
  )
}

export default ExpandableSettingsContent

// -- PROP DESCRIPTIONS -- //
/* 
  header: sets the header title for the content,
  actionType: sets the action icon type (either add or edit for now) for the header action,
  addFormAction: emits a function via a click of the header action, passed through the parent container
*/

ExpandableSettingsContent.propTypes = {
  header: PropTypes.string,
  displayValue: PropTypes.any,
  displayType: PropTypes.string,
  content: PropTypes.string,
  displayImportance: PropTypes.string,
  extraInformation: PropTypes.string
}

const StyledIconAction = styled(IconAction)`
  border-radius: 4px;
  width: fit-content;
`

const StyledSpan = styled.span`
  font-weight: 500;
  text-decoration: none;
  text-transform: none;
  font-size: 14px;
`

const ValueDisplay = styled.div`
  width: 100%;
  margin: 0px 0 0 0;
  padding: 0px;
  display: block;
  position: relative;
  text-transform: capitalize;
  a {
    color: rgb(97, 128, 173);
    font-size: 16px;
    text-decoration: none;
    text-transform: none;
    letter-spacing: 0.5px;
  }
`

const ImportanceDisplay = styled.div`
  font-size: 12px;
  letter-spacing: 0.4px;
  color: rgb(175, 174, 179);
  font-weight: bold;
  margin-top: 4px;
`

const ContentHeading = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 0 0 8px;
  position: relative;
  ${({ showContent }) =>
    showContent &&
    css`
      opacity: 0.9;
      &:hover {
        opacity: 1;
      }
    `}
  @media screen and (max-width: ${({ theme }) => theme.media.mobile}) {
    padding: 0 0 8px 0;
    min-height: 0;
    margin: 0 auto 16px;
  }
`

const ExpandableContentWrapper = styled.div`
  width: 100%;
  margin: 0 0 20px 0;
  padding: 0px;
  display: block;
  position: relative;
  @media screen and (max-width: ${({ theme }) => theme.media.mobile}) {
    margin: 0 auto 0;
  }
`
const ExtraLabelInformation = styled.div`
  color: ${({ theme }) => theme.color.primary.black2};
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.4px;
  line-height: 16px;
`
