import React, { useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { FlexContainer } from 'components/styled/Containers'
import useAppContext from 'services/context/appContext/useAppContext'

const ImageZoomModal = ({ imgSrc }) => {
  const { appDispatchActions } = useAppContext()

  const [isVisible, setIsVisible] = useState(true)

  const closeModal = () => {
    setIsVisible(false)
    appDispatchActions.zoomOut()
  }

  // this function checks if either image width or height is "maxed out"
  // if neither are, it increase width or height of the image depending
  // on the relative aspect ratios of the image vs the window
  const reSizeImg = e => {
    const img = e.target
    const windowW = window.innerWidth - 62
    const windowH = window.innerHeight - 62
    const windowAR = windowW / windowH
    const imgW = img.width
    const imgH = img.height
    const imgAR = imgW / imgH
    if (windowH !== imgH && windowW !== imgW) {
      if (windowAR > imgAR) {
        img.style.height = 'calc(100vh - 62px)'
      } else {
        img.style.width = 'calc(100vw - 62px)'
      }
    }
  }

  return (
    <ImageZoomContainer
      padding="0px"
      onClick={closeModal}
      isVisible={isVisible}
      justify="center"
      align="center"
    >
      <img
        src={imgSrc}
        onLoad={reSizeImg}
        alt="C1 Connect Datafolio Example #0"
      ></img>
    </ImageZoomContainer>
  )
}

export default ImageZoomModal

ImageZoomModal.propTypes = {
  imgSrc: PropTypes.string
}

const ImageZoomContainer = styled(FlexContainer)`
  background: #2c2a33ed;
  border-radius: 0;
  bottom: 0;
  cursor: zoom-out;
  display: ${({ isVisible }) => (isVisible ? `block` : `none`)};
  height: 100vh;
  left: 0;
  margin: 0;
  overflow: scroll;
  padding: 28px;
  position: fixed;
  text-align: center;
  top: 0;
  width: 100vw;
  z-index: ${({ theme }) => theme.order.zoomProjectModal};
  img {
    margin: 0;
    max-height: calc(100vh - 62px);
    max-width: calc(100vw - 62px);
    object-fit: scale-down;
    padding: 0;
  }
  @media screen and (max-width: 568px) and (orientation: portrait) {
    img {
      max-height: calc(100vw - 62px);
      transform: rotate(90deg);
      width: calc(100vh - 62px);
    }
  }
`
