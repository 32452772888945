import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const C1LogoDark = props => (
  <LogoSVG
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 225 62.23"
  >
    <g id="Layer_2" data-name="Layer 2">
      <g id="Large_Header" data-name="Large Header">
        <g id="Text">
          <path
            className="cls-1"
            d="M124.28,8.07a5,5,0,1,1-10-.12,5,5,0,0,1,10,.12ZM116.1,8c0,2.23,1.28,3.7,3.2,3.7s3.19-1.41,3.19-3.62a3.87,3.87,0,0,0-.66-2.41,3,3,0,0,0-2.51-1.25C117.4,4.43,116.1,5.89,116.1,8Z"
          />
          <path
            className="cls-1"
            d="M128.64,5.22c.37-1.34,1.56-2.09,3.29-2.09,2.19,0,3.41,1,3.41,3v5.47H137v1.19h-5.06V11.56h1.79V6.71a2.23,2.23,0,0,0-2.15-2.25c-1.69,0-2.76,1.12-2.76,3v4.13h1.64v1.19h-5.21V11.56H127v-7h-1.78V3.37h3.42Z"
          />
          <path
            className="cls-1"
            d="M147.15,9.77a3.29,3.29,0,0,1-.52,1.36,4.21,4.21,0,0,1-3.75,1.67,4.32,4.32,0,0,1-4-1.79,4.79,4.79,0,0,1-.83-2.85c0-3,1.76-4.87,4.69-4.87,2.29,0,3.75,1,4.27,2.91a6.8,6.8,0,0,1,.24,2.23h-7.53a3,3,0,0,0,3.2,3.11,2.51,2.51,0,0,0,2.72-1.77Zm-1.41-2.53a2.73,2.73,0,0,0-2.93-2.79,3,3,0,0,0-3.12,2.79Z"
          />
          <path
            className="cls-1"
            d="M21.34,8.07a4.66,4.66,0,0,1-4.94,5,4.7,4.7,0,0,1-5-5.08,4.62,4.62,0,0,1,5-4.84A4.61,4.61,0,0,1,21.34,8.07ZM13.17,8c0,2.23,1.27,3.7,3.19,3.7s3.2-1.41,3.2-3.62a3.81,3.81,0,0,0-.67-2.41,3,3,0,0,0-2.51-1.25C14.46,4.43,13.17,5.89,13.17,8Z"
          />
          <path
            className="cls-1"
            d="M25.8,5.48a2.79,2.79,0,0,1,2.94-2.35,3.47,3.47,0,0,1,.78.1v1.5a1.34,1.34,0,0,0-.93-.3,2.6,2.6,0,0,0-1.9.79,3.62,3.62,0,0,0-.89,2.71v3.63h2.08v1.19h-5.5V11.56h1.78v-7H22.38V3.37H25.8Z"
          />
          <path
            className="cls-1"
            d="M34,5.48a2.8,2.8,0,0,1,3-2.35,3.39,3.39,0,0,1,.77.1v1.5a1.32,1.32,0,0,0-.93-.3,2.63,2.63,0,0,0-1.9.79A3.66,3.66,0,0,0,34,7.93v3.63h2.09v1.19H30.56V11.56h1.79v-7H30.56V3.37H34Z"
          />
          <path
            className="cls-1"
            d="M47.63,9.77a3.16,3.16,0,0,1-.52,1.36,4.21,4.21,0,0,1-3.75,1.67,4.33,4.33,0,0,1-4-1.79,4.79,4.79,0,0,1-.83-2.85c0-3,1.77-4.87,4.7-4.87,2.29,0,3.74,1,4.27,2.91a7.14,7.14,0,0,1,.23,2.23H40.24a3,3,0,0,0,3.2,3.11,2.5,2.5,0,0,0,2.72-1.77Zm-1.4-2.53a2.74,2.74,0,0,0-2.94-2.79,3,3,0,0,0-3.11,2.79Z"
          />
          <path
            className="cls-1"
            d="M52.14,11.56h1.63v1.19H48.72V11.56h1.63V1.44H48.72V.1h3.42Z"
          />
          <path
            className="cls-1"
            d="M55.17,6.05c.25-2,1.54-2.95,4.15-2.95a4.42,4.42,0,0,1,3.21,1c.59.59.77,1.23.77,2.67v4.82h1.78v1.19H61.81v-2c-.57,1.54-1.72,2.21-3.7,2.21-2.29,0-3.63-1.05-3.63-2.87a2.69,2.69,0,0,1,1.78-2.63A9.58,9.58,0,0,1,59.81,7c.26,0,.7,0,1.85,0V6.7c0-1-.12-1.42-.54-1.78a2.61,2.61,0,0,0-1.78-.51c-1.5,0-2.31.57-2.41,1.64ZM60,8.29a4.86,4.86,0,0,0-2.71.49,1.54,1.54,0,0,0-.71,1.31c0,1,.77,1.62,2,1.62A3,3,0,0,0,61,10.66a4.1,4.1,0,0,0,.66-2.33C61.12,8.29,60.73,8.29,60,8.29Z"
          />
          <path
            className="cls-1"
            d="M68.36,10.07c0,.68,0,.94.23,1.16a1,1,0,0,0,.78.36.92.92,0,0,0,.92-.64,4.6,4.6,0,0,0,.13-1.18h1.27a3.62,3.62,0,0,1-.37,2.08,2.38,2.38,0,0,1-2.2,1.05,2.5,2.5,0,0,1-1.64-.52,2.7,2.7,0,0,1-.76-2.33V4.56H64.79V3.37h1.93V.61L68.36,0V3.37h3.12V4.56H68.36Z"
          />
          <path
            className="cls-1"
            d="M76.24,11.56h1.49v1.19H72.82V11.56h1.64V4.71H72.82V3.37h3.42Z"
          />
          <path
            className="cls-1"
            d="M88.81,8.07a5,5,0,1,1-10-.12,5,5,0,0,1,10,.12ZM80.64,8c0,2.23,1.28,3.7,3.2,3.7S87,10.3,87,8.09a3.87,3.87,0,0,0-.66-2.41,3,3,0,0,0-2.51-1.25C81.94,4.43,80.64,5.89,80.64,8Z"
          />
          <path
            className="cls-1"
            d="M93.21,5.22c.36-1.34,1.56-2.09,3.29-2.09,2.19,0,3.4,1,3.4,3v5.47h1.64v1.19H96.48V11.56h1.64V6.71A2.23,2.23,0,0,0,96,4.46c-1.69,0-2.76,1.12-2.76,3v4.13H95v1.19h-5.2V11.56h1.78v-7H89.79V3.37h3.42Z"
          />
          <path
            className="cls-1"
            d="M8.37,9.78a3,3,0,0,1-2.9,1.94A3,3,0,0,1,2.6,9.78,4.6,4.6,0,0,1,2.28,8a4.13,4.13,0,0,1,.45-1.95A3,3,0,0,1,5.49,4.44,3,3,0,0,1,8,5.7a3.56,3.56,0,0,1,.37.67h1.84A4.56,4.56,0,0,0,5.47,3.13,4.62,4.62,0,0,0,.82,6.07,5.39,5.39,0,0,0,.5,8,6.51,6.51,0,0,0,.75,9.78,4.59,4.59,0,0,0,5.51,13,4.57,4.57,0,0,0,10.2,9.78Z"
          />
          <circle className="cls-1" cx="108.62" cy="8.09" r="1.24" />
          <circle className="cls-1" cx="111.78" cy="4.93" r="1.24" />
          <circle className="cls-1" cx="105.47" cy="11.22" r="1.24" />
          <path
            className="cls-1"
            d="M225,62.23H35V26.5H225Zm-189-1H224V27.5H36Z"
          />
          <path
            className="cls-1"
            d="M31.15,62.23H0V26.5H31.15ZM1,61.23H30.15V27.5H1Z"
          />
          <path
            className="cls-1"
            d="M79.72,50.42a4,4,0,0,1-2.1.5,4.09,4.09,0,0,1-2.09-.54A4.71,4.71,0,0,1,74,48.9a7.67,7.67,0,0,1-1-2.26,10.72,10.72,0,0,1-.35-2.86A10.85,10.85,0,0,1,73,40.91a7.38,7.38,0,0,1,1-2.27,4.7,4.7,0,0,1,1.58-1.47,4,4,0,0,1,2.07-.53,4.58,4.58,0,0,1,2,.43,3.68,3.68,0,0,1,1.44,1.24l-1.32,1.61a2.41,2.41,0,0,0-2-1.13,2,2,0,0,0-1.21.39,3.1,3.1,0,0,0-.86,1.07,5.64,5.64,0,0,0-.52,1.58,10.11,10.11,0,0,0-.17,2,10,10,0,0,0,.17,1.93,5.53,5.53,0,0,0,.52,1.58,3,3,0,0,0,.87,1.07,2,2,0,0,0,1.24.39A2,2,0,0,0,79,48.41a3,3,0,0,0,.82-.85l1.32,1.56A4.15,4.15,0,0,1,79.72,50.42Z"
          />
          <path
            className="cls-1"
            d="M97.45,43.76a11.56,11.56,0,0,1-.34,2.93,6.81,6.81,0,0,1-1,2.25A4.46,4.46,0,0,1,94.6,50.4a4.3,4.3,0,0,1-4.1,0A4.64,4.64,0,0,1,89,48.94a7.25,7.25,0,0,1-1-2.25,12,12,0,0,1-.33-2.93A12.49,12.49,0,0,1,88,40.84a7.05,7.05,0,0,1,1-2.24,4.55,4.55,0,0,1,1.55-1.45,4.4,4.4,0,0,1,4.11,0,4.43,4.43,0,0,1,1.54,1.45,6.84,6.84,0,0,1,1,2.24A11.48,11.48,0,0,1,97.45,43.76Zm-2.38,0a12,12,0,0,0-.16-1.95,6.19,6.19,0,0,0-.48-1.57,3.22,3.22,0,0,0-.79-1.05,1.77,1.77,0,0,0-2.19,0,3.09,3.09,0,0,0-.79,1.05,5.82,5.82,0,0,0-.47,1.57,12.75,12.75,0,0,0,0,3.9,5.89,5.89,0,0,0,.47,1.58,3,3,0,0,0,.79,1.06,1.66,1.66,0,0,0,1.1.38,1.68,1.68,0,0,0,1.11-.38,3,3,0,0,0,.78-1.06,5.89,5.89,0,0,0,.48-1.58A12.06,12.06,0,0,0,95.07,43.76Z"
          />
          <path
            className="cls-1"
            d="M111.31,50.58,107.45,41h-.06l0,9.6h-2.07V37H108l3.82,9.66h.06l0-9.66h2v13.6Z"
          />
          <path
            className="cls-1"
            d="M128.32,50.58,124.46,41h-.06l0,9.6h-2.07V37H125l3.82,9.66h.06l0-9.66h2v13.6Z"
          />
          <path
            className="cls-1"
            d="M139.38,50.58V37h6.09v2h-3.88v3.61h3.59v1.88h-3.59v4h4.07v2.06Z"
          />
          <path
            className="cls-1"
            d="M160,50.42a4,4,0,0,1-2.1.5,4.09,4.09,0,0,1-2.09-.54,4.71,4.71,0,0,1-1.57-1.48,7.67,7.67,0,0,1-1-2.26,10.72,10.72,0,0,1-.35-2.86,10.85,10.85,0,0,1,.35-2.87,7.38,7.38,0,0,1,1-2.27,4.7,4.7,0,0,1,1.58-1.47,4,4,0,0,1,2.07-.53,4.58,4.58,0,0,1,2,.43,3.68,3.68,0,0,1,1.44,1.24l-1.32,1.61a2.41,2.41,0,0,0-2-1.13,2,2,0,0,0-1.21.39,3.1,3.1,0,0,0-.86,1.07,5.64,5.64,0,0,0-.52,1.58,10.11,10.11,0,0,0-.17,2,10,10,0,0,0,.17,1.93,5.53,5.53,0,0,0,.52,1.58,3,3,0,0,0,.87,1.07,2,2,0,0,0,1.24.39,2,2,0,0,0,1.16-.34,3,3,0,0,0,.82-.85l1.32,1.56A4.15,4.15,0,0,1,160,50.42Z"
          />
          <path
            className="cls-1"
            d="M172.19,38.94V50.58h-2.26V38.94h-2.56V37h7.38v2Z"
          />
          <path
            className="cls-1"
            d="M22.39,37.87a2,2,0,1,1-2-2,2,2,0,0,1,2,2"
          />
          <path className="cls-1" d="M17.65,43.6a2,2,0,1,1-2-2,2,2,0,0,1,2,2" />
          <path
            className="cls-1"
            d="M12.71,49.52a2,2,0,1,1-2-2,2,2,0,0,1,2,2"
          />
        </g>
      </g>
    </g>
  </LogoSVG>
)

// -- PROP DESCRIPTIONS -- //
// color: This sets the fillColor for the svg via LogoSVG (styled-component wrapper)
// height: This sets the height for the svg via LogoSVG (styled-component wrapper)
// width: This sets the width for the svg via LogoSVG (styled-component wrapper)

C1LogoDark.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string
}

export default C1LogoDark

const LogoSVG = styled.svg`
  width: ${width => width || '80%'};
  height: ${height => height || '80%'};
  cursor: pointer;
  .cls-1 {
    fill: ${({ theme, color }) => color || theme.color.bg.black};
  }
`
