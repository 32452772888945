import React from 'react'

const pencilIcon = (
  <svg width="32px" height="32px" viewBox="0 0 32 32" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-376.000000, -841.000000)">
        <g transform="translate(84.000000, 833.000000)">
          <g transform="translate(292.000000, 8.000000)">
            <rect
              opacity="0.2"
              x="0"
              y="0"
              width="32"
              height="32"
              rx="16"
            ></rect>
            <g
              transform="translate(10.366967, 10.000000)"
              fill="#FFFFFF"
              fillRule="nonzero"
            >
              <polygon points="1.1732912 7.63031712 0 11.1911318 3.63720271 10.0424819"></polygon>
              <polygon
                points="1.94596391 6.9177873 7.52319175 1.45769167 9.94807823 3.83165099 
              4.37085039 9.29174663"
              ></polygon>
              <path
                d="M11.2580084,1.75214689 L9.64147385,0.169562603 C9.41054034,-0.0565208675 
              9.06414008,-0.0565208675 8.83320658,0.169562603 L8.31360619,0.67825041 L10.738408,
              3.05212685 L11.2580084,2.54343904 C11.4889419,2.31735557 11.4889419,1.97823036 
              11.2580084,1.75214689 Z"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default pencilIcon
