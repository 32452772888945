import React, { useState, useEffect, useRef } from 'react'
import { Link, useHistory } from 'react-router-dom'
import styled from 'styled-components'
import DeleteModal from 'containers/ProfilePage/components/deleteModal/DeleteModal'
import { PageWrapper, ContentWrapper } from 'components/styled/Wrappers'
import { Persona } from 'components/styled/Images'
import ShareModal from './ShareModal'
import SaveAsDraftModal from 'containers/ProjectPage/SaveAsDraftModal'
import useAppContext from 'services/context/appContext/useAppContext'
import useFetchPublicProfile from 'services/hooks/useFetchPublicProfile'
import useProfileContext from 'services/context/profileContext/useProfileContext'
import useScrollToTop from 'services/hooks/useScrollToTop'
import useClickedOutsideEvent from 'services/hooks/useClickedOutsideEvent'
import backArrowIcon from 'containers/ProjectPage/assets/backArrowIcon'
import ImageZoomModal from 'components/general/ImageZoomModal'

const ProjectPage = () => {
  useScrollToTop()
  const history = useHistory()
  const { state, api, requests, appDispatchActions } = useAppContext()
  const { zoomIn, zoomImgSrc } = state
  const { profileDispatchActions, profileState } = useProfileContext()
  useFetchPublicProfile(state.isPublic)
  const [currentProject, setCurrentProject] = useState({})
  const [currentProfile, setCurrentProfile] = useState({})
  const [isShareModalShowing, setIsShareModalShowing] = useState(false)
  const [isSaveModalShowing, setIsSaveModalShowing] = useState(false)
  const [currentProjectImageIndex, setCurrentProjectImageIndex] = useState(0)
  const [isMoreDropdownShowing, setIsMoreDropdownShowing] = useState(false)
  const [initials, setInitials] = useState('')
  const profile = state.publicProfileData || state.profileData
  const projectIdFromURL = history.location.pathname.split('/').pop()
  const userSlug = profile?.slug
  const dropdownRef = useRef(null)
  useClickedOutsideEvent(dropdownRef, () => setIsMoreDropdownShowing(false))

  const toggleSaveModal = () => {
    setIsSaveModalShowing(prevState => !prevState)
  }

  useEffect(() => {
    if (profile) {
      const fullName = profile.name.split(' ')
      const foundProject = profile.projects.find(
        ({ id }) => id === projectIdFromURL
      )

      setCurrentProfile(profile)

      if (fullName.length > 1) {
        setInitials(`${fullName[0][0]}${fullName[1][0]}`)
      } else {
        setInitials(`${fullName[0][0]}`)
      }

      setCurrentProject(foundProject)
    }
  }, [profile, projectIdFromURL])

  useEffect(() => {
    if (currentProject === undefined) {
      history.push(`/profile/${userSlug}`)
      window.localStorage.setItem('displayProjectNotFound', true)
    }
  }, [currentProject, history, userSlug])

  const handlePersona = () => {
    return (
      <TitleSectionContainer>
        {profile.photo ? (
          <StyledPersona backgroundImage={profile.photo.file_path} />
        ) : (
          <StyledPersona>{initials}</StyledPersona>
        )}
        <TitleContainer>
          <TitleHeading>{currentProject.title}</TitleHeading>
          <SubTitleContainer>
            <>{currentProfile.name}</>
            {currentProfile.role && (
              <>
                <Dot />
                {currentProfile.role}
              </>
            )}
          </SubTitleContainer>
        </TitleContainer>
      </TitleSectionContainer>
    )
  }

  const toggleShareModal = () => {
    setIsShareModalShowing(!isShareModalShowing)
  }

  const handlePrivateButtons = () => {
    return (
      state.profileData &&
      !state.publicProfileData && (
        <PrivateButtonsContainer>
          <PrivateButton isDark onClick={toggleShareModal}>
            share
          </PrivateButton>
          <PrivateButton
            ref={dropdownRef}
            onClick={() => setIsMoreDropdownShowing(!isMoreDropdownShowing)}
          >
            more
          </PrivateButton>
          {handleMoreDropdown()}
        </PrivateButtonsContainer>
      )
    )
  }

  const handleDelete = () => {
    setIsMoreDropdownShowing(false)
    profileDispatchActions.setDeleteModal(currentProject.id, 'project')
  }

  const handleMoreDropdown = () => {
    if (isMoreDropdownShowing) {
      return (
        <MoreDropdownContainer ref={dropdownRef}>
          <Link to={`/profile/project/${currentProject.id}`}>
            <MoreDropdownButton>Edit</MoreDropdownButton>
          </Link>
          <MoreDropdownButton onClick={toggleSaveModal}>
            Unpublish
          </MoreDropdownButton>
          <MoreDropdownButton onClick={handleDelete}>Delete</MoreDropdownButton>
        </MoreDropdownContainer>
      )
    }
  }

  const handleFormatDataOnEdit = async () => {
    const {
      id,
      description,
      link,
      project_assets,
      project_skills,
      title
    } = currentProject

    await api.request(
      requests(id).updateProject,
      {
        description,
        is_featured: false,
        is_public: false,
        link,
        project_assets,
        project_skills,
        title
      },
      () => {
        setIsSaveModalShowing(false)
        appDispatchActions.updatedProfile()
        history.goBack()
      }
    )
  }

  return (
    <ProjectPageWrapper background="#f8f8f8">
      {currentProject && (
        <ContentWrapper>
          {profileState.deleteModal && <DeleteModal />}
          {isShareModalShowing && (
            <ShareModal
              toggleShareModal={toggleShareModal}
              projectId={currentProject.id}
            />
          )}
          {isSaveModalShowing && (
            <SaveAsDraftModal
              toggleSaveModal={toggleSaveModal}
              isExistingProject={true}
              onSubmit={handleFormatDataOnEdit}
            />
          )}
          <Link to={`/profile/${userSlug}`}>
            <BackButton>{backArrowIcon} back to profile</BackButton>
          </Link>
          <PersonaContainer>
            {profile && handlePersona()}
            {handlePrivateButtons()}
          </PersonaContainer>
          {currentProject.project_assets && (
            <ProjectImageWrapper>
              {currentProject.project_assets?.length === 0 ? (
                <ProjectImageContainer>
                  <ProjectImage
                    src={
                      'https://storage.googleapis.com/connect-internal-assets/projects/blank-project-image.jpeg'
                    }
                    alt={`Datafolio: ${currentProfile.title}`}
                  />
                </ProjectImageContainer>
              ) : (
                <>
                  <ProjectImageContainer>
                    <ProjectImage
                      src={
                        currentProject.project_assets[currentProjectImageIndex]
                          .asset.file_path
                      }
                      alt={`Datafolio: ${currentProfile.title}`}
                      onClick={() => {
                        appDispatchActions.zoomIn({
                          src:
                            currentProject.project_assets[
                              currentProjectImageIndex
                            ].asset.file_path
                        })
                      }}
                    />
                  </ProjectImageContainer>
                  {currentProjectImageIndex > 0 && (
                    <PrevButton
                      onClick={() => {
                        setCurrentProjectImageIndex(
                          currentProjectImageIndex - 1
                        )
                      }}
                    >
                      <LefttArrow />
                    </PrevButton>
                  )}
                  {currentProjectImageIndex + 1 <
                    currentProject.project_assets.length && (
                    <NextButton
                      onClick={() => {
                        setCurrentProjectImageIndex(
                          currentProjectImageIndex + 1
                        )
                      }}
                    >
                      <RightArrow />
                    </NextButton>
                  )}
                </>
              )}
            </ProjectImageWrapper>
          )}
          <BottomContainer>
            <SectionContainer>
              <SectionHeader>Description</SectionHeader>
              <DescriptionContainer>
                {currentProject.description}
              </DescriptionContainer>
            </SectionContainer>
            <div>
              {currentProject?.link && (
                <SectionContainer>
                  <SectionHeader>External Link</SectionHeader>
                  <ProjectLink href={currentProject.link} target="_blank">
                    {currentProject.link}
                  </ProjectLink>
                </SectionContainer>
              )}
              {currentProject?.project_skills && (
                <SectionContainer>
                  <SectionHeader>
                    Tools, Languages, and Skills Used
                  </SectionHeader>
                  <SkillsContainer>
                    {currentProject?.project_skills?.map(({ name, id }) => (
                      <Pill key={id}>{name}</Pill>
                    ))}
                  </SkillsContainer>
                </SectionContainer>
              )}
            </div>
          </BottomContainer>
        </ContentWrapper>
      )}
      {zoomIn && <ImageZoomModal imgSrc={zoomImgSrc} />}
    </ProjectPageWrapper>
  )
}

export default ProjectPage

const ProjectPageWrapper = styled(PageWrapper)`
  @media (max-width: 768px) {
    padding-left: 0;
    padding-right: 0;
    padding-top: 104px;
  }
`

const BackButton = styled.button`
  align-items: center;
  background: none;
  border: none;
  color: ${({ theme }) => theme.color.text.darkGray};
  display: flex;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 1px;
  text-transform: uppercase;
`

const PersonaContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
`

const TitleSectionContainer = styled.div`
  align-items: center;
  display: flex;
`

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const SubTitleContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 16px;
`

const StyledPersona = styled(Persona)`
  height: 60px;
  margin-right: 16px;
  min-height: 60px;
  min-width: 60px;
  width: 60px;
`

const Dot = styled.div`
  background: #d8d8d8;
  border-radius: 4px;
  height: 8px;
  width: 8px;
`

const TitleHeading = styled.h1`
  font-size: 24px;
  font-weight: 500;
`

const PrivateButtonsContainer = styled.div`
  display: flex;
  gap: 16px;
`

const PrivateButton = styled.button`
  background: ${({ isDark, theme }) =>
    isDark ? theme.color.text.black : 'none'};
  border: ${({ isDark, theme }) =>
    isDark ? 'none' : `1px solid ${theme.color.text.black}`};
  border-radius: 4px;
  color: ${({ isDark, theme }) =>
    isDark ? theme.color.text.white : theme.color.text.black};
  font-size: 16px;
  font-weight: 500;
  padding: ${({ isDark }) => `12px ${isDark ? '32px' : '24px'} 11px`};
  transition: 150ms ease-out;
  &:hover {
    ${({ isDark }) => !isDark && `background: #e9e9e9`};
  }
`

const MoreDropdownContainer = styled.div`
  background-color: ${({ theme }) => theme.color.bg.white};
  border: ${({ theme }) => `1px solid ${theme.color.primary.black0}`};
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 4px;
  position: absolute;
  right: -52px;
  top: 122px;
  z-index: 1;
`

const MoreDropdownButton = styled.button`
  background: none;
  border: none;
  color: ${({ theme }) => theme.color.primary.black3};
  font-weight: normal;
  padding-right: 54px;
  text-align: left;
  text-transform: none;
  width: 100%;
  &:hover {
    background: ${({ theme }) => theme.color.bg.darkNorm};
    color: ${({ theme }) => theme.color.primary.black5};
  }
`

const BottomContainer = styled.div`
  display: flex;
`

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const SectionHeader = styled.h1`
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.56px;
  margin-bottom: 16px;
`

const ProjectLink = styled.a`
  color: #007aff;
  font-size: 16px;
  margin-bottom: 48px;
  text-decoration: underline;
  text-transform: none;
`

const DescriptionContainer = styled.p`
  font-size: 16px;
  letter-spacing: 0.5px;
  margin-right: 32px;
  width: 758px;
`

const SkillsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
`

const Pill = styled.div`
  background: ${({ theme }) => theme.color.bg.lite};
  border: ${({ theme }) => `1px solid ${theme.color.border.tag}`};
  border-radius: 14px;
  color: ${({ theme }) => theme.color.text.tag};
  font-size: 12px;
  font-weight: bold;
  padding: 4px 8px;
`

const ProjectImageWrapper = styled.div`
  margin-bottom: 48px;
  margin-top: 48px;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
  position: relative;
  width: 100%;
`

const ProjectImageContainer = styled.div`
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
`
const ProjectImage = styled.img`
  border-radius: 4px;
  cursor: zoom-in;
  max-height: 100%;
  max-width: 100%;
`

const DirectionButton = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.color.bg.white};
  border: 1px solid rgba(95, 93, 102, 0.5);
  border-radius: 100px;
  box-shadow: rgb(0 0 0 / 50%) 0px 0px 10px;
  cursor: pointer;
  display: flex;
  height: 32px;
  justify-content: center;
  outline: none;
  position: absolute;
  top: 50%;
  width: 32px;
  &:hover {
    transition: all 250ms ease-out 0s;
    box-shadow: rgb(0 0 0 / 80%) 0px 0px 14px;
  }
`

const NextButton = styled(DirectionButton)`
  margin-right: 15px;
  padding-right: 3px;
  right: 0px;
`

const PrevButton = styled(DirectionButton)`
  left: 0px;
  margin-left: 15px;
  padding-left: 3px;
`

const Arrow = styled.div`
  border: solid black;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 3px;
`

const RightArrow = styled(Arrow)`
  transform: rotate(-45deg);
`

const LefttArrow = styled(Arrow)`
  transform: rotate(135deg);
`
