import React from 'react'
import styled from 'styled-components'
import Input from 'components/forms/Input'
import Select from 'components/forms/Select'
import Textarea from 'components/forms/Textarea'

import {
  isStartYearValid,
  useSetStartDateErrors
} from 'containers/ProfilePage/helpers/areYearsValidInForm'
import { months, populateYears } from 'containers/ProfilePage/helpers/datetime'

const PublicationForm = ({ formProps, data }) => {
  const { register, errors, watch, setError, clearErrors } = formProps
  const { publication_month, publication_year } = watch()
  const years = populateYears(new Date().getFullYear())
  const startDate = isStartYearValid(publication_month, publication_year)
  useSetStartDateErrors(
    startDate,
    setError,
    clearErrors,
    'Publication date cannot be in the future'
  )

  return (
    <>
      <Input
        label="Title"
        testId="publication-input-title"
        type="text"
        name="title"
        placeholder="Ex: Data Management and Analysis Methods"
        register={register}
        errors={errors}
        required="required"
      />
      <Input
        label="Publication/Publisher"
        testId="publication-input-publisher"
        type="text"
        name="publisher"
        placeholder="Ex: Data Science Journal"
        register={register}
        errors={errors}
      />
      <Input
        label="Author(s)"
        testId="publication-input-authors"
        type="text"
        name="authors"
        placeholder="Use commas to separate additional author name(s)"
        register={register}
        errors={errors}
      />
      <DateWrapper>
        <DateContainer>
          <DateContainerField>
            <Select
              label="Publication Date"
              type="text"
              name="publication_month"
              options={months}
              placeholder="Month"
              register={register}
              errors={errors}
              initialValue={data && data.publication_month}
              required="required"
            />
          </DateContainerField>
          <DateContainerField>
            <Select
              blankLabel={true}
              label="Year"
              type="text"
              name="publication_year"
              options={years}
              placeholder="Year"
              register={register}
              errors={errors}
              initialValue={data && data.publication_year}
            />
          </DateContainerField>
        </DateContainer>
      </DateWrapper>
      <Input
        label="Publication URL"
        testId="publication-input-link"
        type="text"
        name="link"
        placeholder=""
        register={register}
        errors={errors}
      />
      <Textarea
        label="Description"
        testId="education-textarea-accomplishment"
        type="text"
        name="description"
        placeholder=""
        register={register}
        errors={errors}
        showCaption={true}
        maxlength="280"
      />
    </>
  )
}

export default PublicationForm

const DateWrapper = styled.div`
  display: flex;
  flex: 1 1 auto;
  gap: 15px;
  width: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 0px;
  }
`

const DateContainer = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
  @media (max-width: 768px) {
    align-items: flex-end;
  }
  @media (max-width: 420px) {
    flex-direction: column;
    gap: 0px;
  }
  :last-child > :first-child > :first-child > :first-child > :first-child {
    margin-right: 0px;
  }
`

const DateContainerField = styled.div`
  width: 100%;
`
