import React from 'react'
import styled from 'styled-components'
import { Container } from 'components/styled/Containers'
import settingsIcon from 'components/forms/assets/settingsIcon'

const SettingsPreferenceInfo = () => {
  return (
    <PreferenceContainer>
      <SVGContainer>{settingsIcon}</SVGContainer>
      <PreferenceTitle>Preferences</PreferenceTitle>
      <PreferenceDescription>
        These questions help our employer partners find you for their job
        opportunities. Your responses{' '}
        <b>will not be featured on your public profile.</b>
      </PreferenceDescription>
    </PreferenceContainer>
  )
}

export default SettingsPreferenceInfo

const SVGContainer = styled(Container)`
  margin-bottom: 16px;
  svg {
    height: 30px;
    circle {
      stroke: ${({ theme }) => theme.color.primary.black6};
    }
    line {
      stroke: ${({ theme }) => theme.color.primary.black6};
    }
  }
`

const PreferenceContainer = styled(Container)`
  background: ${({ theme }) => theme.color.bg.lightPurp};
  border: 1px solid ${({ theme }) => theme.color.border.purp};
  border-radius: 4px;
  margin-bottom: 40px;
  max-width: 285px;
  padding: 32px 16px;
`

const PreferenceTitle = styled.p`
  color: ${({ theme }) => theme.color.primary.black6};
  font-size: 16px;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  letter-spacing: 0.4px;
  line-height: 16px;
  margin-bottom: 16px;
`

const PreferenceDescription = styled.p`
  color: ${({ theme }) => theme.color.primary.black3};
  font-size: 14px;
  font-weight: ${({ theme }) => theme.font.weight.regular};
  letter-spacing: 0.25px;
  line-height: 17px;
  b {
    color: ${({ theme }) => theme.color.primary.black3};
    font-weight: bold;
  }
`
