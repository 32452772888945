const profileActionTypes = {
  REMOVE_DELETE_MODAL_DATA: 'REMOVE_DELETE_MODAL_DATA',
  SET_DELETE_MODAL: 'SET_DELETE_MODAL',
  SET_UPDATE_FORM: 'SET_UPDATE_FORM',
  SET_CREATE_FORM: 'SET_CREATE_FORM',
  FORM_SUBMITTED: 'FORM_SUBMITTED',
  REMOVE_FORM_DATA: 'REMOVE_FORM_DATA',
  CREATING_PROJECT_IN_FORM: 'CREATING_PROJECT_IN_FORM'
}

export default profileActionTypes
