import React from 'react'

const bookmarkOutline = (
  <svg
    width="12px"
    height="15px"
    viewBox="0 0 12 15"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        transform="translate(-1258.000000, -609.000000)"
        fill="#373440"
        fillRule="nonzero"
      >
        <g transform="translate(152.000000, 250.000000)">
          <g transform="translate(0.000000, 32.000000)">
            <g transform="translate(1.000000, 310.000000)">
              <g transform="translate(1095.000000, 8.000000)">
                <path d="M20.3333333,9.20430108 C20.8856181,9.20430108 21.3333333,9.65201633 21.3333333,10.2043011 L21.3333333,22.7900875 C21.3333333,23.3423723 20.8856181,23.7900875 20.3333333,23.7900875 C20.0681168,23.7900875 19.8137629,23.6847307 19.6262266,23.4971943 L16.7071068,20.5780745 C16.3165825,20.1875502 15.6834175,20.1875502 15.2928932,20.5780745 L12.3737734,23.4971943 C11.9832492,23.8877186 11.3500842,23.8877186 10.9595599,23.4971943 C10.7720235,23.3096579 10.6666667,23.055304 10.6666667,22.7900875 L10.6666667,10.2043011 C10.6666667,9.65201633 11.1143819,9.20430108 11.6666667,9.20430108 L20.3333333,9.20430108 Z M20.3333333,10.2043011 L11.6666667,10.2043011 L11.6666667,22.7900875 L14.5857864,19.8709677 C15.3257272,19.131027 16.5012114,19.0920827 17.2869988,19.754135 L17.4142136,19.8709677 L20.3333333,22.7900875 L20.3333333,10.2043011 Z"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default bookmarkOutline
