import styled from 'styled-components'

const Container = styled.div`
  position: relative;
  height: ${props => props.height};
  width: ${props => props.width};
  background: ${props => props.background};
  border: ${props => props.border};
  padding: ${props => props.padding};
  margin: ${props => props.margin};
  min-height: ${props => props.minHeight};
  max-width: ${props => props.maxWidth};
  z-index: ${props => props.zindex};
  text-align: ${props => props.text};
  transition: ${props => props.transition};
  &.pointer {
    cursor: pointer;
  }
`

const FlexContainer = styled(Container)`
  display: flex;
  flex-direction: ${props => props.direction || 'row'};
  justify-content: ${props => props.justify || 'flex-start'};
  align-items: ${props => props.align || 'flex-start'};
  flex-wrap: ${props => props.wrap};
`

const AbsoluteContainer = styled(Container)`
  position: absolute;
  transform: ${props => props.transform};
  top: ${props => props.top};
  bottom: ${props => props.bottom};
  left: ${props => props.left};
  right: ${props => props.right};
`

const FixedContainer = styled(AbsoluteContainer)`
  position: fixed;
`

const FormContainer = styled.form`
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
`

const MaxWidthContainer = styled(Container)`
  width: 100%;
  max-width: 1140px;
  margin: 0 auto;
`

const Divider = styled(Container)`
  height: 1px;
  width: 100%;
  background: #f0f0f0;
  margin-bottom: 24px;
`

export {
  Container,
  FlexContainer,
  FixedContainer,
  AbsoluteContainer,
  FormContainer,
  MaxWidthContainer,
  Divider
}
