import { useEffect } from 'react'
import moment from 'moment'

// Helper functions
export const isStartYearValid = (start_month, start_year) => {
  if (start_month && start_year) {
    const startDate = moment(new Date(`${start_month} 1, ${start_year}`))
    return startDate.isBefore()
  } else {
    return true
  }
}

export const isEndYearValid = (
  start_month,
  start_year,
  end_month,
  end_year
) => {
  if (start_month && start_year && end_month && end_year) {
    const startDate = moment(new Date(`${start_month} 1, ${start_year}`))
    const endDate = moment(new Date(`${end_month} 1, ${end_year}`))
    return endDate.diff(startDate)
  } else {
    return 1
  }
}

// Helpers hooks
export const useSetInvalidInputError = (
  setError,
  clearErrors,
  watch,
  formState,
  formActionType
) => {
  const { start_month, start_year, end_month, end_year } = watch()
  const oneFalse = !start_month || !start_year || !end_month || !end_year
  const oneTrue = start_month || start_year || end_month || end_year
  const formCheck = formActionType === 'update' ? true : formState.submitCount

  useEffect(() => {
    if (oneTrue && oneFalse && formCheck) {
      setError('inputInvalid', {
        type: 'manual',
        message: 'All date inputs need to be filled out'
      })
    } else {
      clearErrors('inputInvalid')
    }
  }, [
    setError,
    clearErrors,
    start_month,
    start_year,
    end_month,
    end_year,
    formCheck,
    oneTrue,
    oneFalse
  ])
}

export const useSetStartDateErrors = (
  startDate,
  setError,
  clearErrors,
  message
) => {
  useEffect(() => {
    if (!startDate) {
      setError('startDate', {
        type: 'manual',
        message: message ? message : 'Start Date cannot be in the future'
      })
    } else {
      clearErrors('startDate')
    }
  }, [setError, clearErrors, startDate, message])
}

export const useSetEndDateErrors = (
  endDate,
  setError,
  clearErrors,
  message
) => {
  useEffect(() => {
    if (endDate < 0) {
      setError('endDate', {
        type: 'manual',
        message: message
          ? message
          : 'End Date cannot be earlier than Start Date'
      })
    } else {
      clearErrors('endDate')
    }
  }, [setError, clearErrors, endDate, message])
}
