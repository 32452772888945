import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import useAppContext from 'services/context/appContext/useAppContext'

const useIsPublic = () => {
  const [isPublic, setIsPublic] = useState(false)
  const { state } = useAppContext()
  const { user } = useParams()
  const { isAuthenticated, profileData } = state

  useEffect(() => {
    const slug = profileData && profileData.slug
    const isUserIsOnOtherProfile = slug !== user && user !== 'project'
    setIsPublic(isUserIsOnOtherProfile)

    return () => setIsPublic(false)
  }, [user, profileData])

  return { isAuthenticated, isPublic }
}

export default useIsPublic
