import React from 'react'

const clockIcon = (
  <svg
    width="14px"
    height="14px"
    viewBox="0 0 14 14"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      opacity="0.5"
    >
      <g
        transform="translate(-328.000000, -86.000000)"
        fill="#000000"
        fillRule="nonzero"
      >
        <g transform="translate(105.000000, 37.000000)">
          <g transform="translate(222.468060, 47.787941)">
            <g transform="translate(0.531940, 1.212059)">
              <path
                d="M7,0 C3.14004518,0 0,3.14004518 0,7 C0,10.8599548 3.14004518,14 
              7,14 C10.8599548,14 14,10.8599548 14,7 C14,3.14004518 10.8599548,0 7,0 Z 
              M10.3291016,10.620697 C10.2153473,10.7344513 10.0660248,10.7917023 9.91670228,
              10.7917023 C9.76737975,10.7917023 9.61795045,10.7344513 9.50430299,10.620697 
              L6.58760071,7.70410156 C6.47790527,7.595047 6.41670228,7.44679261 6.41670228,
              7.29170228 L6.41670228,3.5 C6.41670228,3.1774292 6.67796325,2.91670228 7,2.91670228 
              C7.32203675,2.91670228 7.58329772,3.1774292 7.58329772,3.5 L7.58329772,7.05020143 
              L10.3291016,9.79589844 C10.5571442,10.0240479 10.5571442,10.3926544 10.3291016,10.620697 
              L10.3291016,10.620697 Z"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default clockIcon
