import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Link, useLocation } from 'react-router-dom'
import { FlexContainer } from 'components/styled/Containers'

const SettingsToggle = () => {
  const [currentToggle, setCurrentToggle] = useState(null)
  const location = useLocation()

  // Set survey options to iterate through
  const surveyOptions = [
    {
      num: 1,
      text: 'Profile Settings',
      url: '/settings/basic-profile'
    },
    {
      num: 2,
      text: 'General Preferences',
      url: '/settings/general-preferences'
    },
    {
      num: 3,
      text: 'Employer Preferences',
      url: '/settings/employer-preferences'
    }
  ]

  // Sets the current toggle based on what url is
  useEffect(() => {
    const findCurrentToggle = surveyOptions.find(option => {
      return option.url === location.pathname
    })

    setCurrentToggle(findCurrentToggle)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  return (
    <SettingsContainer>
      {currentToggle &&
        surveyOptions.map(option => {
          const highlighted = currentToggle.num === option.num

          return (
            <NumberContainer key={option.text}>
              <Option highlighted={highlighted}>
                <Link to={option.url}>{option.text}</Link>
              </Option>
            </NumberContainer>
          )
        })}
    </SettingsContainer>
  )
}

export default SettingsToggle

const Option = styled.span`
  cursor: pointer;
  color: ${({ theme, highlighted }) =>
    highlighted ? theme.color.primary.black5 : theme.color.primary.black3};
  font-size: 14px;
  font-weight: ${({ highlighted }) => (highlighted ? 500 : 'normal')};
  text-transform: none;
  letter-spacing: 0.1px;
  text-decoration: none;
  &:hover {
    color: ${({ theme }) => theme.color.primary.black5};
  }
  a {
    color: ${({ theme, highlighted }) =>
      highlighted ? theme.color.primary.black5 : theme.color.primary.black3};
    font-size: 14px;
    font-weight: ${({ highlighted }) => (highlighted ? 500 : 'normal')};
    text-transform: none;
    letter-spacing: 0.1px;
    text-decoration: none;
  }
  a:hover {
    color: ${({ theme }) => theme.color.primary.black5};
  }
`

const NumberContainer = styled(FlexContainer)`
  align-items: center;
  margin-bottom: 16px;
`

const SettingsContainer = styled(FlexContainer)`
  flex-direction: column;
  position: fixed;
  @media (max-width: ${({ theme }) => theme.media.tablet}) {
    position: relative;
    margin-left: 0px;
    margin-bottom: 40px;
  }
`
