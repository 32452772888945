import React from 'react'
import styled from 'styled-components'
import Carousel from 'components/general/Carousel'
import { FlexContainer } from 'components/styled/Containers'
import { ContentWrapper } from 'components/styled/Wrappers'
import { datafolioExamples } from 'containers/HomePage/helpers/content'

const DatafolioCarousel = () => (
  <DatafolioCarouselWrapper>
    <ContentWrapper>
      <Carousel slides={datafolioExamples} />
    </ContentWrapper>
  </DatafolioCarouselWrapper>
)

export default DatafolioCarousel

const DatafolioCarouselWrapper = styled(FlexContainer)`
  position: relative;
  max-height: 900px;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.color.text.black};
  width: 100%;
  padding: 64px 16px;
`
