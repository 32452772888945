import React from 'react'
import Input from 'components/forms/Input'
import Textarea from 'components/forms/Textarea'

const AwardForm = ({ formProps }) => {
  const { register, errors } = formProps

  return (
    <>
      <Input
        label="Name of Award"
        testId="award-input-name"
        type="text"
        name="name"
        placeholder="Ex: Sustainability Hackathon 1st Place"
        register={register}
        errors={errors}
        required="required"
      />
      <Input
        label="Year"
        testId="award-input-year"
        type="text"
        name="year"
        placeholder="Year"
        register={register}
        errors={errors}
        required="required"
      />
      <Textarea
        label="Description"
        testId="award-textarea-description"
        type="text"
        name="description"
        placeholder="Briefly describe the award"
        register={register}
        errors={errors}
        showCaption={true}
      />
      <Input
        className="hidden"
        testId="award-input-order"
        type="number"
        name="order"
        placeholder="Order"
        register={register}
        errors={errors}
      />
    </>
  )
}

export default AwardForm
