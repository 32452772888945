import React, { useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Swipeable } from 'react-swipeable'
import { FlexContainer, Container } from 'components/styled/Containers'

const MobileCarousel = ({ children, slideType }) => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const [userIsSwiping, setUserIsSwiping] = useState(false)

  const nextSlide = () => {
    const next = (currentIndex + 1) % children.length
    setCurrentIndex(next)
  }

  const prevSlide = () => {
    const prev = (currentIndex - 1 + children.length) % children.length
    setCurrentIndex(prev)
  }

  const swipeConfig = {
    onSwipedLeft: () => nextSlide(),
    onSwipedRight: () => prevSlide(),
    onSwiping: () => setUserIsSwiping(true),
    onSwiped: () => setUserIsSwiping(false),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
    trackTouch: true
  }

  return (
    <Swipeable {...swipeConfig} style={{ width: '100%' }}>
      <MobileCarouselWrapper
        className={slideType}
        justify="space-between"
        align="center"
      >
        <Container
          width="100%"
          className={`view col-lg-${
            slideType === 'project-carousel' ? '12' : '8'
          }`}
        >
          <div className="current-slide">
            {children.map((slide, index) => (
              <div
                className={`slide ${index === currentIndex &&
                  'active'} ${userIsSwiping && 'swiping'}`}
                key={`slide-${slide.key || index}`}
              >
                {slide}
              </div>
            ))}
          </div>
          <FlexContainer justify="center" align="center" className="dots">
            {children.map(({ key }, index) => (
              <span
                key={`dot-${key || index}`}
                className={`dot ${index === currentIndex && 'active'}`}
                onClick={() => setCurrentIndex(index)}
              ></span>
            ))}
          </FlexContainer>
        </Container>
      </MobileCarouselWrapper>
    </Swipeable>
  )
}

export default MobileCarousel

// -- PROP DESCRIPTIONS -- //
/*
  slides: all the slides in the carousel
  slideType: sets style type
*/

MobileCarousel.propTypes = {
  slides: PropTypes.array,
  slideType: PropTypes.string
}

MobileCarousel.defaultProps = {
  slides: [0, 1, 2, 3, 4, 5],
  slideType: ''
}

const MobileCarouselWrapper = styled(FlexContainer)`
  width: 100%;
  min-height: 200px;
  height: auto;
  margin: 8px auto;
  .current-slide {
    width: 100%;
    height: auto;
    margin: 0 0 8px;
    padding: 16px;
    position: relative;
    background: transparent;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow: hidden;
    .slide {
      width: 0%;
      height: auto;
      visibility: hidden;
      opacity: 0;
      transition: all 300ms ease-in-out 150ms;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: zoom-in;
      flex-shrink: 1;
      margin: 0 0 0 0;
      padding: 0 0 0 0;
      img {
        width: 100%;
        height: auto;
      }
      &.active {
        visibility: visible;
        opacity: 1;
        height: 100%;
        flex-shrink: 0;
        width: 100%;
      }
      &.swiping {
        cursor: grab;
      }
    }
  }
  .dots {
    margin: 16px auto;
    width: 100%;
    .dot {
      background: ${({ theme }) => theme.color.bg.liteGray};
      border-radius: 2px;
      height: 4px;
      width: 17px;
      margin: 0 4px;
      cursor: pointer;
      transition: all 300ms ease-in-out 150ms;
      &:hover,
      &.active {
        background: ${({ theme }) => theme.color.bg.modal};
      }
    }
  }
`
