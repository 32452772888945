import styled from 'styled-components'

const Segment = styled.div`
  width: ${({ width }) => width || 'calc(100% - 32px)'};
  overflow: hidden;
  padding: 0px 16px;
  margin: 0 0 16px;
  display: block;
  height: auto;
  background: ${({ theme }) => theme.color.bg.white};
  border: 1px solid ${({ theme }) => theme.color.border.norm};
  border-radius: 4px;
  &.shadow {
    pointer-events: none;
    background: ${({ theme }) => theme.color.bg.liteGray};
    width: 100%;
    height: 280px;
  }
  @media screen and (max-width: 1200px) {
    width: 100%;
  }
  @media screen and (max-width: 568px) {
    padding: 0 0 0 0;
  }
`

export default Segment
