import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import Input from 'components/forms/Input'
import InputSubmit from 'components/forms/InputSubmit'
import { FormContainer } from 'components/styled/Containers'
import {
  AuthFormTitle,
  ErrorMessage,
  AuthFormLinkText
} from 'components/styled/Text'
import FormDivider from 'containers/AuthPage/styled/FormDivider'
import useAppContext from 'services/context/appContext/useAppContext'
import { loginValidation } from 'services/validations/authValidation'

const Login = ({ formErrorMessage, setFormErrorMessage }) => {
  const { api, requests, appDispatchActions } = useAppContext()
  const methods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(loginValidation),
    shouldFocusError: false
  })

  // Remove error state when page changes
  const handleRemoveErrors = () => {
    setFormErrorMessage(null)
  }

  // On submission failure
  const onFailure = () => {
    setFormErrorMessage(
      "We couldn't log you in at this time. Try resetting your password or signing up."
    )
  }

  // On submission success
  const onSuccess = response => {
    appDispatchActions.resetAppState()
    handleRemoveErrors()
    api.setTokens(response)
    appDispatchActions.setAuthenticationStatus({ status: true })
  }

  // Submit login form
  const onSubmit = async data => {
    await api.request(
      requests().token,
      data,
      response => onSuccess(response),
      () => onFailure()
    )
  }

  return (
    <FormProvider {...methods}>
      <FormContainer onSubmit={methods.handleSubmit(onSubmit)}>
        <AuthFormTitle>Log In to Account</AuthFormTitle>
        <Input
          type="text"
          name="email"
          placeholder="Email"
          autoComplete="email"
        />
        <Input
          type="password"
          name="password"
          placeholder="Password"
          autoComplete="current-password"
        />
        <SubmitBtnContainer>
          <InputSubmit
            color="#373440"
            background="#FFFFFF"
            border="#373440"
            text="Log in"
          />
        </SubmitBtnContainer>
        {formErrorMessage && <ErrorMessage>{formErrorMessage}</ErrorMessage>}
        <FormDivider />
        <AuthFormLinkText>
          Forgot your password?{' '}
          <Link onClick={handleRemoveErrors} to="/request-new-password">
            Reset it
          </Link>
        </AuthFormLinkText>
      </FormContainer>
    </FormProvider>
  )
}

export default Login

const SubmitBtnContainer = styled.div`
  margin: 28px 0 4px 0;
`
