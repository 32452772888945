import React, { useState } from 'react'
import { ExtendedIconAction } from 'components/styled/Buttons'
import { FlexContainer, Container } from 'components/styled/Containers'
import { LogoInsetCard, CardBody } from 'components/styled/Cards'
import { SchoolIcon } from 'components/graphics/Icons'
import useProfileContext from 'services/context/profileContext/useProfileContext'
import useIsPublic from 'services/hooks/useIsPublic'
import { handleDescription } from 'services/helpers/formatters'
import ProjectCarousel from './ProjectCarousel'
import editIcon from '../assets/editIcon'

const EducationExperience = ({ education, startDate, endDate }) => {
  const [editShowing, setEditShowing] = useState(false)
  const { profileDispatchActions } = useProfileContext()
  const { isAuthenticated, isPublic } = useIsPublic()

  const handleSetUpdateForm = education => {
    profileDispatchActions.setUpdateForm(education, 'educations')
  }

  const {
    education_projects,
    id,
    school_name,
    degree,
    description,
    location
  } = education

  return (
    <>
      <LogoInsetCard
        onMouseOver={() => setEditShowing(true)}
        onMouseLeave={() => setEditShowing(false)}
        key={`exp-${id}`}
      >
        <FlexContainer justify="space-between">
          <FlexContainer>
            <Container margin="0 20px 0 0">
              <SchoolIcon />
            </Container>
            <Container>
              <div className="header">
                <p className="company">{school_name}</p>
              </div>
              {degree && (
                <div className="subheader">
                  <p className="role">
                    <b>{degree}</b>
                  </p>
                  {startDate && (
                    <p className="sub">
                      {startDate}
                      {` - ${endDate}`}
                    </p>
                  )}
                  {location && <p className="sub">{location}</p>}
                </div>
              )}
              {description && (
                <CardBody>{handleDescription(description)}</CardBody>
              )}
            </Container>
          </FlexContainer>
          {isAuthenticated && !isPublic && editShowing && (
            <Container>
              <ExtendedIconAction
                onClick={() => handleSetUpdateForm(education)}
              >
                {editIcon}
              </ExtendedIconAction>
            </Container>
          )}
        </FlexContainer>
      </LogoInsetCard>
      {education_projects && (
        <ProjectCarousel projects={education_projects} isPublic={isPublic} />
      )}
    </>
  )
}

export default EducationExperience
