import styled from 'styled-components'

const Logo = styled.img`
  width: ${({ width }) => width || 'auto'};
  height: ${({ height }) => height || 'auto'};
`

const Persona = styled.div`
  min-width: 80px;
  width: 80px;
  min-height: 80px;
  height: 80px;
  border-radius: 50%;
  background: ${({ backgroundImage }) =>
    backgroundImage
      ? `url("${backgroundImage}") center center no-repeat`
      : 'none'};
  background-color: ${({ theme }) => theme.color.primary.black1};
  background-size: cover !important;
  box-shadow: 1px 1px 2px ${({ theme }) => theme.color.border.norm};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  overflow: hidden;
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0px;
  transition: box-shadow 300ms ease-out, transform 150ms ease-out;
  position: relative;
  img {
    width: 100%;
    height: auto;
  }
  &.mini {
    min-width: 40px;
    width: 40px;
    min-height: 40px;
    font-size: 14px;
    font-weight: bold;
    height: 16px;
    letter-spacing: 0.25px;
  }
`
export { Logo, Persona }
