import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import useAppContext from 'services/context/appContext/useAppContext'

const useFetchPublicProfile = () => {
  const { state, api, requests, appDispatchActions } = useAppContext()
  const { user } = useParams()
  const shouldFetch = !state.profileData || state.profileData.slug !== user
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchPublicProfile = async () => {
      await api.request(requests(user).publicProfile, {}, response => {
        appDispatchActions.setPublicProfileData({ profile: response.data })
      })
      setLoading(false)
    }

    shouldFetch ? fetchPublicProfile() : setLoading(false)

    return () => {
      appDispatchActions.setPublicProfileData({ profile: null })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  return { state, loading }
}

export default useFetchPublicProfile
