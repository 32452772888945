import React, { useEffect } from 'react'
import styled from 'styled-components'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import Input from 'components/forms/Input'
import InputSubmit from 'components/forms/InputSubmit'
import { Container } from 'components/styled/Containers'
import AutoComplete from 'components/forms/AutoComplete'
import { SurveyTitle } from 'components/styled/Text'
import { questionnaire } from 'containers/QuestionnairePage/messages/messages'
import useAppContext from 'services/context/appContext/useAppContext'
import useScrollToTop from 'services/hooks/useScrollToTop'
import { basicInfoValidation } from 'services/validations/questionnaireValidations'
import useFetchLocations from 'containers/QuestionnairePage/hooks/useFetchLocations'

const BasicInfo = () => {
  useScrollToTop()
  const methods = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(basicInfoValidation),
    shouldFocusError: true
  })

  const fetchLocations = useFetchLocations()
  const {
    api,
    requests,
    appDispatchActions,
    history,
    state: { profileData }
  } = useAppContext()
  const { handleSubmit, errors, getValues, setValue } = methods

  useEffect(() => {
    setValue('name', profileData?.name)
  }, [profileData, setValue])

  const onFailure = error => {
    console.error(error)
  }

  const onSuccess = response => {
    history.push('/questionnaire/general-preferences')
    appDispatchActions.setProfileData({ profile: response.data })
  }

  const onSubmit = async data => {
    const values = getValues()
    data.geo_location = values.location
    data.location = values.location.label

    api.request(
      requests().createBasic,
      data,
      response => {
        onSuccess(response)
      },
      error => onFailure(error)
    )
  }

  const { question1, question2, question3 } = questionnaire.basicInfo

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <SurveyTitle>Basic Info</SurveyTitle>
        <Input
          label={question1.label}
          testId={question1.testId}
          type={question1.text}
          name={question1.questionName}
          placeholder={question1.placeholder}
          autoFocus={true}
          errors={errors}
          required="required"
        />
        <AutoComplete
          requestFunc={fetchLocations}
          placeholder={question2.placeholder}
          label={question2.label}
          errors={errors}
          name={question2.questionName}
          required
        />
        <Input
          label={question3.label}
          testId={question3.testId}
          type={question3.text}
          name={question3.questionName}
          placeholder={question3.placeholder}
          errors={errors}
        />
        <SubmitBtnContainer>
          <InputSubmit
            background="#FFFFFF"
            color="#373440"
            width="#100px"
            border="#363540"
            testId="input-submit"
            text="Save"
          />
          <EditMessageText>
            These preferences may be edited later.
          </EditMessageText>
        </SubmitBtnContainer>
      </form>
    </FormProvider>
  )
}

export default BasicInfo

const SubmitBtnContainer = styled(Container)`
  align-items: center;
  display: flex;
`

const EditMessageText = styled.p`
  color: #3b3b3b;
  font-size: 14px;
  font-weight: 500;
  margin: 0 0 0 16px;
`
