import React from 'react'
import styled from 'styled-components'
import { Route, Switch } from 'react-router-dom'
import ProjectForm from 'containers/ProfilePage/components/projectModal/ProjectForm'
import WelcomeHelper from 'containers/ProfilePage/components/welcomeModal/WelcomeHelper'
import DeleteModal from 'containers/ProfilePage/components/deleteModal/DeleteModal'
import ImageZoomModal from 'components/general/ImageZoomModal'
import Profile from './Profile'
import { PageWrapper } from 'components/styled/Wrappers'
import useAppContext from 'services/context/appContext/useAppContext'
import useProfileContext from 'services/context/profileContext/useProfileContext'

const PrivateProfile = () => {
  const { state } = useAppContext()
  const { profileState } = useProfileContext()
  const { profileData, zoomIn, zoomImgSrc } = state
  const { configuration } = profileData
  const showWelcomeScreen = configuration.show_welcome_screen

  return (
    <ProfilePageWrapper background="#f8f8f8">
      {zoomIn && <ImageZoomModal imgSrc={zoomImgSrc} />}
      {showWelcomeScreen && <WelcomeHelper />}
      {profileState.deleteModal && <DeleteModal />}
      <Switch>
        <Route exact path={`/profile/project`} component={ProjectForm} />
        <Route
          exact
          path={'/profile/project/:projectId'}
          component={ProjectForm}
        />
        <Route
          exact
          path={`/profile/:user`}
          children={<Profile profileData={profileData} />}
        />
        <Route
          path={`/profile/:user/projects`}
          children={<Profile profileData={profileData} isProjects />}
        />
      </Switch>
    </ProfilePageWrapper>
  )
}

export default PrivateProfile

const ProfilePageWrapper = styled(PageWrapper)`
  @media (max-width: 768px) {
    padding-left: 0;
    padding-right: 0;
    padding-top: 104px;
  }
`
