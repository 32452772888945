import React, { Fragment } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import moment from 'moment'
import Segment from 'components/styled/Segments'
import {
  useProfileContext,
  reorderChronologically
} from 'services/modules/helpers'
import useIsPublic from 'services/hooks/useIsPublic'
import { ExpandableContent, Experience } from 'services/modules/components'

const WorkExperiences = ({ experiences, isPrivate }) => {
  const { profileDispatchActions } = useProfileContext()
  const { isPublic } = useIsPublic()

  // Render create form
  const handleSetCreateForm = () => {
    profileDispatchActions.setCreateForm('work_experiences')
  }

  if (isPublic && !experiences.length) return null

  return (
    <Segment>
      <ExpandableContent
        isPrivate={isPrivate}
        header={'Experience'}
        addFormAction={handleSetCreateForm}
        actionType="add"
        hasContent={experiences.length > 0}
      >
        {reorderChronologically(experiences, 'experiences').map(
          (experience, index) => {
            const startDate = experience.start_date
              ? moment(experience.start_date).format('MMM YYYY')
              : ''
            const endDate =
              experience.end_date && experience.end_date !== 'Current'
                ? moment(experience.end_date).format('MMM YYYY')
                : 'Present'

            return (
              <Fragment key={experience.id}>
                <Experience
                  experience={experience}
                  endDate={endDate}
                  startDate={startDate}
                />
                {experiences.length - 1 !== index && <Divider />}
              </Fragment>
            )
          }
        )}
      </ExpandableContent>
    </Segment>
  )
}

export default WorkExperiences

WorkExperiences.propTypes = {
  experiences: PropTypes.array
}

const Divider = styled.div`
  background: ${({ theme }) => theme.color.border.norm};
  height: 1px;
  margin-bottom: 32px;
`
