import React from 'react'
import styled from 'styled-components'
import pinIcon from 'containers/JobBoardPage/assets/pinIcon'

const JobLocationInfo = ({ geoLocations, remote }) => {
  const locationLabels = geoLocations.features.map(
    ({ properties }) => properties.label
  )
  return (
    <JobLocationInfoStyled>
      {pinIcon}
      {locationLabels.length === 1 && locationLabels[0]}
      {locationLabels.length > 1 && (
        <Tooltip>
          {`${locationLabels[0]} + ${locationLabels.length - 1}`}
          <div>
            {locationLabels.map(label => (
              <span key={'geo_location_' + label}> {label}</span>
            ))}
          </div>
        </Tooltip>
      )}
      {remote && ', Open to Remote'}
    </JobLocationInfoStyled>
  )
}

export default JobLocationInfo

const JobLocationInfoStyled = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.color.text.subText};
  display: flex;
  font-size: 14px;
  margin-right: 16px;
  svg {
    margin-right: 5px;
  }
`
const Tooltip = styled.div`
  border-bottom: 0.5px dotted #5f5d66;
  color: ${({ theme }) => theme.color.text.subText};
  cursor: pointer;
  font-size: inherit;
  position: relative;
  div {
    display: none;
  }
  :hover div {
    background-color: ${({ theme }) => theme.color.bg.dark};
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    padding: 5px 15px;
    position: absolute;
    width: max-content;
    z-index: 20;
    span {
      color: ${({ theme }) => theme.color.text.lite};
      font-size: 12px;
      text-decoration: none;
    }
  }
`
