import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Button } from 'services/modules/components'
import { ContentWrapper } from 'components/styled/Wrappers'
import { Container } from 'components/styled/Containers'
import useAppContext from 'services/context/appContext/useAppContext'
import useProfileContext from 'services/context/profileContext/useProfileContext'
import useOnboarding from 'services/hooks/useOnboarding'

const InitialOnboarding = ({ openBasicProfileModal }) => {
  const {
    notification,
    notificationQueue,
    totalNotifications,
    actionItemsRemaining,
    dispatchNotifications
  } = useOnboarding()
  const { profileDispatchActions } = useProfileContext()
  const { history, api, requests, appDispatchActions } = useAppContext()
  const remainingItems = totalNotifications - 1
  const completedItems = remainingItems - actionItemsRemaining

  const onFailure = error => {
    console.log(error)
  }

  const onSuccess = response => {
    appDispatchActions.updatedProfile()
  }

  // Generic submission handler
  const updateProfileConfig = () => {
    api.request(
      requests().updateProfileConfiguration,
      { has_dismissed_public_profile_notification: true },
      response => {
        onSuccess(response)
      },
      error => onFailure(error)
    )
  }

  const handleAddAction = name => {
    if (name === 'projects') {
      history.push(`/profile/project`)
    } else if (name === 'summary' || name === 'resume') {
      openBasicProfileModal()
    } else if (name === 'skills') {
      profileDispatchActions.setUpdateForm([], 'skills')
    } else if (name === 'privacy') {
      updateProfileConfig()
      dispatchNotifications(name)
    } else {
      profileDispatchActions.setCreateForm(name)
    }
  }

  return (
    <MobileContainer>
      <ContentWrapper>
        {!!notificationQueue.length && notification && (
          <NotificationWrapper>
            {notificationQueue && (
              <>
                <ProgressText>
                  Complete Your Profile ({completedItems}/{remainingItems})
                </ProgressText>
                <ProgressBarContainer>
                  <ProgressBar
                    percentComplete={
                      100 - (actionItemsRemaining / remainingItems) * 100
                    }
                  />
                </ProgressBarContainer>
              </>
            )}
            <NotificationContainer>
              <NotificationContentContainer>
                <IconSet>{notification.icon}</IconSet>
                <CopyContainer>
                  <NotificationHeader>
                    {notification.header}
                    {notification.link && (
                      <ProfileURLSpan> {notification.link}</ProfileURLSpan>
                    )}
                  </NotificationHeader>
                  <NotificationText>{notification.body}</NotificationText>
                </CopyContainer>
              </NotificationContentContainer>
              <NotificationActions>
                {notification.dismissable && (
                  <DismissalSpan
                    onClick={() => dispatchNotifications(notification.name)}
                  >
                    Remind me later
                  </DismissalSpan>
                )}
                <ActionButton
                  onClick={() => handleAddAction(notification.name)}
                >
                  {notification.action}
                </ActionButton>
              </NotificationActions>
            </NotificationContainer>
          </NotificationWrapper>
        )}
      </ContentWrapper>
    </MobileContainer>
  )
}

export default InitialOnboarding

// -- PROP DESCRIPTIONS -- //
// openBasicProfileModal: opens basic profile modal

InitialOnboarding.propTypes = {
  openBasicProfileModal: PropTypes.func
}

const MobileContainer = styled(Container)`
  @media (max-width: 420px) {
    padding: 0 20px;
  }
`

const NotificationWrapper = styled.div`
  background: ${({ theme, bgColor }) => bgColor || theme.color.bg.lite};
  border: 1px solid ${({ theme }) => theme.color.text.fade};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: space-between;
  margin: 0 0 32px;
  min-height: 100px;
  overflow: hidden;
  padding: 16px 32px;
  width: ${({ width }) => width || '100%'};
  @media (max-width: 768px) {
    align-items: flex-start;
    flex-direction: column;
  }
`

const ProgressText = styled.p`
  color: #747474;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 4px;
`

const ProgressBarContainer = styled.div`
  background: white;
  border-radius: 4px;
  height: 8px;
  margin-bottom: 20px;
  width: 400px;
`

const ProgressBar = styled.div`
  background: #ffc043;
  border-radius: 4px;
  height: 100%;
  width: ${({ percentComplete }) => `${percentComplete}%`};
`

const NotificationContainer = styled.div`
  display: flex;
  height: 60px;
  justify-content: space-between;
`

const NotificationContentContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  min-width: 60%;
  overflow: hidden;
  padding-right: 16px;
  width: auto;
  @media (max-width: 768px) {
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 20px;
  }
`

const IconSet = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0 16px 0 0;
  min-width: 50px;
  width: auto;
  img {
    height: auto;
    width: 100%;
  }
`

const CopyContainer = styled.div`
  display: block;
  margin: 0;
  text-overflow: ellipsis;
  white-space: no-wrap;
`

const NotificationHeader = styled.h2`
  font-size: 16px;
  letter-spacing: 0.15px;
  line-height: 24px;
`

const ProfileURLSpan = styled.span`
  color: ${({ theme }) => theme.color.primary.yellow5};
  font-family: Roboto-Black, sans-serif;
  font-size: 16px;
  font-weight: ${({ theme }) => theme.font.weight.black};
  left: 4px;
  letter-spacing: 1px;
  position: relative;
  text-transform: lowercase;
  top: -1px;
`

const NotificationText = styled.p`
  font-size: 14px;
  letter-spacing: 0.25px;
  line-height: 17px;
`

const NotificationActions = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  width: auto;
`

const DismissalSpan = styled.span`
  color: ${({ theme }) => theme.color.primary.black5};
  cursor: pointer;
  font-size: 12px;
  font-weight: ${({ theme }) => theme.font.weight.black};
  letter-spacing: 1px;
  line-height: 16px;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  &:after {
    content: '';
    border-bottom: 1px solid ${({ theme }) => theme.color.primary.black5};
    bottom: 0;
    left: 0;
    position: absolute;
    transform: scaleX(1);
    transform-origin: center;
    transition: all 300ms ease-out;
    width: 100%;
  }
  &:hover {
    color: ${({ theme }) => theme.color.primary.yellow5};
    &:after {
      transform: scaleX(0);
    }
  }
  @media (max-width: 768px) {
    margin-left: 0;
  }
`

const ActionButton = styled(Button)`
  background: ${({ theme }) => theme.color.primary.black4};
  border-color: ${({ theme, bgColor }) =>
    bgColor || theme.color.primary.black4};
  color: #fff;
  margin: 0 0 0 16px;
`
