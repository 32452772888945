import React from 'react'

const groupIcon = (
  <svg
    width="19px"
    height="16px"
    viewBox="0 0 19 16"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      opacity="0.5"
    >
      <g
        transform="translate(-398.000000, -244.000000)"
        fill="#000000"
        fillRule="nonzero"
      >
        <g transform="translate(153.000000, 165.000000)">
          <g transform="translate(112.000000, 5.000000)">
            <g transform="translate(133.000000, 73.938272)">
              <g transform="translate(0.000000, 0.061728)">
                <path
                  d="M16.0766972,8.63559633 L14.623633,8.63559633 
                C14.7717431,9.04110092 14.8526606,9.4787156 14.8526606,
                9.93482569 L14.8526606,15.4265688 C14.8526606,15.6167339 
                14.8195596,15.7992661 14.7593394,15.9690275 L17.161578,
                15.9690275 C18.0589358,15.9690275 18.7889541,15.2389725 
                18.7889541,14.3416514 L18.7889541,11.3478532 C18.7889541,
                9.85233028 17.5722569,8.63559633 16.0766972,8.63559633 Z"
                ></path>
                <path
                  d="M3.93633028,9.93486239 C3.93633028,9.4787156 
                4.01724771,9.04113761 4.1653578,8.63563303 L2.71229358,
                8.63563303 C1.21673394,8.63563303 0,9.85236697 0,11.3479266 
                L0,14.3417248 C0,15.2390459 0.730018349,15.9691009 1.62737615,
                15.9691009 L4.02965138,15.9691009 C3.96943119,15.7992661 
                3.93633028,15.6167706 3.93633028,15.4266055 L3.93633028,
                9.93486239 Z"
                ></path>
                <path
                  d="M11.0554495,7.22256881 L7.73354128,7.22256881
                 C6.23798165,7.22256881 5.02124771,8.43930275 5.02124771,
                 9.93486239 L5.02124771,15.4266055 C5.02124771,15.7261651 
                 5.26411009,15.9690642 5.56370642,15.9690642 L13.2252844,
                 15.9690642 C13.5248807,15.9690642 13.7677431,15.7262018 
                 13.7677431,15.4266055 L13.7677431,9.93486239 C13.7677431,
                 8.43930275 12.5510092,7.22256881 11.0554495,7.22256881 Z"
                ></path>
                <path
                  d="M9.39449541,0.0308256881 C7.59588991,0.0308256881 
                6.13262385,1.49409174 6.13262385,3.29273394 C6.13262385,
                4.51273394 6.80598165,5.57823853 7.80040367,6.13765138 
                C8.27207339,6.40297248 8.81585321,6.5546055 9.39449541,
                6.5546055 C9.97313761,6.5546055 10.5169174,6.40297248 
                10.9885872,6.13765138 C11.9830459,5.57823853 12.656367,
                4.51269725 12.656367,3.29273394 C12.656367,1.49412844 
                11.1931009,0.0308256881 9.39449541,0.0308256881 Z"
                ></path>
                <path
                  d="M3.6667156,3.07115596 C2.32157798,3.07115596 
                1.22726606,4.16546789 1.22726606,5.5106055 C1.22726606,
                6.85574312 2.32157798,7.95005505 3.6667156,7.95005505 
                C4.00792661,7.95005505 4.33284404,7.87937615 4.628,7.75233028 
                C5.13831193,7.53262385 5.55908257,7.14370642 5.81944954,6.65655046 
                C6.00220183,6.3146422 6.10616514,5.92458716 6.10616514,5.5106055 
                C6.10616514,4.16550459 5.01185321,3.07115596 3.6667156,3.07115596 Z"
                ></path>
                <path
                  d="M15.1222752,3.07115596 C13.7771376,3.07115596 
                12.6828257,4.16546789 12.6828257,5.5106055 C12.6828257,
                5.92462385 12.786789,6.3146789 12.9695413,6.65655046 
                C13.2299083,7.14374312 13.6506789,7.53266055 14.1609908,7.75233028 
                C14.4561468,7.87937615 14.7810642,7.95005505 15.1222752,7.95005505 
                C16.4674128,7.95005505 17.5617248,6.85574312 17.5617248,5.5106055 
                C17.5617248,4.16546789 16.4674128,3.07115596 15.1222752,3.07115596 Z"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default groupIcon
