export const underline = ({ theme, borderColor }) =>
  `
    &::after {
      border-bottom: 2px solid ${borderColor || theme.color.text.link};
      bottom: -4px;
      content: '';
      left: 0;
      position: absolute;
      transform: scaleX(0);
      transform-origin: center;
      transition: all 300ms ease-out;
      width: 100%;
    }
    &:hover {
    &::after {
        transform: scaleX(1);
    }
}`

export const overlay = ({ theme, bgColor }) =>
  `
    &::after {
      background: ${bgColor || theme.color.bg.dark};
      border-radius: 8px;
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      transform-origin: center;
      transition: all 300ms ease-out;
      width: 100%;
      opacity: 0;
      pointer-events: none;
    }
    &:hover {
    &::after {
      opacity: 0.6;
    }
}`
